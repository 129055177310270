@import "utils";
@import "../../admin/newComponents/setting/billing/PaymentModal.scss";

$font-family: 'Open Sans', sans-serif;
$font-weight-bold: bold;
$font-weight-regular: 400;
$font-weight-light: 200;

.modal-edit-dealer, .modal-setup-dealer {
  .help-block {
    color: #a94442 !important;
    font-weight: 500 !important;
    font-size: 11px !important;
  }

  .has-error {
    border-color: #a94442;
    box-shadow: none;
  }

  @include item-center;
  display: flex !important;

  .modal-dialog {
    height: 320px;
    width: 1000px;
    margin: 30px auto;
  }

  .modal-content {
    @include col100;
    border-radius: 0;
  }

  .modal-body {
    min-height: 320px;
    float: left;
    padding: 0;
    width: 100%;
    position: relative;
    @include item-center;

    .panel-content {
      float: left;
      width: 100%;
      background-color: #ffffff;
      height: 320px;
      align-items: center;
      justify-content: center;

      .icon-close-content {
        position: absolute;
        top: -15px;
        right: -15px;
      }

      .close-modal-icon {
        width: 32px;
        position: absolute;
        top: -18px;
        right: -18px;

        &:hover {
          cursor: pointer;
        }
      }

      .icon-close {
        width: 32px;
        height: 32px;
        fill: #ffffff;
        background-color: #ff565e;
        border-radius: 50%;
      }

      .icon-close:hover {
        cursor: pointer;
      }

      .panel-top {
        height: 10%;
        padding: 15px 0 0 15px;
        width: 100%;

        .label-name {
          font-size: 15px;
          color: #787878;
          font-weight: $font-weight-bold;
        }
      }

      .panel-bottom {
        height: 90%;
        padding: 5px;
        width: 100%;

        .panel-left-edit {
          float: left;
          width: 30%;
          height: 100%;
          overflow: hidden;
          padding: 5px 5px 5px 10px;

          .logo-dealer-content {
            float: left;
            width: 100%;
            border: 1px solid #EFEFEF;
            height: 80px;
            margin-top: 5px;
            align-items: center;
            justify-content: center;
            display: flex;
            border-radius: 13px;

            .img-customer {
              cursor: pointer;
              border-radius: 13px;

              > div {
                @include item-center;

                svg {
                  height: 45px;
                  width: 45px;
                  fill: blue;
                }
              }
            }

            .img-dealer {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }

          .container-icon-image {
            float: right;
            display: flex;

            .icon-image-content {
              float: left;
              padding: 10px;
            }

            .icon-image-content:hover {
              cursor: pointer;
            }
          }

          .icon-image {
            width: 14px;
            height: 14px;
            fill: #D7DAE2;
          }

          label {
            font-size: 12px;
            color: #787878;
            float: left;
            font-weight: $font-weight-bold;
            width: 100%;
          }

          .row-icon {
            float: left;
            width: 100%;

            .description-icon {
              font-size: 11px;

              .drop-zone-element {
                display: inline-block;
              }

              .upload-icon {
                cursor: pointer;
              }
            }

            .sub-description-icon {
              width: 70%;
              text-align: justify;
              font-size: 11px;
              float: left;
            }

            .col-icon-browser {
              float: right;
              width: 30%;

              .icon-image {
                width: 60px;
                height: 60px;
                float: right;
              }

              .browser-icon {
                float: right;
                margin-top: 5px;
              }

              .logo-action-content {
                width: 16px;
                height: 16px;
                float: right;
                margin-left: 15px;
              }
            }
          }
        }

        @mixin container-select() {
          width: 25%;
          padding-right: 10px;
          float: left;
          label {
            font-size: 13px;
            color: #787878;
            float: left;
            font-weight: $font-weight-regular;
          }
          .select {
            width: 100%;
            float: left;
            border-radius: 4px;
            border: 1px solid #D7DAE2;
            display: flex;
            justify-content: center;
            height: 34px;
            align-items: center;
            position: relative;

            .container-label {
              width: 75%;
              float: left;
              padding-left: 12px;
              position: absolute;
              left: 0;

              label {
                margin-bottom: 0;
                font-size: 12px;
                color: #787878;
                font-weight: 500;
                float: left;
              }
            }

            .container-icon {
              right: 10px;
              position: absolute;

              i {
                color: #A4AFB7;
                font-size: smaller;
              }
            }

            .icon-arrow-content {
              position: absolute;
              right: 2px;
              top: 4px;
            }

            .icon-arrow-expand {
              height: 8px;
              fill: #A4AFB7;
            }

            .icon-arrow-content:hover {
              cursor: pointer;
            }
          }
        }

        .container-status {
          @include container-select;
          width: 25%;
        }

        .container-select {
          @include container-select;
        }

        .panel-right-edit {
          width: 70%;
          height: 100%;
          text-align: center;
          float: right;
          padding: 5px;

          .colR27, .colR16, .colR12, .colR40, .colR25, .colR50 {
            label {
              font-size: 13px;
              color: #787878;
              float: left;
              font-weight: $font-weight-regular;
              font-family: $font-family;
            }

            .label-error {
              color: red;
              padding-left: 2px;
              font-weight: $font-weight-light;
            }

            p {
              float: left;
              font-size: 12px;
              color: #787878;
              font-weight: 700;
            }

            span {
              color: red;
              padding-left: 2px;
            }
          }

          .colR27 {
            float: left;
            padding-right: 10px;
            width: 27.5%;
          }

          .colR16 {
            float: left;
            padding-right: 10px;
            width: 16%;
          }

          .colR12 {
            float: left;
            padding-right: 10px;
            width: 12%;
          }

          .colR40 {
            float: left;
            padding-right: 10px;
            width: 40%;
          }

          .colR25 {
            float: left;
            padding-right: 10px;
            width: 25%;
          }

          .first-row {
            float: right;

            .row-edit {
              padding: 5px 0 10px 0;
              display: flex;
            }
          }

          .second-row {
            float: left;
            padding: 10px 10px 0 0;
            width: 100%;
            position: relative;

            .container-title-contact {
              display: flex;

              .title-contact {
                font-size: 15px;
                color: #787878;
                float: left;
              }
            }

            .container-button {
              float: right;
              position: absolute;
              right: 15px;
              bottom: 0;

              .btn-save {
                height: 36px;
                width: 80px;
                border-radius: 5px;
                background-color: #2194F1;
                border: 1px solid #D7DAE2;
                color: #ffffff;
                font-family: $font-family;
                font-weight: $font-weight-regular;
                font-size: 13px;
              }

              .btn-skip {
                height: 36px;
                width: 80px;
                background-color: transparent;
                border: none;
                color: #707070;
                font-weight: $font-weight-regular;
                font-family: $font-family;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }

  .btn-save {
    height: 36px;
    border-radius: 5px;
    background-color: #00de8a;
    border: 1px solid #00de8a;
    color: #ffffff;
    font-family: $font-family;
    font-weight: $font-weight-regular;
    font-size: 13px;
  }
}

.modal-add-dealer, .modal-add-account-owner, .modal-select-tier, .modal-add-payment-method {
  .help-block {
    color: #a94442 !important;
    font-weight: 500 !important;
    font-size: 12px !important;
  }

  .has-error {
    border-color: #a94442;
    box-shadow: none;
  }

  @include item-center;
  display: flex !important;

  .modal-dialog {
    width: 1000px;
    margin: 30px auto;
  }

  .modal-content {
    @include col100;
    border-radius: 10px;
  }

  .modal-body {
    float: left;
    padding: 0;
    width: 100%;
    position: relative;
    @include item-center;

    .loader-container {
      width: 94%;
      height: 90%;
      @include item-center;
      position: absolute;
      z-index: 10;

      .loader {
        border: 7px solid #f3f3f3; /* Light grey */
        border-top: 7px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 64px;
        height: 64px;
        animation: adjust-hue 1s linear infinite;
      }

      @keyframes adjust-hue {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .panel-content {
      float: left;
      width: 100%;
      background-color: #ffffff;
      height: 560px;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      .icon-close-content {
        position: absolute;
        top: -15px;
        right: -15px;
      }

      .close-modal-icon {
        width: 32px;
        position: absolute;
        top: -18px;
        right: -18px;

        &:hover {
          cursor: pointer;
        }
      }

      .icon-close {
        width: 32px;
        height: 32px;
        fill: #ffffff;
        background-color: #ff565e;
        border-radius: 50%;
      }

      .icon-close:hover {
        cursor: pointer;
      }

      .panel-top {
        height: 40px;
        padding: 15px 0 0 20px;
        width: 100%;
        display: flex;
        border-bottom: 1px solid #D7DAE2;

        .panel-top-left {
          width: 20%;

          label {
            font-weight: $font-weight-bold;
            font-size: 14px;
            color: #787878;
          }
        }

        .panel-top-right {
          width: 80%;
          display: flex;

          .title, .title-account {
            height: 100%;
          }

          .title-account {
            margin-left: 10px;
          }

          .label-name {
            font-size: 14px;
            color: #787878;
            font-weight: $font-weight-bold;
          }

          .label-selected {
            font-size: 14px;
            color: #2194F1;
            font-weight: $font-weight-bold;
          }
        }

      }

      .panel-bottom {
        height: 90%;
        padding: 5px;
        width: 100%;

        .panel-left {
          float: left;
          width: 25%;
          height: 100%;
          overflow: hidden;
          padding: 10px 5px 5px 20px;

          .icon-image {
            width: 14px;
            height: 14px;
            fill: #D7DAE2;
          }

          label {
            font-size: 12px;
            color: #787878;
            float: left;
            font-weight: $font-weight-bold;
            width: 100%;
          }

          .new-dealer-photo-container, .new-account-owner-photo-container {
            width: 100%;
            height: 130px;
            padding-bottom: 20px;
            padding-top: 30px;

            .new-dealer-photo, .new-account-owner-photo {
              width: 100%;
              height: 102px;
              border-radius: 13px;
              background: #2194F1;
              @include item-center;
              border: 1px solid #FFF;

              .img-dealer {
                cursor: pointer;

                > div {
                  @include item-center;

                  svg {
                    height: 45px;
                    width: 45px;
                    fill: #ffffff;
                  }
                }
              }

              .image-dealer {
                width: 100%;
                height: 102px;
                border-radius: 13px;
                padding: 0;
                object-fit: contain;
              }
            }

            .new-account-owner-photo {
              width: 102px;
              height: 102px;
              border-radius: 50%;

              .image-dealer {
                width: 102px;
                border-radius: 45px;
              }
            }
          }

          .new-account-owner-photo-container {
            padding-top: 15px;
            height: 155px;
            @include item-center;
          }

          .container-button {
            .back {
              width: 67px;
              height: 32px;
              border-radius: 4px;
              text-align: center;
              float: left;
              color: #fff;
              background-color: #2194F1;
              cursor: pointer;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .img-back {
              width: 10px;
              margin: 0 5px 0 0;

              > div {
                svg {
                  fill: #ffffff;
                }
              }
            }
          }

          .container-icon-image {
            float: right;
            display: flex;

            .icon-image-content {
              float: left;
              padding: 10px;
            }

            .icon-image-content:hover {
              cursor: pointer;
            }
          }

          .row-icon {
            float: left;
            width: 100%;

            .description-icon {
              font-size: 11px;

              .drop-zone-element {
                display: inline-block;
              }

              .upload-icon {
                cursor: pointer;
              }
            }

            .sub-description-icon {
              width: 65%;
              text-align: justify;
              font-size: 11px;
              float: left;
            }

            .col-icon-browser {
              float: right;
              width: 35%;

              .icon-image {
                width: 60px;
                height: 60px;
                float: right;
              }

              .browser-icon {
                float: right;
                margin-top: 5px;
              }

              .logo-action-content {
                width: 16px;
                height: 16px;
                float: right;
                margin-left: 15px;
              }
            }
          }
        }

        .container-select, .container-status {
          width: 25%;
          padding-left: 10px;
          float: left;

          label {
            font-size: 13px;
            color: #787878;
            float: left;
            margin: 10px 0 8px 0;
            font-weight: $font-weight-regular;
          }

          .select {
            width: 100%;
            float: left;
            border-radius: 4px;
            border: 1px solid #D7DAE2;
            display: flex;
            justify-content: center;
            height: 32px;
            align-items: center;
            position: relative;

            .container-label {
              width: 75%;
              float: left;
              padding-left: 12px;
              position: absolute;
              left: 0;

              label {
                margin: 0;
                font-size: 13px;
                color: #787878;
                font-weight: $font-weight-regular;
                float: left;
              }
            }

            .container-icon {
              right: 10px;
              position: absolute;

              i {
                color: #A4AFB7;
                font-size: smaller;
              }
            }

            .icon-arrow-content {
              position: absolute;
              right: 2px;
              top: 4px;
            }

            .icon-arrow-expand {
              height: 8px;
              fill: #A4AFB7;
            }

            .icon-arrow-content:hover {
              cursor: pointer;
            }
          }
        }

        .panel-right {
          width: 75%;
          float: left;
          padding-right: 20px;

          .new-dealer-form, .new-admin-form {
            .flex-row, .flex-row-domain, .content-dealer-information {
              .flex-colR1 {
                padding-right: 0;
                padding-left: 10px;
              }

              .item {
                margin-top: 10px;

                span {
                  font-size: 13px;
                  color: #787878;
                  font-weight: $font-weight-regular;
                }

                .label-error {
                  color: $button-color-red;
                  padding-left: 2px;
                }

                input {
                  margin-top: 8px;
                  height: 32px;
                  font-weight: $font-weight-regular;
                }

                .error-message {
                  font-size: 10px;
                  color: $button-color-red;
                }

                .available-message {
                  font-size: 10px;
                  color: $green-color;
                }

                .container-input {
                  position: relative;
                  border: none;

                  .domain {
                    position: absolute;
                    top: 2px;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 28px;
                    background: #FFF;
                    margin-right: 10px;
                    font-size: 14px;
                    color: #cdd3d8;
                  }

                  input {
                    webkit-box-shadow: none;
                    box-shadow: none;

                    &:focus {
                      outline: none !important;
                      //border-color: #ccc;
                    }
                  }
                }
              }

              .container-title-contact {
                padding: 10px 0 0 10px;

                .title-contact {
                  font-size: 15px;
                  float: left;
                  font-weight: $font-weight-bold;
                  color: #787878;
                }
              }
            }

            .flex-row-domain {
              width: 50%;
            }

            .content-dealer-information {
              display: flex;

              .content-phone {
                width: 25%;
              }

              .content-email {
                width: 50%;
                border-bottom: none;
              }
            }
          }

          .container-button {
            .save-new-dealer, .next {
              width: 67px;
              height: 32px;
              border-radius: 4px;
              padding: 4px 0;
              text-align: center;
              float: right;
              margin: 14px 0;
              color: #fff;
              background-color: #4AD991;
              cursor: pointer;
              border: none;
              font-size: 13px;
              font-weight: $font-weight-regular;
              font-family: $font-family;
            }

            .next {
              background-color: #2194F1;
            }

            .disabled {
              background-color: $icon-arrow-color;
              color: #FFF;
              cursor: unset;
            }

            .save-new-dealer {
              margin: 26px 0;
            }
          }
        }
      }

      .panel-plans-container {
        margin: 20px;
        display: flex;
        flex-direction: column;

        .panel-plans-body {
          display: flex;
          justify-content: space-around;

          .plan-container {
            display: flex;
            width: 25%;
            flex-direction: column;
            border: 1px solid #D7DAE2;
            padding: 17px;
            margin: 2px;

            .plan-title-section {
              display: flex;
              margin-bottom: 5px;

              .plan-title-text {
                font-size: 15px;
                font-weight: bold;
              }

              .plan-select-link {
                margin-left: auto;
                color: #A4AFB7;

                &:hover {
                  color: #2194F1;
                  cursor: pointer;
                }
              }

              .plan-selected {
                color: #2194F1;
                cursor: default;
              }
            }

            .plan-body-section {
              display: flex;
              flex-direction: column;
              border-top: 2px solid #D7DAE2;
              border-bottom: 2px solid #D7DAE2;
              padding: 10px;

              .plan-price-container {
                display: flex;
                height: 40px;
                font-weight: bold;

                .plan-price-currency {
                  font-size: 16px;
                }

                .plan-price-amount {
                  font-size: 42px;
                  align-self: center;
                }

                .plan-price-periodicity {
                  font-size: 10px;
                  align-self: end;
                }
              }

              .plan-price-comment {
                font-size: 6px;
                color: #D7DAE2;
                margin-top: 10px;
              }
            }

            .plan-features-section {
              display: flex;
              flex-direction: column;
              margin-top: 10px;
              font-size: 9px;
              color: #4D4F5C;
            }
          }
        }

        .container-plan-buttons {
          display: flex;
          margin-top: 10px;

          .back {
            width: 67px;
            height: 32px;
            border-radius: 4px;
            text-align: center;
            float: left;
            color: #fff;
            background-color: #2194F1;
            cursor: pointer;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .img-back {
            width: 10px;
            margin: 0 5px 0 0;

            > div {
              svg {
                fill: #ffffff;
              }
            }
          }

          .save-new-dealer, .next, .next-disabled {
            margin-left: auto;
            width: 67px;
            height: 32px;
            border-radius: 4px;
            text-align: center;
            color: #fff;
            background-color: #4AD991;
            cursor: pointer;
            border: none;
            font-size: 13px;
            font-weight: $font-weight-regular;
            font-family: $font-family;
          }

          .next {
            background-color: #2194F1;
          }

          .next-disabled {
            background-color: #BCBCCB;
          }
        }
      }

      .panel-payment-method-container {
        display: flex;
        flex-direction: column;

        .col-md-6, .col-md-10 {
          padding-left: 0px;
        }

        .bnt-save, .btn-skip, .btn-skip-create {
          width: 67px;
          height: 32px;
          border-radius: 4px;
          padding: 4px 0;
          text-align: center;
          float: right;
          color: #fff;
          background-color: #4AD991;
          cursor: pointer;
          border: none;
          font-size: 13px;
          font-weight: $font-weight-regular;
          font-family: $font-family;
        }

        .btn-skip {
          background-color: #2194F1;
          float: none;
        }

        .btn-skip-create {
          margin-right: 10px;
          width: 120px;
          background-color: transparent;
          color: #A4AFB7;

          &:hover {
            color: #2194F1;
          }
        }

        .back {
          width: 67px;
          height: 32px;
          border-radius: 4px;
          text-align: center;
          float: left;
          color: #fff;
          background-color: #2194F1;
          cursor: pointer;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .img-back {
          width: 10px;
          margin: 0 5px 0 0;

          > div {
            // padding: 0px 30px;
            svg {
              fill: #ffffff;
            }
          }
        }

        .intro-message {
          // padding: 0px 30px;
          font-size: 11px;
        }
      }
    }
  }
}

.modal-add-account-owner {
  .modal-dialog {
    min-height: 300px;
  }

  .modal-body {
    min-height: 300px;

    .panel-content {
      height: 300px;

      .panel-bottom {
        .panel-left {
          width: 20%;
        }

        .panel-right {
          width: 80%;
        }
      }
    }
  }
}

.modal-select-tier.new-subscription {
  .close-modal-icon {
    .injected-svg {
      background: white;
      border-radius: 50%;
    }
  }
}

.modal-select-tier {
  .modal-dialog {
    min-height: 420px;
    height: auto;
  }

  .payment-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .close-modal-icon {
      background: red;
    }

    .btn-add-new-card {
      border: 1px solid #D7DAE2;
      cursor: pointer;
      border-radius: 5px;
      height: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      justify-content: center;
      align-items: center;

      &:hover {
        border: 1px solid $light-blue-color-1;
      }
    }

    .creditCard-container {
      width: 100%;
      padding: 9%;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: center;
      min-width: 620px;

      @include payment-method-style;
    }

    ::-webkit-scrollbar {
      width: 7px;
    }

    ::-webkit-scrollbar-track {
      background: #EFEFEF;
      border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
      background: #A4AFB7;
      border-radius: 2px;
    }

    .column-container {
      width: 30%;
      height: 296px;
      margin: 19px 19px 19px 40px;
      overflow: auto;
      display: flex;

      .creditCard-container {
        padding: 0;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin-right: 19px;
        min-width: 0;

        .btn-add-new-card {
          border: 1px solid $light-blue-color-1;
          padding: 7px;
          max-height: 36px;
        }

        .payment-method-item-container {
          width: 100%;

          .payment-method-item {
            margin-bottom: 0;
            padding: 7px;
          }
        }
      }
    }

    .panel-payment-method-container {
      button {
        position: absolute;
        bottom: 19px;
        right: 41px;
        width: 95px;
      }
    }
  }

  .modal-body {
    min-height: 420px;
    height: auto;

    .panel-content {
      min-height: 420px;
      height: auto;
    }
  }

  .manual-goBack-btn-modal {
    background-color: #2194F1;
    border: none;
    position: absolute;
    left: 40px;
    bottom: 19px;
  }

  .payment-btn {
    left: auto;
    bottom: auto;
    bottom: 19px;
    right: 40px;
    background-color: #4AD991;
  }
}

.modal.modal-add-payment-method.dashboard-update {
  .modal-dialog {
    .modal-content {
      padding: 20px;
    }
  }
}

.modal.modal-add-payment-method.dashboard-update {
  .modal-dialog {
    .form-row {
      .form-group.col-md-6, .col-md-10 {
        padding-left: 0;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .modal.modal-add-payment-method.dashboard-update {
    .modal-dialog {
      .close-modal-icon {
        top: 5px;
        right: 5px;
      }

      .form-row {
        .form-group {
          padding: 0;
        }
      }
    }
  }
}

.modal-add-payment-method {
  .modal-dialog {
    min-height: 340px;
    height: auto;
  }

  .modal-body {
    min-height: 340px;
    height: auto;

    .panel-content {
      min-height: 340px;
      height: auto;
    }
  }

  .panel-payment-method-container{
    margin: 20px 40px;
    .btn-save{
      min-width: 67px;
      padding: 4px 8px;
      width: auto;
    }
  }
}

.modal-add-dealer {
  .modal-dialog {
    height: 500px;
    width: 1000px;

    .panel-content {
      height: 100%;

      .panel-top {
        height: 40px;
      }

      .panel-bottom {
        height: 460px;
      }
    }
  }
}

.modal-add-account-owner {
  .modal-dialog {
    .panel-content {
      .panel-right {
        .container-message-error {
          padding-top: 15px;
          padding-left: 10px;
          font-size: 13px;
          color: red;
          width: 250px;
          float: left;
          //text-align: center;
        }
      }
    }
  }
}

//DealerInfo/index.jsx maybe???
.dealer-content {
  .container-header {
    display: flex;
    background: white;
    position: fixed;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
    height: 56px;
    z-index: 5;
    left: 50px;
    right: 0;
    transition: all 0.3s ease;
    align-items: center;

    .arrow-back {
      padding-left: 40px;
      float: left;

      svg {
        width: 16px;
        fill: #2194F1;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .dealer-title {
      font-size: 20px;
      float: left;
      background-color: #fff;
      border-color: #dddee0;
      border-radius: 5px;
      font-weight: 700;
      color: #787878;
      @include item-center;
      justify-content: flex-start;
      width: 125px;
      padding: 0 21px 4px 50px;

      span {
        font-size: 14px;
        color: #6d6e72;
        padding: 0 10px;
        font-weight: $font-weight-regular;
      }
    }

    .dealer-name {
      font-size: 18px;
      color: #787878;
      margin: 0 12px 3px 12px;
      font-weight: $font-weight-bold;
    }

    .template-name {
      font-size: 18px;
      color: #787878;
      margin: 0 12px 3px 12px;
      font-weight: $font-weight-bold;
    }

    .container-button {
      right: 60px;
      position: absolute;

      .btn-save {
        height: 34px;
        width: 82px;
        color: white;
        border: 1px solid #D7DAE2;
        border-radius: 4px;
        background-color: #4AD991;
      }
    }
  }

  .container-center {
    padding: 90px 50px 0 50px;

    .help-block {
      color: #a94442 !important;
      font-weight: 500 !important;
      font-size: 12px !important;
    }

    .setting-container {
      display: block;

      .template-section, .template-section-body {
        background: #fff;
        float: left;
        width: 100%;
        padding: 20px 50px;
        border-bottom: 1px solid #efefef;
        display: block;
      }

      .accordion-section {
        //background: white;
        float: left;
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid #EFEFEF;
        display: block;

        .section-title {
          padding-left: 40px;
          padding-bottom: 20px;
          display: flex;
          align-items: center;
          width: 100%;

          .section-title-label {
            padding: 0 0 0 15px;
            margin: 0;
            font-weight: $font-weight-bold;
            font-size: 18px;
            color: #787878;
            font-family: $font-family;
          }

          .icon-add-file-template {
            float: left;
            padding-left: 10px;

            div {
              height: 100%;
              display: flex;
            }

            .icon-add-svg {
              width: 15px;
              height: 24px;
              cursor: pointer;
              fill: $light-blue-color-1;
            }
          }
        }

        .section-body {
          padding: 0 40px;

          .plan-container {
            display: flex;

            #graphs {
              max-width: 332px;
              grid-area: report;
              display: flex;
              flex-direction: column;
              padding: 11px 20px;

              .subsection-header-container {
                display: flex;
                flex-direction: row;
                align-content: center;
                margin-bottom: 17px;

                .header-text {
                  font-size: 21px;
                }

                .button-container {
                  margin-left: auto;
                  display: flex;
                  align-items: center;
                }
              }

              .btn-theme {
                background-color: #2194f1;
                color: #fff;
                border-radius: 5px;
                height: 32px;
                border: 0 !important;
                font-size: 13px;
                width: 73px;
              }

              .subsection-container {
                margin-bottom: 17px;
              }

              .subsection-header {
                font-size: 9px;
                color: #787878;
              }

              .subsection-text {
                font-size: 13px;
              }

              .subsection-credit-card-info {
                display: flex;
                align-items: center;
              }

              .subsection-link {
                font-size: 13px;
                color: #2194f1;
                cursor: pointer;
              }

              .icon-credit-card-logo {
                width: 44px;
                height: 19px;
              }
            }

            .current-features-section {
              margin-left: 20px;
              width: 100%;
              height: 100%;
              box-shadow: 0 0 15px #00000012;
              display: flex;
              flex-direction: column;
              padding: 11px 20px;
              max-width: 332px;
              border: 1px solid #CACED5;
              border-radius: 12px;

              .current-due-header-information {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .header-text {
                  font-size: 21px;
                  font-weight: bold;
                }

                .header-button {
                  color: #2194F1;
                  cursor: pointer;
                }
              }

              .included-item-container, .not-included-item-container {
                display: flex;
                align-items: center;

                .progress-bar-label {
                  font-size: 9px;
                  color: #787878;
                  padding-right: 5px;
                  margin-left: auto;
                }

                .progress-bar-container {
                  width: 60px;
                }

                svg {
                  width: 13px;
                  fill: #4AD991;
                  margin-right: 10px;
                  height: fit-content;
                }

                .icon {
                  font-size: 17px;
                  margin-left: 2px;
                  margin-right: 10px;
                  color: #D7DAE2;
                }

                .message-text {
                  font-size: 13px;
                }
              }

              .not-included-item-container {
                .message-text {
                  color: #D7DAE2;
                }
              }
            }
          }
        }

        //should we move this outside
        input {
          color: #2194F1 !important;
          font-size: 13px;
          font-weight: $font-weight-regular;
        }
      }

      .dealer-info {
        .panel-bottom {
          height: 90%;
          padding: 5px;
          width: 100%;

          .panel-left-edit {
            float: left;
            width: 30%;
            height: 100%;
            overflow: hidden;
            padding: 5px 5px 5px 0;

            .logo-dealer-content {
              float: left;
              width: 100%;
              border: 1px solid #EFEFEF;
              height: 140px;
              margin-top: 5px;
              align-items: center;
              justify-content: center;
              display: flex;
              border-radius: 13px;

              .img-customer {
                cursor: pointer;
                border-radius: 13px;

                > div {
                  @include item-center;

                  svg {
                    height: 45px;
                    width: 45px;
                    fill: blue;
                  }
                }
              }

              .img-dealer {
                object-fit: contain;
                width: 100%;
                height: 100%;
              }
            }

            .container-icon-image {
              float: right;
              display: flex;

              .icon-image-content {
                float: left;
                padding: 10px;
              }

              .icon-image-content:hover {
                cursor: pointer;
              }
            }

            .icon-image {
              width: 14px;
              height: 14px;
              fill: #D7DAE2;
            }

            label {
              font-size: 12px;
              color: #787878;
              float: left;
              font-weight: $font-weight-light;
            }
          }

          .container-select, .container-status {
            //width: 25%;
            //padding-right: 10px;
            //float: left;
            //label{
            //  font-size: 13px;
            //  color: #787878;
            //  float: left;
            //  font-weight: $font-weight-regular;
            //}
            //.select{
            //  width: 100%;
            //  float: left;
            //  border-radius: 4px;
            //  border: 1px solid #D7DAE2;
            //  display: flex;
            //  justify-content: center;
            //  height: 34px;
            //  align-items: center;
            //  position: relative;
            //  .container-label{
            //    width: 75%;
            //    float: left;
            //    padding-left: 12px;
            //    position: absolute;
            //    left: 0;
            //    label{
            //      margin-bottom: 0;
            //      font-size: 13px;
            //      color: #2194F1;
            //      font-weight: $font-weight-regular;
            //      float: left;
            //    }
            //  }
            //  .container-icon{
            //    right: 10px;
            //    position: absolute;
            //    i{
            //      color: #A4AFB7;
            //      font-size: smaller;
            //    }
            //  }
            //  .icon-arrow-content{
            //    position: absolute;
            //    right: 2px;
            //    top: 4px;
            //  }
            //  .icon-arrow-expand {
            //    height: 8px;
            //    fill: #A4AFB7;
            //  }
            //  .icon-arrow-content:hover{
            //    cursor: pointer;
            //  }
            //}
          }

          .panel-right-edit {
            width: 70%;
            height: 100%;
            text-align: center;
            float: right;
            padding: 5px 0 5px 10px;

            .colR27, .colR16, .colR13, .colR43, .container-subdomain, .colR25, .colR50 {
              label {
                font-size: 13px;
                color: #787878;
                float: left;
                font-weight: $font-weight-regular;
                font-family: $font-family;
              }

              .label-error {
                color: red;
                padding-left: 2px;
              }

              p {
                float: left;
                font-size: 12px;
                color: #787878;
                font-weight: 700;
              }

              span {
                color: red;
                padding-left: 2px;
                font-weight: $font-weight-light;
              }
            }

            .colR27 {
              float: left;
              padding-right: 10px;
              width: 27.5%;
            }

            .colR16 {
              float: left;
              padding-right: 10px;
              width: 16%;
            }

            .colR13 {
              float: left;
              padding-right: 10px;
              width: 13%;
            }

            .colR43 {
              float: left;
              padding-right: 10px;
              width: 43.5%;
            }

            .container-subdomain {
              float: left;
              padding-right: 10px;
              width: 50%;
            }

            .first-row {
              float: left;
              width: 100%;

              .row-edit {
                padding: 5px 0 10px 0;
                display: flex;
              }
            }

            .second-row {
              float: left;
              padding: 10px 0 0 0;
              width: 100%;
              position: relative;

              .container-title-contact {
                display: flex;

                .title-contact {
                  font-size: 15px;
                  color: #787878;
                  float: left;
                }
              }

              .container-button {
                float: right;
                position: absolute;
                right: 0;
                bottom: 0;
                padding-right: 10px;

                .btn-save {
                  height: 36px;
                  width: 80px;
                  border-radius: 5px;
                  background-color: #2194F1;
                  border: 1px solid #D7DAE2;
                  color: #ffffff;
                  font-family: $font-family;
                  font-weight: $font-weight-regular;
                  font-size: 13px;
                }
              }
            }
          }

          //.container-status, .container-select{
          //  label{
          //    font-size: 13px;
          //    color: #787878;
          //    float: left;
          //    font-weight: $font-weight-regular;
          //    font-family: $font-family;
          //  }
          //}
        }
      }

      .account-owner-info, .file-templates-section, .invoices-section {
        .section-body {
          padding: 0;
        }

        .dp-table-logo-image {
          img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            box-shadow: 0 0 10px #A4AFB7;
          }
        }
      }
    }
  }

  .dp-table-container {
    table {
      td {
        height: 50px;
      }
    }
  }

  .template-section {
    background-color: transparent;

    .title-template-section {
      width: 100%;

      .title {
        font-size: 15px;
        font-family: $font-family;
        font-weight: $font-weight-bold;
        color: #787878;
        margin: 0;
      }
    }

    .title-template-section-body {
      width: 100%;
      float: left;
      margin-top: 10px;

      .file-name, .file-code, .file-status {
        width: 230px;
        float: left;

        .title-name, .title-code, .title-status, .info-label .title, .label-make {
          width: 100%;
          float: left;
          color: #787878;
          font-family: $font-family;
          font-weight: $font-weight-regular;
          font-size: 10px;
        }

        .classInput {
          .name-input, .code-input, .form-control {
            border: 1px solid #EFEFEF;
            float: left;
            background-color: #fff;
            border-radius: 5px;
            width: 95%;
            padding: 10px;
            outline: none;
            height: 34px;
            color: #787878 !important;
          }
        }
      }

      .file-code, .file-status {
        margin-left: 10px;
      }

      .file-status {
        .status-assigned, .container-label {
          //--border: 1px solid #ccc;
          border-radius: 4px;
          height: 34px;
          display: flex;
          align-items: center;
          padding: 0 5px;
          position: relative;
          width: 120px;
          float: left;

          .label-make {
            color: #4D4F5C;
            font-size: 12px;
            margin: 0 0 0 5px;
            font-weight: $font-weight-regular;
          }

          .icon-arrow-content {
            position: absolute;
            padding: 1px 0;
            right: 10px;

            .icon-arrow-expand {
              height: 8px;
              fill: #A4AFB7;
            }
          }

          &:hover {
            cursor: pointer;
          }
        }

        .select {
          float: left;
          position: relative;
          padding-left: 0;

          .container-icon {
            position: absolute;
            padding: 5px 0;
            right: 10px;

            i {
              color: #A4AFB7;
              height: 8px;
            }
          }
        }
      }

      .popover-for-select {
        padding: 0;
      }
    }
  }

  .template-section-body {
    .template-section-body-title {
      width: 100%;
      float: left;
      display: flex;

      .title {
        font-size: 15px;
        font-family: $font-family;
        font-weight: $font-weight-bold;
        color: #787878;
        margin: 0;
      }

      .icon-right {
        width: 15px;
        margin: 3px 0 0 10px;

        .container-icon {
          .icon-question {
            div {
              display: flex;
            }
          }

          .svg-icon-question {
            fill: #D7DAE2;
            border: 1px solid #D7DAE2;
            border-radius: 50px;
            width: 15px;
            height: 15px;

            &:hover {
              border: 1px solid #787878;
              fill: #787878;

              g {
                circle {
                  fill: #787878;
                }
              }
            }
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .editor-html {
      width: 100%;
      margin-top: 10px;

      .title-html {
        margin: 0;
        font-size: 9px;
        font-family: $font-family;
        font-weight: $font-weight-regular;
      }

      .content-html-editor {
        border: 1px solid #EFEFEF;
        width: 100%;
      }
    }
  }

}

.body-question {
  margin: 2px 0;
  font-weight: $font-weight-regular;
  font-size: 13px;
  color: #787878;
  font-family: $font-family;
}

//move this inside a scope
.dp-select-dropdown {
  padding-right: 10px;
  width: 25%;
  text-align: left;

  label {
    font-size: 13px;
    color: #787878;
    //float: left;
    font-weight: $font-weight-regular;
  }

  .container-label {
    label {
      color: #2194f1;
    }
  }
}
