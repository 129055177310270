@import "../../common/styles/utils";
@import "../../common/styles/constants";

.account-confirmation{
  display: flex;
  flex-direction: column;

  .home-navigation .home-navigation-container{
    justify-content: center;
  }

  .container-form-create-password{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $background-color-input;

    .title{
      font-size: 18px;
      font-weight: 700;
      color: $black-color;
      align-items: center;
      margin-top: 45px;
    }

    .sub-title{
      font-size: 14px;
      font-weight: 400;
      color: $black-color;
      align-items: center;
    }

    .form-content{
      display: flex;
      flex-direction: column;
      width: auto;
      max-width: 350px;
      height: auto;
      margin: 0 0 100px 0;
      box-shadow: none;
      padding: 30px 20px;

      .new-password-container, .confirm-password-container{
        position: relative;

        .label-info{
          width: auto;
          background-color: $background-color-input;
          padding: 0;
          font-weight: 500;
          font-size: 14px;
          color: $text-color-7;
        }
        .label-error {
          color: $error-red;
          font-size: 14px;
          font-weight: 600;
          padding-left: 5px;
        }

        .password-input:hover {
          background-color: $background-color-input-hover;
        }

        .password-input:hover::placeholder {
          color: $black-color !important;
        }

        .message-general-error, .message-error{
          top: 5px;
          left: 0;
        }
      }

      .confirm-password-container{
        margin-top: 20px;
      }

      .requirements-box{
        .form-group-login{
          padding: 0;
          color: $gray-color-placeholder;
          .icon-arrow-green{
            svg{
              height: 13px;
              width: 17px;
            }
          }
        }
      }

      .text-center{
        text-align: left;

        .btn-start, .btn-disabled{
          cursor: pointer;
          width: auto;
          height: 40px;
          padding: 8px 16px 8px 16px;
          gap: 8px;
          border-radius: 6px;
          color: $background-color-input;
          background-color: $text-color-6;
          font-weight: 500;
          font-size: 14px;
          white-space: normal;
        }
        .btn-start:hover {
          background-color: $background-color-button-hover;
          color: $text-color-6 !important;
        }
        .btn-disabled {
          background-color: grey;
          cursor: not-allowed;
          color: white;
          width: auto;
          height: 40px;
          font-size: 14px;
          margin-top: 25px;
          border: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .account-confirmation .container-form-create-password .sub-title{
    font-size: 11px;
  }
}