@import "src/common/styles/utils";
@import "src/common/styles/constants";
#gdg-home-page {

  ul {
    list-style-type: none;
  }

  a:hover{
    text-decoration: none;
    cursor: pointer;
  }

  font-family: Montserrat, serif;

  // ----- font styles - start -----
  .small-blue-font {
    font-size: 14px;
    color: #3B86FF;
  }

  .title-font {
    font-size: 60px;
    font-weight: bold;
    line-height: 1;
    padding-bottom: 20px;
  }
  .regular-font {
    max-width: 650px;
    font-size: 18px;
    font-weight: 300;
    padding-bottom: 20px;
  }

  // ----- font styles - end ------

// --- nav section ---

  .home-navigation{
    .home-navigation-container{

      .nav-items-container{
        .nav-items{
          .nav-item-solutions{
            .dp-overlay-new dp-overlay-bottom {
              dp-scroll-frame{
                font-size: 13px;
                font-weight: bold;

                ul{
                  padding: 0;
                  margin: 0;

                  li{
                    display: flex;
                    height: 20px;

                    a{
                      font-size: 12px;
                      color: #4D4F5C;
                      align-items: center;
                      display: flex;

                      &:focus{
                        color: #3B86FF;
                      }
                    }

                    .dp-icon key-arrow-down-rounded-icon{
                      display: flex;
                      align-items: center;

                      svg{
                        width: 16px;
                        height: 16px;
                      }
                    }
                  }
                }
              }

            }
          }
        }
      }
    }

  }

  // --- sections styles - start ---

  .section-center-aligned {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 12px;
    padding-left: 12px;
    text-align: center;

  }

  .smart-integrations {
    background: #F5F6FA;
  }

  .after-intro-section-padding {
    @media only screen and (max-width: 1024px) {
      padding: 160px 30px 100px 30px;
    }
    @media only screen and (max-width: 768px) {
      padding: 125px 12px 70px 12px;
    }
    @media only screen and (max-width: 560px) {
      padding: 70px 12px 70px 12px;
    }
  }

  .default-section-padding {
    padding: 100px 30px 100px 30px;
    @media only screen and (max-width: 768px) {
      padding: 70px 12px 70px 12px;
    }
  }

  .finance-application, .message-application, .reviews-request, .reviews-track,
  .reviews-application, .inventory-management-application, .sale-process-application,
  .website-builder-application, .team-work-application, .task-notification-application, .todo-teams, .got-task, .template-eye{
    .section-content {
      padding: 40px;
    }
    .title-font, .sub-title-font{
      color: #292757;
      font-size: 45px;
      @media only screen and (max-width: 768px) {
        font-size: 40px;
      }
    }
    .sub-title-font{
      font-size: 30px;
      font-weight: 600;
      @media only screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
  }
  .reviews-application, .reviews-request, .reviews-track{
    .title-font, .sub-title-font{
      font-size: 40px;
      @media only screen and (max-width: 768px) {
        font-size: 32px;
      }
    }
    .regular-font{
      font-size: 16px;
    }
    .sub-title-font{
      font-size: 20px;
      font-weight: 600;
    }
    .section-content {
      .section-wrapper{
        img{
          max-width: 44vw;
          @media only screen and (max-width: 768px) {
            max-width: 60vw;
          }
        }
      }
    }
  }

  .message-application, .reviews-request, .todo-teams, .template-eye{
    background: #FFFFFF;
  }

  .reviews-track{
    background: #1D2130;
    .title-font, .sub-title-font, .regular-font{
      color: #FFFFFF;
    }
  }

  .inventory-management-application, .sale-process-application, .website-builder-application, .team-work-application, .task-notification-application {
    @media only screen and (max-width: 1024px) {
      .section-wrapper{
        .title-font{
          padding-top: 60px;
          font-size: 32px;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      .section-wrapper{
        >div{
          display: flex;
          flex-direction: column;
          .title-font, .regular-font{
            text-align: center;
          }
          .gdg-button{
            width: auto;
            margin: 0 auto;
          }
        }
      }
    }
  }

  .sale-process-application, .website-builder-application{
    @media only screen and (min-width: 1024px) {
      padding-top: 120px;
    }
  }

  .extra-padding{
    @media only screen and (min-width: 1024px) {
      padding-top: 200px;
    }
  }

  @media only screen and (max-width: 768px) {
    .title-font {
      font-size: 40px;
    }
    .slitSection{
      @media only screen and (max-width: 768px) {
        .section-content:nth-child(3){
          width: 100%;
          padding: 0 10px 0 0 !important;
          img{
            width: 100%;
            max-width: inherit !important;
          }
        }
      }
    }
  }
}

.marketing-page-home{
  @media only screen and (max-width: 560px){
    .intro-banner{
      height: 560px;
    }
    >div:nth-child(3){
      padding-top: 90px !important;
    }
  }

  @media only screen and (max-width: 500px){
    .intro-banner{
      height: 540px;
    }
    >div:nth-child(3){
      padding-top: 100px !important;
    }
  }

  @media only screen and (max-width: 450px){
    >div:nth-child(3){
      padding-top: 80px !important;
    }
  }

  @media only screen and (max-width: 407px){
    >div:nth-child(3){
      padding-top: 100px !important;
    }
  }
}

.customer-management-page{
  @media only screen and (max-width: 560px){
    .intro-banner{
      height: 530px;
    }
  }

  @media only screen and (max-width: 500px){
    .intro-banner{
      height: 510px;
    }
    >div:nth-child(3){
      padding-top: 50px !important;
    }
  }

  @media only screen and (max-width: 450px){
    .intro-banner{
      height: 490px;
    }
  }

  @media only screen and (max-width: 386px){
    .intro-banner{
      height: 505px;
    }
    >div:nth-child(3){
      padding-top: 40px !important;
    }
  }

}

.reviews{
  @media only screen and (max-width: 560px){
    .intro-banner{
      height: 580px;
    }
    >div:nth-child(3){
      padding-top: 140px !important;
    }
  }
}

.to-do-teams{

  .team-work-application{
    padding-bottom: 0 !important;
    div:nth-child(3){
      padding-bottom: 0 !important;
    }
  }
  
  @media only screen and (max-width: 560px){
    .intro-banner{
      height: 520px;
    }
    .team-work-application{
      padding-top: 50px !important;
      .title-font{
        padding-top: 10px !important;
      }
    }
  }

  @media only screen and (max-width: 500px){
    .team-work-application{
      padding-top: 30px !important;
    }
  }

  @media only screen and (max-width: 450px){
    .intro-banner{
      height: 500px;
    }
  }

  @media only screen and (max-width: 364px){
    .intro-banner{
      height: 520px;
    }
  }
}

.website-builder{
  @media only screen and (max-width: 560px){
    .intro-banner{
      height: 580px;
    }
    .website-builder-application{
      padding-top: 10px !important;
    }
  }

  @media only screen and (max-width: 400px){
    .website-builder-application{
      padding-top: 0 !important;
    }
  }

  @media only screen and (max-width: 388px){
    .intro-banner{
      height: 620px;
    }
    .website-builder-application{
      padding-top: 20px !important;
      .title-font{
        padding-top: 30px !important;
      }
    }
  }
}

.sales-process{
  @media only screen and (max-width: 560px){
    .intro-banner{
      height: 580px;
    }
  }

  @media only screen and (max-width: 480px){
    .intro-banner{
      height: 550px;
    }
    .sale-process-application{
      .title-font{
        padding-top: 40px !important;
      }
    }
  }

  @media only screen and (max-width: 413px){
    .intro-banner{
      height: 590px;
    }
    .sale-process-application{
      .title-font{
        padding-top: 40px !important;
      }
    }
  }
}

.inventory-management{
  @media only screen and (max-width: 560px){
    .inventory-management-application{
      padding-top: 20px !important;
    }
  }

  @media only screen and (max-width: 500px){
    .intro-banner{
      height: 560px;
    }
  }
}

// --- sections styles - end ---

// --- request demo styles - start ---
.request-demo-modal{
  .modal-dialog{
    min-width: 600px;
  }
}
// --- request demo - end ---

// Login section styles ----

.newLogin-section-gdg{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: 'Montserrat';
  background-color: #F5F6FA;
  position: absolute;
  z-index: -11;

  .home-navigation .home-navigation-container{
    justify-content: center;
    height: 65px;
  }

  .login-main-container{
    display: flex;
    height: 100%;
    width: 100%;

    .login-image-container{
      width: 50%;
      @include item-center;
      background-color: $table-thead-color;

      img{
        height: 450px;
        width: auto;
      }
    }

    .login-credentials-container{
      width: 50%;
      @include item-center;
      background-color: $background-color-input;

      .form-recover-password{
        padding: 30px 50px;
        background-color: $background-color-input;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 510px;
        width: 70%;

        .logo-godealergo-recover{
          img{
            width: 140px;
            margin: 0 auto;
            cursor: pointer;
          }
        }

        .recover-password-img{
          text-align: center;
        }

        .text-forgot-password{
          padding-top: 30px;
          color: $text-color-13;
          font-size: 12px;
          p{
            margin: 0;
          }
        }

        .btn-back-login{
          background-color: $background-color-3;
          font-weight: 300;
          color: $background-color-input;
          border: 1px;
          border-radius: 1px;
          padding: 10px 15px;
        }

        .label-send-again{
          color: $background-color-3;
          font-size: 12px;
          cursor: pointer;
        }
      }

      .login-form-container{
        @include item-center;
        align-items: flex-start;
        flex-direction: column;
        background-color: $background-color-input;
        width: 100%;
        max-width: 411px;
        height: fit-content;
        max-height: 303px;
        padding: 40px 30px;

        .login-form-logo{
          display: flex;

          img{
            width: 211px;
            height: 37px;
            margin: 0px;
            cursor: pointer;
          }
        }

        .login-title{
          display: flex;
          color: $text-color-7;
          font-size: 24px;
          font-weight: 700;
          padding: 10px 0px;
        }

        .login-message{
          font-size: 15px;
          font-weight: normal;
        }

        .google-btn{
          border: 0.5px solid !important;
          color: $border-color-2 !important;
          @include item-center;
          display: none !important;

          .icon-class{
            width: 18px;
            height: 18px;
            position: absolute;
            left: 15px;
          }

          >span{
            color: $btn-color-5 !important;
          }
        }

        .first-separator{
          display: none;
          align-items: center;
          width: inherit;
          justify-content: space-between;
          padding: 22px 0 0;

          .hr-left{
            width: 30%;
          }

          .separator-description{
            width: auto;
            @include item-center;
            margin: 0;
            font-weight: bold;
            text-align: center;
            color: $border-color-2;
            font-size: 10px;
          }

          .hr-right{
            width: 30%;
          }
        }

        .credentials-container{
          display: flex;
          flex-direction: column;
          padding: 0 0 10px;
          width: inherit;

          .email-login{
            font-size: 14px;
            color: $text-color-7;
            text-align: left;
            font-weight: 500;
          }


          .email-input{
            height: 40px;
            font-size: 15px;
            color: $text-color-12;
            border: 1px solid $text-color-4;
            border-radius: 6px;
            padding: 0 10px;
          }

          .email-input:hover {
            background-color: $background-color-input-hover;
          }

          .email-input:hover::placeholder {
            color: $black-color !important;
          }

          .email-input:focus {
            box-shadow: none;
            border-color: $black-color;
            background-color: $background-color-input;
          }

          .email-input:focus-visible{
            outline: none;
          }

          .password-login{
            padding-top: 10px;
            font-size: 14px;
            color: $text-color-7;
            text-align: left;
            font-weight: 500;
          }

          .password-input{
            height: 40px;
            font-size: 15px;
            color: $text-color-12;
            border: 1px solid $text-color-4;
            border-radius: 6px;
            padding: 0 10px;
          }

          .password-input:focus-visible{
            outline: none;
          }

          .password-input:hover {
            background-color: $background-color-input-hover;
          }

          .password-input:hover::placeholder {
            color: $black-color !important;
          }

          .password-input:focus {
            box-shadow: none;
            border-color: $black-color;
            background-color: $background-color-input;
          }

          input:focus{
            border-color: $border-color-5;
          }

        }

        .keep-password-container{
          display: flex;
          width: inherit;
          justify-content: flex-start;
          padding-bottom: 10px;

          .checkbox-container{
            @include item-center;
            display: none;

            .checkbox-keep{
              width: auto !important;
            }

            .description-keep{
              text-align: left;
              font-weight: 300;
              font-size: 15px;
              color: $btn-color-5;
            }
          }

          .forgot-container{
            @include item-center;
            font-weight: 500;
            text-align: right;
            font-size: 10px;
            color: $text-color-7;
            letter-spacing: 0;
            cursor: pointer;
            margin-bottom: 0;
          }
        }

        .sign-in-button{
          border-radius: 6px;
          color: $background-color-input;
          background-color: $btn-color-3;
          font-weight: 500;
          font-size: 14px;
        }

        .sign-in-button:hover {
          background-color: $background-color-button-hover;
          span{
            color: $btn-color-3 !important;
          }

        }

        .second-separator{
          width: inherit;
          display: none;
        }

        .sign-up-container{
          padding-bottom: 62px;
          display: none;

          .sign-up-1{
            text-align: center;
            font-weight: 300;
            font-size: 15px;
            color: $btn-color-5;
          }

          .sign-up-2{
            padding-left: 5px;
            text-align: center;
            font-weight: 300;
            font-size: 15px;
            color: $primary-blue;
          }
        }
      }

    }
  }

  .container-back{
    display: flex;
    align-items: center;
    position: absolute;
    left: 60px;
    top: 60px;
    span{
      padding-left: 10px;
      color: #4D4F5C;
      &:hover{
        cursor: pointer;
      }
    }
    .dp-icon.arrow-back-icon {
      .a {
        fill: #000;
      }
    }
    .box-icon{
      width: 40px;
      height: 40px;
      @include item-center;
      .dp-icon{
        height: 22px;
        width: 22px;
      }
      &:hover{
        background-color: #000;
        cursor: pointer;
        .dp-icon.arrow-back-icon {
          .a {
            fill: #FFF;
          }
        }
      }
    }
  }
}

// tablet mode login page
@media (max-width: 1300px){
  .google-btn{
    >span{
      padding-left: 120px !important;
    }
  }
}

@media (max-width: 1100px){
  .google-btn{
    >span{
      padding-left: 100px !important;
    }
  }
}


//Mobile mode
@media (max-width: 1024px) {
  .newLogin-section-gdg{
    overflow: auto;
    .login-image-container{
      width: 0;
    }

    .login-main-container{
      flex-direction: column-reverse;

      .login-image-container, .login-credentials-container{
        width: 100%;
        height: 50%;
        //max-height: 390px;
      }

      .login-image-container img{
        height: -webkit-fill-available;
      }

      .login-credentials-container{
        width: 100%;
        background-color: $background-color-input;
        //min-height: 670px;
        //overflow: auto;

        .login-form-container{
          //width: 75%;

          .google-btn{
            .icon-class{
              width: 25px;
              height: 25px;
            }

            >span{
              padding-left: 120px !important;
              color: $btn-color-5 !important;
              font-size: 14px !important;
            }
          }

          .first-separator{
            .separator-description{
              color: $text-color-14;
              font-size: 12px;
            }
          }

          .credentials-container{
            .email-input{
              height: 45px;
            }

            .password-input{
              height: 45px;
            }
          }

          .keep-password-container{
            padding-bottom: 10px;
          }
        }

      }
    }


    .container-back{
      left: calc(21.895% - 10px);
    }
  }
}

@media (max-width: 1024px) and (max-height: 600px) {
  .newLogin-section-gdg .login-main-container .login-image-container, .newLogin-section-gdg .login-main-container{
    height: auto;

    .login-credentials-container , .login-image-container{
      min-height: 500px;

      img{
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 600px){
  .newLogin-section-gdg .login-main-container .login-image-container img{
    width: 100%;
    height: auto;
  }
  .newLogin-section-gdg{
    .login-credentials-container {

      .login-form-container {
        width: 90%;
      }
    }
    .container-back{
      left: calc(9.5% - 10px);
    }
  }
}

@media (max-width: 540px) and (max-height: 720px) {
  .newLogin-section-gdg .login-main-container .login-image-container, .newLogin-section-gdg .login-main-container{
    height: auto;

    .login-credentials-container , .login-image-container{
      min-height: 500px;

      img{
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 450px){
  .newLogin-section-gdg .login-main-container{
    width: 100%;
    height: auto;

    .login-credentials-container, .login-image-container{
      min-height: 500px;

      .login-form-container{
        width: 95%;
      }
    }
  }
  .newLogin-section-gdg{
    .login-credentials-container {

      .login-form-container {
        width: 95%;

        .login-title{
          font-size: 36px;
        }
      }
    }
    .container-back{
      left: calc(4.875% - 10px);
    }
  }
}