@import 'src/common/styles/constants';
@import 'src/common/styles/utils';

.priority-repair {
  width: 100%;
  float: left;
  padding-bottom: 5px;
}

.priority-repair-updated , .task-dueDate , .username{
  font-size: 9px;
}

.task-bottom {
  display: flex;
  flex-direction: column;
  gap: 0;
  span {
    display: block;
  }
}

.taskHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 5px $border-button-color-pagination;
  height: 50px;
  background: #fff;
  color: black;
  font-weight: bold;
  font-size: 15px;
  padding: 2px 0;
  position: relative;
  border-radius: 5px;
  margin: 0 20px;

  .header-right {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    width: 33%;

    #task-completed{
      width: 55%;
      margin-right: 20px;
    }

    span {
      color: #2194F1;
      font-weight: normal;
    }
    .icon-svg {
      display: flex;
      width: 15px;
      height: 15px;
    }

    .styled-progress{
      width: 100%;
      height: 6px;
      appearance: none;
    }

    .styled-progress::-webkit-progress-bar {
      background-color: #e0e0e0;
      border-radius: 3px;
    }

    .styled-progress::-webkit-progress-value {
      background-color: $light-blue-color-1;
      border-radius: 3px;
    }
  }
  .header-left {
    display: flex;
    gap: 10px;
    padding-left: 15px;
    .icon-svg {
      width: 10px;
      height: 10px;
    }
  }
}