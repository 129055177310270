.section-title {
  font-weight: bold;
  font-size: 21px;
}
.total-section {
  display: flex;
  flex-direction: row;
  gap: 60px;
  padding-bottom: 20px;
  .total {
    display: flex;
    flex-direction: column;
    h3 {
      color: #787878;
      font-weight: normal;
      font-size: 16px;
      margin-top: 15px;
      margin-bottom: 5px;
    }
    .total-value {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
.footer-cost{
  .add-cost{
    font-size: 14px
  }
}
.body-cost {
  padding-left: 15px;
  padding-bottom: 15px;
  padding-right: 0;

  .total-section {
    padding-right: 15px;
  }
  .scroll-container {
    //overflow-x: auto;
    .cost-container {
      gap: 8px;
      display: grid;
      grid-template-columns: 1fr auto auto auto auto;
      padding-right: 0;
      width: 100%; // 600px;

      .body-cost-fields {
        display: contents;
        .info-title {
          .cost-input {
            border: 1px solid #D9D9D9;
            float: left;
            background-color: #fff;
            border-radius: 5px;
            width: 100%;
            padding: 10px;
            outline: none;
          }
        }
        .container-drop-zone {
          display: none;
        }
      }
      .body-cost-fields .title-input {
        display: none;
      }
      .body-cost-fields:first-child .title-input {
        display: inline;
      }
      .body-cost-fields .info-title, .body-cost-fields .info-price {
        display: flex;
        flex-direction: column;
      }
      .body-cost-fields .currencyinput {
        margin-top: 11px;
      }
      .body-cost-fields:first-child .currencyinput {
        margin-top: 26px;
      }
      .footer-cost {
        display: contents;
      }
    }
  }
  .body-cost-header {
    .info-title {
      label {
        padding: 0;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
  .body-cost .scroll-container {
    overflow-x: auto;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .body-cost .scroll-container .cost-container  {
    width: 600px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .body-cost .scroll-container .cost-container  {
    width: 800px;
  }
}
.body-cost .body-cost-header, .sub-total-cost .body-cost-header {
  display: none;
}

.body-cost-fields .title-input {
  display: none;
}
.body-cost-fields:first-child .title-input {
  display: inline;
}
.body-cost-fields .info-title, .body-cost-fields .info-price {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .body-cost-fields:first-child .currencyinput {
    margin-top: 11px;
  }
  #containerCost {
    .cost-container {
      width: 650px;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .body-cost-fields:first-child .currencyinput {
    margin-top: 11px;
  }
}
