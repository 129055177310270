@import "src/common/styles/utils";
@import "src/common/styles/constants";

.modal-backdrop.in{
  opacity: 0;
}
.container-modal{
  display: flex !important;
  @include item-center;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  visibility: unset;
  opacity: unset;
  .modal-dialog{
    width: 36%;
    min-width: 494px;
    height: auto;

    .modal-content{
      border-radius: 0;
      border: 1px solid transparent;
      .modal-body{
        padding: 0;
        @include item-center;
      }
    }
  }
}

.container-body{
  position: relative;
  height: 100%;
  width: 100%;
  font-family: Montserrat !important;
  padding: 40px 45px;
  .container-logo{
    margin-top: 10px;
    text-align: center;
  }
  .title{
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    padding-top: 30px;
    padding-bottom: 20px;
    color: $black-color;
  }
  .form{
    height: 100%;
    width: 100%;
    .form-first-row{
      display: flex;
      width: 100%;
      justify-content: space-between;
      .form-first-row-element, .form-first-row-second-element{
        display: flex;
        width: 49%;
        flex-direction: column;
      }
    }
    .title-input-get-in{
      font-weight: 500;
      line-height: 1;
      color: #292757;
      margin-bottom: 10px;
      font-size: 12px;
    }
    .mandatory-field{
      font-size: medium;
      font-weight: 600;
      line-height: 1;
      text-align: left;
      color: $red-color-mandatory-field;
      padding-left: 3px;
    }
    .input-message{
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }
    input{
      background: $background-color-input 0 0 no-repeat padding-box;
      border: 1px solid $gray-input-border-color;
      border-radius: 6px;
      font-family: Montserrat !important;
    }
    .form-control:hover{
      background-color: $background-color-input-hover;
    }
    .form-control:hover::placeholder{
      color: $black-color !important;
    }
    .form-control:focus{
      box-shadow: none;
      border-color: $black-color;
      background-color: $background-color-input;
    }
    .btn-send-content{
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 15px;
      .btn-send{
        cursor: pointer;
        color: #FFFFFF;
        border: none;
        border-radius: 6px;
        width: 67px;
        background-color: $light-blue-color-2;
        font-weight: 500;
        padding: 12px;
        font-size: 16px;
        white-space: normal;
        word-break: break-word;
        height: auto;
        text-decoration: none;
      }
      .btn-send:hover{
        background-color: $background-color-button-hover;
        color: $blue-color-placeholder-hover !important;
      }
    }
    .info-row{
      text-align: center;
      margin-top: 25px;
      .phone, .email{
        color: $gray-color-placeholder;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

      }
    }
  }
}

.content-center{
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}

@media screen and (max-width: 768px) {
  .container-modal {
    .modal-dialog {
      min-width: 369px !important;
    }
  }

  .container-body{
    padding: 40px 20px;
    .title{
      font-size: 28px;
      font-weight: bold;
      line-height: 16px;
    }
    .form{
      .form-first-row{
        display: flex;
        width: 100%;
        flex-direction: column;
        .form-first-row-element, .form-first-row-second-element{
          display: flex;
          width: 100%;
          flex-direction: column;
        }
        .form-first-row-second-element{
          margin-top: 10px;
        }
      }
      .input-message{
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .container-modal {
    .modal-dialog {
      min-width: calc(100% - 40px) !important;
    }
  }
}


