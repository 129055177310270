@import 'src/common/styles/utils';
@import 'src/common/styles/constants';
.footer-section-gdg{
  background-color: $table-thead-color;

  .footer-third-column > span, a{
    &:hover{
      color: $primary-blue !important;
    }
  }

  a{
    text-decoration: none;
  }

  ul {
    list-style-type: none;
  }

  .content{
    display: flex;
    padding: 75px 80px 65px;
    width: 100%;
    //padding-left: 140px;

    .footer-first-column{
      display: flex;
      flex-direction: column;
      padding-right: 130px;
      max-width: 50%;

      .logo{
        width: 82px;
        height: 69px;
      }

      .social-media-section{
        .description{
          font-size: 16px;
          color: $text-color-14;
          text-align: left;
          font-weight: 300;
          padding: 25px 0 44px;
        }
      }
    }

    .footer-second-column{
      display: flex;
      flex-direction: column;
      padding: 50px 0 0 0;
      width: 25%;

      .contact-title{
        font-size: 21px;
        color: $text-color-14;
        text-align: left;
        font-weight: bold;
      }

      .contact-info{
        >ul{
          padding-left: 0;

          >li{
            font-size: 16px;
            font-weight: 300;

            >a{
              color: $text-color-14;
              text-align: left;

              &:focus{
                text-decoration: none;
              }
            }
          }
        }
      }

    }

    .footer-third-column{
      display: flex;
      flex-direction: column;
      padding: 50px 0 0 0;
      width: 25%;

      .feature-title{
        font-size: 21px;
        color: $text-color-14;
        text-align: left;
        font-weight: bold;
      }

      >span{
        font-size: 16px;
        color: $text-color-14;
        text-align: left;
        font-weight: 300;
        cursor: pointer;
      }
    }
  }

  .social-media-links{
    display: flex;
    justify-content: center;

    .icon-menu-content{
      display: flex;
      align-items: center;
      padding-right: 26px;

      >div {
        display: flex;
      }
    }
    svg{
      width: auto;
      height: 31px;

    }
  }

  >hr{
    padding-left: 140px;
    padding-right: 140px;
    margin: 30px 0 0;
  }
  .end-content{
    display: flex;
    justify-content: center;
    //margin-left: 140px;

    .brand-details{
      padding: 20px 0;
      font-size: 16px;
      color: $text-color-15;
      font-weight: 300;
    }

    >div>div{
      display: none;
    }
  }
}

/*** TABLET VIEW ***/
@media (max-width: 1024px) {

  .footer-section-gdg {

    .social-media-links{
      .icon-menu-content{
        padding-left: 20px;
        padding-right: 0;
      }
      svg{
        height: 24px !important;
      }
    }

    .content{
      padding: 35px 40px 41px ;
      align-items: flex-start;
      justify-content: space-between;

      .footer-first-column{
        //flex-direction: row;
        padding-right: 80px;
        //width: 290px;

        .logo{
          width: 60px;
          height: 50px;
        }

        .social-media-section{
          .description{
            font-size: 12px !important;
            //padding: 0 0 23px 20px;
          }
        }
      }

      .footer-second-column{
        //padding: 0 0 0 0;
        padding-right: 0;

        .contact-title{
          font-size: 15px;
          margin: 0;
        }

        .contact-info{
          padding-top: 7px;
          >ul{
            >li{
              font-size: 12px;
            }
          }
        }

      }

      .footer-third-column{
        //padding: 0 0 0 0;
        padding-right: 0;

        .feature-title{
          font-size: 15px;
          margin: 0;
          padding-bottom: 7px;
        }

        >span{
          font-size: 12px;
        }
      }
    }



    >hr{
      padding-left: 24px;
      padding-right: 24px;
      //margin: 0;
    }

    .end-content{
      display: flex;
      margin: 0;
      justify-content: center;

      .brand-details{
        padding: 16px 0 20px;
        font-size: 10px;
        color: $text-color-15;
        font-weight: 300;
      }
    }
  }

}

/*** MOBILE VIEW ***/
@media (max-width: 767px){
  .footer-section-gdg{
    .content{
      padding: 35px;
      flex-direction: column;

      .footer-first-column{
        width: 100%;
        max-width: unset;
        padding: 0;

        .logo{
          width: 40px;
          height: 37px;
        }

        .social-media-section .description{
          padding-bottom: 25px;
        }
      }

      .footer-second-column, .footer-third-column{
        padding: 0 0 20px;
        width: 100%;

        h4{
          padding-bottom: 10px;
        }
      }
    }

    .social-media-links{
      width: 100%;
      //padding-top: 20px;
      @include item-center;
      .icon-menu-content{
        padding: 0 10px;

        svg{
          height: 23px !important;
        }
      }
    }

    .end-content{
      flex-direction: column;
      align-items: center;
      padding: 30px;
      >div{
        width: auto;
        .brand-details{
          text-align: center;
          width: 100%;
          padding: 0 0 12px 0;
          margin: 0;
        }
        >div{
          display: flex;
          justify-content: space-between;
        }
      }
      a{
        font-size: 10px;
        color: $text-color-15;
        font-weight: 300;
      }
    }
  }
}