@import "../../../../../common/styles/utils";
@import "../../../../../common/styles/constants";
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.page-container {
  .home-navigation-container {
    justify-content: center;
  }
}
.page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 80px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    padding-top: 40px;
    padding-left: 32px;
    padding-right: 32px;
    align-items: center;

    .only-one-tab {
      margin-bottom: 0px !important;
    }

    .tabs, .only-one-tab {
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: center;
      margin-bottom: 40px;
      max-width: 540px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      font-family: "Montserrat", sans-serif;
      box-sizing: border-box;

      .tab-title {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        border-bottom: 1px solid black;

        .tab {
          border-bottom: none;
          flex: 1 1 0;
          text-align: center;
          label {
            font-size: 16px;
            line-height: 19.5px;
            font-weight: 500;
            cursor: inherit;
            white-space: nowrap;
          }
          &.tab-selected {
            border-bottom: 2px solid black;
            .label-selected {
              font-weight: 700;
              color: black;
            }
          }
          &.tab-clickable {
            cursor: pointer;
          }
          .label-name {
            color: $label-text-color;
            cursor: pointer;
          }
        }

      }

      .tab-description {
        text-align: center;
      }
    }

    .form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
      width: 100%;
      max-width: 650px;
      margin: 0 45px;

      input::placeholder {
        color: $gray-color-placeholder !important;
      }

      .submit-button {
        background-color: $primary-blue;
        color: white;
        width: 90px;
        height: 40px;
        margin-top: 25px;
        font-size: 14px;
      }
      .submit-button:hover {
        background-color: $button-submit-hover-background-color;
        color: $button-submit-hover-color;
        border: none;
      }
      .button-disabled {
        background-color: grey;
        cursor: not-allowed;
        color: white;
        width: 90px;
        height: 40px;
        margin-top: 25px;
        font-size: 14px;
      }


      .select-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-right: 0;

        .select {
          border: $border-default;
          background-color: $background-color-input;
          padding: 6px 12px;
          border-radius: 4px;
        }

        .container-title {
          .label-make {
            font-weight: 700;
            color: black;
            font-size: 14px;
          }

          .label-error {
            color: $error-red;
            font-size: 14px;
          }
        }

        .help-block {
          top: 65px;

        }
      }

      .error-message-tab {
        color: red;
        grid-column: span 2;
      }
    }
    .form, .form-admin-info, .verification-container, .public-form-payment-method {
      font-family: "Inter", sans-serif;
    }
    .form input,
    .form label,
    .form-admin-info input,
    .form-admin-info label,
    .verification-container input,
    .verification-container label,
    .public-form-payment-method input,
    .public-form-payment-method label {
      font-family: "Inter", sans-serif;
    }

    .check-mail-confirm-container{
      @include item-center();
      flex-direction: column;

      .description-mail-confirm-container{
        .title-email-sent, .title-email-resent{
          font-weight: 900;
          font-size: 20px;
          line-height: 24.38px;
          text-align: center;
          color: $black-font-color;
          padding: 5px 0;

          .email-resent-container{
            display: flex;
            justify-content: center;

            .icon-check-resent-email{
              margin-right: 10px;
              svg{
                fill: $icon-color-2;
                width: 24px;
                height: 24px;
              }
            }
          }
        }
        .subtitle-email-confirm{
          font-weight: 500;
          font-size: 16px;
          line-height: 19.5px;
          text-align: center;
          color: $black-font-color;
          padding: 5px 0;
        }
      }

      .buttons-email-confirm-container{
        display: flex;
        padding: 20px 0;

        .resend-email-button{
          width: 122px;
          height: 40px;
          border-radius: 6px;
          border-width: 1px;
          padding: 8px 16px;
          gap: 8px;
          background-color: $background-color-input;
          color: $black-font-color;
          margin-right: 5px;
        }
        .resend-email-button:hover {
          background-color: $btn-color-6;
          color: $black-font-color;
        }
        .resend-email-button:focus {
          border: 1px solid $text-color-8 !important;
        }
        .back-home-button{
          width: 124px;
          height: 40px;
          border-radius: 6px;
          padding: 8px 16px;
          gap: 8px;
          background-color: $primary-blue;
          color: $background-color-input;
          margin-left: 5px;
        }
        .back-home-button:hover {
          background-color: $background-color-button-hover;
          color: $blue-color-placeholder-hover !important;
        }
      }

      .support-email-confirm-container{
        padding-top: 10px;

        .left-info-support, .right-info-support{
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
        }
        .right-info-support{
          padding-left: 10px;
          color: $primary-blue;
          cursor: pointer;
        }
      }
    }

    .form-admin-info {
      display: flex;
      flex-direction: column;
      max-width: 650px;
      width: 100%;
      //padding: 0 20px 0 20px;
      padding: 0;

      .first-row-admin-info, .second-row-admin-info {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        gap: 10px;

        .name-admin, .last-name-admin, .email-admin, .phone-admin {
          font-family: "Inter", sans-serif;
          width: 100%;
          height: auto;
          gap: 5px;
        }

        .form-control:hover {
          background-color: $background-color-input-hover;
        }

        .form-control:hover::placeholder {
          color: $black-color !important;
        }

        .form-control:focus {
          box-shadow: none;
          border-color: $black-color;
          background-color: $background-color-input;
        }

      }

      .button-row-admin-info {
        padding: 10px 0;

        .continue-admin-info-button-enable, .continue-admin-info-button-disable {
          cursor: pointer;
          width: auto;
          height: 40px;
          padding: 8px 16px 8px 16px;
          gap: 8px;
          border-radius: 6px;
          color: $background-color-input;
          background-color: $light-blue-color-2;
          font-weight: 500;
          font-size: 14px;
          white-space: normal;
        }

        .continue-admin-info-button-disable {
          background-color: grey;
          cursor: not-allowed;
          color: white;
        }

        .continue-admin-info-button-enable:hover {
          background-color: $background-color-button-hover;
          color: $blue-color-placeholder-hover !important;
        }
      }

      .error-message-tab {
        color: red;
      }
    }

    .public-form-payment-method {
      width: 340px;
      align-self: center;

      .main-message-box {
        background-color: black;
        color: white;
        border-radius: 10px;
        margin-bottom: 20px;
        padding: 15px;
        display: flex;
        gap: 10px;

        .icon-main-message-box {
          fill: white;
          margin-top: 2px;

          svg {
            height: 18px;
            width: 18px;
          }
        }

        .content-main-message-box {
          display: flex;
          flex-direction: column;

          .title {
            font-size: 16px;
            font-weight: 700;
          }

          .subtitle {
            font-size: 14px;
          }
        }
      }

      .form-row {
        display: flex;
        justify-content: space-between;
      }

      .col-md-12, .col-md-6{
        padding-right: 0px;
        padding-left: 0px;
        float: none;
        width: 100%;
      }

      .cvv-container {
        margin-left: 10px;
      }

      .submit-button {
        background-color: $text-color-6;
        color: white;
        min-width: 90px;
        height: 40px;
        border-radius: 6px;

        &:hover {
          background-color: $btn-color-2 !important;
          color: $text-color-6 !important;
        }
      }

      .disabled-button {
        background-color: $btn-color-5;
        color: white;
        min-width: 90px;
        height: 40px;
        border-radius: 6px;
        font-size: 14px;
        cursor: wait;
      }

      .error-message {
        color: $text-color-10;
        font-size: 10px;
      }

      .error-field {
        border: 1px solid $border-color-3;
      }

      .custom-payment-input {
        height: 34px;
        padding: 6px 12px;
        border-radius: 4px;
        border: 1px solid $border-color-4;
      }

      .custom-invalid-payment-input {
        height: 34px;
        padding: 6px 12px;
        border-radius: 4px;
        border: 1px solid $border-color-3;
      }

      .error-message-tab {
        color: red;
        margin-bottom: 20px;
      }
    }
    .verification-container {
      max-width: 650px;
      width: 100%;
      .business-info-section, .admin-info-section {
        display: grid;
        grid-template-columns: 3fr 2fr 1fr;
        grid-template-rows: repeat(2, auto);
        border-bottom: 1px solid $gray-input-border-color;
        padding-bottom: 20px;
      }
      .business-info-section {
        grid-template-areas:
          "business-name business-phone-number business-phone-number"
          "address address link"
        ;
        .business-name {
          grid-area: business-name;
        }
        .business-phone-number{
          grid-area: business-phone-number;
        }
        .address {
          grid-area: address;
        }
        .link {
          grid-area: link;
        }
      }
      .admin-info-section {
        grid-template-areas:
          "name name name"
          "phone-number email link"
        ;
        .name {
          grid-area: name;
        }
        .phone-number {
          grid-area: phone-number;
        }
        .email {
          grid-area: email;
        }
        .link {
          grid-area: link;
        }
      }

      .label {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $dark-blue-color-1;
        padding: 0;
      }
      .value {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $black-color-1;
      }
      .link {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $light-blue-color-4;
        text-align: right;
        padding-top: 22px;
        cursor: pointer;
      }
      .submit-button {
        cursor: pointer;
        width: auto;
        height: 40px;
        padding: 8px 16px 8px 16px;
        gap: 8px;
        border-radius: 6px;
        color: $background-color-input;
        background-color: $light-blue-color-2;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        white-space: normal;
        border: 0;
        margin-top: 20px;
      }
      .submit-button:hover {
        background-color: $light-blue-color-3;
        color: $light-blue-color-2;
      }
    }
  }
}

@media (max-width: 1024px) {
  .page-container {
    margin-bottom: 80px;
  }

  .page-container .container {
    .public-form-payment-method {
      width: 320px;
    }
  }
}

@media (max-width: 768px) {
  .page-container {
    margin-bottom: 80px;
  }

  .page-container .container {
    .public-form-payment-method {
      width: 340px;
    }
    .tabs .tab-title .tab label {
      font-size: 16px;
    }
  }
}

@media (max-width: 570px) {
  .page-container .container .tabs .tab-title .tab label {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  #streetAddress {
    font-size: 14px;
  }

  .page-container {
    margin-bottom: 80px;

    .container {
      width: 100%;
      padding: 32px 20px 0 20px;

      .public-form-payment-method {
        width: 100%;
      }

      .form-admin-info {
        width: 100%;
        padding: 0;

        .first-row-admin-info, .second-row-admin-info {
          flex-direction: column;
          padding: 0 !important;

          .name-admin, .last-name-admin, .email-admin, .phone-admin {
            width: 100%;
            height: auto;
            margin: 5px 0;
          }
        }
      }

      .tabs {
        margin: 0 0 20px 0;

        .tab-title .tab label {
          font-size: 10px;
        }
      }

      .form {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        margin: 0;
      }
      .verification-container {
        .business-info-section, .admin-info-section {
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(3, auto);
        }
        .business-info-section {
          grid-template-areas:
            "business-name"
            "business-phone-number"
            "address"
            "link"
          ;
        }
        .admin-info-section {
          grid-template-areas:
            "name"
            "phone-number"
            "email"
            "link"
          ;
        }
        .link {
          padding-top: 0;
          text-align: left;
        }
      }
    }
  }
}
@media (max-width: 380px) {
  .page-container .container .tabs .tab-title .tab label {
    font-size: 8px;
  }
}

@media (max-width: 360px) {
  .page-container .container {
    .tabs {
      .tab-title {
        gap: 7px;

        .tab label {
          font-size: 10px;
        }
      }

      .tab-description {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 340px) {
  .page-container .container .tabs .tab-title .tab label {
    font-size: 9px;
  }
}

@media (max-width: 320px) {
  .page-container {
    .container {
      padding: 16px 12px 0 12px;

      .tabs {
        margin-bottom: 10px;

        .tab-title {
          .tab {
            width: max-content;
          }
          .tab-description {
            display: none;
          }
        }

        .tab-description {
          font-size: 11px;
        }
      }

      .form-admin-info {
        width: 100% !important;
        padding: 0 0 20px 0;

        .first-row-admin-info, .second-row-admin-info {
          flex-direction: column;
          padding: 0 !important;

          .name-admin, .last-name-admin, .email-admin, .phone-admin {
            width: 100% !important;
            height: auto;
            margin: 5px 0;
          }
        }
      }

      .tabs .tab-title .tab label {
        font-size: 9px;
      }
    }
  }
}