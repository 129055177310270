$box-margin-color : #EFEFEF;
$icon-color : #787878;
$height-modal : 330px;
$height-box-input : 32px;
$height-photo: 36px;
$text-size-table : 15px;
$text-size-select : 13px;
$text-color-table : #8F979D;
$icon-arrow-color : #A4AFB7;
$icon-arrow-height-svg : 8px;
$icon-exclamation-color : #ffca83;
$border-color-icon-exclamation :#fff4e5;
$text-color : #787878;
$light-blue-color-1: #2194F1;
$light-blue-color-2: #3B86FF;
$light-blue-color-3: #DEEAFF;
$light-blue-color-4: #3758F9;
$dark-blue-color-1: #64748B;
$green-color: #4AD991;
$button-color-red : #FF6565;
$table-thead-color: #F5F6FA;
$border-button-color-pagination: #D7DAE2;
$color-button-all : #005294;
$text-color-table-head : #A3A6B4;
$font-family: 'Open Sans', sans-serif;
$font-weight-bold: bold;
$font-weight-regular: 400;
$font-weight-light: 200;
$width-sidebar-hide: 50px;
$padding-container-center: 80px 25px 0 25px;
$height-container-header: 56px;
$icon-options-width: 15px;
$icon-options-height: 16px;
$text-dark-color: #575757;
$button-not-selected-background: #809FB833;
$black-font-color: #242424;
$orange-color: #F3A200;
$border-color-dropdown: #D9D9D9;
$placeholder-color-hovered: #292929;
$border-color-input: #7EAFFF;
$background-color-input: #FFFFFF;
$border-color-taskDueDate: #CCCCCC;
$text-color-taskDueDate: #4D4F5C;
$text-disabled-background-color: #F6F5FB;
$black-color: #000000;
$black-color-1: #0F172A;
$gray-color-placeholder: #64748B;
$background-color-button-hover: #E0EAFD;
$blue-color-placeholder-hover: #4D82F3;
$background-color-input-hover: #F2F5F9;
$gray-input-border-color: #CBD5E1;
$red-color-mandatory-field: #DF0C0C;
$button-submit-hover-background-color : #DCEAFE;
$button-submit-hover-color : #0056B3;

@mixin shadow-header(){
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
}

@mixin shadow-body(){
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

