@import 'src/common/styles/constants';
@import 'src/common/styles/utils';

.header-column {
  h4 {
    font-weight: 600;
  }
}
.task-section-header {
  position: fixed;
  height: 40px;
  z-index: 40;
  transition: all 0.3s ease;
  width: calc(100% - 140px);
  padding-left: 0;
  @include item-center;

  .container-section-task .card{
    box-shadow: 0 2px 6px #0000000A;
    border: .5px solid $box-margin-color;

    .card-name h4{
      color: $black-font-color;
    }
  }
}
.task-section-header, .task-section-header-vertical {
  transition: all 0.3s ease;
}

.task-box-header, .task-box-header-vertical {
  @include col100;
  width: calc(100% - 50px);
  z-index: 1;
  //padding: 30px 0 0 20px;
  height: 40px;
  position: fixed;
  top: 115px;
}
.container-header-task {
  background: white;
  height: $height-container-header;
  z-index: 2;
  left: 0;
  transition: all 0.3s ease;
  width: 100%;
  position: fixed;
  box-shadow: 0 2px 6px #0000000A;
  border: 0.5px solid #EFEFEF;

  .task-name{
    color: $black-font-color;
  }
}
.container-body-task {
  z-index: 100;
  float: left;
  width: 100%;
  padding: 96px 0 0 0;
  min-width: 958px;
  background-color: #FFFFFF;

  .task-section-body {
    position: relative;
    float: left;
    padding-top: 0;
    width: 100%;
    background-color: #FFFFFF;

    #task-section0, #task-section1, #task-section2{
      padding-top: 10px;
      padding-bottom: 15px;
    }

    #section0, #section1, #section2 {
      padding: 0 20px 0 20px;
      //box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    }

  }

  .only-task-section-body {
    position: relative;
    float: left;
    padding-top: 96px;
    width: 100%;
    background-color: #FFFFFF;

    #section0, #section1, #section2 {
      padding: 0 20px 0 20px;
      //box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    }
  }
}

#ContainerTasks{
  padding-top: 0;
}

.section-header-wrapper {
  padding-right: 30px;
  padding-left: 50px;
  margin-top: 5px;
  background-color: #fff;
  box-shadow:
    0 -2px 5px rgba(0, 0, 0, 0.04),
    0 2px 5px rgba(0, 0, 0, 0.04);
}
.container-list-task {
  border: .5px solid $border-button-color-pagination;
  transition: 0.3s;
  height: 80px;
  background: #fff;
  color: #a3a3a4;
  padding: 2px 0;
  position: relative;
  border-radius: 5px;

  .list-task-left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.container-list-task-body .flex-row{
  padding: 0 20px;
  .newSubtask{
    border: .5px solid $border-button-color-pagination;
    transition: 0.3s;
    height: 40px;
    background: #fff;
    color: #5A9FEC;
    padding: 2px 0;
    position: relative;
    border-radius: 5px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
  }
}

.subtask-body-container{
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
}

.line-1, .line-2{
  position: absolute;
  width: 1px;
  border: .5px solid $box-margin-color;
}
.line-1{
  left: 33.3%;
}
.line-2{
  left: 66.6%;
}

@media screen and (max-width: 1024px){
  #dashboardContainer{
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    #taskBoxHeader{
      padding: 0;
      height: 40px;
      width: 1461px !important;
      position: relative;
      top: 55px;

      .task-section-header{
        left: 0 !important;
        width: 1461px !important;
        position: relative;
        .container-section-task{
          margin: 0;
        }
      }
    }

    #bodyTask{
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      width: 1461px;
      padding: 55px 0 0 0;

      #taskSectionBody{
        width: 1461px;
      }
    }

    #ContainerTasks{
      padding-left: 0;
      padding-bottom: 0;
      width: 1461px;
      #taskSectionBodyUpdate{
        width: 1461px;

        .subtask-body-container{
          position: relative;
          .taskHeader{
            //width: calc(100% * 2 / 3 - 40px);
            width: 919px;
            position: sticky;
            top: 0;
            left: 20px;
          }
        }


      }
    }
  }

}

@media screen and (max-width: 853px){
  #dashboardContainer{
    #taskBoxHeader{
      width: 1182px !important;

      .task-section-header{
        left: 0 !important;
        width: 1182px !important;
      }
    }

    #bodyTask{
      width: 1182px;
      padding: 55px 0 0 0;

      #taskSectionBody{
        width: 1182px;
      }
    }

    #ContainerTasks{
      padding-left: 0;
      width: 1182px;
      #taskSectionBodyUpdate{
        width: 1182px;

        .subtask-body-container{
          position: relative;
          .taskHeader{
            width: 748px;
            position: sticky;
            top: 0;
            left: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 820px){
  #dashboardContainer{
    #taskBoxHeader{
      width: 1132px !important;

      .task-section-header{
        left: 0 !important;
        width: 1132px !important;
      }
    }

    #bodyTask{
      width: 1132px;

      #taskSectionBody{
        width: 1132px;
      }
    }

    #ContainerTasks{
      padding-left: 0;
      width: 1132px;
      #taskSectionBodyUpdate{
        width: 1132px;

        .subtask-body-container{
          position: relative;
          .taskHeader{
            width: 715px;
            position: sticky;
            top: 0;
            left: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  #taskBoxHeader{
    top: 78px;
  }

  #bodyTask{
    padding-top: 77px;
    padding-bottom: 0;
    #taskSectionBody{
      width: 110%;

      #task-section0, #task-section1, #task-section2{
        padding-bottom: 0 !important;
        padding-right: 20px;
      }

      #task-section1, #task-section2{
        padding-left: 20px;
      }

      #task-section2{
        padding-right: 0;
      }
    }
  }

  #ContainerTasks{
    padding-left: 0;
    #taskSectionBodyUpdate{
      width: 110%;

      .taskHeader{
        width: 33.33%
      }

      .subtaskContainerSection{
        .container-section-task{
          padding-right: 20px;

          .flex-row{
            padding: 0 0 0 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 390px){
  #dashboardContainer{
    #taskBoxHeader{
      width: 1030px !important;

      .task-section-header{
        left: 0 !important;
        width: 1030px !important;
      }
    }

    #bodyTask{
      width: 1030px;

      #taskSectionBody{
        width: 1030px;

        #task-section1, #task-section2{
          padding-left: 20px;
        }

        #task-section2{
          padding-right: 0;
        }
      }
    }

    #ContainerTasks{
      padding-left: 0;
      width: 1030px;
      #taskSectionBodyUpdate{
        width: 1030px;

        .subtask-body-container{
          position: relative;
          .taskHeader{
            //width: 335px !important;
            position: sticky;
            top: 0;
            left: 20px;
          }

          #taskSectionBody > .container-section-task:nth-child(2), #taskSectionBody > .container-section-task:nth-child(3){
            padding-left: 20px;
          }

          #taskSectionBody > .container-section-task:nth-child(3){
            padding-right: 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait){
  #dashboardContainer {
    #ContainerTasks {
      #taskSectionBodyUpdate {
        .subtask-body-container {
          position: relative;
          .taskHeader {
            position: sticky;
            width: calc(100vw - 40px);
            left: 20px;
            top: 0;
            box-shadow: 0 0 10px #D7DAE2;
          }

          #taskSectionBody > .container-section-task:nth-child(2), #taskSectionBody > .container-section-task:nth-child(3){
            padding-left: 20px;
          }

          #taskSectionBody > .container-section-task:nth-child(3){
            padding-right: 0;
          }
        }
      }
    }
  }
  #containerHeaderTask {
    .task-button{
      .container-task-button-add {
        margin:0;
        .dp-button--icon-only {
          width: 30px;
          height: 30px;
        }
      }
    }

  }
  #dashboardContainer{
    #taskBoxHeader {
      #taskSectionHeader {
        .container-section-task {
          .card {
            .icon-arrow-content {
              display: none;
            }
            .card-task {
              display: none;
            }
          }
        }
      }
    }
  }

  .container-list-task{
    .list-task {
      .list-task-left {
        width: 97%;
        .LinesEllipsis {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

      }
    }
  }
  #containerHeaderTask {
    .task-filter-type {
      .task-type-label {
        font-size: 11px;
        margin-right: 5px;
        }
      .task-container-drop-down {
        .btn-drop-down-task {
          height: 28px;
        }
      }
      .container-icon {
        .icon-sort-content {
          svg {
            height: 10px;
          }
        }
      }
    }
  }
  #ContainerTasks {
    #taskSectionBodySubtask{
      .taskHeader {
        .header-left {
          width: 80%;

          >span{
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .header-right{
          width: 20%;
        }
      }
    }
  }
}