$text-color: #787878;
$light-blue-color-1: #2194F1;
$label-text-color: #666;
$border-default: 1px solid #ccc;
$primary-blue: #3B86FF;
$error-red: rgb(255, 101, 101);
$btn-color-1: #434756;
$btn-color-2: #DEEAFF;
$btn-color-3: #2E5AEB;
$btn-color-4: #E2E8F0;
$btn-color-5: #4D4F5C;
$btn-color-6: #E3E8EF;
$btn-color-7: #E0EAFD;
$text-color-2: #A4C3F4;
$text-color-3: #F1F5F9;
$text-color-4: #CBD5E1;
$text-color-5: #2E5AEB;
$text-color-6: #3758F9;
$text-color-7: #0F172A;
$text-color-8: #D7DAE2;
$text-color-9: #43425D;
$text-color-10: #DF0C0C;
$text-color-11: #526AF1;
$text-color-12: #6d6f7a;
$text-color-13: #292757;
$text-color-14: #1D2130;
$text-color-15: #717171;
$border-color-1: #D9DBE2;
$border-color-2: #D9D9D9;
$border-color-3: #DF0C0C;
$border-color-4: #CCCCCC;
$border-color-5: #66afe9;
$background-color-2: #0F172A;
$background-color-3: #057FFA;
$icon-color-1: #1C1B1F;
$icon-color-2: #73B65F;
$line-color-1: #D7DAE276;

$font-family-1: 'Montserrat';
$font-family-2: 'Inter';
$font-size-1: 16px;
$font-size-2: 14px;

@import "constants";

@mixin item-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin col100() {
  float: left;
  width: 100%;
}

@mixin col33() {
  float: left;
  width: 33.33%;
}

@mixin one-single-line-text() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin simple-line-separator() {
  border: 1px solid #D7DAE2;
  opacity: 1;
  margin: 5px 0;
}

@mixin border-box() {
  padding: 4px 5px 5px 7px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

@mixin btn-disabled() {
  border-color: #bbb;
  background-color: #bbb;
}

@mixin default-popover() {
  overflow: auto;
  max-height: 110px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  border-radius: 2px;
  padding: 5px 0;
}

@mixin classic-button($text-color: #ffffff, $background-color: $green-color, $width: 90px,
$height: 32px, $border: 1px solid $background-color, $font-size: 13px, $font-weight: $font-weight-regular, $border-radius: 4px  ) {
  color: $text-color;
  background-color: $background-color;
  border-radius: $border-radius;
  border: $border;
  height: $height;
  width: $width;
  font-size: $font-size;
  font-family: 'Open Sans', sans-serif;
  font-weight: $font-weight;
  @include item-center;
}

@mixin ellipsis-text($numberOfLines : 4) {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $numberOfLines;
}

@mixin word-break-button() {
  white-space: normal;
  word-break: break-word;
  height: auto;
}

@mixin row-base() {
  float: left;
  width: 100%;
}

@mixin col-50() {
  float: left;
  width: 50%;
}
