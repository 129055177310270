@import "src/common/styles/utils";
.pricing-container{
  font-family: Montserrat, serif;

  .first-row{
    display: flex;
    flex-direction: column;
    background-color: #F5F6FA;
    align-items: center;

    .intro{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;

      .Title{
        font-size: 38px;
        color: $btn-color-5;
        font-weight: bold;

        .Highlight-Title {
          color: $text-color-5;
        }
      }

      .Cancel{
        font-size: 14px;
        color: $btn-color-5;
        font-weight: 300;
        padding-bottom: 25px;
      }

      .try-button{
        background-color: #057FFA;
        border-radius: 1px;
        font-weight: 300;
      }

      .plan-type{
        display: none;
        padding: 40px 0px 20px;

        .month-text{
          font-size: 13px;
          color: #4D4F5C;
          font-weight: 300;
          margin-right: 10px;
        }

        .plan-toggle{
          height: 20px;

          .switch {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 20px;

            .slider{
              background-color: #057FFA;

              &:before{
                height: 20px;
                width: 20px;
              }
            }
            .slider.round.off:before{
              border: 2px #057FFA solid;
            }

            input:checked + .slider{
              background-color: #4AD991;
            }

            input:checked + .slider:before{
              border: 2px #4AD991 solid;
              transform: translateX(20px);
            }
          }
        }

        .annual-text{
          font-size: 13px;
          color: #4D4F5C;
          font-weight: 300;
          margin-left: 10px;
        }
      }
    }

    .tiers{
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      padding: 20px 0 60px 0;
      width: 100%;

      .tier-container{
        display: flex;
        padding: 0 10px;
        height: fit-content;
        width: 413px;

        .tier{
          display: flex;
          flex-direction: column;
          width: 100%;
          background-color: #FFFFFF;

          .recommended-section {
            display: flex;
            justify-content: center;
            background-color: #000000;
            color: #FFFFFF;
          }

          .main-tier-section {
            border-bottom: 1px solid $border-color-2;
            padding: 20px;

            .tier-title{
              color: $text-color-9;
              font-weight: bold;
              font-size: 38px;
            }

            .tier-subtitle {
              font-size: 16px;
            }

            .price-section{
              margin-top: 50px;
              margin-bottom: 10px;
              display: flex;

              height: 40px;
              align-items: flex-end;
              flex-direction: row;

              .tier-price {
                display: flex;
                align-items: flex-start;
                font-size: 38px;
                font-weight: bold;
                color: $text-color-9;
                font-family: "Open Sans";
                margin-right: 5px;
              }

              .period {
                display: flex;
                font-size: 16px;
                color: $text-color-9;
                margin: 0px;
                height: 100%;
              }

              .features{
                display: flex;
                font-size: 9px;
                font-weight: 300;
                color: $text-color-8;
                margin: 0px;
              }
            }

            .free-trial-btn-container {
              display: flex;

              .free-trial-btn {
                margin: 0;
                border: 1px solid $text-color-4 !important;
                border-radius: 6px !important;
                background-color: #FFFFFF !important;

                a {
                  color: $text-color-7 !important;
                  font-weight: 500;
                }

                &:hover {
                  background-color: $btn-color-4 !important;
                }
              }

              .free-trial-recommended-btn {
                margin: 0;
                border-radius: 6px !important;
                background-color: $btn-color-3 !important;
                color: #FFFFFF !important;
                font-weight: 500;

                a {
                  font-weight: 500;
                }

                &:hover {
                  background-color: $btn-color-2 !important;

                  a {
                    color: $text-color-6 !important;
                  }
                }
              }
            }
          }

          .description-section{
            display: flex;
            flex-direction: column;
            padding: 20px;
            font-size: 18px;
            justify-content: space-between;

            .tier-item {
              height: 34px;
              display: flex;
              align-items: center;

              .tier-item-text {
                font-size: 16px;
                min-height: 34px;
                padding-left: 5px;
                display: flex;
                align-items: center;
              }

              .right-svg-image-container {
                height: 18px;
                width: 18px;
              }

              .right-icon-svg {
                height: 18px;
                width: 18px;

                path {
                  fill: $icon-color-1;
                }
              }
            }

            .desc-1{
              display: flex;

              .desc-text1{
                font-size: 13px;
                color: #4D4F5C;
                font-weight: bold;
                margin-right: 2px;
              }

              .desc-team{
                font-size: 13px;
                color: #4D4F5C;
                font-weight: 300;
              }
            }

            .desc-2{
              display: flex;

              .desc-text2{
                font-size: 13px;
                color: #4D4F5C;
                font-weight: bold;
                margin-right: 2px;
              }

              .desc-listing{
                font-size: 13px;
                color: #4D4F5C;
                font-weight: 300;
              }
            }

            .desc{
              font-size: 13px;
              color: #4D4F5C;
              font-weight: 300;
            }
          }

          .features-button{
            >span{
              font-weight: 300;
            }
          }

          .show-more-container{
            display: flex;
            flex-direction: column;
            align-items: center;

            .Try-feature-button{
              background-color: #057FFA;
              border-radius: 1px;
              font-weight: 300;
            }

            .less-button{
              >span{
                font-weight: 300;
              }
            }
          }


          .plan-button{
            background-color: #057FFA;
            border-radius: 1px;
            font-weight: 300;
            display: none;
            width: 100px;
            height: 30px;
            font-size: 11px;

            a{
              display: flex;
              align-items: center;
            }
          }
        }

        &:hover{
          .plan-button{
            display: flex ;
          }
        }
      }
    }
  }

  .second-row{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px 70px;
    background-color: $background-color-2;

    .title-plans{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 50px;
      width: 100%;

      .title1{
        color: $text-color-2;
        font-weight: 300;
        font-size: 14px;
      }

      .title2{
        color: $text-color-3;
        font-weight: bold;
        font-size: 35px;
      }
    }

    .table-plans{
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;
      width: 100%;

      .table-content {

        table{

          .header-elements{
            tr th{
              border-bottom: 1px solid;
              border-left: 1px solid;
              border-color: $border-color-1;
              text-align: center;
              padding-bottom: 5px;

              .title{
                width: 100%;
                color: $text-color-4;
                font-size: 25px;
              }
              .price{
                color: $text-color-3;
                font-size: 18px;
              }
            }
            .th-tier-0{
              text-align: left;
              vertical-align: bottom;
              border-left: none;
            }
          }

          .body-elements{
            tr{
              td{
                border-bottom: 1px solid;
                border-left: 1px solid;
                border-color: $border-color-1;
                text-align: center;
                padding: 10px 0;
                width: 160px;
                color: $text-color-3;
              }

              >td:nth-child(1){
                border-left: none;
                text-align: left;
                width: 300px;
              }

            }

            .tr-tier-details-2, .tr-tier-details-6, .tr-tier-button{
              td{
                border-bottom: none;
              }
            }

            .tr-tier-details-0, .tr-tier-details-1{
              td{
                padding: 17px 0;
              }
            }

            .tr-tier-details-8, .tr-tier-details-10, .tr-tier-details-12{
              .tier1-det-12{
                font-weight: bold;
                font-size: 20px;
              }
            }

            .tr-tier-button{
              td{
                padding-bottom: 0px;
              }
            }
          }
        }
        .tr-tier-button{
          .table-button{
            background-color: $btn-color-3;
            border-radius: 6px;
            margin: 0 auto;
            display: inline-block;

            a {
              font-weight: 400;
            }

            &:hover {
              background-color: $btn-color-2 !important;

              a {
                color: $text-color-6 !important;
              }
            }
          }
        }
      }
    }
  }

  .footer-section-gdg{
    background-color: #F5F6FA;
  }

  .check-normal{
    width: 100%;


    >div{
      svg{
        width: 15px;
        height: auto;
        fill: #4AD991;
      }
    }
  }

  .title-component{
    display: flex;
    flex-wrap: wrap;

    .title{
      width: 100%;
      font-size: 25px;
      color: $text-color-4;
    }
    .subtitle1, .subtitle2{
      font-size: 13px;
      font-weight: 300;
      color: $text-color-3;
    }
    .subtitle1{
      padding-right: 5px;
    }
    .subtitle2{
      color: $text-color-2;
      cursor: pointer;
    }
  }

  .Feature-component{
    .subtitle1, .subtitle2{
      font-size: 16px;
      font-weight: 300;
    }
    .features-list{
      display: flex;
      justify-content: space-between;
      padding: 10px 0;

      .title{
        width: auto;
        font-size: 16px;
        font-weight: 300;
      }

      .dp-icon, span{
        display: flex;
        justify-content: center;
        width: 25px;
      }

      span{
        font-weight: bold;
        font-size: 18px;
      }
    }
    .line-separator{
      height: 1px;
      border-bottom: 1px solid $border-color-1;
      width: 98%;
      margin: 0;
    }

    .line-separator:nth-last-child(1){
      display: none;
    }
  }
}

@media (min-width: 1500px){
  .pricing-container .first-row .tiers {
    gap: 50px;
    justify-content: center;
  }
}

@media (max-width: 1300px){
  .pricing-container .first-row .tiers .tier-container{
    width: 380px;
  }
}

@media (max-width: 1200px){
  .pricing-container .first-row .tiers .tier-container{
    width: 350px;
  }
}

@media (max-width: 1100px){
  .pricing-container .first-row .tiers .tier-container{
    width: 330px;
  }
}

@media (max-width: 1024px){
  .pricing-container{
    .first-row{
      .intro{
        .Title{
          font-size: 38px;
          text-align: center;
          word-break: break-word;
        }

        .Cancel{
          font-size: 16px;
        }

        .try-button{
          width: 155px;
          height: 50px;
          >span{
            font-size: 16px !important;
          }
        }

        .plan-type{
          .month-text, .annual-text{
            font-size: 16px;
          }
          .plan-toggle{
            .switch{
              width: 50px !important;
              height: 25px !important;

              .slider{
                &:before{
                  height: 25px !important;
                  width: 25px !important;
                }
              }
              input:checked + .slider:before{
                transform: translateX(25px);
              }
            }
          }
        }
      }
      .tiers{
        padding: 0px 100px 60px 100px;
        justify-content: space-between;
        width: 100%;

        .tier-container{
          width: 360px;

          .tier{
            width: 100%;

            .tier-title{
              font-size: 30px;
            }

            .separator-1, .separator-2{
              width: 100%;
            }

            .price-section{
              .first-part{
                .tier-price{
                  font-size: 62px;
                }
              }

              .features{
                font-size: 16px;
              }
            }

            .description-section{
              .desc-1, .desc-2{
                .desc-text1, .desc-team, .desc-text2, .desc-listing{
                  font-size: 16px;
                }
              }
              .desc{
                font-size: 16px;
                word-break: break-word;
                width: 50%;
                min-width: 200px;
              }
            }

            .plan-button{
              display: flex;
            }

            .main-tier-section {
              .free-trial-btn-container {
                display: flex;

                .free-trial-btn {
                  margin: 0;
                  border-radius: 6px !important;
                  background-color: $btn-color-1 !important;

                  a {
                    color: #FFFFFF !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .title-component{
      .subtitle1, .subtitle2{
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 950px){
  .pricing-container .first-row .tiers {
    padding: 0px 80px 60px 80px;
  }

  .pricing-container .first-row .tiers .tier-container{
    width: 340px;
  }
}

@media (max-width: 880px){
  .pricing-container {
    .first-row {
      height: auto;
    }

    .footer-section-gdg{
      bottom: 0;
    }
  }

  .pricing-container .first-row .tiers {
    padding: 0 30px 30px 30px;
  }
}

@media (max-width: 767px){
  .pricing-container{
    .first-row{
      height: auto;
      .tiers{
        padding: 10px 20px;
        justify-content: center;
        .tier-container{
          padding: 0px 0px 30px 0px;
          width: 368px;
        }
      }
    }

    .title-component{
      .subtitle1, .subtitle2{
        font-size: 13px;
      }
    }
  }

  .pricing-container .first-row .intro .Title {
    font-size: 28px;
  }

  .pricing-container .first-row .intro .Cancel {
    font-size: 16px;
    display: flex;
    flex-direction: column;
  }

  .pricing-container .second-row {
    align-items: flex-start;
  }

  .pricing-container .second-row .table-plans{
    align-items: flex-start;
    overflow: auto;
    width: 100%;

    .table-content{
      width: 800px;
    }
  }

  .pricing-container .second-row .title-plans .title2{
    font-size: 28px;
  }
}

@media (max-width: 500px){
  .pricing-container{
    .first-row{
      .tiers{
        .tier-container{
          width: 100%;

          .tier{
            width: 100%;

            .separator-1, .separator-2{
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .pricing-container .first-row .intro .Cancel {
    font-size: 14px;
  }
}
