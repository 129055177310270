@import "src/common/styles/utils";
@import "src/common/styles/constants";

.request-demo-modal .modal-dialog{
  min-width: 600px;
  width: 40%;
}

.box-demo{
  font-family: 'Montserrat';
  background: $background-color-input;
  padding: 0 60px 40px 60px;
  .container-icon-cancel{
    margin-top: 20px;
    margin-left: 50px;
    width: 100%;
    display: flex;
    justify-content: end;
    svg{
      height: 15px;
      width: 15px;
    }
  }

  .logo-godealergo{
    width: 200px;
    margin: 0 auto 30px;
    display: none;
  }
}

.marketing-form{
  h3{
    font-size: 28px;
    font-weight: 800;
    text-align: center;
    margin-top: 20px;
    color: $black-color;
  }
  >span{
    text-align: center;
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: $black-color-1;
  }
  .container-text{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    .line-left, .line-right{
      width: calc(50% - 120px);
      border-top: 1px solid;
      color: $line-color-1;
    }
    span{
      color: $text-color-13;
      font-weight: 700;
      font-size: 10px;
    }
  }

  .container-inputs{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    label{
      font-size: 14px;
      font-weight: 500;
      color: $black-color-1;
    }
    span{
      color: $text-color-10;
      font-size: 12px;
      font-weight: 600;
    }
    .label-make{
      font-size: 14px;
      font-weight: 500;
      color: $black-color-1;
    }
    >div{
      width: 48%;
      padding-right: 0;
      padding-bottom: 15px;
      input, .select{
        height: 40px;
        border-radius: 6px;
        border: 1px solid $text-color-4;
        box-shadow: none;
        font-family: 'Montserrat' !important;
      }
      input::placeholder{
        color: $gray-color-placeholder !important;
        font-weight: 400;
      }
    }
    .container-label{
      .label-make{
        font-size: 14px;
        font-weight: 400;
        color: $gray-color-placeholder;
      }
    }
    .w100{
      width: 100%;
    }
    .container-button-send{
      width: 100%;
      @include item-center;
    }
    .market-btn{
      border-radius: 6px;
      height: 40px;
      font-weight: 300;
      font-size: 15px;
      background-color: $btn-color-3;
      margin: 16px 0 0 0;
      @include item-center;
      width: auto;
      padding: 14px 22px;

      a{
        font-size: 14px;
        font-weight: 500;
        color: $background-color-input;
      }
    }
    .market-btn:hover {
      background-color: $light-blue-color-3 !important;
      a{
        color: $btn-color-3;
      }
    }
    .last-input{
      padding-bottom: 10px;
    }
    .required-text{
      font-size: 11px;
      display: none;
    }
    .dp-select-dropdown{
      .help-block{
        position: unset;
        font-size: 11px;
      }
    }
  }
  .container-inputs{
    .dp-select-dropdown {
      .container-title {
        .label-error {
          color: $button-color-red;
          font-size: 14px;
        }
      }
    }
  }
}

.loader-demo{
  opacity: 0.6 !important;
  background-color: $black-color !important;
}

@media (max-width: 1024px) {
  .box-demo .container-icon-cancel{
    display: none;
  }

  .marketing-form h3{
    margin-top: 40px;
  }
}