@import "catalog";
@import "src/catalog/templateMain/templateMain";
@import "src/catalog/template5/template5";
@import "utils";
@import "constants";
@import "page-builder";
@import 'review';
@import "tasks";
@import "message";
@import 'testimonials';

.loader-marketing{
  background: #fff9 !important;
}

.non-resize{
  resize: none;
}

.light-blue {
  color: $light-blue-color-2;
}

.right {
  float: right;
}

.error-color {
  color: $button-color-red !important;
}

.pagination-right {
  display: flex;
}

@mixin container-adjust-huener($width-container: 100%, $height-container: 100%, $width-adjust-huener: 64px, $height-adjust-huener: 64px, $width-border-adjust-huener: 7px,
$color-adjust-huener: #3498db, $color-secondary-adjust-huener: #f3f3f3) {
  width: $width-container;
  height: $height-container;
  position: absolute;
  @include item-center;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  .loader {
    border: $width-border-adjust-huener solid $color-secondary-adjust-huener;
    border-top: $width-border-adjust-huener solid $color-adjust-huener;
    border-radius: 50%;
    width: $width-adjust-huener;
    height: $height-adjust-huener;
    animation: adjust-hue 1s linear infinite;
  }
  @keyframes adjust-hue {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.container-adjust-huener {
  @include container-adjust-huener;
}

@mixin cancel-button($font-size: 13px,$margin: 0,$text-color: $button-color-red ) {
  border: none;
  background: transparent;
  letter-spacing: 0;
  color: $text-color;
  opacity: 1;
  margin: $margin;
  font-size: $font-size;
  @include item-center;
}

@mixin item-prefix-input($left: 22px,$width: 8px, $color: $text-color, $top: 1px, $font-size: 14px, $height: 100%) {
  position: absolute;
  z-index: 1;
  height: $height;
  display: flex;
  align-items: center;
  font-size: $font-size;
  width: $width;
  left: $left;
  color: $color;
  top: $top;
}

.disabled-select {
  background-color: #80808066 !important;
}

.cursor-default {
  cursor: default !important;
}

.Popover-status-inventory-photo {
  .container-icon-exclamation {
    height: 34px !important;
    margin: 0 !important;
    display: flex !important;
    @include item-center ;

    .icon-error {
      div {
        @include item-center;

        .svg-icon-error {
          height: 14px;
          width: 14px;
          fill: $icon-exclamation-color;
        }
      }
    }

    .title-suggestion {
      box-shadow: 0 0 10px #0000002B;
      position: absolute;
      right: 0;
      top: 28px;
      border: 1px solid rgb(215, 218, 226);
      border-radius: 5px;
      opacity: 1;
      background-color: white;
      z-index: 2200;
      height: 26px;
      align-items: center;
      padding: 5px 10px;
      display: none;

      .icon-error {
        padding-right: 5px;
      }

      label {
        color: $text-color-table;
        font-weight: 500;
        font-size: 12px;
        margin: 0;
      }
    }
  }
}

.thread-class {
  tr {
    th {
      padding: 15px 0;
      font-size: $text-size-select;
    }
  }
}

.tbody-class {
  tr {
    td {
      padding: 14px 0 14px 3px !important
    }
  }
}

.icon-arrow-content {
  float: right;
  padding: 1px 5px;
}

@mixin shadow-header(){
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
}

@mixin shadow-body(){
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.main-header {
  position: relative;
  max-height: 100px;
  z-index: 100;

  .navbar {
    -webkit-transition: margin-left .3s ease-in-out;
    -o-transition: margin-left .3s ease-in-out;
    transition: margin-left .3s ease-in-out;
    margin-bottom: 0;
    margin-left: 230px;
    border: none;
    min-height: 68px;
    border-radius: 0;
    background-color: #eff3f6;
    border-bottom: 10px solid #337ab9;
  }

  .navLeft {
    width: 30%;
  }

  .navCenter {
    width: 40%;
    justify-content: center;
    display: flex;
    margin-top: 2px;
  }

  .navRight {
    width: 30%;
    justify-content: flex-end;
    display: flex;
    padding-top: 10px;
  }

  .fa-plus-circle, .glyphicon-user, .glyphicon-question-sign, .glyphicon-search {
    color: #337ab9;
  }

  .messages-envelope-o {
    color: rgb(128, 128, 128);
  }

  .iconSearch {
    font-size: 28px;
  }

  button:focus, i:focus, a:focus {
    border: 0 !important;
    outline: 0 !important;
    background: transparent !important;
    box-shadow: none !important;
  }

}

li.dropdown {
  display: inline-block;
}

.dropdown-content {
  opacity: 0;
  visibility: hidden;
  width: 312px;
  position: absolute;
  top: 60px;
  left: -135px;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, .5);
  border-radius: 3px;
  z-index: 1000;

  .columnHelp {
    width: 100%;
    float: left;
    padding: 20px 15px;

    h4 {
      margin-top: 0;
      padding-left: 5px;
      font-weight: 700;
      color: #666464;
    }
  }

  .column {
    width: 50%;
    float: left;
    padding: 20px 15px;

    h4 {
      margin-top: 0;
      padding-left: 10px;
      font-weight: 700;
      color: #666464;
    }
  }

  .columnPlus {
    width: 33.33%;
    float: left;
    padding: 20px 0 20px 20px;

    h4 {
      margin-top: 0;
      padding-left: 10px;
      font-weight: 700;
      color: #666464;
    }

    .nameTittle {
      width: 55%;
      padding-right: 0;
      padding-left: 10px;
    }

    .nameIcon {
      width: 45%;
      padding-left: 0;
    }
  }

  .columnUserFirst {
    width: 65%;
    float: left;
    padding: 20px 15px;

    h4 {
      margin-top: 0;
      padding-left: 5px;
      font-weight: 700;
      color: #666464;
    }
  }

  .columnUserSecond {
    width: 35%;
    float: left;
    padding: 20px 10px;
    background-color: #f7f5f5;

    h4 {
      margin-top: 0;
      padding-left: 5px;
      font-weight: 700;
      color: #666464;
    }
  }

  .columnUser {
    .btn {
      padding-left: 0;
    }

    ul {
      display: none;
      padding: 0 0 0 10px;

      li {
        list-style: none;

        a {
          color: #777;
        }
      }
    }
  }

  .column, .columnUserFirst, .columnUserSecond, .columnHelp, .columnPlus {
    .btn {
      background-color: transparent;
      padding-left: 5px;
      width: 100%;
      text-align: left;
      padding-bottom: 2px;
      padding-top: 2px;
      margin-bottom: 6px;
    }
  }

  .flyout-header {
    position: absolute;
    top: -10px;
    left: -1px;
    height: 10px;
    background-color: #1790d6;
    border-left: 1px solid #1790d6;
    border-right: 1px solid #1790d6;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    box-sizing: content-box;
    width: 100%;
  }

  .flyout-tip {
    border-bottom: 10px solid #1790d6;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-top-color: #1790d6;
    content: "";
    background-color: transparent;
    position: absolute;
    transition: transform 35s;
    -webkit-transition: -webkit-transform 35s;
    opacity: 1;
    z-index: -1;
    justify-content: center;
    display: flex;
    top: -20px;
    margin-left: 152px;
  }

  h3 {
    margin-top: 0;
    padding-left: 10px;
    margin-bottom: 0;
  }

  .rowCarBox {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    float: left;
    padding-top: 5px;

    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .rowCarBox :hover {
    background-color: #1790d6;
  }

  .hoverPlus :hover {
    background-color: #1790d6;
  }
}

.columnUser a, .hoverPlus a, .hoverHelp a {
  color: #777 !important;
}

.columnUser a:hover, .hoverPlus a:hover, .hoverHelp a:hover {
  background-color: #1790d6 !important;
  color: white !important;
}

.dropdown-content a:hover {
  background-color: #f1f1f1
}

.filterStyle:hover .priceNoShow {
  display: block;
  width: 8%;
  background-color: #f8f8f8;
  height: 122px;
  padding: 4px 5px 15px 5px;
  border: 1px solid #cccccc;
  position: absolute;
  z-index: 10;
  margin-left: 20.3%;
  margin-top: -17px;
}

.showBoxHistory, .showBoxPlus, .showBoxHelp, .showBoxUser, .showBoxCarHelp {
  display: block;
  visibility: visible;
  opacity: 1;
}

.main-sidebar {
  display: none;
}

.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  width: inherit;

  li {
    position: relative;
    margin: 0;
    padding: 0;
    width: inherit;

    .icon-container {
      float: left;
      padding: 13px 15px;
    }

    .icon-menu {
      width: 20px;
      height: auto;
      fill: #ffffff;

      path, rect {
        fill: #ffffff;
      }

      .a {
        stroke: none;
      }
    }
  }
}

.sidebar-menu {
  a {
    color: #93a4c2;
    cursor: pointer;
    text-decoration: none;
  }

  #sidebar-more-options {
    border: 0;
    background-color: transparent;
    display: none;
    cursor: default;
  }
}

.name-home {
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;

  a {
    cursor: pointer;
    text-decoration: none;
    color: white;
  }
}

.user-panel {
  a {
    cursor: pointer;
    text-decoration: none;
    color: #8b91a0;
  }

  a :hover {
    color: #fff;
  }
}

.name-client {
  color: white;
  font-weight: 600;
}

.user-panel {
  position: relative;
  width: 100%;
  padding: 10px;
  overflow: hidden;
}

.img-circle {
  border-radius: 50%;
  margin-top: 20px;
}

.labelWelcome {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
  color: white;
}

.sidebar-menu {
  li {
    color: #93a4c2;
    float: left;
    width: inherit;
    height: 46px;
    cursor: pointer;

    a {
      span {
        padding-left: 10px;
      }

      img {
        width: 20px;
      }
    }

    .custom-badge {
      padding: 0 !important;
      float: left;

      span {
        background-color: red !important;
        color: white !important;
        top: 5px !important;
        right: 5px !important;
        width: 12px !important;
        height: 12px !important;
      }
    }
  }
}

.menu-sidebar :hover {
  color: #fff;
}

a.active {
  color: #fff;
}

.image-user {
  width: 50%;
  padding: 0;
  text-align: center;
}

.label-user {
  width: 50%;
  padding: 0;
}

.f-icon-facebook-profile {
  height: 0;
  padding-left: 11px;
  display: flow-root;
}

.f-icon-instagram-profile {
  height: 0;
  padding-left: 11px;
  display: flow-root;
}

.f-icon-linkedin-profile {
  height: 0;
  padding-left: 0;
  display: flow-root;
}

.icon-facebook-profile {
  height: 20px;
  position: relative;
  float: left;
  top: 9px;
  fill: rgb(0, 116, 247);
}

.icon-instagram-profile {
  fill: rgb(231, 65, 223);
  height: 20px;
  position: relative;
  float: left;
  top: 9px;
}

.check-circle-profile {
  height: 0;
  padding-right: 11px;
  display: flow-root;
}

.icon-check-circle {
  height: 22px;
  position: relative;
  float: right;
  top: -30px;
  fill: rgb(140, 231, 185);
}

.popover-category {
  padding-top: 10px;
}

#invBtnList, #invBtnGrid, #invBtnRepeat {
  border: 0;
  outline: 0;
  font-size: 20px;
}

.icon-header {
  padding: 5px;
}

.icon-arrow-expand {
  height: $icon-arrow-height-svg;
  fill: #A4AFB7;
}

.circle {
  width: 16px;
  height: 16px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #f35958;
  float: right;
}

.circle-car-edit {
  width: 40px;
  height: 40px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #f35958;
}

.icon-label {
  padding-left: 5px;
  padding-top: -2px;
  font-size: 12px;
  color: white;
}

.label-account {
  padding-left: 10px;
}

.line-separator {
  height: 1px;
  background: #8b91a0;
  border-bottom: 1px solid #313030;
  width: 90%;
  margin-left: 5%;
}

.car-separator {
  height: 10px;
  background: #e5e9ec;
}

.v-separator {
  content: "";
  display: inline-block;
  width: 0;
  height: 17px;
  border-right: 1px solid #fafafa;
  border-left: 1px solid #b4b4b4;
  padding: 0;
  position: relative;
  top: 10px;
}

.input {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
}

.icon-search {
  padding: 8px 0 0 8px;
}

.input-search {
  padding: 4px 0 0 0;
}

.title-header {
  background-color: #e5e9ec;
  height: 70px;

  h1 {
    padding-top: 15px;
    margin-left: 2%;
    color: #cbcccc;
  }
}

#typeahead-box {
  color: white;
  background-color: #575A5D;
  height: auto;
  position: relative;

  .ic-tokens {
    padding: 4px 6px;
  }

  .ic-tokeninput-list {
    display: none;
  }

  .ic-tokeninput-is-open .ic-tokeninput-list {
    display: block;
  }

  .ic-tokeninput-input {
    max-width: 40px;
    border: 0 none;
    background-color: transparent;
    color: white;
  }

  .ic-tokeninput-input:focus {
    outline: 0;
  }

  .ic-token {
    border: 1px solid #C1C7CF;
    padding: 3px 8px;
    margin-right: 6px;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .ic-token-delete-button {
    margin-right: 5px;
    cursor: pointer;
  }

  .tokenizer-values-list {
    position: absolute;
  }
}

.ic-tokens {
  position: relative;
  padding: 0;
}

.inline-flex {
  display: inline-flex;
}

.ic-tokeninput-list {
  position: absolute;
  z-index: 1;
  border: 1px solid #aaa;
  background: #fff;
  top: 100%;
  left: 0;
  padding: 5px 0;
  color: black;
  width: 100%;
  box-sizing: border-box;
}

.ic-tokeninput-option {
  display: block;
  padding: 2px 16px;
  cursor: default;
}

.ic-tokeninput-button {
  display: none;
}

.ic-tokeninput-option:focus {
  outline: 0;
  color: white;
  background: hsl(200, 50%, 50%);
}

.typeahead-combobox {
  border-bottom: 1px solid #aaa;
  padding: 2px 8px;
  font-weight: $font-weight-bold;
}

.typeahead-combobox:focus {
  outline: none;
}

.csv-header {
  position: relative;
  padding: 0;
}

.csv-header-selected {
  color: green;
}

.csv-cell-error {
  color: red;
}

.message-not-found {
  background-color: #e5e9ec;
  height: 40px;

  h3 {
    padding-top: 7px;
    margin-left: 2%;
    color: #cbcccc;
  }
}

.back-inventory {
  background-color: #e5e9ec;
  height: 50px;
  font-size: 12px;
  position: absolute;
  margin-left: 60%;
  width: 39%;
}

.container-car {
  background-color: #e5e9ec;
}

.header-specs {
  display: flex;

  h3 {
    margin-bottom: 0;
  }

  p {
    font-size: 12px;
    color: #c3c4cc;
  }

  .label-car-title {
    padding-left: 0;
    float: left;
  }

  .label-car-desc {
    font-size: 12px;
    color: #c3c4cc;
    font-weight: $font-weight-light;
  }

  .form-group {
    margin-bottom: 0;
  }

  .label-car-desc-row {
    margin-top: -15px;
    margin-left: 15px;
  }

}

.header-administrative {
  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.header-inventory {
  h3 {
    margin-bottom: 0;
  }

  p {
    font-size: 12px;
    color: #c3c4cc;
    margin-bottom: 0;
  }
}

.rowCar {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  float: left;
  margin-right: 45px;
}

.rowRepairLeft {
  width: 95%;
  float: left;
}

.rowCost {
  i {
    top: 0;
    position: absolute;
    z-index: 1;
    padding: 35px 8px;
  }

  input {
    padding-left: 20px;
  }
}

.text-area-repair {
  padding-left: 15px;
}

.mod-repair, .mod-image, .mod-users, .mod-locations, .mod-customers {
  background-color: #337ab7;
  color: white;
}

.mod-newattribute {
  background-color: white;
  color: rgb(179, 187, 192);
  height: 50px;
}

.btn-mod {
  background-color: #cbcccc;
}

.btn-mainImage {
  color: #fff;
  background-color: #238fcf;
  border-color: #238fcf;
}

.repair-summary {
  padding-bottom: 70px;
  margin-left: 15px;

  table {
    width: 20%;

    .type-repair {
      width: 16%;
    }

    .format-currency {
      width: 1%;
      text-align: right;
    }

    .format-number {
      width: 3%;
      text-align: right;
    }
  }
}

.columnCar {
  padding-left: 0;
  padding-right: 0;
}

.car-label {
  width: 40%;
  float: left;
  font-size: 11.5px;
  margin-top: 5px;
  text-align: left;
  color: #666771;

  label {
    font-weight: $font-weight-light !important;
  }
}

@mixin form-control() {
  font-family: 'Open Sans', sans-serif !important;
}

.car-input {
  width: 62%;
  padding-left: 0;
  padding-right: 0;
  float: left;

  .form-control {
    height: 28px;
  }

  i {
    top: 41px;
    position: absolute;
    z-index: 1;
    padding: 35px 4px 2px 4px;
  }

  #miles {
    padding-left: 8px;
  }

  #suggestedPrice {
    padding-left: 16px;
  }

  #description {
    height: 75px;
  }
}

.location-select {
  width: 62%;
  padding-left: 0;
  margin-left: 14px;
  padding-right: 0;
  float: left;
}

.body-specs {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 44px;
  display: flex;

  .panel-body {
    padding: 15px 0 15px 0;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .car-upload-des {
    font-size: 12px;
    color: #c3c4cc;
    padding-left: 10px;
  }

  .form-horizontal {
    float: left;
  }
}

.table-header {
  background-color: blue;
}

#invCarouselOne {
  margin-top: 3px;
  border-style: solid;

  .carousel-control.right, .carousel-control.left {
    width: 39px;
  }
}

#invCarouselTwo {
  height: 70px;
  background-color: #555358;

  .carousel-inner {
    margin-top: -18px;
    height: inherit;

    .active, .item {
      margin-top: -15px;
      height: inherit;
    }
  }

  a {
    margin-top: 0;
  }

  .carousel-control {
    width: 3%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: #555358;
    height: inherit;

    .carouselPrev, .carouselNext {
      font-size: 16px;
    }
  }

  .carousel-indicators {
    display: none;
  }
}

.containerCarouselTwo {
  height: 73px;

  .car-image-plus {
    background-color: #dcd9db;
    width: 17%;
    padding: 0;
    height: 70px;

    a {
      height: 50px;
      justify-content: center;
      display: flex;
      margin-top: 0;
      text-decoration: initial;
      cursor: pointer;
    }

    label {
      font-size: 7.5px;
      justify-content: center;
      display: flex;
    }
  }

  .carouselTwo {
    padding: 0;
    width: 83%;
  }
}

#carInvItems {
  padding-top: 15px;
  padding-left: 14px;
  padding-right: 14px;
}

.link-inventory {
  float: right;
  margin-top: 20px;
  margin-left: 15px;
}

.link-inventory-icon {
  margin-top: 12px;
  float: right;
  font-size: 24px;
  margin-left: 5px;
  color: #878787;
}

.car-text-area {
  width: 100%;
  padding-left: 25px;
  padding-right: 0;
  float: left;

  #description {
    height: 115px;
  }
}

.body-mechanical {
  border-right: 10px solid #e5e9ec;
  padding-bottom: 20px;
  width: 27%;

  .form-group {
    margin-bottom: 10px;
  }
}

.label-car-title {
  h5 {
    font-size: 24px !important;
    font-weight: 500;
    margin-bottom: -16px;
    padding-left: 10px;
    color: #777777;
  }
}

.body-carInfo {

  border-right: 10px solid #e5e9ec;
  width: 27%;
  padding-left: 0;
}

.body-carousel {
  width: 46%;

  .panel-body {
    padding: 15px 0 0 0;
  }

  .label-tittle {
    font-size: 24px;
    font-weight: 700;
    padding-left: 10px;
    margin-bottom: 0;
    color: black;
  }

  .car-image-plus {
    margin-left: -11%;
    margin-top: 1px;
  }

  .form-horizontal {
    width: 100%;
  }

  .image-gallery-slide .image-gallery-description {
    bottom: 0;
    left: 86%;
    background: transparent;

    .delete {
      border-radius: 50%;
      background: #238fcf;
    }

    .car-icon-delete {
      font-size: 28px;
      color: white;
      margin-left: -3px;
      margin-right: -3px;
    }
  }

  .image-gallery-thumbnail {
    padding-right: 0;
    float: left;
  }
}

.react-autosuggest__suggestions-list {
  list-style: none;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out 15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out 15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s
}

.react-autosuggest__input {
  list-style: none;
  display: block;
  width: 100%;
  height: 28px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out 15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out 15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s
}

#react-autowhatever-1 {
  position: absolute;
  background-color: white;
  width: 50%;
}

.btnRight {
  width: 15%;
  float: left;
  padding: 0;
  margin-top: 5px;

  .btnSubmit {
    color: #fff;
    background-color: #f89406;
    border-color: #f89406;
    padding: 0;
    height: 30px;
    width: 80px;
  }

  .col50 {
    width: 50%;
    float: left;
  }
}

.body-management {
  .panel-body {
    padding: 15px 0 15px 0;
  }
}

/******aboutUS Easy Navigation Template ******/

@mixin body-sec-details() {
  @include col100;
  padding: 5px 0;
  border-left: 1px solid #EFEFEF;
  border-right: 1px solid #EFEFEF;
  border-bottom: 1px solid #EFEFEF;
  border-radius: 0 0 5px 5px;
}

.about-us-section {
  float: left;
  margin-top: 10px;

  .row-sec {
    .body-sec-details {
      .row-sec-info {
        border-bottom: 0;
      }

      .popover-box-category {
        padding: 0;
      }
    }

    .btn-message-dp {
      cursor: default;
    }
  }

  @mixin label-sec() {
    color: #bec1c5;
    font-weight: 300;
    margin: 0;
    padding-bottom: 3px;
    padding-top: 10px;
    font-size: 14px;
  }

  .row-button {

    @include label-sec;

    label {
      font-size: 11px
    }

    .sec-switch {
      float: right;
      padding-top: 8px;

      .switch {
        margin: 0;
      }
    }

    padding: 0 20px 0 20px;
  }

  .align-right {
    float: right !important;
  }

  .input-values {
    padding: 10px !important;
  }

  .containers-icons {
    width: 20%;
    display: grid;

    label {
      width: 100%;
      padding-bottom: 4px;
    }

    .section-icon {
      @include item-center;
      width: 100%;
      border: 1px solid #EFEFEF;
      border-radius: 5px;
      height: 80%;

      svg {
        fill: #787878;
      }

      i {
        color: #787878;
        padding-left: 4px;
      }
    }

  }

  .body-sec-hours-locations {
    display: inline-block;
    padding: 5px 2% !important;
    @include body-sec-details;

    .titles {

      label {
        padding-left: 5px;
      }

      @mixin label-sec() {
        float: left;
        margin: 0;
        color: #A4AFB7;
        font-weight: $font-weight-regular;
        padding-top: 4px;
      }

      .label-sec-days {
        @include label-sec;
        width: 56%;
      }

      .label-sec-from {
        @include label-sec;
        width: 22%;

      }

      .label-sec-to {
        @include label-sec;
        width: 22%;
      }
    }

    .row-days {
      width: 100%;
      display: inline-block;
      height: 34px;
      margin-top: 5px;

      .days {
        width: 54%;
        float: left;
        border: 1px solid $box-margin-color;
        border-radius: 4px;
        height: 34px;
        padding: 0 8px;
        margin-right: 2%;
        @include item-center;

        i {
          color: $icon-color;
          width: 50%;
        }

        .fa-chevron-down:before {
          float: right;
        }

      }

      input {
        border: 1px solid #EFEFEF;
        height: 34px;
        border-radius: 4px;
        width: 21%;
        text-align: center;
        -webkit-box-shadow: none;
      }

      .from {
        margin-right: 2%;
      }

    }

    .label-icon {
      width: 20%;
    }

  }

  .body-sec-media {
    display: inline-block;
    padding: 5px 2% !important;
    @include body-sec-details;

    #labelFilesMax {
      float: right !important;
    }

    .container-url-video {
      padding-bottom: 10px;

      input {
        border: 1px solid #EFEFEF;
        height: 34px;
        border-radius: 4px;
        width: 100%;
        padding-left: 4px;
      }
    }

    .container-drop-zone {
      float: right;
      padding: 2px;
      width: 25%;

      #dropZone {
        border: 1px dotted #A4AFB7;
        float: right;
        border-radius: 5px;

        .container-images {
          padding: 0 4px;

          .label-upload-logo {
            font-size: 10px;
            text-align: center;
          }
        }

        .cloud-upload-content {
          @include item-center;

          svg {
            fill: #A4AFB7;
          }
        }

        .label-upload-file {
          padding-top: 4px;
        }
      }

      > div:focus {
        outline: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .uploaded-images {
      display: inline-block;
      width: 100%;

      .current-image-container {
        width: 25%;
        float: left;
        padding: 2px;
        position: relative;

        .react-player__preview {
          border-radius: 5px;
        }

        > div {
          height: 65px !important;
        }

        img {
          width: 100%;
          height: 65px;
          border-radius: 5px;
          object-fit: cover;
        }
      }

      .icon-delete-setting {
        display: none;
        position: absolute;
        width: calc(100% - 4px);
        top: 2px;
        height: 65px;
        background-color: #D10C0C;
        opacity: 0.68;
        border-radius: 4px;
        padding: 20px 5px;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .row-delete {
          text-align: center;
          color: #FDFDFD;

          h5 {
            margin: 0;
          }
        }

        .icon-content-delete-setting {
          padding-top: 0;
        }

        .cloud-delete-content {
          margin-bottom: -10px;
        }

        .cloud-delete {
          width: 16px;
          height: 16px;
          fill: white;
          cursor: pointer;
        }
      }

      .current-image-container:hover {
        .icon-delete-setting {
          display: block;
        }
      }
    }

  }

  .container-value-header {
    @include col100;

    .label-icon {
      width: 20%;
    }
  }

  .container-icon-body-title {
    float: left;
    display: flex;
    padding: 10px 20px;
    border: none;

    .container-message {
      padding-left: 5px;

      .title, .title-click {
        font-weight: $font-weight-regular;
        color: $text-color;
        margin: 0;
        font-size: 11px;
      }

      .title-click {
        color: $light-blue-color-1;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .container-icon-exclamation {
      .icon-error {
        div {
          display: flex;
        }
      }

      .svg-icon-error {
        fill: #ffca83;
        border: 1px solid #fff4e5;
        border-radius: 50px;
        width: 15px;
        height: 15px;
      }
    }
  }
}

#dropzone-modal-dashboard {
  height: 140px !important;
  top: 3px;
  position: relative;
  border-color: transparent !important;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
}

#id-user-modal-image {
  left: 52px;
  z-index: 0;
}

.about-us-icons-container {
  height: 155px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  position: relative;

  .icons-row-container {
    width: 100%;
    height: 26px;
    align-items: center;
    justify-content: space-evenly;
    display: flex;

    .about-us-icon {
      width: 15px;

      &:hover {
        cursor: pointer;
      }

      .icon-about-us {
        width: 15px;

        .icon-about-us-svg {
          width: 15px;
          height: 21px;
          fill: #787878;
        }
      }
    }
  }
}

.layout-about-5 {
  padding: 30px;
  height: 770px;
  background-color: #F4F4F4;

  .container-testimonial {
    .carousel-testimonial {
      .section-testimonial {
        .row-customer {
          .col-info {
            .label-experience {
              @include ellipsis-text;
              padding-right: 15%;
            }
          }
        }
      }
    }
  }

  .layout-about-box {
    .goal {
      .box-item, .box-operation {
        border-radius: 10px;
      }

      .item-template-main {
        width: 100%;

        .row-item-template-main {
          width: 100%;
          padding-bottom: 15px;
          display: flex;

          @mixin box-item-general() {
            border-radius: 10px;
            float: left;
            width: 100%;
            display: flex;
            .wrapper {
              float: left;
              padding: 10px;

              svg {
                width: 12px !important;
                height: 12px;
              }
            }
            .label-value {
              //padding: 15px 0;
              margin: 0;
              font-size: 16px;
            }
          }

          .col-item-left {
            padding-right: 10px;

            .box-item {
              @include box-item-general;
              padding-right: 20px;
              align-items: center;
              height: 100%;
              border: 1px solid #CACACA !important;
            }
          }
        }
      }
    }
  }
}

.view-previous-about-us {

  .box-goal {
    padding: 0 0 20px 0 !important;;
  }

  .layout-about {
    .box-operation {
      border: none;
    }

    .layout-about-box {
      padding: 25px;
      width: 100%;
      height: 100%;
      border: 1px solid #CACACA;
      background-color: #fff;

      .goal {
        margin: 20px 0;
        padding: 0 0 20px 25px;
        float: right;

        .box {
          label {
            color: #4D4D4D;
          }

          span {
            color: #4D4D4D;
          }

          .general-title {
            font-size: 48px;
            word-break: break-word;
          }

          .title-hours-operation {
            font-size: 25px;
          }
        }

        .item {
          .row-item {
            display: flex;

            .col-item-left, .col-item-right {
              .box-item {
                border: 1px solid #CACACA;
                display: flex;
                align-items: center;
                height: 100%;
              }
            }
          }
        }
      }
    }

    .about-testimonial {
      .layout-customer-testimonial {
        margin-top: 40px;
        height: 230px !important;

        .carousel-content-0 {
          margin-top: 80px !important;

          .carousel-content-text-0 {
            height: 100px !important;
            width: 100% !important;
            padding-left: 58px !important;

            .carousel-content-image-0 {
              padding-top: 0 !important;
              left: -50px !important;
              top: -40px !important;

              image {
                width: 60px !important;
                height: 60px !important;
              }
            }

            > div {
              p {
                padding-top: 5% !important;
              }

              h4 {
                margin-top: 0 !important;
              }
            }

            .carousel-content-social-network {
              padding-top: 15px !important;

            }

          }
        }

        .carousel-content-1 {
          width: 100% !important;
          margin-top: 55px !important;

          .carousel-content-text-1 {
            height: 135px !important;
            width: 100% !important;
            padding-left: 62px !important;

            .carousel-content-image-1 {
              padding-top: 0 !important;
              left: -50px !important;
              top: -40px !important;

              image {
                width: 80px !important;
                height: 80px !important;
              }
            }

            > div {
              p {
                padding-top: 6% !important;
              }
            }

            .carousel-content-social-network {
              padding-top: 15px !important;

            }

          }
        }

        .carousel-content-2 {
          margin-top: 40px !important;

          .carousel-content-text-2 {
            height: 160px !important;
            width: 100% !important;
            padding-left: 75px !important;

            .carousel-content-image-2 {
              left: -50px !important;
              top: -40px !important;
              padding-top: 0 !important;

              image {
                width: 100px !important;
                height: 100px !important;
              }
            }

            > div {
              p {
                padding-top: 40px !important;
              }

              h4 {
                margin-top: 0 !important;
              }
            }

            .carousel-content-social-network {
              padding-top: 15px !important;

            }

          }
        }

        .carousel-content-3 {
          margin-top: 60px !important;

          .carousel-content-text-3 {
            height: 130px !important;
            width: 100% !important;
            padding-left: 86px !important;

            .carousel-content-image-3 {
              padding-top: 30px !important;
              left: -37.5px !important;

              image {
                width: 75px !important;
                height: 75px !important
              }
            }

            > div {
              p {
                padding-top: 30px !important;
              }

              h4 {
                margin-top: 5px !important;
              }
            }

            .carousel-content-social-network {
              padding-top: 15px !important;

            }

          }
        }

        .carousel-content-4 {
          margin-top: 80px !important;

          .carousel-content-text-4 {
            height: 95px !important;
            width: 100% !important;
            padding-left: 49px !important;

            .carousel-content-image-4 {
              padding-top: 20px !important;
              left: -27.5px !important;

              image {
                width: 55px !important;
                height: 55px !important;
              }
            }

            > div {
              p {
                padding-top: 16px !important;
              }

              h4 {
                margin-top: 0 !important;
              }
            }

            .carousel-content-social-network {
              padding-top: 15px !important;

            }

          }
        }

        .content-up-down {
          top: 35px !important;

          .semi-circle-up {
            top: 100px !important;
            left: 14% !important;

            &:hover {
              background: white
            }
          }

          .semi-circle-down {
            top: 100px !important;
            left: 78% !important;

            &:hover {
              background: white
            }
          }
        }
      }

      .layout-dealer-carousel {
        margin-top: 20px;

        .carousel-content-0, .carousel-content-1, .carousel-content-2, .carousel-content-3,
        .carousel-content-4 {
          margin-top: 0 !important;
          height: 100%;
        }
      }
    }

    .goal .box {
      padding: 20px 30px;

      span {
        font-size: 11px;
      }
    }

    .dealer-medias-testimonials {
      width: 50%;
      float: left;

      .layout-dealer-carousel {
        .car-image-selected {
          height: 350px !important;
        }

        .car-image-gallery {
          @mixin content-image() {
            width: 50%;
            float: left;
            padding-right: 10px;
            height: auto;
            padding-bottom: 10px;
            .react-player__preview {
              border-radius: 3px !important;
            }
            img {
              border-radius: 3px !important;
            }
          }

          .content-image-media-5 {
            @include content-image;
          }
        }
      }

      .container-testimonial {
        height: 300px;

        .body-customer-section {
          height: 100%;

          .section-left {
            height: 100%;
            justify-content: center;
            padding-right: 0;
            width: 10%;
          }

          .container-testimonial {
            height: 100%;
            width: 80%;
          }

          .section-right {
            height: 100%;
            justify-content: center;
            padding-left: 0;
            width: 10%;
          }

        }

        .bar-progress {
          .slider-bar-total {
            height: 6px;
            width: 55%;

            .slider-bar-current {
              height: 6px;
              bottom: 0;
            }
          }
        }
      }

    }

    .item .box-item .label-value {
      padding: 20px 15px 20px 0;
      font-size: 16px;
    }

  }

  .layout-about-5 {
    height: auto;

    .box-operation {
      border: 1px solid #CACACA;
      border-radius: 10px;
    }

    .layout-dealer-carousel {
      display: flex;
      flex-direction: column;

      .car-image-selected {
        padding: 0 10px 10px 0;
      }

      .car-image-gallery {
        display: flex;
        flex-wrap: wrap;

        > div {
          flex: 0 0 12.5%;
          height: 70px !important;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .footer-modern {
      top: 874px;
    }
  }

  .only-one-image {
    padding: 0 25% 0 25%;
    width: 100%;
    height: 100%;
  }

}

/******aboutUS Easy Navigation Template ******/

.pdfFile {
  float: left;
  display: flex;
  align-items: center;

  .left-container-pdf {
    float: left;
    display: flex;
    flex-grow: 1;

    .car-image-main {
      border-radius: 5px;
      width: 50px;
      height: 50px;
    }
  }

  .right-container-pdf {
    float: left;
    display: flex;
    flex-grow: 1;
  }
}

.link-all {
  margin-left: 20px;
  margin-top: 7px;
}

.filter-button {
  width: 75%;
  margin-left: 25%;
  padding-top: 20px;
}

.line-separator-filter {
  height: 1px;
  background: #e5e9ec;
  border-bottom: 1px solid #e5e9ec;
  margin-right: 15px;
  margin-left: 15px;
}

.car-image-column {
  .bottom-car {
    .options {
      display: none;
    }
  }
}

.car-image-column:hover {
  .bottom-car {
    .options {
      display: block;
    }
  }
}

.bottom-car {
  padding: 8px;
  background: white;
  display: inline-block;
  width: 100%;

  .select {
    width: 60%;
    float: left;

    .loader-container {
      width: 100%;
      display: flex;
      justify-content: center;

      .loader {
        border: 7px solid #f3f3f3; /* Light grey */
        border-top: 7px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 36px;
        height: 36px;
        animation: adjust-hue 1s linear infinite;
      }

      @keyframes adjust-hue {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    div {
      .Select-control {
        height: 28px;

        .Select-placeholder {
          line-height: 26px;
        }

        .Select-value {
          line-height: 26px !important;

          .Select-value-label {
            line-height: 26px;
          }
        }

        .Select-input {
          height: 26px;
        }
      }
    }
  }

  .options {
    float: right;
    width: 30%;
    margin-top: 10px;

    .icon-option {
      float: right;
      cursor: pointer;
      margin-right: 13px;
    }

    .ic-delete {
      width: 15px;
      height: 16px;
      fill: $button-color-red;
    }

    .ic-deactivate {
      width: 16px;
      height: 11px;
      fill: #2194F1;
    }

    .ic-link-sale {
      width: 15px;
      height: 16px;
      stroke-width: 0.5px;
      fill: #4AD991;
    }
  }

  .message-status {
    width: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    color: green;
    text-align: right;
  }

  .info-attribute-car {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;

    .label-attribute-car {
      font-size: 11px;
      color: #43425D;
      font-weight: 500;
    }
  }
}

.info-car {
  width: 100%;
  text-align: left;
  font-size: 14px;
  padding: 12px 12px 0 12px;
  background-color: #fff;
  display: flex;

  .label-car-information {
    color: #787878;
    font-weight: 500;
    padding-left: 4px;
    width: 100%;
  }

  .form-control {
    height: 28px;
    font-size: 12px;
  }

  .form-horizontal {
    padding-top: 12px;
  }

  .options {
    align-items: center;
    justify-content: center;
    width: 25%;

    i {
      cursor: pointer;
      padding-left: 10px;
    }
  }

  .label-attribute-car {
    display: none;
  }
}

.data-mileage-car {
  text-align: left;
  font-weight: $font-weight-light;
  font-size: 10px;
  font-family: $font-family;
  letter-spacing: 0;
  color: #787878;
  opacity: 1;
  padding: 0 0 14px 16px;
}

.bottom-car {
  .option-delete {
    display: none;
  }
}

#car-image {
  background-color: white;
  width: 100%;
  float: left;

  .body-carousel {
    width: 100%;
    padding: 0 10%;

    .label-tittle {
      padding-left: 0;
    }
  }
}

.panel-car-information {
  width: 100%;
  float: left;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.info-table {
  margin-top: -20px;
  width: 100%;
  height: 40px;

  td {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    width: 25%;
  }

  .td-border-left {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #337ab9;
    color: white;
  }

  .tdIcon {
    padding-top: 3px;
  }
}

.select-visibility {
  margin-top: -20px;
  margin-left: 15px;
  padding-right: 5px;
  width: 74%;
}

.select-state {
  margin-top: -20px;
  margin-left: -10px;
  padding-right: 5px;
  width: 100%;
}

.transparent {
  background-color: transparent;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.car-icon-edit {
  font-size: 28px;
  margin-left: -4px;
  color: white;
}

.car-image-row {
  padding-right: 20px;
  padding-left: 0;
  padding-top: 2px;

  .car-image-column {
    padding-left: 0;
    display: inline-table;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
    border: 1px solid #EFEFEF;
    width: 100%;
    float: left;

    .border-top {
      float: left;
      position: absolute;
      left: 33%;
      width: 30%;
    }
  }

  .car-image-column-no-hover {
    padding-left: 0;
    display: inline-table;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(0, 0, 0, 0.12);
    width: 100%;

    .border-top {
      border-top: 3px $button-color-red solid;
      float: left;
      position: absolute;
      left: 33%;
      width: 30%;
    }
  }

  .car-image-column:after {
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .car-image-column:hover {
    margin-top: -3px;
  }

  .car-image-column:hover .bottom-car .options {
    display: block;
  }

  .car-image-column:hover .bottom-car .message-status {
    display: none;
  }

  .car-image-column:hover .border-top {
    position: relative;
    left: 0;
    width: 100%;
  }

  .car-image-column:hover a {
    display: block;
  }

  .car-image-column a {
    position: absolute;
    display: none;
  }

  .car-image-column a.update {
    top: 25%;
    left: 30%;
    bottom: 50%;
    right: 55%;
    width: 40px;
    height: 40px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #238fcf;
  }

  .car-image-column a.delete {
    top: 25%;
    left: 51%;
    bottom: 50%;
    right: 45%;
    width: 40px;
    height: 40px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #238fcf;
  }
}

#logo, #help {
  float: right;
}

.car-icon-delete {
  font-size: 20px;
  margin-left: -5.5px;
  margin-top: -1px;
  color: white;
}

.car-image-column a.delete {
  top: 84%;
  left: 88.6%;
  width: 30px;
  height: 30px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #238fcf;
  position: absolute;
}

.label-delete {
  margin-left: -16px;
  color: black;
  margin-top: 3px;
  font-size: 12px;
}

.input-currency {
  position: relative;
}

.carousel-control {
  opacity: 0.8;
}

.carousel-control:hover {
  opacity: 0.8;
}

#invCarouselOne {
  #dropZone {
    width: 400px;
  }
}

#carousel li {
  margin-right: 5px;
}

#carousel img {
  display: block;
  opacity: 5;
  cursor: pointer;
}

#carousel img:hover {
  opacity: 1;
}

#carousel .flex-active-slide img {
  opacity: 1;
  cursor: default;
}

.makeShow {
  display: block;
  width: 8%;
  background-color: #f8f8f8;
  height: 60px;
  padding: 13px 5px 15px 5px;
  margin-left: 34.7%;
  border: 1px solid #cccccc;
  position: absolute;
  z-index: 10;
  margin-top: -10px;
}

.makeNoShow {
  margin-left: 13%;
  height: 0;
  visibility: hidden;
  position: absolute;
}

.modelShow {
  display: block;
  width: 8%;
  background-color: #f8f8f8;
  height: 60px;
  padding: 13px 5px 15px 5px;
  border: 1px solid #cccccc;
  position: absolute;
  z-index: 10;
  margin-left: 42.8%;
  margin-top: -10px;
}

.yearShow {
  display: block;
  width: 5%;
  background-color: #f8f8f8;
  height: 122px;
  padding: 4px 5px 15px 5px;
  border: 1px solid #cccccc;
  position: absolute;
  z-index: 10;
  margin-left: 50.9%;
  margin-top: -10px;
}

.modelNoShow {
  width: 13%;
  height: 0;
  visibility: hidden;
  position: absolute;
}

.yearNoShow {
  width: 13%;
  height: 0;
  visibility: hidden;
  position: absolute;
}

.priceShow {
  display: block;
  width: 8%;
  background-color: #f8f8f8;
  height: 122px;
  padding: 4px 5px 15px 5px;
  border: 1px solid #cccccc;
  position: absolute;
  z-index: 10;
  margin-left: 19.5%;
  margin-top: -10px;
}

.priceNoShow {
  display: none;
  position: absolute;
}

.mileageNoShow {
  width: 9%;
  height: 0;
  visibility: hidden;
  position: absolute;
}

.mileageShow {
  display: block;
  width: 7%;
  background-color: #f8f8f8;
  height: 122px;
  padding: 4px 5px 15px 5px;
  border: 1px solid #cccccc;
  position: absolute;
  z-index: 1;
  margin-left: 27.6%;
  margin-top: -10px;
}

.typeNoShow {
  width: 9%;
  height: 0;
  visibility: hidden;
  position: absolute;
}

.typeShow {
  display: block;
  width: 8%;
  background-color: #f8f8f8;
  height: 60px;
  padding: 13px 5px 15px 5px;
  border: 1px solid #cccccc;
  position: absolute;
  z-index: 10;
  margin-left: 56%;
  margin-top: -10px;
}

.statusNoShow {
  width: 9%;
  height: 0;
  visibility: hidden;
  position: absolute;
}

.statusShow {
  display: block;
  width: 8%;
  background-color: #f8f8f8;
  height: 60px;
  padding: 13px 5px 15px 5px;
  border: 1px solid #cccccc;
  position: absolute;
  z-index: 10;
  margin-left: 64.1%;
  margin-top: -10px;
}

.inventorySearch {
  width: 25%;
  float: left;
  padding-left: 20px;
  padding-right: 0;
  z-index: 0;
}

.filterStyle {
  width: 14.2%;
  float: left;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.iconInventoryPage {
  width: 5%;
}

.iconNewCar {
  width: 5%;
  text-align: right;
}

.clear-filter {
  margin-top: -4px;
  padding: 6px 0;
}

.advance-search {
  margin-top: -4px;
}

#btnSearchCar {
  position: relative;
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  z-index: 2;
}

.flyout-tip {
  border-bottom: 10px solid #264a6d;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top-color: #264a6d;
  content: "";
  background-color: transparent;
  position: absolute;
  top: -458px;
  transition: transform 35s;
  -webkit-transition: -webkit-transform 35s;
  opacity: 0;
  z-index: -1;
}

.left-inner-addon {
  position: relative;
}

.inputPricing {
  position: relative;
  padding: 0;
}

.left-inner-addon input, .inputPricing input {
  padding-left: 22px;
}

.left-inner-addon i {
  position: absolute;
  padding: 7px 8px;
  pointer-events: none;
  left: 0;
  top: 20px;
  z-index: 5;
}

.inputPricing i {
  position: absolute;
  padding: 10px 8px;
  pointer-events: none;
  left: 0;
  top: 0;
  z-index: 5;
}

.carousel-control {
  opacity: 0.8;
}

.carousel-control:hover {
  opacity: 0.8;
}

.input-currency.left input {
  padding-left: 18px;
}

.input-currency:before {
  position: absolute;
  top: 0;
  content: "$";
}

.input-currency.left:before {
  left: 5px;

  .form-control.col-sm-9 {
    position: initial;
  }
}

/* pre-built style */
.ui-sortable {
  display: block;
  position: relative;
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.ui-sortable:before,
.ui-sortable:after {
  content: " ";
  display: table;
}

.ui-sortable:after {
  clear: both;
}

.ui-sortable .ui-sortable-item {
  float: left;
  cursor: move;
  width: 20%;
}

.ui-sortable .ui-sortable-item.ui-sortable-dragging {
  position: absolute;
  z-index: 1688;
}

.ui-sortable .ui-sortable-placeholder {
  display: none;
}

.ui-sortable .ui-sortable-placeholder.visible {
  display: block;
  z-index: -1;
}

.container-inventory {
  border-left: 10px solid #e5e9ec;
  border-right: 10px solid #e5e9ec;
  margin-left: 0;
  margin-right: 0;
  margin-top: 38px;
  padding-bottom: 55px;

  .container-car-tab {
    padding-top: 10px;

    .nav-tabs {
      border-bottom: 1px solid white;
      background-color: #d0d1d3;
      width: 678px;
    }
  }
}

.container-header-car {
  .navbar {
    min-height: 40px;
    margin-bottom: 0;
    height: 40px;

    .container-fluid {
      height: inherit;
      padding: 0;

      ul {
        width: 100%;

        .iconHistory {
          width: 3%;
          z-index: 10;
        }

        .iconNewCar {
          width: 94%;
          z-index: 5;

          a {
            padding-left: 0;
            padding-right: 0;
          }

          .button {
            width: 100%;
            text-align: left;
            padding-left: 10px;
          }
        }

        .iconHelp {
          width: 3%;
          z-index: 5;
        }

        .iconClose {
          width: 3%;
        }
      }
    }
  }

  .navbar-nav {
    margin-top: 0;

    li {
      height: 40px;
      padding: 0;

      a {
        height: inherit;
      }
    }
  }

  .button {
    background-color: #0090d9;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
    cursor: pointer;
    height: 39px;
    width: 100%;
    margin-top: -7px;
    padding-top: 8px;
  }

  .borderRightIcon {
    border-right: 1px solid #0182c9;
  }

  .navbar-nav li a {
    padding: 7px 0 7px 0;
  }
}

.container-pie-car, .container-footer-customer {
  padding: 0;
  position: fixed;
  bottom: 0;
  z-index: 10;

  .navbar-default {
    background-color: #0090d9;
    border-color: #0090d9;
  }

  .navbar {
    border-radius: 0;
    margin-bottom: 0;
  }

  .container {
    width: initial;
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
  }

  .navbar-nav {
    margin-top: 2px;
    margin-right: 10px;

    li {
      padding: 0;
    }

    .btn-primary {
      background-color: #496b91;
      border-color: #496b91;
    }
  }
}

.bodyPlus {
  opacity: 0.2;
  position: relative;
  z-index: 100;
  width: 100%;
}

.circlePlus {
  width: 100px;
  height: 100px;
  position: fixed;
  z-index: 100;
  top: 40%;
  left: 46%;
  display: none;
}

.showCirclePlus {
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 100;
  top: 63%;
  left: 73%;
  display: block;
}

#circlePlus {
  .fa-plus-circle {
    color: #5cb85c;
  }
}

.container-car-pricing, .container-car-customer {
  padding-right: 0;
  padding-left: 0;
  border-top: 15px solid #e5e9ec;
  padding-bottom: 50px;

  .container-purchasing {
    width: 30%;
    padding-left: 0;
    padding-right: 0;
    border-right: 5px solid #e5e9ec;
    height: 1029px;
  }

  .container-customer {
    width: 30%;
    padding-left: 0;
    padding-right: 0;
    border-right: 10px solid #e5e9ec;
    height: 910px;
  }

  .purchase-info, .contact-info, .sales-forms, .registration-forms, .finance-forms, .recoursements, .testimonials {
    padding-top: 20px;

    h3 {
      padding-left: 15px;
      padding-bottom: 15px;
      font-weight: 700;
    }

    h5 {
      font-size: 24px;
      font-weight: 700;
    }

    .fa {
      color: #0090d9
    }

    label {
      font-weight: $font-weight-light;
    }

    .padding0 {
      padding: 0;
    }

    .margin0 {
      margin: 0;
    }
  }

  .header-pricing, .header-customer {
    height: 50px;
    background: #0090d9;
    color: white;

    .title {
      padding-top: 12px;
    }

    .icon {
      padding-top: 1px;
      text-align: right;
    }
  }

  .container-pricing {
    width: 40%;
    padding-right: 0;
    padding-left: 0;
    border-right: 10px solid #e5e9ec;
    border-left: 10px solid #e5e9ec;
  }

  .container-forms {
    width: 40%;
    padding-right: 0;
    padding-left: 0;
    border-right: 10px solid #e5e9ec;
    border-left: 10px solid #e5e9ec;
    height: 910px;
  }

  .container-sale {
    width: 30%;
    padding-right: 0;
    padding-left: 0;
    border-left: 5px solid #e5e9ec;
    height: 1029px;
  }

  .container-follow-up {
    width: 30%;
    padding-right: 0;
    padding-left: 0;
    border-left: 10px solid #e5e9ec;
    height: 910px;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .inputLabel {
    padding-left: 0;
    padding-top: 8px;
    font-size: 12px;
  }

  .radio + .radio, .radio {
    margin-top: 8px;
  }

  .radioLeft {
    padding-left: 5px;
  }

  .radio {
    font-size: 12px;

    input {
      margin-top: 1px;
    }
  }

  .inputRadio {
    margin-left: -15px;
  }

  .radioGroup {
    padding-left: 10px;
  }

  .selectClass {
    background-color: #f5f5f5;
  }
}

.errors {
  border: 1px solid #ab3438;
  background-color: #e3b6b6;
  padding: 20px 15px;

  ul {
    list-style: none;
    margin: 0
  }
}

.repairDate {
  .input-group {
    width: 100%;

    .form-control {
      border-radius: 4px;
      position: absolute;
    }

    .form-control:not(:first-child):not(:last-child) {
      border-radius: 4px;
    }

    border-radius: 4px;
  }

  .input-group-addon {
    padding: 0;
    cursor: not-allowed;

    i {
      padding-right: 10px;
      margin-top: 10px;
      z-index: 2;
      position: relative;
      cursor: none;
      pointer-events: none;
    }
  }
}

.help-block {
  display: block;
  margin-top: 0;
  margin-bottom: -10px;
  font-size: 11px;
  color: $button-color-red;
}

.container-cars {
  min-height: 550px;
  overflow-y: auto;
  padding-bottom: 15px;
  position: relative;
  padding-top: 0 !important;

  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    .loader {
      border: 7px solid #f3f3f3; /* Light grey */
      border-top: 7px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 64px;
      height: 64px;
      animation: adjust-hue 1s linear infinite;
    }

    @keyframes adjust-hue {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

#componentPlus {
  margin-top: 15px;
  width: 660px;
  left: -297px;

  h3 {
    padding-top: 20px;
    padding-left: 25px;
  }
}

.container-carousel {
  .image-gallery-image {
    width: 100%;
    height: 400px;
    text-align: center;
    background-color: black;

    img {
      height: 100%;
      max-width: 100%;
    }
  }

  .image-gallery-thumbnails-container {
    img {
      height: 90px;
    }
  }
}

.label-car-description {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  padding-left: 25px;
  padding-top: 15px;
}

.image-gallery-content {
}

.inputText {
  padding: 0 15px 0 0;
}

.inputTextArea {
  padding: 0 15px 0 0;
}

.iconTitle {
  font-size: 20px;
  margin-top: -4px;
}

.iconForms {
  font-size: 80px;
}

.columnFormCar {
  width: 20%;
  padding-left: 0;
}

.columnFormStockCar {
  width: 26.5%;
  padding-left: 0;
}

.container-repairs {
  min-height: 570px;
}

.repair-body, .customer-body, .administrative-body, .container-cars {
  padding: 25px 0 15px;

  .btn-default {
    background-color: transparent;
    border-color: transparent;
    padding: 5px;
    font-size: 20px;
  }

  .repair-filter, .customer-filter, .administrative-filter {
    background-color: #cbcbcb;
  }

  .btnRepair, .btnCustomer, .btnUser {
    color: #337ab9;
  }

  .btnPlus {
    font-size: 10px;
    color: #09cc04;
    margin-left: -11px;
  }

  .table-bs-header {
  }

  .table {
    margin-bottom: 0;

    tr {
      border-bottom: 3px solid white;
    }
  }

  .react-bs-table {
    background-color: #eff3f6;
    color: #a6abb1;

    .react-bs-container-body {
      color: #7d8588;
    }
  }

  .table-column {
    background-color: #dfe3e6;
    width: 8%;
  }

  .table-fields {
    width: 15%;
  }

  .row-edit {
    font-size: 18px;
    margin-left: 5px;
    margin-top: -4px;
  }

  .row-delete {
    font-size: 18px;
    margin-left: 1px;
    margin-top: 1px;
  }

  .glyphicons:before {
    padding: 3px 3px;
  }

  .fontIcon {
    font-size: 18px;
  }

  .table-bordered > thead > tr > th {
    border: 1px solid transparent;
  }

  .table-bordered > tbody > tr > th, .table-bordered > tbody > tr > td {
    border: 1px solid transparent;
  }

  .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: transparent;
  }
}

.table {
  .attribute-col-type {
    padding-left: 70px;
    width: 100px;
    font-weight: 600;
  }

  .attribute-col-name {
    width: 260px;
    font-weight: 600;
  }

  .attribute-col-code {
    width: 230px;
    font-weight: 600;
  }

  .attribute-col-audience {
    width: 180px;
    font-weight: 600;
  }

  .attribute-col-response {
    width: 180px;
    font-weight: 600;
  }

  .attribute-col-icons {
    width: 100px;
  }
}

.filterLetter {
  margin-top: 8px;
  cursor: pointer;
}

.navList {
  list-style-type: disc;
  padding-left: 0;
  padding-top: 5px;
  color: #868f96;

  li {
    display: inline;
  }

  .circle {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    margin-top: 7px;
    margin-right: 6px;
    background-color: #868f96;
  }

  .classLi {
    width: 3.7%;
    padding: 0;
    float: left;
  }
}

.button-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
}

.upload-text {
  text-align: center;
  padding-top: 20px;

  .label-upload-text {
    font-size: 21px;
    color: #787878;
    font-weight: $font-weight-bold;
  }

  .label-upload-text-sub {
    font-size: 20px;
    color: #A4AFB7;
    text-decoration: underline;
  }
}

.navLetter {
  margin-top: 3px;
  padding: 0;
}

.inventoryBar {
  float: left;
  width: 100%;
  background-color: #cbcbcb;
  height: 50px;

  .btn-default {
    background-color: transparent;
    border-color: transparent;
    padding: 5px;
    font-size: 20px;

    .btnCar {
      color: #337ab9;
      font-size: 30px;
    }

    .btnPlus {
      font-size: 12px;
      color: #09cc04;
      margin-left: -24px;
      margin-top: -6px;
    }
  }
}

#loader {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 10000;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: adjust-hue 2s linear infinite;
  animation: adjust-hue 2s linear infinite;
}

@-webkit-keyframes adjust-hue {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes adjust-hue {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0
  }
  to {
    bottom: 0;
    opacity: 1
  }
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0
  }
  to {
    bottom: 0;
    opacity: 1
  }
}

.backgroundLoader {
  position: fixed;
  height: 100%;
  width: 100%;
  background: black;
  z-index: 10000;
  left: 0;
  top: 0;
  opacity: 6;
}

.hideMessage, .showMessage {
  display: block;
  position: fixed;
  text-align: center;
  left: 40%;
  top: 40%;
  z-index: 1000;
}

.showMessage, .showMessageAttribute {
  z-index: 1000;
}

.hideMessage {
  z-index: -1;
}

.fadeOut {
  opacity: 0;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
}

.image-gallery-right-nav, .image-gallery-left-nav {
  background-color: transparent;
  border: transparent;
}

//--------------------part public------------------
.f11w1 {
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
  min-width: 320px;
}

.header {
  color: #ffffff;
  background-color: #fbfbfb;
  padding: 0;
  margin: 0;

  .container-fluid:first-child {
    padding-left: 0;
    padding-right: 0;
  }

  .container-fluid {
    .leftHeader {
      width: 25%;
    }
  }

  .site-name {
    font-style: italic;
    color: #e60000;

    h1 {
      cursor: pointer;
      margin: 20px 0 0 0;
      font-size: 36px;
      font-weight: 600;
    }

    a {
      cursor: pointer;
      text-decoration: none;
      color: #e60000;
    }
  }
}

.menuHead {
  width: 75%;
  margin-top: -15px;
  padding: 0;
}

/*#home {
  background-color: transparent;
  height: 50px;
}*/

/*#home :hover {
  color: #e60000;
  cursor: pointer;
}*/

#about {
  background-color: transparent;
  height: 50px;
}

#about :hover {
  color: #e60000;
  cursor: pointer;
}

#testimonial {
  background-color: transparent;
  height: 50px;
}

#testimonial :hover {
  color: #e60000;
  cursor: pointer;
}

/*#location {
  background-color: transparent;
  height: 50px;
}

#location :hover {
  color: #e60000;
  cursor: pointer;
}*/

/*#contact {
  background-color: transparent;
  height: 50px;
}

#contact :hover {
  color: #e60000;
  cursor: pointer;
}*/

#financed {
  background-color: transparent;
  height: 50px;
}

#financed :hover {
  color: #e60000;
  cursor: pointer;
}

/*#inventory {
  background-color: transparent;
  height: 50px;
}

#inventory :hover {
  color: #e60000;
  cursor: pointer;
}*/

#facebook {
  background-color: transparent;
}

#facebook :hover {
  color: #e60000;
  cursor: pointer;
}

#googleplus {
  background-color: transparent;
}

#googleplus :hover {
  color: #e60000;
  cursor: pointer;
}

#btnLogin, #btnLoginR {
  @include btnBase;
  padding: 5px 10px;
  background-color: #e60000;
  color: white;
  margin-top: 10px;
  margin-left: 0;
}

#loginBar, #loginBarR {
  display: inline-block;
  float: right;
}

@mixin btnBase() {
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

#socialAndLogin {
  display: inline-block;
  float: right;
  height: 0;
  visibility: hidden;
  z-index: 1;
}

#socialAndLoginR {
  display: inline-block;
  float: right;
  width: 20%;
}

#socialLinks {
  display: inline;

  .navbar-nav {
    margin-left: 50%;
  }
}

#mainHeaderBody {
  padding-top: 15px;
}

.main-nav {
  .navbar-default {
    border: none;
    color: #ffffff; /* #6d7480 */
    font-size: 13px;
    margin: 14px 0 10px 0;

    .navbar-nav {
      width: 80%;

      .menuHeader :hover {
        cursor: pointer;
        text-decoration: none;
        color: #e60000;
      }
    }

    #socialLinks {
      .navbar-nav {
        width: 75%;
      }
    }

    .navbar-nav > .active > a {
      background-color: transparent;
      color: #e60000;
    }

    .navbar-nav > .active > li > a {
      background-color: transparent;
      color: #e60000;
    }

  }
}

#typeahead-box {
  color: white;
  background-color: #575A5D;
  height: auto;
  position: relative;

  .ic-tokens {
    padding: 4px 6px;
  }

  .ic-tokeninput-list {
    display: none;
  }

  .ic-tokeninput-is-open .ic-tokeninput-list {
    display: block;
  }

  .ic-tokeninput-input {
    max-width: 40px;
    border: 0 none;
    background-color: transparent;
    color: white;
  }

  .ic-tokeninput-input:focus {
    outline: 0;
  }

  .ic-token {
    border: 1px solid #C1C7CF;
    padding: 3px 8px;
    margin-right: 6px;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .ic-token-delete-button {
    margin-right: 5px;
    cursor: pointer;
  }

  .tokenizer-values-list {
    position: absolute;
  }
}

.typeahead-combobox {
  border-bottom: 1px solid #aaa;
  padding: 2px 8px;
  font-weight: $font-weight-bold;
}

.typeahead-combobox:focus {
  outline: none;
}

#inventorySearchBox, #inventorySearchBoxHeader {
  margin-bottom: 20px;

  h3 {
    margin-top: 5px;
    font-weight: 700;
  }

  .form-control {
    @include btnBase;
    color: white;
    background-color: #575A5D;
  }

  .input-disabled {
    background-color: #3c3f42;
  }

  label {
    color: white;
  }

  #searchOr {
    margin: 8px 0;
    text-align: center;
    font-weight: $font-weight-bold;
  }

  #simpleSearchBox {
    background-color: black;
  }

  #main-panel {
    margin-bottom: 0;

    div:first-child {
      @include btnBase;
    }

    @include btnBase;
    background-color: #2D3134;

    .panel-heading {
      background-color: #14181c;
      color: white;
    }
  }

  #main-panel-header {
    margin-bottom: 0;

    div:first-child {
      @include btnBase;
    }

    @include btnBase;
    background-color: #2D3134;

    .panel-heading {
      background-color: #14181c;
      color: white;
    }
  }

  #main-panel-body {
    margin-bottom: 0;

    div:first-child {
      @include btnBase;
    }

    @include btnBase;
    background-color: #2D3134;

    .panel-heading {
      background-color: #14181c;
      color: white;
    }
  }

  #panfooter {
    border-top: 1px solid #ddd;
  }

  button {
    @include btnBase;
    width: 100%;
    height: 34px;
    color: #721f1f;
    background-color: white;
    font-weight: 600;
  }
}

.banner {
  width: 100%;
  height: 100%;
  position: relative;
  color: #fff;
  margin-top: 76px;

  .banner-imgContainer {
    width: inherit;
    height: 580px;
    overflow: hidden;
  }

  .banner-image {
    width: 100%;
    height: auto;
    background-attachment: fixed;
    background-size: cover;
  }

  #banRow {
    top: 0;
    height: 100%;
    width: 33%;
    min-width: 320px;
    max-width: 445px;
    left: 5%;
    position: absolute;

    .col-md-4 {
      top: 5%;
    }

    .col-md-12 {
      top: 5%;
    }
  }

  #banRow2 {
    min-width: 320px;
    max-width: 1300px;
    width: 100%;
    left: 1%;
    height: auto;
    max-height: inherit;
    position: absolute;
    padding-left: 35%;
    top: 30%;

    .col-md-8 {
      .bannerCaption {
        h1 {
          font-size: 46px;
          font-weight: 700;
          margin-bottom: 20px;
        }
      }
    }
  }

  .panel-body {
    margin-bottom: -8px;
  }
}

#simpleSearchForm {
  margin-top: -10px;
  padding-bottom: 8px;
}

#advancedSearchForm .form-group {
  margin-top: -5px;
}

#inventoryFormGroup {
  margin-bottom: 5px;
}

.inline-flex {
  display: inline-flex;
}

.ic-tokeninput-button {
  display: none;
}

#carSel {
  background-color: transparent;
  margin-top: 30px;
  margin-bottom: 50px;

  #carSelList {
    padding: 0 50px;
  }

  .left {
    left: -60px;
    background-image: none;

    .glyphicon {
      top: 90px;
      background-color: black;
      color: black;
      transform: rotate(45deg); /* rotate right arrow squares 45 deg to point right */
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
    }
  }

  .right {
    right: -60px;
    background-image: none;

    .glyphicon {
      top: 90px;
      background-color: black;
      color: black;
      transform: rotate(45deg); /* rotate right arrow squares 45 deg to point right */
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
    }
  }
}

#carLstItem {
  border: 1px solid #f2f2f2;
  display: inline-block;
  margin: 0 auto;
  width: 100%;

  img {
    display: block;
    margin: 0 auto;
  }

  .carImg {
  }

  #carDescription {
    color: black;
    font-weight: 600;
  }

  .btn {
    @include btnBase;
    background-color: #e60000;
    width: 120px;
    height: 33px;
    margin-top: 4px;
  }
}

.carItemOptions, .carViewOptions {
  margin-top: 15px;
  font-size: 10px;

  .carItemOption {
    border-top: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    padding: 5px 0;

    img {
      max-width: 15px;
      margin-right: 5px;
    }

    div {
      display: inline-block;
      margin: 1px;
    }
  }

  .carItemOption:last-child {
    border-right: none;
  }

}

#cars {
  color: black;

  #cars-title {
    margin-top: 0;
  }

  #carsButtons {
    margin-top: 50px;
  }
}

.section {
  background-color: #ffffff;
  padding: 95px 0 0 0;
}

#btnLeft {
  @include btnBase;
  width: 18%;
  height: 49px;
  background-color: #14181c;
  font-weight: 600;
  color: #87898b;
  padding: 15px;
}

#btnCenter {
  @include btnBase;
  width: 18%;
  height: 49px;
  background-color: #e30a17;
  color: white;
  font-weight: 600;
  padding: 15px;
  z-index: 1;
}

#btnRight {
  @include btnBase;
  width: 18%;
  height: 49px;
  background-color: #14181c;
  font-weight: 600;
  color: #87898b;
  padding: 15px;
}

.btn-arrow-right {
  @include btnBase;
  position: relative;
  padding-left: 36px;
  padding-right: 36px;

  &:after { /* make two squares (before and after), looking similar to the button */
    content: "";
    position: absolute;
    top: 7px; /* move it down because of rounded corners */
    width: 35px; /* same as height */
    height: 35px; /* button_outer_height / sqrt(2) */
    background: inherit; /* use parent background */
    border: inherit; /* use parent border */
    border-left-color: transparent; /* hide left border */
    border-bottom-color: transparent; /* hide bottom border */
    border-radius: 0 4px 0 0; /* round arrow corner, the shorthand property doesn't accept "inherit" so it is set to 4px */
    -webkit-border-radius: 0 4px 0 0;
    -moz-border-radius: 0 4px 0 0;
    transform: rotate(45deg); /* rotate right arrow squares 45 deg to point right */
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    right: -17px;
    z-index: 1;

  }
}

.btn-arrow-left {
  @include btnBase;
  position: relative;
  padding-left: 36px;
  padding-right: 36px;

  &:before { /* make two squares (before and after), looking similar to the button */
    content: "";
    position: absolute;
    top: 7px; /* move it down because of rounded corners */
    width: 35px; /* same as height */
    height: 35px; /* button_outer_height / sqrt(2) */
    background: inherit; /* use parent background */
    border: inherit; /* use parent border */
    border-left-color: transparent; /* hide left border */
    border-bottom-color: transparent; /* hide bottom border */
    border-radius: 0 4px 0 0; /* round arrow corner, the shorthand property doesn't accept "inherit" so it is set to 4px */
    -webkit-border-radius: 0 4px 0 0;
    -moz-border-radius: 0 4px 0 0;
    transform: rotate(225deg); /* rotate left arrow squares 225 deg to point left */
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    left: -17px;
    z-index: 1;
  }
}

#btnInv {
  @include btnBase;
  width: 25%;
  height: 50px;
  background-color: #e60000;
  color: white;
  font-weight: 700;
  padding: 15px 0;
}

#gmapToolTip {
  h4 {
    color: black;
    margin-top: 0;
    margin-bottom: 0.2em;
  }

  .btn {
    @include btnBase;
    margin-top: 0.5em;
    color: white;
    background: black;
  }
}

.gooMap {
  width: 100%;
  height: 800px;
}

#btnLocLeft, #btnLocRight {
  @include btnBase;
  width: 100%;
  color: white;
  margin-bottom: 0.4em;
}

#btnLocLeft {
  background-color: #e60000;
}

#btnLocRight {
  background-color: #0fcde1;
}

#whoWeAreModal {

  font-size: small;

  p, h3 {
    margin-top: 0.3em;
  }

  h3 {
    color: white;
  }

  .carousel-indicators {
    visibility: hidden;
  }

  .modal-content {
    background-color: black;
  }

  .modal-dialog {
    border-color: white;
    border-style: solid;
  }
}

#idBtnLink {
  margin-left: -10px;
  z-index: 1;
}

.layLocation {
  float: left;
  width: 100%;
  background-color: #ffffff;
  padding-top: 5%;

  .layLocationHeader {
    padding: 1% 20%;
    background-color: black;
    color: white;

    h4 {
      margin: 0;
    }

    h1 {
      margin: 0;
    }
  }

  #map {
    width: 100%;
    height: 700px;
  }

  @mixin btnBase() {
    border: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  #gmapToolTip {
    h4 {
      color: black;
      margin-top: 0;
      margin-bottom: 0.2em;
    }

    .btn {
      @include btnBase;
      margin-top: 0.5em;
      color: white;
      background: black;
    }
  }
}

#aboutUs {

  p {
    color: #777;
  }

  p:first-child {
    margin-top: 2.5em;
  }

  h3 {
    color: white;
    margin-top: 0.3em;
  }

  #whoButtons {
    margin-top: 1.5em;
  }

  #whoImage {
    padding-right: 0;
  }

  .carousel-indicators {
    visibility: hidden;
  }
}

#whoText {
  width: 52%;
  margin-left: 13%;
}

#testimonialItem {
  margin-left: 15%;
}

.btnLink {
  font-size: 13px;
  margin-top: -2.2px;
  padding: 0 10px;
  z-index: 1;
}

#layoutBlog {
  .container-fluid {
    padding-top: 0.8em;
    padding-bottom: 1em;
    margin-bottom: 1.5em;
    width: 100%;
    height: 5%;
    color: black;
    font-weight: 800;
  }

  background-color: #f0f2f3;
  width: 100%;
  height: 100%;

  .carousel-indicators li {
    background-color: #721f1f;
    border-color: #721f1f;
  }

  .carousel-indicators .active {
    background-color: white;
  }

}

#layTestimonialHeader {
  margin-left: 15%;
}

#carBlog {
  background-color: inherit;

  .carousel-indicators {
    background-color: inherit;
    bottom: -3em;
  }

  .left {
    background-image: none;
  }

  .right {
    background-image: none;
  }
}

#linesep {
  margin-top: 70px;

  #circlesep {
    font-size: 20px;
    color: black;
  }

  .col-md-offset-1 {
    margin-left: 12%;
  }

  .col-md-4 {
    padding: 0;
  }

  .col-md-2 {
    width: 10%;
    padding: 0;
  }

  hr {
    margin-top: 10px;
    border-color: black;
    width: 100%;
    border-top-width: 2px;
  }
}

#blogSecCon {
  margin-top: 2.5em;

  h3 {
    font-weight: 800;
    color: black;
  }
}

#blogThiCon {
  margin-bottom: 100px;

  input {
    @include btnBase;
    width: 35%;
  }

  button {
    @include btnBase;
    color: white;
    background-color: black;
    width: 10%;
  }
}

#msgBox {
  input {
    @include btnBase;
    color: white;
  }

  #mainPanelMsgBox {
    div:first-child {
      @include btnBase;
    }

    @include btnBase;
    background-color: rgba(0, 0, 0, 0.5);

    .input {
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
    }
  }
}

#panelbodyMsgBox {
  padding: 14px;
}

.textBox {
  padding: 0;
  margin-top: 0;
}

.formGroup {
  margin-bottom: 14px;
}

#getInTouchTextArea {
  @include btnBase;
  background-color: rgba(0, 0, 0, 0.3);
}

#getInTouchBtn {
  button {
    @include btnBase;
    width: 100%;
    height: 50px;
    color: white;
    background-color: #e60000;
  }
}

#lgetInTouch {
  width: 100%;
  height: 588px;
  color: #fff;
  min-width: 320px;
  position: absolute;

  .banner-imgContainer {
    width: inherit;
    overflow: hidden;
    background-color: #6d7480;
  }

  .banner-image2 {
    width: 100%;
    height: auto;
  }

  #banRowl {
    top: 5%;
    width: 100%;
    min-width: 320px;
    max-width: 1308px;
    left: 1%;
    position: absolute;
    margin-top: -12px;
  }
}

.controlsLayout {
  max-width: 290px;
  margin: 0 auto;
  width: 100%;
}

#idMsgBox {
  max-width: 675px;
  max-height: 271px;
}

#idReferencePie {
  visibility: hidden;
  color: #2D3134;
}

#footerGetInTouch {
  margin-top: 43px;
  margin-bottom: 0;
}

#btnGetInTouch01, #btnGetInTouch02, #btnGetInTouch03, #btnGetInTouch04 {
  @include btnBase;
  width: 125px;
  height: 53px;
  color: white;
  font-weight: 300;
  margin-left: 5px;
  margin-right: 5px;
}

#btnGetInTouch01 {
  background-color: #1b78c7;
}

#btnGetInTouch02 {
  background-color: #00bff3;
}

#btnGetInTouch03 {
  background-color: #da421f;
}

#btnGetInTouch04 {
  background-color: #ffb400;
}

#idAddressPie {
  margin-bottom: 0;
}

#piePage {
  color: #2D3134;

  h5 {
    margin-bottom: 10px;
  }
}

.footerGetInTouch, .piePage {
  float: left;
  width: 100%;
  text-align: center;
}

.footerGetInTouch {
  padding: 5% 0 1% 0;
}

.piePage {
  padding: 0 0 3% 0;
}

.containerFooter {
  float: left;
  width: 100%;
}

#invCarView {
  margin-top: 76px;
  width: 90%;
  margin-left: 5%;
}

.rectangleM {
  width: 4px;
  height: 30px;
  background: #B41D1C;
  margin-top: 15px;
}

.rectangleL {
  width: 4px;
  height: 45px;
  background: #B41D1C;
  margin-top: 15px;
}

.rectangleD {
  width: 4px;
  height: 30px;
  background: #B41D1C;
}

.rectangleS {
  width: 4px;
  height: 25px;
  background: #B41D1C;
  margin-top: 15px;
}

.rectangleLeft {
  width: 4px;
  height: 30px;
  background: #B41D1C;
  margin-top: 15px;
  float: right;
}

#containerTitleCar {
  width: 95%;
  margin-left: -10px;
}

#titleCar {
  width: 90%;
  font-weight: 600;
  color: black;
  margin-top: 10px;
  margin-bottom: 0;
}

#btnPrice {
  @include btnBase;
  padding: 5px 10px;
  background-color: #E90101;
  color: white;
  margin-top: 10px;
  margin-left: 0;
  font-size: 20px;
  font-weight: 600;
  width: 125px;
}

.idBellow {
  font-size: 10.5px;
}

#idCarouselLeft {
  width: 66.7%;
}

#idCarouselRight {
  width: 33.3%;

  #idDescription {
    width: 100%;
    display: block;
    color: #151515;
    margin-top: 9px;
    font-weight: 700;
  }

  #carViewDescription {
    margin-left: -10px;
    margin-top: -5px;
    height: 50px;
  }
}

.description {
  font-size: 85%;
}

#carCarouselOne, #carCarouselTwo, #carViewSel {
  .carousel-indicators {
    visibility: hidden;
  }
}

.carLetterColor {
  color: #721f1f;
  font-size: 15px;
}

#tableCar {
  width: 90%;
  font-size: 11px;
  font-weight: 600;
  color: #404040;
  margin-top: 20px;

  tr {
    height: 30px;
  }
}

.rowTable {
  background: #EAEAEA;
}

.carViewOptions {
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  color: #202020;
}

.icon-letter {
  height: 25px;
  padding: 4px;
  width: 100%;
}

.icon-letter-color {
  height: 25px;
  padding: 4px;
  width: 100%;
  background: #F8F8F8;
}

#idCall {
  width: 100%;
  margin-top: 60px;

  #idImageCar {
    background: #939393;
    height: 62px;
  }

  #idImgPhone {
    width: 48px;
    margin-left: -9px;
    margin-top: 5px;
  }

  #btnCall {
    @include btnBase;
    padding: 10px 0;
    background-color: #6A6A6A;
    color: white;
    margin-left: 0;
    font-size: 18px;
    font-weight: 600;
    height: 62px;
  }

  #btnCallUser {
    @include btnBase;
    padding: 5px 10px;
    background-color: #E90101;
    color: white;
    margin-left: 0;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
  }
}

#idTitleCall {
  margin-top: 18px;
  margin-left: -15px;
  width: 90%;
  color: #1D1D1D;
  font-weight: 600;
  padding: 0;
}

#idRectangleM {
  padding: 0;
  width: 10px;
}

#idOverView {
  padding: 5px;
  width: 95%;
  margin-top: 5px;
}

.idCarOther {
  color: #2E2E2E;
  font-weight: 700;
}

.rectangleWidth {
  width: 38%;
}

#idViewMore {
  margin-top: 10px;
  text-align: center;
  width: 24%;
  padding: 0;
}

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

#imgIconCar {
  max-width: 48px;
}

.carouselPrev, .carouselNext {
  margin-top: 30px;
  font-size: 30px;
}

#carViewSel {
  background-color: transparent;
  margin-top: 30px;
  margin-bottom: 50px;
  padding-top: 15px;

  #carViewList {
    padding: 0 60px;
  }

  .left {
    left: -60px;
    background-image: none;

    .glyphicon {
      top: 90px;
      background-color: black;
      color: black;
      transform: rotate(45deg);
    }
  }

  .right {
    right: -60px;
    background-image: none;

    .glyphicon {
      top: 90px;
      background-color: black;
      color: black;
      transform: rotate(45deg);
    }
  }
}

.btnLinkDes {
  font-size: 12px;
  margin-top: -2.2px;
  padding: 0 10px;
  cursor: pointer;
  z-index: 1;
}

#btnInvCar {
  @include btnBase;
  width: 25%;
  height: 33px;
  background-color: #e60000;
  color: white;
  font-weight: 700;
  padding: 7px 0;
}

#iconCar {
  padding-top: 15px;
}

#inventoryList {
  .btn {
    @include btnBase;
    background-color: #e60000;
  }

  .text-left:first-of-type {
    color: #14181c;
    font-weight: 800;
  }

  i {
    margin-left: 15px;
  }

  img {
    width: 185px;
  }
}

#photoGrid {
  text-align: center;

  .btn {
    @include btnBase;
    background-color: #e60000;
  }

  #blockTitle {
    color: #14181c;
    font-weight: 800;
  }

  i {
    margin-left: 15px;
  }

  img {
    max-width: 200px;
  }

  #perMonth, #priceGrid {
    margin-top: -4%;
  }

  #btnViewGrid {
    margin-top: 4%;
  }
}

#btnInvCarGrid {
  @include btnBase;
  width: 60%;
  height: 35px;
  background-color: #e60000;
  color: white;
  font-weight: 700;
  padding: 8px 0;
}

#inventoryFinances {
  h1, h2 {
    color: white;
  }

  button {
    @include btnBase;
    color: #721f1f;
  }

  height: 500px;
  position: relative;
  background-color: #e60000;

  .finance-bg {
    width: inherit;
    height: inherit;
    position: relative;
    overflow: visible;

    img {
      max-width: 350px;
      position: absolute;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
    }
  }

  .financeData1 {
    left: 0;
    top: 0;
    position: absolute;
    align-content: center;
    width: 100%;

    .text-center {
      margin-top: 10px;
    }
  }

  .financeData2 {
    left: 23%;
    top: 85%;
    width: inherit;
    position: absolute;
    align-content: center;
  }
}

#callUsToday {
  float: right;
  margin-right: 3%;
}

#inventoryView {
  padding-top: 25px;
  padding-bottom: 25px;

  select {
    border: 0;
    outline: 0;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 700;
  }

  #invBt1, #invBt2 {
    border: 0;
    outline: 0;
  }
}

#inventoryViewRightUp {
  display: none;
  height: 0
}

#inventorySearchBoxHeader {
  float: left;
  width: 30%;
  margin-left: 10%;
  margin-right: 10%;
  height: 88px;
  background-color: #2D3134;
  min-width: 280px;
}

#inventorySearchBoxHeader .panel-heading {
  background-color: #14181c;
  color: white;
  border: none;
}

#simpleSearchForm {
  margin-top: -10px;
  padding-bottom: 8px;
}

#inventoryFormGroup {
  margin-bottom: 5px;
}

.form-inline .form-group {
  font-size: 10px;
}

ul.pagination li {
  display: inline;
}

ul.pagination li a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

ul.pagination li a.active {
  background-color: #4CAF50;
  color: white;
}

ul.pagination li a:hover:not(.active) {
  background-color: #ddd;
}

#idNote {
  margin-left: -10%;
  width: 100%;
  margin-top: 8%;
  font-size: 9.5px;
}

#inventoryViewRight {
  width: 18%;
}

#inventoryHeader {
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  margin-top: 76px;

  .banner-imgContainer {
    width: inherit;
    height: inherit;
    overflow: hidden;
  }

  .banner-image {
    width: 100%;
    height: auto;
  }

  .banner-image2 {
    width: 100%;
    height: 100%;
    min-height: inherit;
    position: relative;
    vertical-align: middle;
    background-attachment: fixed;
    background-size: cover;
    background-color: #464646;
  }

  #inventoryRow {
    position: absolute;
    top: 35%;
    width: 100%;
    height: 50%;

    .col-md-4 {
      h1 {
        top: 10%;
        font-weight: 800;
        color: white;
        font-size: 70px;
      }

    }
  }
}

#inventoryHeaderTitle {
  margin-left: 16%;

  h1 {
    font-size: 46px;
    font-weight: 700;
  }
}

.imageWaiterList {
  position: relative;
}

#selOrder, #selFilter {
  font-size: 12px;
}

.financedColumn {
  padding-bottom: 5px;
  padding-top: 5px;
}

.labelFinanced {
  color: #777777;
  font-size: 14px;
  text-align: left;
}

.placeholderRequired::-webkit-input-placeholder {
  content: '*';
  color: #777777;
}

.placeholderRequired::-moz-placeholder {
  color: #777777;
}

.placeholderRequired:-ms-input-placeholder {
  color: #777777;
}

.placeholderRequired::-webkit-input-placeholder::before {
  content: '*';
  color: red;
}

.placeholderRequired::before {
  content: '*';
  color: red;
}

.placeholderRequired:-ms-input-placeholder:after {
  content: '*';
  color: red;
}

.btn-link-finance {
  font-weight: $font-weight-regular;
  color: #777777;
  border-radius: 0;
  cursor: pointer;
  text-decoration: underline;
}

.btn-link-finance:hover {
  text-decoration: underline;
}

.residenceRow, #employmentRowAddress, #employmentRowName {
  margin-left: -1%;
}

#employmentRowPhone {
  margin-left: 8px;
}

#employmentRowZip {
  margin-left: 5px;
}

#employmentRowYear, #employmentRowMonth, #residenceRowYear, #residenceRowMonth {
  width: 13%;
}

#linkEmployment {
  width: 24%;
  margin-top: 7px;
  padding-top: 23px;
}

#residenceRowAddress {
  padding: 0;
  margin-left: -18px;
}

#residenceRowApt {
  margin-left: 13px;
}

#residenceRowZip {
  margin-left: 7px;
}

#linkResidence {
  width: 100%;
}

.financeForm #score_1, .financeForm #score_2, .financeForm #score_3, .financeForm #score_4, .financeForm #score_5, .financeForm #score_6, .financeForm #donotknow {
  width: 13.1%;
  padding-left: 0;
  padding-right: 0;
}

.financeForm {

  .container-fluid {
    button {
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0);
      border-color: #f5f6f7;
      width: 125px;
      border-right: none;
      font-weight: 300;
      margin: 5px;
    }

    input {
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0);
      border-color: #f5f6f7;
      border-right: none;
      color: black;
    }

    select {
      border-radius: 6px;
      border: #575a5d;
      border-right: none;
      background-color: white;
    }

    select:not([multiple]) {
      -webkit-appearance: none;
      -moz-appearance: none;
      background-position: right 50%;
      background-repeat: no-repeat;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
      padding: 5em 1.5em 5em 5em;
    }
  }

  #typeid {
    width: 170px;
    padding-left: 0;
    padding-right: 0;
  }

  .rowFinance, .rowResident, .rowEmployment {
    width: 100%;
    float: left;
    padding-top: 1%;
  }

  .col100 {
    width: 100%;
    float: left;
    padding: 1% 1% 1% 0;
  }

  .colRequired {
    background-color: #FFFEA6;
    color: rgb(119, 119, 119);
    float: left;
  }

  h3 {
    margin-top: 20px;
    color: #777777;
  }

  .form-control-feedback {
    top: 20px;
    color: #777777;
  }

  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 5px 0 0 5px;
  }

  #dobmdy_group {
    float: left;

    input {
      background-color: #FFFEA6;
    }
  }

  .popover-content {
    color: black;
  }
}

.error-message-edit {
  font-size: 10px;
  color: #f95606;
}

#financeViewBody {
  width: 70%;
  margin-left: 15%;
}

#textRequired {
  text-align: right;
  font-weight: 600;
  color: #e60000;
  padding-left: 15px;
  padding-right: 15px;
}

#btnApply {
  margin-left: 31px;
}

.btn-success-finance {
  color: #fff;
  background-color: #4e4e4e;
  border-color: #4e4e4e;
  width: 250px;
  height: 60px;
  font-size: 28px;
  border-radius: 0;
}

.btnAbove {
  background-color: #19191a;
  width: 250px;
  height: 6px;
  margin-left: 31px;
}

#financeHeader {
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  margin-top: 76px;

  .banner-imgContainer {
    width: inherit;
    height: inherit;
    overflow: hidden;
  }

  .banner-image {
    width: 100%;
  }

  .banner-image2 {
    width: 100%;
    height: 100%;
    min-height: inherit;
    position: relative;
    vertical-align: middle;
    background-attachment: fixed;
    background-size: cover;
    background-color: #464646;
  }

  #inventoryRow {
    position: absolute;
    top: 35%;
    width: 100%;
    height: 50%;

    .col-md-6 {
      h1 {
        top: 10%;
        font-weight: 800;
        color: white;
        font-size: 70px;
      }

    }
  }
}

.login-wrapper {
  position: absolute;
  top: 50px;
  width: 100%;
  margin-left: 0;
  animation: bounceInDownSmall 1s ease;
  -webkit-animation: bounceInDownSmall 1s ease;
  -moz-animation: bounceInDownSmall 1s ease;
  -ms-animation: bounceInDownSmall 1s ease;
  -o-animation: bounceInDownSmall 1s ease
}

.login-wrapper .login-widget {
  margin: 20px auto 0;
  width: 400px
}

.fadeInUp {
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s
}

.animation-delay8 {
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both
}

.text-success {
  color: #65cea7
}

.bounceIn {
  animation-name: bounceIn;
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -ms-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.7)
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05)
  }
  100% {
    -webkit-transform: scale(1)
  }
}

@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(0.7)
  }
  50% {
    opacity: 1;
    -moz-transform: scale(1.05)
  }
  100% {
    -moz-transform: scale(1)
  }
}

@-ms-keyframes bounceIn {
  0% {
    opacity: 0;
    -ms-transform: scale(0.7)
  }
  50% {
    opacity: 1;
    -ms-transform: scale(1.1)
  }
  100% {
    -ms-transform: scale(1)
  }
}

@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(0.7)
  }
  50% {
    opacity: 1;
    -o-transform: scale(1.1)
  }
  100% {
    -o-transform: scale(1)
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.7)
  }
  50% {
    opacity: 1;
    transform: scale(1.1)
  }
  100% {
    transform: scale(1)
  }
}

.animation-delay2 {
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -ms-animation-delay: 3s;
  -o-animation-delay: 3s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both
}

.messagesContainer {
  .messagesHeader {
    padding: 0 1%;
  }

  h2 {
    padding: 0 2%;
    color: #777777;
  }

  .table-responsive {
    padding: 0 1%;
    color: #777777;

    .table > thead > tr > th {
      border-bottom: none;
    }
  }
}

.financeContainer {
  padding: 0 2%;
  color: #777777;

  .table > thead > tr > th {
    border-bottom: none;
  }
}

.messContent {
  padding: 1% 2%;
  float: left;
  width: 100%;
}

.applicant-section-wrapper {
  @include col100;
  position: relative;

  .container-icon-body-title {
    position: absolute;
    top: 20px;
    right: 30px;
    width: 420px;
  }
}

.icon-toggle-horizontal-line {
  width: 15px;
  height: 1px;
  background-color: #FFFFFF;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.icon-toggle-vertical-line {
  width: 1px;
  height: 15px;
  background-color: #FFFFFF;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.applicant-section {
  padding: 0 20px 0 30px;
  border-bottom: 1px solid #cacaca;
  display: flow-root;

  .applicant-section-header {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
    display: inline-block;

    .applicant-section-header-info {
      float: left;
      margin-left: 12px;
    }

    .applicant-section-header-subtitle {
      font-size: 13px;
      font-family: $font-family;
      color: #515151;
      font-weight: 500;
    }
  }

  .applicant-section-header-title {
    font-family: $font-family;
    font-size: 15px;
    font-weight: $font-weight-bold;
    color: #515151;
  }

  .accordionHide {
    display: none;
  }

  .applicant-section-header-icon {
    background-color: #2194F1;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    float: left;
    position: relative;
    cursor: pointer;
  }

  .applicant-section-content {
    padding-bottom: 30px;
    width: 100%;
    float: left;

    .item {
      margin-top: 10px;

      .applicant-input {
        font-size: 13px;
        color: #2194F1 !important;
        background-color: transparent;
      }

      .dropdown-item {
        font-size: 13px;
        color: #2194F1 !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
      }

      select {
        cursor: pointer;
        background: url('../../../public/assets-images/ic_expand_more.png') no-repeat right;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-size: 14px;
        background-position-x: calc(100% - 7px);
        outline-width: 0 !important;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
      }

      select:focus {
        outline-width: 0 !important;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
      }

    }

    .new-comment-container{
      display: flex;
      .input-comment{
        width: 95%;
        border-radius: 17px;
        border: 1px solid #EFEFEF;
        height: 35px;
        padding-left: 10px;
        margin-right: 15px;
        outline: none;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 15px;
      }
      .icon-send-container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 33px;
        background-color: #2194F1;
        border: 1px solid #D7DAE2;
        border-radius: 16px;

        svg{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15px;
          height: auto;
          fill: #FFFFFF;
        }
      }
    }
  }
}

.popover-roles-modal {
  border: 1px solid #D7DAE2;
  padding: 10px;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  top: -3px !important;
  width: 100% !important;
  position: relative !important;
  left: 0 !important;
  z-index: 0 !important;
  overflow: auto;
  height: 135px;
}

.letter-roles-modal {
  font-size: 13px !important;
  color: $text-color;
}

.panel-title-credit {
  font-size: 15px;
  font-weight: $font-weight-bold;
  color: #515151;
}

.applicant-radio-button {
  color: #7F7F7F;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
}

.applicant-radio-button:hover {
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  background-color: #2194F1;
  color: #FFFFFF;
}

.applicant-radio-button-selected {
  background-color: #2194F1;
  color: #FFFFFF;
  text-align: center;
}

.rowFinance {
  float: left;
  width: 100%;
  padding: 1% 0 0 0;

  .btnSubmit {
    color: #fff;
    background-color: #f89406;
    border-color: #f89406;
    padding: 0;
    height: 30px;
    width: 150px;
    float: left;
  }

  .col30 {
    float: right;
    width: 30%;
    padding-right: 20px;
  }

  .col20 {
    float: left;
    width: 20%;
    padding-left: 20px;
  }

}

#financeStatusForm {
  .btn-success {
    margin-left: 8px;
  }
}

.image-display {
  float: left;
  width: 65%;
  padding: 0 1%;
}

.cropper-container {
  position: relative;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  direction: ltr !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.cropper-container img {
  display: block;
  width: 100%;
  min-width: 0 !important;
  max-width: none !important;
  height: 100%;
  min-height: 0 !important;
  max-height: none !important;
  image-orientation: 0deg !important;
}

.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cropper-drag-box {
  background-color: #fff;
  filter: alpha(opacity=0);
  opacity: 0;
}

.cropper-modal {
  background-color: #000;
  filter: alpha(opacity=50);
  opacity: 5;
}

.cropper-view-box {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: rgba(102, 153, 255, 0.75) solid 1px;
}

.cropper-dashed {
  position: absolute;
  display: block;
  filter: alpha(opacity=50);
  border: 0 dashed #eee;
  opacity: 5;
}

.cropper-dashed.dashed-h {
  top: 33.33333333%;
  left: 0;
  width: 100%;
  height: 33.33333333%;
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.cropper-dashed.dashed-v {
  top: 0;
  left: 33.33333333%;
  width: 33.33333333%;
  height: 100%;
  border-right-width: 1px;
  border-left-width: 1px;
}

.cropper-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  filter: alpha(opacity=75);
  opacity: 75;
}

.cropper-center:before,
.cropper-center:after {
  position: absolute;
  display: block;
  content: " ";
  background-color: #eee;
}

.cropper-center:before {
  top: 0;
  left: -3px;
  width: 7px;
  height: 1px;
}

.cropper-center:after {
  top: -3px;
  left: 0;
  width: 1px;
  height: 7px;
}

.cropper-face,
.cropper-line,
.cropper-point {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  filter: alpha(opacity=10);
  opacity: 1;
}

.cropper-face {
  top: 0;
  left: 0;
  background-color: #fff;
}

.cropper-line {
  background-color: #69f;
}

.cropper-line.line-e {
  top: 0;
  right: -3px;
  width: 5px;
  cursor: e-resize;
}

.cropper-line.line-n {
  top: -3px;
  left: 0;
  height: 5px;
  cursor: n-resize;
}

.cropper-line.line-w {
  top: 0;
  left: -3px;
  width: 5px;
  cursor: w-resize;
}

.cropper-line.line-s {
  bottom: -3px;
  left: 0;
  height: 5px;
  cursor: s-resize;
}

.cropper-point {
  width: 5px;
  height: 5px;
  background-color: #69f;
  filter: alpha(opacity=75);
  opacity: 75;
}

.cropper-point.point-e {
  top: 50%;
  right: -3px;
  margin-top: -3px;
  cursor: e-resize;
}

.cropper-point.point-n {
  top: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: n-resize;
}

.cropper-point.point-w {
  top: 50%;
  left: -3px;
  margin-top: -3px;
  cursor: w-resize;
}

.cropper-point.point-s {
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: s-resize;
}

.cropper-point.point-ne {
  top: -3px;
  right: -3px;
  cursor: ne-resize;
}

.cropper-point.point-nw {
  top: -3px;
  left: -3px;
  cursor: nw-resize;
}

.cropper-point.point-sw {
  bottom: -3px;
  left: -3px;
  cursor: sw-resize;
}

.cropper-point.point-se {
  right: -3px;
  bottom: -3px;
  width: 20px;
  height: 20px;
  cursor: se-resize;
  filter: alpha(opacity=100);
  opacity: 1;
}

.cropper-point.point-se:before {
  position: absolute;
  right: -50%;
  bottom: -50%;
  display: block;
  width: 200%;
  height: 200%;
  content: " ";
  background-color: #69f;
  filter: alpha(opacity=0);
  opacity: 0;
}

.row {
  padding-right: 32px;
  padding-top: 15px;
  width: 100%;
  float: left;
  display: flex;
  padding-left: 32px;
}

.col11, .col9 {
  width: 9.5%;
  float: left;
}

.modal-sig-in {
  .modal-body {
    display: flex;
    height: 270px;

    .body-left {
      width: 45%;
      background-color: #F5F6FA;
      @include item-center;

      img {
        width: 80%;
      }
    }

    .body-right {
      width: 55%;

      .container-icon-close {
        width: 100%;

        .icon-close {
          float: right;
          width: 100%;

          div {
            float: right;

            svg {
              height: 35px;
            }

            svg:hover {
              cursor: pointer;
            }
          }

        }
      }

      .message-error {
        width: 100%;
        text-align: center;
        padding-top: 27px;
        color: #787878;
        font-weight: $font-weight-bold;
        font-size: 16px;
      }

      .date-error {
        width: 100%;
        text-align: center;
        color: #43425D;
        font-weight: $font-weight-light;
        font-size: 13px;
      }

      .container-button-sign {
        width: 100%;
        padding-top: 30px;
        @include item-center;

        button {
          background-color: #2194F1;
          border: 1px solid #2194F1;
          border-radius: 4px;
          color: white;
          min-width: 30%;
          height: 29px;
        }
      }
    }
  }
}

.set-homepage-title-modal-container {
  @include item-center;
  display: flex !important;

  .modal-dialog {
    width: 550px;
    height: auto;

    .modal-body {
      padding: 21px 31px;

      .body-container {
        display: flex;
        flex-direction: column;

        .title {
          display: flex;
          align-items: center;
          font-size: 21px;
          font-weight: bold;
          min-height: 28px;
          padding-bottom: 13px;
        }

        .title-message {
          border-left: 2px solid #D7DAE2;
          padding-left: 11px;
          margin-bottom: 17px;
          font-size: 13px;
        }

        .label-input {
          font-size: 9px;
          padding-left: 11px;
          min-height: 13px;
          display: flex;
          align-items: center;
        }

        .dealer-name-input {
          min-height: 32px;
          border: 1px solid #D7DAE2;
          font-size: 13px;
          margin-bottom: 17px;
        }

        .keywords-message {
          font-size: 13px;
          margin-bottom: 13px;
        }

        .pills-container {
          min-height: 32px;
          border: 1px solid #D7DAE2;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 2px;
          padding: 2px 0px;

          .pill-container {
            background-color: #c7e3ff;
            color: #575757;
            margin-right: 2px;
            min-height: 26px;
            margin-left: 2px;
            display: flex;
            align-items: center;
            justify-content: center;

            .pill-label {
              display: flex;
              justify-content: space-around;
            }

            .pill-delete-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              color: #3B86FF;
              height: 18px;
              width: 18px;
              border: 1px solid #3B86FF;
              border-radius: 18px;
              cursor: pointer;
            }
          }
        }

        .keyword-input {
          min-height: 32px;
          border: 1px solid #D7DAE2;
          //font-size: 13px;
          //margin-bottom: 9px;
          padding: 2px 5px;
          width: 50%;
        }

        .choose-title-message {
          font-size: 13px;
          margin-bottom: 11px;
        }

        .choose-title-container {
          display: flex;
          margin-bottom: 20px;

          .first-column {
            display: flex;
            width: 55%;
            flex-direction: column;
            gap: 12px;

            .title-option-container {
              display: flex;
              align-items: center;
              margin-bottom: 7px;
              min-height: 18px;
              font-size: 13px;
              height: 18px;

              .checkbox-title-option {
                width: 18px !important;
                height: 18px !important;
              }

              .label-title-option {
                display: flex;
                justify-content: center;
              }
            }
          }

          .second-column {
            display: flex;
            width: 45%;
            flex-direction: column;

            .title-container {
              display: flex;
              align-items: center;
              font-size: 9px;
              min-height: 13px;
              padding-left: 10px;
            }

            .preview-container {
              display: flex;
              flex-direction: column;
              border: 1px solid #EFEFEF;
              padding: 8px 10px;

              .title-preview {
                color: #1A0EAB;
                font-size: 10px;
                font-weight: bold;
                min-height: 11px;
              }

              .url-preview {
                color: #176E2E;
                font-size: 8px;
                min-height: 9px;
              }

              .desc-preview {
                font-size: 8px;
                height: 27px;
              }
            }
          }
        }

        .footer-container {
          display: flex;
          margin-top: 7px;

          .save-btn {
            margin-left: auto;
            height: 32px;
            width: 64px;
            font-size: 13px;
            color: #FFFFFF;
            background-color: #4AD991;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 3px;
          }
        }
      }
    }
  }

  .close-modal-icon {
    width: 32px;
    position: absolute;
    top: -15px;
    right: -13px;

    &:hover {
      cursor: pointer;
    }
  }
}

.set-homepage-description-modal-container {
  @include item-center;
  display: flex !important;

  .modal-dialog {
    width: 530px;
    height: auto;

    .modal-body {
      padding: 21px 31px;

      .body-container {
        display: flex;
        flex-direction: column;

        .title {
          display: flex;
          align-items: center;
          font-size: 21px;
          font-weight: bold;
          min-height: 28px;
          padding-bottom: 13px;
        }

        .title-message {
          border-left: 2px solid #D7DAE2;
          padding-left: 11px;
          margin-bottom: 15px;
          font-size: 13px;
        }

        .label-textarea {
          font-size: 9px;
          padding-left: 11px;
          min-height: 13px;
          display: flex;
          align-items: center;
        }

        .description-textarea {
          font-size: 13px;
          border: 1px solid #D7DAE2;
          margin-bottom: 12px;
        }

        .requirements-container {
          font-size: 13px;
          display: flex;
          flex-direction: column;

          .requirement-option {
            display: flex;
            flex-direction: row;
            min-height: 18px;
            align-items: center;

            .svg-container {
              height: 15px;
              width: 15px;
            }

            .left-icon-svg {
              height: 15px;
              width: 15px;
            }

            .requirement-label {
              padding-left: 7px;
            }
          }
        }

        .footer-container {
          display: flex;
          margin-top: 21px;

          .save-btn {
            margin-left: auto;
            height: 32px;
            width: 64px;
            font-size: 13px;
            color: #FFFFFF;
            background-color: #4AD991;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 3px;
          }
        }
      }
    }
  }

  .close-modal-icon {
    width: 32px;
    position: absolute;
    top: -15px;
    right: -13px;

    &:hover {
      cursor: pointer;
    }
  }
}

.optimize-site-modal-container {
  @include item-center;
  display: flex !important;

  .modal-dialog {
    width: 600px;
    height: auto;

    .modal-body {
      padding: 25px 31px;

      .body-container {
        display: flex;
        flex-direction: column;

        .title-message-container {
          display: flex;
          align-items: center;
          font-size: 21px;

          .svg-image-container {
            height: 23px;
            width: 23px;
          }

          .title-message {
            justify-content: center;
            min-height: 23px;
            padding-left: 5px;
          }
        }

        .sub-title-message {
          font-size: 13px;
          min-height: 24px;
          display: flex;
          align-items: center;
        }

        .option-container {
          display: flex;
          align-items: center;
          min-height: 20px;

          .svg-image-container {
            height: 15px;
            width: 15px;
          }

          .option-text-container {
            font-size: 13px;
            min-height: 20px;
            display: flex;
            align-items: center;

            .option-text {
              padding-left: 5px;
            }
          }
        }
      }

      .footer-section {
        display: flex;
        margin-top: 15px;

        .got-it-btn {
          margin-left: auto;
          height: 32px;
          width: 64px;
          font-size: 13px;
          color: #FFFFFF;
          background-color: #3B86FF;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 3px;
        }
      }
    }

    .left-title-icon-svg {
      width: 23px;
      height: 23px;
    }

    .left-icon-svg {
      width: 15px;
      height: 15px;
    }
  }

  .close-modal-icon {
    width: 32px;
    position: absolute;
    top: -15px;
    right: -13px;

    &:hover {
      cursor: pointer;
    }
  }
}

.add-homepage-contacts-details-modal-container {
  @include item-center;
  display: flex !important;

  .modal-dialog {
    width: 547px;
    height: auto;

    .modal-body {
      padding: 25px 31px;

      .body-container {
        display: flex;
        flex-direction: column;

        .title-message-container {
          display: flex;
          align-items: center;
          font-size: 21px;
          min-height: 28px;
          padding-bottom: 13px;
        }

        .columns-container {
          display: flex;

          .add-contacts-first-column {
            width: 290px;

            .important-message-container {
              border-left: 2px solid #D7DAE2;
              padding-left: 11px;
              margin-bottom: 15px;
              font-size: 13px;
            }

            .sub-title-container {
              font-size: 13px;
              padding-bottom: 4px;
            }

            .how-to-do-container {
              display: flex;
              flex-direction: row;

              .svg-image-container {
                height: 83px;
                width: 18px;
                display: flex;
              }

              .how-to-do-steps {
                display: flex;
                flex-direction: column;
                width: 270px;
                height: 83px;
                justify-content: space-between;
                font-size: 13px;
                margin-left: 6px;

                .template-editor-text {
                  color: #3B86FF;
                }
              }
            }
          }

          .add-contacts-second-column {
            width: 200px;
            display: flex;
            justify-content: right;

            .svg-image-container {
              height: 192px;
              width: 170px;
              display: flex;
              align-items: flex-end;
            }
          }
        }

        .footer-section {
          display: flex;
          margin-top: 15px;

          .got-it-btn {
            margin-left: auto;
            height: 32px;
            width: 64px;
            font-size: 13px;
            color: #FFFFFF;
            background-color: #3B86FF;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 3px;
          }
        }
      }

      .footer-icon-svg {
        height: 162px;
        width: 170px;
      }

      .steps-icon-svg {
        height: 83px;
        width: 18px;
      }
    }
  }

  .close-modal-icon {
    width: 32px;
    position: absolute;
    top: -15px;
    right: -13px;

    &:hover {
      cursor: pointer;
    }
  }
}

.connect-homepage-social-networks-modal-container {
  @include item-center;
  display: flex !important;

  .modal-dialog {
    width: 547px;
    height: auto;

    .modal-body {
      padding: 25px 31px;

      .body-container {
        display: flex;
        flex-direction: column;

        .title-message-container {
          display: flex;
          align-items: center;
          font-size: 21px;
          min-height: 28px;
          padding-bottom: 13px;
        }

        .columns-container {
          display: flex;

          .add-contacts-first-column {
            width: 290px;

            .important-message-container {
              border-left: 2px solid #D7DAE2;
              padding-left: 11px;
              margin-bottom: 15px;
              font-size: 13px;
            }

            .sub-title-container {
              font-size: 13px;
              padding-bottom: 4px;
            }

            .how-to-do-container {
              display: flex;
              flex-direction: row;

              .svg-image-container {
                height: 83px;
                width: 18px;
                display: flex;
              }

              .how-to-do-steps {
                display: flex;
                flex-direction: column;
                width: 270px;
                height: 83px;
                justify-content: space-between;
                font-size: 13px;
                margin-left: 6px;

                .template-editor-text {
                  color: #3B86FF;
                }
              }
            }
          }

          .add-contacts-second-column {
            width: 200px;
            display: flex;
            justify-content: right;

            .svg-image-container {
              height: 192px;
              width: 170px;
              display: flex;
              align-items: flex-end;
            }
          }
        }

        .footer-section {
          display: flex;
          margin-top: 15px;

          .got-it-btn {
            margin-left: auto;
            height: 32px;
            width: 64px;
            font-size: 13px;
            color: #FFFFFF;
            background-color: #3B86FF;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 3px;
          }
        }
      }

      .social-media-icon-svg {
        height: 162px;
        width: 170px;
      }

      .steps-icon-svg {
        height: 83px;
        width: 18px;
      }
    }
  }

  .close-modal-icon {
    width: 32px;
    position: absolute;
    top: -15px;
    right: -13px;

    &:hover {
      cursor: pointer;
    }
  }
}

.add-homepage-links-modal-container {
  @include item-center;
  display: flex !important;

  .modal-dialog {
    width: 530px;
    height: auto;

    .modal-content {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }

    .modal-body {
      padding: 25px 31px;

      .body-container {
        display: flex;
        flex-direction: column;

        .title-message-container {
          display: flex;
          align-items: center;
          font-size: 21px;

          .svg-image-container {
            height: 23px;
            width: 23px;
          }

          .title-message {
            justify-content: center;
            min-height: 23px;
            padding-left: 5px;
          }
        }

        .sub-title-message {
          font-size: 13px;
          min-height: 24px;
          display: flex;
          align-items: center;
        }

        .option-container {
          display: flex;
          align-items: center;
          min-height: 20px;

          .svg-image-container {
            height: 15px;
            width: 15px;
          }

          .option-text-container {
            font-size: 13px;
            min-height: 20px;
            display: flex;
            align-items: center;

            .option-text {
              padding-left: 5px;
            }
          }
        }
      }

      .footer-section {
        display: flex;
        margin-top: 15px;

        .got-it-btn {
          margin-left: auto;
          height: 32px;
          width: 64px;
          font-size: 13px;
          color: #FFFFFF;
          background-color: #3B86FF;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 3px;
        }
      }
    }

    .title-icon-svg {
      width: 23px;
      height: 23px;
    }

    .left-icon-svg {
      width: 15px;
      height: 15px;
    }
  }

  .close-modal-icon {
    width: 32px;
    position: absolute;
    top: -15px;
    right: -13px;

    &:hover {
      cursor: pointer;
    }
  }
}

.location-modal-container, .modal-setup-dealer {
  @include item-center;
  display: flex !important;

  .help-block {
    color: #a94442 !important;
    font-weight: $font-weight-light !important;
    font-size: 12px !important;
  }

  .has-error {
    border-color: #a94442;
    box-shadow: none;
  }

  .modal-dialog {
    width: 1100px;

    .modal-content {
      .modal-body {
        display: flex;
        position: relative;
        padding: 15px;

        .container-body {
          width: 100%;
          display: flex;

          .container-title {
            .title {
              color: #787878;
              font-size: 15px;
              font-weight: $font-weight-bold;
            }
          }

          .container-option {
            padding: 0 10px;

            &:hover {
              background: #F5F6FA;
            }

            .option-label {
              font-weight: 500;
            }
          }

          @mixin popover-location() {
            overflow: auto;
            max-height: 110px;
            box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
            border-radius: 2px;
            padding: 5px 0;
          }

          .popover-location {
            @include popover-location;
          }

          .left-section {
            width: 75%;

            .popover-status-location {
              @include popover-location;
              max-height: 60px;
            }

            .popover-state-location {
              @include popover-location;

              > div {
                position: relative !important;
              }
            }

            .colR27, .colR16, .colR12, .colR40 {
              label {
                font-size: 11px;
                color: $text-color;
                font-weight: $font-weight-regular;
                float: left;
              }
            }

            .colR27 {
              float: left;
              padding-right: 10px;
              width: 27.5%;

              p {
                float: left;
                font-size: 11px;
                color: $text-color;
                font-weight: $font-weight-light;
              }

              span {
                color: red;
                padding-left: 2px;
                font-weight: $font-weight-light;
              }

              label {
                float: left;
              }
            }

            .colR16 {
              float: left;
              padding-right: 10px;
              width: 16%;
            }

            .colR12 {
              float: left;
              padding-right: 10px;
              width: 12%;
            }

            .colR40 {
              float: left;
              padding-right: 10px;
              width: 40%;

              label {
                margin-bottom: 8px;
              }
            }

            @mixin container-select() {
              width: 16%;
              padding-right: 10px;
              float: left;
              .label-city, .label-state {
                font-size: 10px;
                color: #787878;
                font-weight: $font-weight-regular;
                float: left;
              }
              .select {
                width: 100%;
                float: left;
                border-radius: 4px;
                border: 1px solid #D7DAE2;
                display: flex;
                justify-content: center;
                height: 34px;
                align-items: center;
                position: relative;

                .container-label {
                  width: 75%;
                  float: left;
                  padding-left: 12px;
                  position: absolute;
                  left: 0;

                  label {
                    margin-bottom: 0;
                    font-size: 12px;
                    color: #787878;
                    font-weight: $font-weight-regular;
                    float: left;
                  }
                }

                .container-icon {
                  right: 10px;
                  position: absolute;

                  i {
                    color: #A4AFB7;
                    font-size: smaller;
                  }
                }
              }
            }

            .container-status {
              @include container-select;
              width: 25%;
              padding-top: 19px;
            }

            .container-select {
              @include container-select;
            }

            .first-section {
              float: right;

              .first-row {
                width: 100%;
                float: right;
                padding-bottom: 10px;
              }

              .second-row {
                float: right;
                width: 100%;
                padding-bottom: 10px;
              }
            }

            .second-section {
              float: left;
              width: 100%;
              padding-bottom: 10px;
            }
          }

          .right-section {
            width: 25%;

            .popover-hour-location {
              @include popover-location;
              max-height: 90px;

              > div {
                position: relative !important;
              }
            }

            .first-section {
              width: 100%;
              float: left;

              .body-sec-hours-locations {
                .titles {
                  width: 100%;
                  float: right;
                  padding-bottom: 2px;

                  .label-sec-days {
                    font-size: 11px;
                    color: $text-color;
                    float: left;
                    width: 33.3%;
                    font-weight: $font-weight-regular;
                  }
                }

                .row-days {
                  width: 100%;
                  float: right;

                  .days, .days-disabled {
                    width: 31.3%;
                    float: left;
                    border-radius: 4px;
                    border: 1px solid #D7DAE2;
                    margin-right: 2%;
                    display: flex;
                    justify-content: center;
                    height: 34px;
                    align-items: center;
                    margin-bottom: 3%;
                    position: relative;

                    .container-label-hour {
                      width: 75%;
                      padding-left: 10px;
                    }

                    .label-sec {
                      font-size: $text-size-select;
                      color: #787878;
                      font-weight: $font-weight-regular;
                      float: left;
                      margin-bottom: 0;
                      position: absolute;
                      left: 0;
                      padding-left: 10px;
                    }

                    i {
                      color: #A4AFB7;
                      position: absolute;
                      right: 10px;
                      font-size: smaller;
                    }
                  }

                  .days-disabled {
                    .label-sec {
                      color: #ccc !important;
                      font-weight: $font-weight-regular;
                    }

                    i {
                      color: #ccc !important;
                    }
                  }
                }
              }
            }

            .second-section {
              width: 100%;
              float: left;
              padding-right: 3%;
              padding-top: 54px;

              .first-container {
                .btn-save {
                  float: right;
                  color: #ffffff;
                  border-radius: 5px;
                  border-color: transparent;
                  height: 30px;
                  width: 80px;
                  background: #4ad991;
                }
              }
            }
          }
        }
      }
    }
  }

  .close-modal-icon {
    width: 32px;
    position: absolute;
    top: -15px;
    right: -13px;

    &:hover {
      cursor: pointer;
    }
  }

}

.dp-overlay-new {
  border: 1px solid #ccc;
  position: absolute;
  z-index: 1;
  background: #fff;
  min-width: 100px;
  max-height: 300px;
  border-radius: 4px;
  display: flex;
  clear: both;
  padding: 10px;
}

.dp-overlay-full {
  min-width: 0;
  max-width: 100%;
}

.dp-overlay-top {
  transform: translate(0, -100%);
}

.user-popover-roles {
  max-height: 172px;
  overflow-y: auto;
}

/* Start modal receipt */
.container-general-receipt {
  .modal-dialog {
    width: 450px !important;
    height: auto !important;

    .modal-content {
      border: none;
      box-shadow: unset;
      border-radius: unset;
    }

    .close-modal-icon {
      z-index: 1;
      width: 30px !important;

      svg {
        background: #FFF;
        border-radius: 50%;
      }
    }

    .container-receipt-modal {
      width: 100%;
      height: 100%;

      .approved {
        color: $green-color !important;
      }

      .container-top {
        @include item-center;
        flex-flow: column;
        background: $green-color;
        clip-path: polygon(100% 100%, 98% 92%, 96% 100%, 94% 92%, 92% 100%, 90% 92%, 88% 100%, 86% 92%, 84% 100%, 82% 92%, 80% 100%, 78% 92%, 76% 100%,
                74% 92%, 72% 100%, 70% 92%, 68% 100%, 66% 92%, 64% 100%, 62% 92%, 60% 100%, 58% 92%, 56% 100%, 54% 92%, 52% 100%, 50% 92%,
                48% 100%, 46% 92%, 44% 100%, 42% 92%, 40% 100%, 38% 92%, 36% 100%, 34% 92%, 32% 100%, 30% 92%, 28% 100%, 26% 92%, 24% 100%,
                22% 92%, 20% 100%, 18% 92%, 16% 100%, 14% 92%, 12% 100%, 10% 92%, 8% 100%, 6% 92%, 4% 100%, 2% 92%, 0% 100%, 0 0, 100% 0);

        .add-icon {
          width: 30px;

          svg {
            fill: #FFF;
          }
        }

        span {
          font-size: 15px;
          color: #FFF;
          font-weight: 600;
        }
      }

      .container-bottom {
        padding: 30px 20px;
        border-bottom: 0;

        .sub-container-top {
          padding: 0 15px 15px 15px;

          .first-box-receipt {
            display: flex;

            span {
              font-size: 13px;
              color: #000000;
              line-height: 1.3;
            }

            .box-left {
              .container-info {
                .info-text {
                  width: 100%;
                  float: left;
                  font-weight: 700;
                }
              }
            }

            .box-right {
              display: flex;
              flex-direction: column;

              .title-section {
                white-space: nowrap;
              }

              .info-text {
                text-align: end;
                font-weight: 700;
              }
            }
          }

          .second-box-receipt, .third-box-receipt, .fourth-box-receipt {
            padding-top: 5px;

            span {
              font-size: 12px;
              color: #000000;
              line-height: 1.3;
            }

            .container-title-section {
              display: flex;

              .title-line-section {
                white-space: nowrap;
                color: #000;
              }

              .line-right {
                width: 100%;
                border-bottom: 1px solid #D7DAE2;
                margin: 0 0 18px 6px;
              }
            }

            .box-transaction-info {
              padding-top: 2px;

              .info-transaction {
                display: flex;
                width: 100%;

                .label-info-item {
                  width: 120px;
                  font-weight: 600;
                }

                .info {
                  width: calc(100% - 120px);
                  text-align: end;
                }
              }
            }
          }
        }

        .sub-container-bottom {
          padding: 15px;
          display: flex;
          width: 100%;
          background-color: #F5F6FA;

          span {
            font-size: 15px;
            font-weight: 700;
            color: #000000;
          }

          .text-left {
            width: 90px;
            float: left;
          }

          .text-right {
            width: calc(100% - 90px);
            float: right;
          }
        }

        .container-icons-receipt {
          float: right;
          display: flex;

          .container-print-icon, .container-download-icon {
            width: 18px;
            margin: 0 10px;

            svg {
              width: 100%;

              &:hover {
                cursor: pointer;
              }
            }
          }

          .container-print-icon {
            margin: 10px;
          }

          .container-download-icon {
            margin: 10px 5px 10px 10px;
          }
        }
      }
    }
  }
}

/* End modal receipt*/


@media (min-width: 992px) {
  .cropper-point.point-se {
    width: 10px;
    height: 10px;
  }
}

.dashboard-statistic {
  .row-statistics-user {
    .btn-cancel {
      margin-right: 0;
      padding-right: 0;
    }
  }
}

@media (min-width: 1200px) {
  .cropper-point.point-se {
    width: 5px;
    height: 5px;
    filter: alpha(opacity=75);
    opacity: 75;
  }
}

.cropper-bg {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}

.cropper-invisible {
  filter: alpha(opacity=0);
  opacity: 0;
}

.cropper-hide {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}

.invoice-header {
  float: left;
  width: 100%;
  padding: 0 0 2% 0;

  label {
    font-weight: $font-weight-regular;
  }

  h3 {
    text-align: center;
  }

  .separator {
    border: 1px solid black;
  }

  .invoice-dealer, .invoice-buyer {
    float: left;
    width: 50%;

    .column2, .column {
      float: left;
      width: 50%;
      padding: 1% 2%;
    }

    .column {
      padding: 1% 1%;
    }

    h4 {
      padding-left: 2%;
    }
  }

  .column5 {
    float: left;
    width: 20%;
    padding: 1% 0;

    .inputLabel {
      float: left;
      padding: 2% 2% 2% 0;
    }

    .inputNumber {
      float: left;
    }
  }

  .infoDealer, .infoBuyer {
    float: left;
    width: 96%;
    border: 1px dashed;
    margin: 0 2%;
  }

}

.invoice-body {
  float: left;
  width: 50%;
  padding: 0 1%;

  label {
    font-weight: $font-weight-regular;
  }

  .saleTitle {
    background-color: black;
    color: white;
    float: left;
    width: 100%;
  }

  .inputLabel {
    float: left;
    width: 45%;
  }

  .inputNro {
    float: left;
    width: 5%;
  }

  .inputSale {
    float: left;
    position: relative;
    width: 50%;

    i {
      position: absolute;
      padding: 10px 8px;
      pointer-events: none;
      left: 0;
      top: 0;
      z-index: 5;
    }

    input {
      padding-left: 22px;
    }
  }

  .rowSale {
    padding: 5px 0;
    float: left;
    width: 100%;
  }
}

.vehicle-body {
  float: left;
  width: 50%;
  padding: 0 1%;

  .rowV {
    padding: 10px 0;
    float: left;
    width: 100%;
  }

  .rowI {
    padding: 5px 0;
    float: left;
    width: 100%;

    label {
      float: left;
      width: 20%;
    }

    input {
      float: left;
      width: 80%;
    }
  }

  label {
    font-weight: $font-weight-regular;
  }

  .containerCheck {
    float: left;
    width: 20%;

    input {
      margin-right: 10px;
      float: left;
    }
  }

  .vehicleTitle {
    background-color: black;
    color: white;
    float: left;
    width: 100%;
  }

  .col {
    float: left;
    width: 100%;
    padding: 5px 0;

    label {
      float: left;
      width: 20%;
    }

    input {
      float: left;
      width: 80%;
    }
  }

  .colL2, .colR2 {
    float: left;
    width: 50%;
    padding: 5px 0;

    label {
      float: left;
      width: 40%;
    }

    input {
      float: left;
      width: 60%;
    }
  }

  .colL2 {
    padding-right: 5px;
  }

  .colR2 {
    padding-left: 5px;
  }
}

.addition-body {
  float: left;
  width: 100%;

  .additionTitle {
    background-color: black;
    color: white;
    float: left;
    width: 100%;
  }

  .col2 {
    float: left;
    width: 50%;
    padding: 0 1%;
    text-align: justify;

    input {
      float: left;
      margin: 5px 10px 0 0;
    }
  }

  .text {
    padding: 1% 0;
    float: left;

    .inputNotice {
      position: absolute;
      margin: 0;
      height: 20px;
    }

    .textOther {
      position: absolute;
      padding-left: 160px;
    }
  }

  .square {
    width: 10px;
    height: 10px;
  }

  .signature {
    float: left;
    width: 100%;
    border: 1px solid;
    margin: 70px 0 0 0;
  }
}

.odometer-header {

  .rowHeader {
    border: 2px solid;
    width: 100%;
    float: left;
    display: flex;
  }

  .col10 {
    width: 10%;
    float: left;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 0;
      font-weight: $font-weight-regular;
      font-size: 18px;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .col08 {
    width: 80%;
    float: left;
    text-align: center;
    padding: 1% 0;
  }

  h3 {
    font-weight: 300;
    margin-top: 10px;
  }

  .rowPie {
    float: left;
    width: 100%;
    padding: 1% 0;

    h4 {
      float: left;
      font-weight: $font-weight-regular;
    }

    h5 {
      float: right;
      font-weight: $font-weight-regular;
    }
  }

}

.odometer-body {

  .line-horizontal {
    border: 2px solid;
    float: left;
    width: 100%;
  }

  .section1, .section2, .section3, .section4 {
    float: left;
    width: 100%;

    label {
      font-weight: $font-weight-regular;
    }

    .col, .col2, .col4, .col8, .col10, .col45, .col55 {
      padding: 0 5px 10px 5px;
      border-top: 1px solid #93949f;
    }

    .col2 {
      float: left;
      width: 50%;
    }

    .col4 {
      float: left;
      width: 25%;
      border-left: 1px solid #93949f;
    }

    .col8 {
      float: left;
      width: 12.5%;
      border-left: 1px solid #93949f;
    }

    .col10 {
      float: left;
      width: 10%;
    }

    .col45 {
      float: left;
      width: 45%;
      border-left: 1px solid #93949f;
    }

    .col55 {
      float: left;
      width: 55%;
    }

    .col40 {
      float: left;
      width: 40%;
      padding: 2% 10%;
      text-align: center;
    }

    .col60 {
      float: left;
      width: 60%;
      padding: 0 2%;
    }

    input {
      background-color: #f1f4ff;
    }

    input:focus {
      background-color: white;
    }

    .inputMileage {
      float: right;
      padding: 2% 2%;
      text-align: center;
    }

    .inputDigitR, .inputDigit {
      float: left;
      width: 12.5%;

      .form-control {
        border-radius: 0;
      }
    }

    .inputDigitR {
      .form-control {
        border-right: 0;
      }
    }
  }

  .rowSection {
    border-top: 1px solid #93949f;
  }

  .rowS {
    float: left;
    width: 100%;
  }

}

.section4 {
  .col45 {
    border-bottom: 1px solid #93949f;
  }

  .col55 {
    border-bottom: 1px solid #93949f;
  }
}

.odometer-statement {
  padding: 0 10px 60px 10px;
  float: left;
  width: 100%;

  .container-pie-car {
    float: left;
    width: 100%;
  }
}

.label-checkbox {
  float: right;
  padding-top: 8px;
  padding-right: 8px;
}

.appContainer {
  display: flex;
  float: left;
  width: 100%;
}

.app-left {
  float: left;
  width: 25%;
  background-color: #e0e0e0;

  .appListTitle {
    background-color: #808080;
    color: white;
    text-align: center;
  }

  label {
    font-weight: $font-weight-regular;
  }

  .appListBody {
    padding: 0 15px;

    .containerCheck input {
      margin-right: 10px;
      float: left;
    }
  }

  .col60 {
    text-align: center;
    font-size: 16px;

    a {
      padding: 1%;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .col45 {
    padding: 7px;
  }

  .appSaveDate {
    float: left;
    width: 100%;
    padding: 0 15px;
  }
}

.app-right {
  padding: 10px 0 0 0;
  float: left;
  width: 75%;
}

.rowApp {
  float: left;
  width: 100%;

  .col20 {
    float: left;
    width: 20%;
    padding: 1% 0;
  }

  .col63 {
    float: left;
    width: 63%;
    padding: 0 1% 1% 1%;

    .navbar {
      width: 100%;
      padding: 0;
      margin: 0;
    }

    .navbar-default {
      background-color: white;
      border-color: white;
    }

    .container {
      width: 100%;
      padding: 0;
    }

    .col16 {
      float: left;
      width: 16%;
      padding: 1%;
    }

    .filterStyle {
      margin-left: 0;

      a {
        padding: 7px 0;
      }
    }
  }

  .col17 {
    float: left;
    width: 17%;
    padding: 1% 0;

    .col50 {
      width: 50%;
      float: left;
    }
  }

  .priceShow {
    margin-left: 15.5%;
  }

  .mileageShow {
    margin-left: 24%;
  }

  .makeShow {
    margin-left: 31.2%;
  }

  .modelShow {
    margin-left: 40%;
  }

  .yearShow {
    margin-left: 48.7%;
  }

  .typeShow {
    margin-left: 55%;
  }

  .btnSubmit {
    color: #fff;
    background-color: #f89406;
    border-color: #f89406;
    padding: 0;
    height: 30px;
    width: 80px;
  }

}

.app-body {
  float: left;
  width: 100%;
  padding: 0 1%;
}

#car-list {
  float: left;
  width: 100%;

  .col33 {
    float: left;
    width: 33.33%;
  }

  label {
    text-align: center;
    width: 100%;
    font-weight: $font-weight-regular;
  }

  img {
    cursor: pointer;
  }
}

.application-body {
  float: left;
  width: 100%;

  .app-title {
    text-align: center;
    background-color: gray;
    color: white;
    display: flex;
    flex-direction: column;

    h3 {
      margin-top: 10px;
    }
  }
}

.iconInactive :hover {
  cursor: pointer;
}

.form-horizontal .form-group, .pa {
  padding-right: 10px;
  padding-left: 10px;
}

.modal-users {
  .rowSelect {
    margin: -15px 10px 15px 10px
  }
}

.upload-photo {
  padding: 0 0 1px 10px;
}

.role-column {
  text-align: center !important;
}

.calculator-content {
  float: left;
  width: 100%;
  padding: 0 15px;

  .rowCalculator {
    float: left;
    width: 100%;
    padding: 5px 0;

    label {
      margin-bottom: 0;
      margin-top: 7px;
      font-size: 13px;
    }

    .form-control[disabled] {
      cursor: pointer;
    }
  }

  .rowPopupCalculator {
    float: left;
    width: 100%;
    padding: 5px 0;

    label {
      margin-bottom: 0;
      margin-top: 5px;
      font-size: 12px;
    }

    .form-control {
      height: 28px;
    }
  }

  .popupCalculator {
    text-align: center;
    background-color: #fffea6;
    width: 300px;
    height: 150px;
    border-style: double;
    border-color: black;
    border-width: 3px;
    position: absolute;
    top: 470px;
    left: 350px;
  }

  .popupTL {
    text-align: center;
    background-color: #fffea6;
    width: 300px;
    height: 220px;
    border-style: double;
    border-color: black;
    border-width: 3px;
    position: absolute;
    top: 630px;
    left: 350px;
  }

  .popupClose {
    text-align: right;
    padding-right: 10px;

    a {
      color: red;
      cursor: pointer;
      font-weight: 500;
    }
  }
}

.col80 {
  width: 80%;
  float: left;
}

@media screen and (max-width: 500px){
  .body-car-mechanical-info{
    .col80 {
      width: 100%;
      float: left;
      .classCarHeaderInput{
        padding-right: 15px;
        textarea{
          height: 320px;
        }
      }
    }
  }
}

.col60 {
  float: left;
  width: 60%;
}

.col55 {
  width: 55%;
  float: left;
}

.col50 {
  width: 50%;
  float: left;
}

.colL50 {
  width: 50%;
  float: left;
  padding-right: 10px;
}

.col45 {
  width: 45%;
  float: left;
}

.col40 {
  float: left;
  width: 40%;
}

.colL35 {
  width: 35%;
  float: left;
  padding-right: 10px;
}

@mixin col33() {
  width: 33.33%;
  float: left;
}

.col33 {
  @include col33;
}

.colL33 {
  width: 33.33%;
  float: left;
  padding-right: 10px;
}

@mixin col30() {
  width: 30%;
  float: left;
}

.col30 {
  @include col30;
}

.colL30 {
  width: 30%;
  float: left;
  padding-right: 10px;
}

.col25 {
  width: 25%;
  float: left;
}

.col75 {
  width: 75%;
  float: left;
}

.colR75 {
  width: 75%;
  float: left;
  padding-right: 10px;
}

.colR25 {
  width: 25%;
  float: left;
  padding-right: 10px;
}

.colR20 {
  width: 25%;
  float: left;
  padding-right: 10px;
}

.colCR30 {
  width: 30%;
  float: left;
  padding-right: 10px;
  padding-bottom: 15px
}

.colR35 {
  width: 35%;
  float: left;
  padding-right: 10px;
}

.colR50 {
  width: 50%;
  float: left;
  padding-right: 10px;
}

.colL25 {
  width: 25%;
  float: left;
  padding-right: 10px;
}

.col20 {
  width: 20%;
  float: left;
}

.colL20 {
  width: 20%;
  float: left;
  padding-right: 10px;
}

.col16 {
  width: 16.66%;
  float: left;
}

.col15 {
  width: 15%;
  float: left;
}

.col12 {
  width: 12.5%;
  float: left;
}

.colL12 {
  width: 12.5%;
  float: left;
  padding-right: 10px;
}

.colL15 {
  width: 15%;
  float: left;
  padding-right: 10px;
}

.col10 {
  width: 10%;
  float: left;
}

.colL10 {
  width: 10%;
  float: left;
  padding-right: 10px;
}

.col35 {
  width: 35%;
  float: left;
}

.col70 {
  width: 70%;
  float: left;
}

.col85 {
  width: 85%;
  float: left;
}

.col14 {
  width: 14%;
  float: left;
}

.col13 {
  width: 13%;
  float: left;
}

.colL13 {
  width: 13%;
  float: left;
  padding-right: 10px;
}

.popover-title {
  margin-top: 0;
}

.popover-content {
  color: black;
}

.check50 {
  float: left;
  width: 50%;
  color: #777777;

  label {
    font-weight: $font-weight-regular;
  }

  input {
    margin-right: 5px;
    float: left;
  }
}

.check33 {
  float: left;
  width: 33%;
  color: #777777;

  label {
    font-weight: $font-weight-regular;
  }

  input {
    margin-right: 5px;
    float: left;
  }
}

.rowPurchase {
  width: 100%;
  float: left;
  padding-bottom: 1%;
}

.panelApplication {
  color: whitesmoke;
  background-color: white;
  border-color: #ddd;

  h5 {
    font-weight: 700;
  }
}

.sectionStyle {
  color: whitesmoke;
  background-color: white;
}

.app-header {
  float: left;
  width: 100%;
  padding: 0 1%;

  .car-purchase {
    float: left;
    width: 100%;
    padding: 1% 0;
    color: #777777;

    label {
      font-weight: $font-weight-regular;
    }

    h3 {
      margin-top: 10px;
    }
  }
}

.text-sereach {
  width: 42%;
  height: 30px;
  float: left;
  font-size: 13px;
}

.image-gallery-image {
  .btn-icon {
    position: absolute;
    bottom: 10px;
    width: 100%;

    button {
      bottom: 10px;
      left: 3%;
      position: absolute;
      cursor: pointer;
      z-index: 10;
      height: 27px;
      font-size: 12px;
    }
  }
}

.image-gallery-thumbnail-label {
  .btn-icon {
    position: absolute;
    bottom: -5px;
    padding-left: 64px;
    color: white;
    font-weight: 900;
  }
}

.overlay-inventory {
  padding: 0 !important;

  .modal-dialog {
    width: 100%;
    margin: 0;

    .modal-body {
      padding: 0;
    }

    .modal-header {
      padding: 0;
      border: none;
    }

    .modal-content {
      border: none;
      overflow-y: auto;
    }
  }
}

.glyphicons {
  cursor: pointer;
}

.setting {
  .table-responsive {
    padding: 0 1%;
    color: #777777;

    .table > thead > tr > th {
      border-bottom: none;
    }
  }
}

.modal-upload {
  .modal-dialog {
    width: 80%;
  }
}

.error-row {
  background-color: #e3b6b6;
}

.error-column {
  background-color: #eee;
  color: #d9534f;
}

.container-admin {
  ul {
    li {
      padding: 3px 0;
      border-radius: 3px;

      a:hover {
        background-color: transparent !important;
        color: white !important;
        text-decoration: none;
      }
    }

    li:hover {
      a {
        background-color: transparent !important;
        color: white !important;
        text-decoration: none;
      }

      background-color: #1790d6 !important;
      color: transparent !important;
    }
  }

}

.colT100 {
  width: 100%;
  float: left;
}

.row-icon-delete {
  color: red;
  font-size: 20px;
  cursor: pointer;
}

.table-header-column {
  font-weight: $font-weight-bold !important;
  font-size: 14px !important;
  padding: 0 5px !important;
}

.table-header-column-Forms {
  font-size: 13px !important;
  font-weight: 700 !important;
}

.marginHeaderFormatterBottom {
  margin-bottom: -7px;
}

.iconActive {
  color: #5cb85c;
}

.iconInactive {
  color: #d9534f;
}

.icon-user {
  .iconActive:hover {
    color: #5cb85c;
  }

  .iconInactive:hover {
    color: #d9534f;
  }
}

.sort-icon {
  float: left;
  font-size: 16px;
  padding-top: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.classLabel {
  width: 30%;
  float: left;
}

.classInputCustomer {
  padding-bottom: 11px;
}

.classInputCar {
  width: 60%;
  float: left;
  padding-bottom: 10px;

  input {
    height: 30px !important
  }

  select {
    height: 30px !important
  }
}

.classInputCarInfo {
  width: 100%;
  float: left;
  padding: 0 15px 10px 0;
  position: relative;

  input {
    border-radius: 3px;
    height: 38px;
    border-color: #84858a;
  }

  select {
    height: 30px !important
  }
}

.carHeaderLabel {
  label {
    font-size: 12px;
    color: #787878;
    font-weight: $font-weight-bold;
    padding-left: 18px;
  }
}

.carInfoLabel {
  label {
    font-size: 13px;
    color: #787878;;
    font-weight: $font-weight-regular;
    padding-left: 10px;
  }
}

.has-error-vin {
  .form-control {
    border-color: $button-color-red;
    box-shadow: none;
  }
}

.has-found-vin {
  .form-control {
    border-color: #F3A200;
    box-shadow: none;
  }
}

.classHeaderInput {
  input {
    width: 70%;
    height: 30px;
  }

  select {
    width: 70%;
    height: 30px;
  }

  button {
    width: 70%;
    height: 30px;
  }
}

.classCarHeaderInput, .classInputCarMileage {
  padding-right: 20px;

  input {
    height: 38px;
    border-color: #84858a;
  }

  select {
    width: 70%;
    height: 30px;
  }

  button {
    width: 70%;
    height: 30px;
  }

  textarea {
    height: 256px;
    border-color: #84858a;
  }
}

.carDescriptionInput {
  textarea {
    height: 100px;
  }
}

.customerLabel {
  label {
    font-weight: $font-weight-light;
  }
}

.classInputUser {
  padding-bottom: 12px;
}

.userLabel {
  color: #333;
  font-weight: 700;
}

.car-upload-des {
  font-size: 12px;
}

.class-section {
  width: 100%;
  float: left;
  border: 1px solid #2976bd;
  background: #f5f6f7;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-radius: 3px;

  .class-section-sub {
    width: 100%;
    float: left;
    border: 1px solid #2976bd;
    background: #f5f6f7;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 3px;

  }

  .class-row {
    width: 100%;
    float: left;
    border: 1px solid #2976bd;
    background: #f5f6f7;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 3px;
  }

  h3 {
    margin: 0;
    padding: 10px;
    color: #2976bd;
  }
}

.icon-drag-drop {
  float: right;
  font-size: 20px;
  padding-right: 10px;

  i {
    padding: 0 5px;
    cursor: pointer;
  }
}

.formBuilder-separator {
  height: 1px;
  background: #e5e9ec;
}

.formBuilderHeader {
  float: left;
  padding-right: 10px;
}

.formBuilderSection-separator {
  height: 1px;
  background: #a3a3a5;
  width: 80%;
  float: left;
}

.formBuilderSections-separator {
  height: 2px;
  background: #e5e9ec;
  width: 80%;
  float: left;
}

.AttributeValues-separator {
  height: 2px;
  background: #e5e9ec;
  width: 90%;
  float: left;
  margin-bottom: 10px
}

.delete {
  top: 25%;
  left: 51%;
  bottom: 50%;
  right: 45%;
  width: 40px;
  height: 40px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #238fcf;
}

.header-form-builder {
  padding: 0 20px;
  color: #777777;

  h1 {
    margin: 0;
    padding: 10px 0;
  }
}

.element-group {
  width: 100%;
  float: left;
}

.element-group:hover {
  .icon-drag {
    visibility: visible;
  }
}

.icon-drag {
  text-align: center;

  .icon-drag-i {
    visibility: hidden;
  }
}

.icon-drag:hover {
  .icon-drag-i {
    cursor: pointer;
    color: #25a2b3;
    visibility: visible;
  }
}

.col90 {
  width: 90%;
  float: left;
}

.btn-add-section {
  width: 100%;
  float: left;
  padding: 0 20px;
  font-size: 36px;
  text-align: right;

  a {
    color: #01bcd5;
  }
}

.rfSI {
  cursor: pointer;
  display: inline-block;
  fill: #25a2b3;
  max-height: 24px;
  max-width: 24px;
  vertical-align: middle;
}

.rfSI.sm {
  max-height: 16px;
  max-width: 16px;
}

.rfSI.lg {
  max-height: 28px;
  max-width: 28px;
}

.rfSI.lg + .rfSI.lg {
  margin-left: 12px;
}

.rfSI.xlg {
  max-height: 32px;
  max-width: 32px;
}

.rfSI.xlg + .rfSI.xlg {
  margin-left: 12px;
}

.rfSI + .rfSI {
  margin-left: 12px;
  margin-right: 0;
}

.rfSI svg {
  cursor: pointer;
  display: block;
  fill: #25a2b3;
  height: 100%;
  opacity: 1;
  width: 100%;
}

.rfSI svg.disabled {
  color: #e1e1e1;
  cursor: not-allowed;
}

.rfSI svg.disabled:hover, .rfSI svg.disabled:active, .rfSI svg.disabled:focus {
  color: rgba(0, 0, 0, 0.7);
  cursor: not-allowed;
}

.rfSI svg:hover, .rfSI svg:active, .rfSI svg:focus {
  fill: #5D98CC;
}

.rfSI.rfSI-input {
  position: absolute;
  right: 0;
  top: 16px;
  fill: #25a2b3;
}

.rfSI.mdBtn-icon {
  position: absolute;
  right: 0;
  top: 16px;
  fill: #25a2b3;
}

.rfSI.g5 svg {
  fill: #555555;
}

.rfSI.g5 svg:hover, .rfSI.g5 svg:active, .rfSI.g5 svg:focus {
  fill: #5D98CC;
}

.rfSI.g6 svg {
  fill: #555555;
}

.rfSI.g6 svg:hover, .rfSI.g6 svg:active, .rfSI.g6 svg:focus {
  fill: #5D98CC;
}

.rfSI.g7 svg {
  fill: #555555;
}

.rfSI.g7 svg:hover, .rfSI.g7 svg:active, .rfSI.g7 svg:focus {
  fill: #5D98CC;
}

.rfSI.g8 svg {
  fill: #555555;
}

.rfSI.g8 svg:hover, .rfSI.g8 svg:active, .rfSI.g8 svg:focus {
  fill: #5D98CC;
}

.rfSI.g9 svg {
  fill: #555555;
}

.rfSI.g9 svg:hover, .rfSI.g9 svg:active, .rfSI.g9 svg:focus {
  fill: #5D98CC;
}

.rfSI.orange svg {
  fill: #fa8138;
}

.rfSI.orange svg:hover, .rfSI.orange svg:active, .rfSI.orange svg:focus {
  fill: #5D98CC;
}

.rfSI.blue svg {
  fill: #5D98CC;
}

.rfSI.blue svg:hover, .rfSI.blue svg:active, .rfSI.blue svg:focus {
  fill: #e13a5d;
}

.rfSI.pink svg {
  fill: #e13a5d;
}

.rfSI.pink svg:hover, .rfSI.pink svg:active, .rfSI.pink svg:focus {
  fill: #5D98CC;
}

.rfSI.purple svg {
  fill: #25a2b3;
}

.rfSI.purple svg:hover, .rfSI.purple svg:active, .rfSI.purple svg:focus {
  fill: #5D98CC;
}

.rfSI.red svg {
  fill: #F44336;
}

.rfSI.red svg:hover, .rfSI.red svg:active, .rfSI.red svg:focus {
  fill: #5D98CC;
}

input + .rfSI-input svg:hover, input + .rfSI-input svg:active, input + .rfSI-input svg:focus {
  fill: currentColor;
}

input:focus + .rfSI-input svg {
  transition: 0.2s ease-in-out;
  fill: #5D98CC;
}

input:focus + .rfSI-input svg:hover, input:focus + .rfSI-input svg:active, input:focus + .rfSI-input svg:focus {
  fill: #5D98CC;
}

.raG-5,
.raG-10,
.raG-15,
.raG-20,
.raG-25,
.raG-30,
.raG-33,
.raG-35,
.raG-40,
.raG-45,
.raG-50,
.raG-55,
.raG-60,
.raG-65,
.raG-66,
.raG-70,
.raG-75,
.raG-80,
.raG-85,
.raG-90,
.raG-95,
.raG-100 {
  width: 100%;
}

.form-builder {
  padding: 15px 15px 0 15px;
  width: 100%;
  float: left;
}

.form-builder .inline-block {
  display: inline-block;
}

.form-builder .form-horizontal {
  margin: 10px 0;
}

.form-builder .drag-empty-container {
  background-color: white;
  height: 125px;
  width: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.5);
  margin-bottom: 5px;
}

.form-builder .form-checklist-container {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
}

.form-builder .form-options-container {
  margin: 0 0 20px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 12px 20px;
}

.form-builder .form-options-container h4 {
  margin: 0;
  font-size: 14px;
}

.form-builder .form-options-container hr {
  margin: 0;
  border-bottom: 1px dashed #e1e1e1;
}

.form-builder .form-options-container .form-question-type {
  margin-right: 24px;
  display: inline-block;
  text-align: center;
}

.form-builder .form-options-container .form-question-type:hover, .form-builder .form-options-container .form-question-type:active, .form-builder .form-options-container .form-question-type:focus {
  cursor: pointer;
}

.form-builder .form-options-container .form-question-type span.rfSI {
  margin-bottom: 12px;
}

.form-builder .form-options-container .form-question-type p {
  font-size: 12px;
  font-weight: 700;
  color: #25a2b3;
}

.form-builder .form-builder-question {
  background-color: transparent;
  margin-bottom: 12px;
}

.form-builder .form-builder-question.active {
  padding: 12px 20px;
  background-color: rgba(0, 0, 0, 0.1);
}

.form-builder .form-builder-question select,
.form-builder .form-builder-question input,
.form-builder .form-builder-question .rf-select2-override .select2-choices {
  background-color: inherit !important;
}

.form-builder .form-builder-option input {
  padding: 0;
}

.form-builder .form-date-picker .rfSI-input {
  top: 8px;
}

.form-builder .form-builder-inline-add-button {
  position: relative;
  height: 24px;
}

.form-builder .form-builder-inline-add-button .inline-hover-add-button {
  bottom: auto;
  top: 0;
}

.form-builder .option-container2,
.form-builder .form-builder-add-option {
  min-height: 36px;
}

.form-builder .option-container2 .rf-input,
.form-builder .form-builder-add-option .rf-input {
  padding: 0;
  vertical-align: bottom;
}

.form-builder .form-question-container {
  border: 1px solid transparent;
}

.form-builder .form-question-container .parent-item {
  border: 1px solid #25a2b3;
}

.form-builder .form-question-container .child-item {
  border: 1px solid #25a2b3;
}

.twitter-typeahead .tt-hint, .twitter-typeahead .tt-input {
  margin-bottom: 0;
  height: 32px;
  padding: 16px 32px;
  background-color: white !important;
}

#myTypeAhead .glyphicon-search {
  position: absolute;
  z-index: 2;
  left: 10px;
  width: 15px;
  text-align: center;
  padding: 8px 0;
  display: inline-block;
  font-size: 15px;
  margin-left: 0;
  top: 1px;
}

.rfG-row {
  margin-left: -8px;
  margin-right: -8px;
  font-size: 0;
  clear: both;
}

.rfG-row.mbtm-10 {
  margin-bottom: 10px;
}

.rfG-row.mbtm-20 {
  margin-bottom: 20px;
}

.rfG-row.mbtm-40 {
  margin-bottom: 40px;
}

.rfG-row:after, .rfG-row:before {
  content: '';
  clear: both;
  display: table;
}

/*-- MD GRID --*/
@media (min-width: 769px) {
  .rfG-md-5 {
    width: 5%;
  }

  .rfG-md-10 {
    width: 10%;
  }

  .rfG-md-15 {
    width: 15%;
  }

  .rfG-md-20 {
    width: 20%;
  }

  .rfG-md-25 {
    width: 25%;
  }

  .rfG-md-30 {
    width: 30%;
  }

  .rfG-md-33 {
    width: 33.33%;
  }

  .rfG-md-35 {
    width: 35%;
  }

  .rfG-md-40 {
    width: 40%;
  }

  .rfG-md-45 {
    width: 45%;
  }

  .rfG-md-50 {
    width: 50%;
  }

  .rfG-md-55 {
    width: 55%;
  }

  .rfG-md-60 {
    width: 60%;
  }

  .rfG-md-65 {
    width: 65%;
  }

  .rfG-md-66 {
    width: 66.66%;
  }

  .rfG-md-70 {
    width: 70%;
  }

  .rfG-md-75 {
    width: 75%;
  }

  .rfG-md-80 {
    width: 80%;
  }

  .rfG-md-85 {
    width: 85%;
  }

  .rfG-md-90 {
    width: 90%;
  }

  .rfG-md-95 {
    width: 95%;
  }

  .rfG-md-100 {
    width: 100%;
  }

  /*-- MD OFFSET GRID --*/
  .rfG-md-off-0 {
    margin-left: 0;
  }

  .rfG-md-off-5 {
    margin-left: 5%;
  }

  .rfG-md-off-10 {
    margin-left: 10%;
  }

  .rfG-md-off-15 {
    margin-left: 15%;
  }

  .rfG-md-off-20 {
    margin-left: 20%;
  }

  .rfG-md-off-25 {
    margin-left: 25%;
  }

  .rfG-md-off-30 {
    margin-left: 30%;
  }

  .rfG-md-off-33 {
    margin-left: 33.33%;
  }

  .rfG-md-off-35 {
    margin-left: 35%;
  }

  .rfG-md-off-40 {
    margin-left: 40%;
  }

  .rfG-md-off-45 {
    margin-left: 45%;
  }

  .rfG-md-off-50 {
    margin-left: 50%;
  }

  .rfG-md-off-55 {
    margin-left: 55%;
  }

  .rfG-md-off-60 {
    margin-left: 60%;
  }

  .rfG-md-off-65 {
    margin-left: 65%;
  }

  .rfG-md-off-66 {
    margin-left: 66.66%;
  }

  .rfG-md-off-70 {
    margin-left: 70%;
  }

  .rfG-md-off-75 {
    margin-left: 75%;
  }

  .rfG-md-off-80 {
    margin-left: 80%;
  }

  .rfG-md-off-85 {
    margin-left: 85%;
  }

  .rfG-md-off-90 {
    margin-left: 90%;
  }

  .rfG-md-off-95 {
    margin-left: 95%;
  }

  .container-setting-column {
    width: 33.33%;
  }
}

.tt-menu {
  /* UPDATE: newer versions use tt-menu instead of tt-dropdown-menu */
  max-height: 180px;
  overflow-y: auto;
  text-align: left;
  width: 100%;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tt-dataset {
  padding-left: 10px;
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 18px;
  line-height: 24px;
}

.tt-suggestion.tt-cursor { /* UPDATE: newer versions use .tt-suggestion.tt-cursor */
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion p {
  margin: 0;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0097cf;
}

.typeahead-group {
  font-weight: $font-weight-bold;
  margin-top: 5px;
  margin-bottom: 5px;
}

.md-frame .md-frame-accordion h3 {
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  font-weight: 600;
}

.element-edit-header {
  width: 100%;
  float: left;
  padding: 10px 0 0 15px;
}

.form-builder-attribute {
  width: 100%;
  float: left;
  padding: 15px;
}

.attribute-fields {
  width: 30%;
  float: left;
  padding-right: 15px;
  padding-bottom: 10px;
}

.attribute-label {
  width: 100%;
  padding-left: 5px;
  font-weight: $font-weight-regular;
}

.col87 {
  float: left;
  width: 87%;
}

.border-search-options {
  border-radius: 25px;
  border: 2px solid #00bcd4;
  padding: 20px;
  height: 150px;
}

.disabled-button {
  pointer-events: none;
  color: #777777;
}

a:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.container-car-image {
  width: 100%;
  height: 400px;
  text-align: center;
  background-color: black;
}

.main-car-image {
  position: relative;
}

.upload-image-car, .upload-image-car-info {
  border-width: 2px;
  border-color: #666666;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.upload-image-car {
  height: 300px;
  border-style: dashed;
  float: left;
}

.upload-image-car-info {
  text-align: center;
  padding: 200px 0 200px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-document-car {
  text-align: center;
  padding: 30px 0 30px 0;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(234, 234, 234);
  border-radius: 4px;
}

.line1-sold-main {
  width: 95%;
  height: 80px;
  border-bottom: 2px solid #111;
  -webkit-transform: translateY(0) translateX(5px) rotate(35deg);
  position: absolute;
  top: 35px;
  left: -5px;
}

.line2-sold-main {
  width: 95%;
  height: 15px;
  border-bottom: 2px solid #111;
  -webkit-transform: translateY(0) translateX(5px) rotate(35deg);
  position: absolute;
  top: 65px;
  left: 36px;
}

.text-sold-main {
  position: absolute;
  left: 26%;
  bottom: 32%;
  font-size: 50px;
  color: red;
  font-weight: 700;
}

.image-gallery-slide img {
  width: auto !important;
}

/**************************************** New style  **************************************************/
@mixin transition-all() {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar-collapse {
  width: 200px;
  background: #394464 linear-gradient(#394464, #005193);
  float: left;
  left: 0;
  @include transition-all;
  position: fixed;
  z-index: 50;
}

.hide-sidebar {
  width: $width-sidebar-hide;
}

@mixin container-component() {
  float: left;
  padding-left: $width-sidebar-hide;
  padding-bottom: 30px;
  background-color: #f5f6fa;
  width: 100%;
  position: relative;
  -webkit-transition: padding 0.3s ease;
  -moz-transition: padding 0.3s ease;
  -o-transition: padding 0.3s ease;
  transition: padding 0.3s ease;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.container-component {
  @include container-component;

  .message-bar-container {
    display: flex;
    min-height: 40px;
    background-color: #4AD991;
    color: #FFFFFF;
    padding: 10px 30px;
    align-items: center;

    .icon {
      font-size: 25px;
      margin-left: auto;
      cursor: pointer;
    }
  }
  .message-alert{
    background-color: #F3A200;
    justify-content: space-between;

    .button {
      display: block;
      font-weight: 700;
      cursor: pointer;
    }

  }

  .message-bar-container-alert {
    display: flex;
    height: 39px;
    justify-content: space-between;
    align-items: center;
    background-color: #F3A200;
    padding: 0px 30px;

    .right-section {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .icon {
      display: inline;
    }
    .pay {
      margin-left: auto;
      display: inline;
      cursor: pointer;
      font-weight: 700;
    }
  }
}

.container-collapse {
  padding-left: 200px;
}

.wrapper-header {
  height: 60px;
  float: right;
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #FFF;

  .pill-box {
    width: auto;
  }
}

.menu-bar-collapse {
  float: left;
  width: 200px;
  height: 100%;
  padding: 15px;
  font-size: 20px;
  background-color: #3a3a52;
  text-align: center;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;

  a {
    color: #fff;
    cursor: pointer;
  }
}

.hide-menu-bar {
  width: 100%;
}

.menu-user {
  float: right;
}

.sidebar-icon-selected {
  border-left: 3px #2194f1 solid;
  background-color: #363b58;

  a {
    padding-left: 12px !important;
    color: #fff;
  }

  label {
    color: #fff;
  }

  .icon-container {
    margin-left: -3px;
  }
}

.label-menu {
  padding: 12px 0;
  margin: 0;
  font-weight: $font-weight-light;
  font-size: 17px;
  font-family: $font-family;
}

.sidebar-menu li:hover {
  color: #fff;
  cursor: pointer;

  a {
    color: #fff;
  }

  label {
    cursor: pointer;
    color: #fff;
  }
}

.btn-home {
  float: right;
  color: #424355;
  background-color: #fff;
  border-color: #41435a;
  border-radius: 0;
  font-weight: 700;
  font-style: oblique
}

.container-message-btn-setting {
  width: inherit;
  .sidebar-menu-setting {
    position: fixed !important;
    bottom: 0;
    width: inherit;
  }
}

.more-options {
  bottom: 20px;
  position: absolute !important;
  cursor: default !important;
  padding: 0 15px !important;

  &:hover {
    color: #93a4c2 !important;
  }
}

#menu-search {
  margin-top: 15px;
  border: 0;
  float: left;
  width: 360px;
  padding: 5px;
  outline: none;
  font-family: $font-family;
  font-weight: $font-weight-regular;
  font-size: 13px;

  &::placeholder {
    color: $icon-arrow-color !important;
  }

  &:hover::placeholder {
    color: $placeholder-color-hovered !important;
  }
}

.menu-search {
  a {
    font-size: 18px;
    padding: 17px 15px 17px 17px;
    float: left;
    color: #bebcc7;
  }

  input {

  }
}

.dropdown {
  .menu-user-a {
    color: #787878;
    font-weight: $font-weight-bold;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin: 10px 0;
    font-size: 14px;

    span {
      padding: 0 5px 0 15px;
    }

    i {
      color: #b3bac0;
      padding: 0 10px;
    }

    .image-icon {
      width: 24px;
      border-radius: 50%;
    }

    .image-icon:hover {
      cursor: pointer;
    }
  }

  .dropdown-menu {
    right: 0 !important;
    left: inherit;
    padding: 0;
    border-radius: 7px;
    border: 1px solid #D7DAE2;
    box-shadow: 0 0 15px #00000012;
    min-width: 166px !important;

    li:hover {
      cursor: pointer;

      label {
        cursor: pointer;
      }

      .dropdown-menu-option-icon {
        fill: white;
      }
    }


    .dropdown-menu-option, .dropdown-menu-option-sign-out, .dropdown-menu-option-second {
      float: right;
      width: 100%;

      label {
        margin: 0;
        color: #787878;
        float: right;
        font-weight: $font-weight-regular;
      }
    }

    .dropdown-menu-option {
      padding: 10px 12px 3px 12px;
    }

    .dropdown-menu-option-sign-out {
      padding: 15px 12px 10px 12px;
    }

    .dropdown-menu-option-second {
      padding: 3px 12px;
    }

    .dropdown-menu-option-icon {
      padding-right: 5px;
      width: 20px;
    }
  }

  .menu-user-a-icon {
    color: #4f5055;
    font-weight: 600;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin: 10px 0;
    float: right;
  }
}

.dropdown .menu-user-a:hover {
  text-decoration: none;
}

.dropdown .menu-user-a i:hover {
  cursor: pointer;
}

.menu-user {
  .menu-user-u {
    margin: 0;
  }
}

.menu-notification {
  float: right;
  padding: 16px 0;
}

.menu-pay {
  float: right;
  margin: 10px 20px 10px 0;
}

/* ------ Dashboard ------ */

.dashboard-header {
  float: left;
  width: 100%;
  color: #777;
  background-color: #F5F6FA;
  height: 60px;
  display: flex;
  align-items: center;
  @include shadow-header;

  h2 {
    font-size: 30px;
    margin-top: 20px;
  }

  .container-header-dashboard {
    padding-right: 50px;

    .left-container {
      .tile-dashboard {
        width: 100%;
      }
    }

    .right-container {
      .btn-user {
        float: right;
        color: white;
        background: rgb(33, 148, 241);
        padding: 5px 20px;
        cursor: pointer;
        font-family: $font-family;
        font-weight: $font-weight-regular;
      }
    }
  }
}

.dashboard-body {
  float: left;
  width: 100%;
  padding: 24px 25px;

  .dashboard-graphic {
    float: left;
    width: 100%;
    display: flex;

    .dashboard-user {
      float: left;
      width: 30%;
      background-color: #fff;
      text-align: center;
      padding: 20px 0 30px 0;

      .icon-user-content {
        #dropzone-modal-dashboard {
          position: relative;
        }
      }

      .icon-user {
        box-shadow: 0 0 10px 0 #a4afb7;
        border: 1px solid #FFF;
      }

      .password {
        font-size: 9px;
        font-weight: lighter;
        color: #2194F1;
        padding-left: 10px;
      }

      .new-user-photo-circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #2194F1;
        -webkit-box-shadow: 0 0 10px 0 #a4afb7;
        -moz-box-shadow: 0 0 10px 0 #a4afb7;
        box-shadow: 0 0 10px 0 #a4afb7;
        color: #fff;
        padding-top: 2px;
        border: 1px solid #FFF;
        align-content: center;
        margin: -30px auto 2px;
        position: relative;
        left: 40px;

        .fa-camera {
          font-size: 12px;
        }
      }

      .react-switch-bg {
        border-radius: 5px !important;
      }

      .react-switch-handle {
        border-radius: 4px !important;

      }

      .btn-user {
        margin-top: 40px;
        background-color: #2194F1;
        color: #ffffff;
        border-radius: 5px;
        font-family: $font-family;
        font-size: 13px;
        font-weight: $font-weight-regular;
      }

      .align-right {
        text-align: right;

        .btn-cancel {
          background: white;
          color: rgb(33, 148, 241);
          margin-right: 5px;
        }
      }

      .btn-password-change {
        text-align: right;
        width: 41%;
        align-self: center;
        padding-right: 0;
      }

      .userInfoRow {
        margin-top: 12px;

        .error-password {
          color: red;
          font-size: 10px;
        }
      }

      .dropdown-item {
        text-align: left;

        span {
          color: #787878;
        }
      }

      .userInfoLabel {
        color: #A4AFB7;
        font-size: 10px;
        line-height: 12px;
        text-align: left;
        padding-left: 10px;

        label {
          font-weight: lighter;
        }
      }

      .userProfileInput {
        border-radius: 4px;
        height: 38px;
        border-color: #A4AFB7;
        box-shadow: none;
        font-size: 13px;
      }

      .urlIconSocial {
        padding-left: 40px;
        padding-right: 45px;
      }

      .userProfilePasswordInput {

        border-radius: 4px;
        height: 38px;
        border-color: #4AD991;
        box-shadow: none;
        font-size: 13px

      }

      @include shadow-body;
      margin-right: 1%;
      border-radius: 10px;

      .icon-user-content {
        @include col100;
        padding: 30px 0;
        position: relative;
      }

      .icon-user {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;

        &:hover {
          cursor: pointer;
        }
      }

      .icon-user-svg {
        background-color: #d7dae2;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
        box-shadow: 0 0 10px 0 #a4afb7;
        border: 1px solid #FFF;
        margin: 0 auto;

        svg {
          width: 150px;
          height: 150px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .data-user, .data-user-name, .data-user-rol, .data-user-phone {
        @include col100;
        color: #787878;

        span {
          font-size: 13px;
          font-family: $font-family;
          font-weight: $font-weight-regular;
        }
      }

      .data-user-rol {
        padding: 0 0 10px 0;

        span {
          font-size: 15px;
          font-family: $font-family;
          font-weight: $font-weight-bold;
        }
      }

      .data-user-name {
        padding: 10px 0 0 0;

        span {
          font-size: 21px;
          font-family: $font-family;
          font-weight: $font-weight-bold;
        }
      }

      .react-switch-handle {
        height: 25px !important;
      }

      .profile-switch {
        @include item-center;
        height: 100%;
        font-size: 9px;
        font-weight: lighter;
        color: white;
        padding-right: 4px;
      }

      .profile-switch-container {
        float: right;
      }
    }

    .dashboard-container-user {
      padding: 10px 0;

      .second-container {
        padding: 10px 40px;

        .container-left {
          padding-right: 5px;
        }

        .container-right {
          padding-left: 5px;

        }

        .userInfoRow {
          .dropdown-roles-user {
            border: none;
            box-shadow: none;
            height: 100%;
            max-height: 192px;
            overflow: auto;
          }
        }
      }
    }

    .dashboard-statistic {
      float: left;
      width: 33%;
      background-color: #fff;
      padding: 20px 0;
      margin-right: 1%;

      .row-statistics-user {
        float: left;
        width: 100%;
        padding-left: 20px;

        .container50 {
          text-align: left;
        }

        .margin-top {
          margin-top: 40px;

          .align-left {
            text-align: left;
          }

          .userInfoRow {
            .userInfoSmallTitle {
              float: left;
            }

            .margin-top {
              margin-top: 20px;

              .userInfoSmallTitle {
                float: left;
              }
            }
          }
        }
      }

      .user-settings-column {
        width: 50%;
      }

      .userInfoTitle {
        color: #787878;
        font-weight: $font-weight-bold;
        font-size: 15px;
      }

      .userInfoSmallTitle {
        color: #787878;
        font-weight: $font-weight-regular;
        font-size: 13px;
      }

      .userInfoRow {
        padding-right: 20px;
      }

      @include shadow-body;
      margin-left: 1%;
      border-radius: 10px;
    }

    .dashboard-theme {
      float: left;
      width: 33%;
      background-color: #fff;
      padding: 20px;
      margin-left: 1%;
      border-radius: 10px;
      position: relative;
      @include shadow-body;

      .content-image-theme {
        width: 100%;
        float: left;
        padding-bottom: 10px;

        div > div > svg {
          width: 100%;
          height: 50%;
        }

        img {
          width: 100%;
        }
      }

      .title-theme {
        font-size: 15px;
        color: #787878;
        width: 100%;
        padding-left: 10px;
        font-family: $font-family;
        font-weight: $font-weight-bold;
      }

      .title-page {
        padding-left: 10px;

        &:hover {
          cursor: pointer;
        }
      }

      .content-button {
        @include item-center;
        margin-top: 40px;

        .btn-theme {
          background-color: #2194F1;
          color: #ffffff;
          border-radius: 5px;
          height: 36px;
          bottom: 30px;
          border: 0 !important;
          font-family: $font-family;
          font-weight: $font-weight-regular;
          font-size: 13px;
        }
      }
    }
  }

  .dashboard-item, .dashboard-item-theme {
    float: left;
    width: 100%;
    padding-top: 20px;

    .dashboard-item-customer {
      float: left;
      width: 30%;
      margin-right: 1%;
    }

    .dashboard-item-content {
      float: left;
      background-color: #fff;
      padding: 20px 0;
      @include shadow-body;
      border-radius: 10px;

      h5 {
        padding: 0 20px;
        float: left;
        font-size: 15px;
        font-family: $font-family;
        font-weight: $font-weight-bold;
        color: #787878;
      }

      img {
        float: right;
        padding: 5px 20px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .total {
      width: 100%;
    }

    .left {
      width: 48.5%;
      margin-right: 1.5%;
    }

    .right {
      width: 48.5%;
      margin-left: 1.5%;
    }

    .name-template {
      float: left;
      width: 70%;

      .theme-active {
        width: 100%;
      }

      label {
        font-weight: 700;
        padding: 0 0 0 20px;
        color: #4ed4a2;
        font-family: $font-family;
        font-size: 11px;
      }
    }

    .btn-custom-content {
      float: right;
      width: 30%;
      padding: 10px 20px;

      .btn-custom {
        float: right;
        background-color: #2194F1;
        color: #ffffff;
        border-radius: 5px;
        font-size: 13px;
        font-family: $font-family;
        font-weight: $font-weight-regular;
      }
    }
  }
}

.dashboard-footer {
  width: 100%;
  color: #777;
  background-color: #FFF;
  height: 60px;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  @include shadow-header;
  border-top: 2px solid #F5F6FA;
  padding: 0px 30px;

  .icon-arrow-next, .icon-arrow-back {
    width: 15px;
    height: 13px;
    .a {
      fill: #2194F1;
    }
  }
}

.section-requirements-user-option {
  border-bottom: 2px #787878 solid;
  display: flex;
  margin-right: 54px;
  margin-top: 12px;
  width: 95%;
  float: left;
  margin-bottom: 10px;

  .options-change-password {
    padding-right: 0 !important;
    width: 100% !important;
    margin-top: 65px !important;
    margin-bottom: 10px !important;
  }
}

.section-requirements {
  color: #787878;
  font-size: 15px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 16px;
}

.form-group-requirements {
  color: rgb(164, 175, 183);
  text-align: left;
  padding-left: 16px;
  cursor: pointer;
  font: normal 13px/22px 'Poppins', sans-serif;
  position: relative;
  display: flex;
}

.dashboard-item-car {
  float: left;
  width: 68%;
  margin-left: 1%;
}

.btn-statistics {
  float: right;
  color: #424355;
  background-color: #fff;
  border-color: #dddee0;
  border-radius: 3px;
  font-weight: 700;
  margin-top: 15px;

  span {
    font-size: 11px;
    color: #6d6e72;
    padding: 0 10px;
  }

  i {
    color: #a7aeb4;
  }
}

.row-statistics {
  float: left;
  width: 100%;
  padding: 0 20px;

  h3 {
    color: #787878;
    float: left;
  }
}

.row-statistics-image {
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    vertical-align: middle;
    width: 75%;
  }
}

.title-dashboard {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 100%;
  padding-left: 30px;

  h2 {
    float: left;
    font-size: 21px;
    color: #787878;
    font-weight: $font-weight-bold;
    width: 95px;
    margin: 0;
    font-family: $font-family;
  }

  .title-text-info {
    width: auto;
  }
}

.update-dashboard {
  width: 20%;
  float: right;
  height: 100%;
  background-color: #fff;

  .update-icon {
    float: right;
    padding: 15px 40px;
  }

  .update-icon:hover {
    cursor: pointer;
  }

  .update-icon-dashboard {
    height: 20px;
    width: 20px;
    fill: #A4AFB7;
  }
}

/* ------ Dashboard ------ */

.modal-search {
  position: absolute;
  top: 60px;
  background-color: #fff;
  left: 50px;
  border-top: 1px #f6f6f8 solid;
  -webkit-transition: left 0.3s ease;
  -moz-transition: left 0.3s ease;
  -o-transition: left 0.3s ease;
  transition: left 0.3s ease;
  z-index: 10;

  .search-item {
    float: left;
    width: 25%;
    padding: 15px 0;
    border-bottom: 1px #f6f6f8 solid;
  }

  .search-task-title {
    color: #87c2a4;
    padding: 0 25px;

    h5 {
      margin-bottom: 0;
    }
  }

  .search-car-title {
    color: #cf8989;
    padding: 0 25px;
  }

  .search-user-title {
    color: #e0b469;
    padding: 0 25px;
  }

  .search-customer-title {
    color: #6393bb;
    padding: 0 25px 10px 25px;
    float: left;
    width: 100%;

    h5 {
      margin-bottom: 0;
      float: left;
    }
  }

  .dot {
    height: 5px;
    width: 5px;
    background-color: #e27271;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 3px;
  }

  .row-search {
    padding: 8px 25px;
    color: #a9aeb2;
    float: left;
    width: 100%;

    label {
      padding: 8px 0 0 10px;
      font-weight: 300;
    }
  }

  .icon-search-arrow {
    display: none;
    float: right;
    padding: 9px 0;
  }

  .row-search:hover {
    background-color: #e4edf4;

    .icon-search-arrow {
      display: block;

    }
  }
}

.modal-collapse {
  left: 200px;
}

.row-modal-search {
  float: left;
  width: 100%;
}

.row-history {
  padding: 0 0 15px 10px;
  color: #a9aeb2;

  label {
    font-weight: 500;
    padding-left: 10px;
  }
}

.btn-category {
  float: right;
  background-color: #fff;
  border-color: #9d9ca2;
  border-radius: 5px;
  font-weight: 500;
  margin-top: 5px;

  span {
    font-size: 11px;
    color: #bab9bf;
    padding: 0 10px;
  }

}

.btn-basic {
  padding: 6px 12px;
}

.btn-pagination {
  border: 1px #e5e6e9 solid;
  border-right: 1px transparent solid;
  color: #7c7b80;
  background-color: #fff;
}

.btn-p-first {
  border-radius: 4px 0 0 4px;
  width: 50px;
}

.btn-p-last {
  border-radius: 0 4px 4px 0;
  width: 50px;

  .btn-container-message {
    border-right: 1px #e5e6e9 solid;
  }
}

@mixin setting-container() {
  float: left;
  width: 100%;
  @include shadow-body;
  background-color: #fff;
  display: flex;
  border-radius: 5px;

  .popover-general-message {
    overflow: hidden;
  }

  .setting-form-header-header {
    .row-message {
      height: 60px;
    }
  }

  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;

    .loader {
      border: 7px solid #f3f3f3; /* Light grey */
      border-top: 7px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 64px;
      height: 64px;
      animation: adjust-hue 1s linear infinite;
    }

    @keyframes adjust-hue {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.message-container, .setting-container, .container-add-attribute, .testimonial-container, .container-invoices, .container-payment, .setting-seo-container {
  @include setting-container;
}

.setting-container {
  border-radius: 10px;

  .template-container {
    .template-group-0 {
      margin: 0;
      padding-bottom: 15px;
    }

    .template-group-1 {
      margin: 0;
      padding-top: 15px;
      padding-bottom: 0;
    }
  }

  .seo-section-item {
    height: 34px;

    .left-icon-svg {
      height: 20px;
      width: 20px;
    }

    .right-icon-svg {
      height: 20px;
      width: 20px;

      path {
        fill: rgba(0, 0, 0, 0.5)
      }
    }

    &:hover {
      background-color: #f5f6fa;
      cursor: pointer;
    }
  }
}

.setting-seo-container {
  border-radius: 10px;
  min-height: 420px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  width: 50%;
  margin-bottom: 10px;

  .seo-section-header {
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    .header-title-container {
      font-size: 21px;
      padding-bottom: 7px;
    }

    .sub-title-container {
      display: flex;

      .sub-title-text {
        font-size: 13px;
        min-height: 26px;
      }

      .progress-bar-container {
        width: 100px;
        margin-left: auto;
        min-height: 10px;
      }

      .progress-bar-label {
        padding-left: 5px;
        display: flex;
        align-items: start;
        min-height: 10px;
        font-size: 9px;
      }
    }

    .header-message-container {
      font-size: 13px;
      min-height: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #f5f6fa;
    }
  }

  .seo-section-item {
    height: 34px;
    display: flex;
    align-items: center;
    padding: 0 20px;

    .left-svg-image-container {
      height: 20px;
      width: 20px;
    }

    .left-icon-svg {
      height: 20px;
      width: 20px;
    }

    .seo-item-text {
      font-size: 13px;
      min-height: 34px;
      padding-left: 5px;
      display: flex;
      align-items: center;
    }

    .right-svg-image-container {
      margin-left: auto;
      height: 20px;
      width: 20px;
    }

    .right-icon-svg {
      height: 20px;
      width: 20px;

      path {
        fill: rgba(0, 0, 0, 0.5)
      }
    }

    &:hover {
      background-color: #f5f6fa;
      cursor: pointer;
    }
  }

  .custom-horiz-line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #f5f6fa;
    margin: 1em 0;
    padding: 0;
  }
}

.setting-user-filters {
  position: relative;
  width: 100%;
  padding-left: 55px;

  .container-left {
    float: left;
    width: 45%;
    display: flex;
  }

  .container-right {
    float: right;
    //width: 39%;
    right: -18px;
    display: flex;
    position: relative;
    padding-left: 30px;

    .user-buttons {
      padding: 14px;
      position: relative;

      button {
        width: 140px;
        border: 1px solid $border-button-color-pagination !important;
        float: left;
        margin: 0 5px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }

      .icon-active, .icon-inactive {
        div {
          @include item-center;
        }

        .svg-inactive {
          .a {
            fill: #ffffff;
          }
        }
      }

      .all {
        color: $color-button-all;
      }

      .active {
        color: $green-color;

        svg {
          height: 15px;
          fill: $green-color;
          padding-right: 5px;
        }

        label {
          margin-bottom: 0;
        }

        &:hover {
          label {
            cursor: pointer;
          }
        }
      }

      .inactive {
        color: $button-color-red;

        svg {
          height: 15px;
          width: 15px;
          fill: $button-color-red;
          margin-right: 5px;
        }

        label {
          margin-bottom: 0;
        }

        &:hover {
          label {
            cursor: pointer;
          }
        }
      }
    }

    .user-pagination {
      float: right;
      padding: 14px;
      position: relative;
      right: 20px;
      font-size: 12px;

      .dp-pagination-container {
        .pagination-left {
          .btn-page-group {
            border-radius: 4px;
            height: $height-box-input;
          }
        }

        .pagination-right {
          display: flex;

          .btn-back {
            border-radius: 4px 0 0 4px;
            height: $height-box-input;
          }

          .btn-center {
            border-top: 1px solid $border-button-color-pagination;
            border-bottom: 1px solid $border-button-color-pagination;
            border-left: none;
            border-right: none;
            height: $height-box-input;
          }

          .btn-next {
            border-radius: 0 4px 4px 0;
            height: $height-box-input;
          }
        }
      }
    }
  }


}

.setting-inventory-container {
  float: left;
  width: 100%;
}

.setting-container, .setting-inventory-container {
  .inventory-body {
    padding: 0;
    width: 100%;
    float: left;

    .section-box, .section-box-delete-inventory, .section-box-sold-image {
      @include col100;
      float: left;
      width: 35%;
      background-color: #fff;
      text-align: center;
      padding: 20px 20px 30px 20px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
      margin-right: 2%;
      border-radius: 10px;
    }

    .section-box-delete-inventory {
      width: 30%;
      padding: 20px 28px 30px 20px;
    }

    .section-box-sold-image {
      margin-top: -83px;
      padding: 20px 25px 30px 20px;
    }

    .section-left-inventory, .section-delete-inventory {
      float: left;
      padding-left: 8px;

      .container-title-coming-soon {
        text-align: left;

        .title-coming-soon {
          color: $text-color;
          font-size: 21px;
        }
      }

      .container-icon-sub-title {
        display: flex;
        text-align: left;
        width: 100%;
        float: left;

        .container-icon-exclamation {
          padding: 4px 10px 0 0;

          .icon-error div {
            svg {
              height: 16px;
              width: 16px;
              fill: $icon-exclamation-color;
              border: 1px solid $border-color-icon-exclamation;
              border-radius: 50px;
            }
          }
        }

        .container-sub-title {
          .sub-title-coming-soon {
            padding: 2px 6px 0 0;
            color: $text-color;
            font-size: 13px;
          }
        }
      }

      .container-select-status {
        width: 160px;
        float: left;

        .label-select-status {
          font-size: 10px;
          color: #A4AFB7;
          font-weight: 500;
          text-align: left;
          padding: 0 0 8px 11px;
        }

        .box-select-status {
          width: 100%;
          @include border-box;
          height: $height-box-input;
          text-align: left;
          background-color: white;

          .label-select {
            font-size: 13px;
            color: $text-color;
            font-weight: 500;
            font-family: 'Open Sans', sans-serif;
          }

          &:focus {
            border: 1px solid #ccc !important;
          }
        }
      }

      .container-title-sold {
        padding: 7px 0 8px 0;
        float: left;
        width: 100%;
        text-align: left;

        .title-coming-soon {
          color: $text-color;
          font-size: 21px;
        }

        .box-switch {
          float: right;
        }
      }

      .container-sold-image {
        float: left;
        width: 100%;

        img {
          max-width: 100%;
        }
      }
    }

    .section-delete-inventory {
      .message-general-error {
        top: -2px !important;
        text-align: left;
        float: left;
        width: 100%;
      }

      .container-new-password {
        width: 100%;
        float: left;

        .message-error {
          top: 2px !important;
          text-align: left;
          float: left;
        }

        .label-select-status {
          font-size: 10px;
          color: #A4AFB7;
          font-weight: 500;
          text-align: left;
          padding: 8px 0 2px 11px;
        }

        .icon-padlock {
          position: absolute;
          width: 25px;
        }

        .icon-open-padlock {
          height: 18px;
          margin-top: 7px;
          fill: #A4AFB7;
        }

        .input-password, .input-confirm-password {
          width: 100%;
          border: 1px solid #ccc;
          border-radius: 4px;
          height: $height-box-input;
          padding: 4px 5px 5px 22px;
          text-align: left;
          background-color: white;
          outline: none;

          .label-select {
            font-size: 13px;
            color: $text-color;
            font-weight: 500;
          }

          &:focus {
            //border: 1px solid #ccc !important;
          }
        }
      }

      .container-btn-create {
        padding: 20px 0 0 0;
        float: right;
        text-align: right;

        .btn-create-password, .btn-change-password {
          background: #2194F1;
          color: white;
          float: left;
          border-radius: 5px;
          border: #D7DAE2 1px solid;
          font-size: 12px;
          height: 30px;
          width: 60px;
          margin-left: 5px;
        }

        .btn-change-password {
          width: 115px;
          white-space: nowrap;
        }
      }
    }

    .section-box{
      position: inherit;
    }
  }

  .popover-box-inventory-location {
    padding: 5px 0 5px 5px;
    height: 126px;
    border: 1px solid #D7DAE2;
    border-radius: 4px;

    .popover-body-status {
      .options-status {
        .content-check {
          div > div {
            label {
              color: $text-color !important;
              font-family: 'Open Sans', sans-serif !important;
            }
          }
        }
      }
    }
  }
}

.setting-container-user {
  @include setting-container;
  display: block;
}

.header-customer-info {
  width: 100%;
  height: 70px;
  align-items: center;
  float: left;
  border-bottom: 1px solid #EFEFEF;

  .customer-image {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    padding: 1px;
    box-shadow: 0 0 10px #666;
    object-fit: cover;
    cursor: pointer;
  }

  .img-customer {
    cursor: pointer;
    @include item-center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #2194F1;
    padding: 0;
    float: right;
    bottom: 0;
    position: relative;
    right: 14px;
    top: 25px;

    > div {
      @include item-center;

      svg {
        height: 10px;
        width: 10px;
        fill: white;
      }
    }
  }

  .customer-name {
    padding: 0 0 0 15px;
    margin: 0;
    font-weight: $font-weight-bold;
    font-size: 21px;
    color: #787878;
  }

  .container-customer-image {
    padding-left: 50px;
  }
}

.customer-info, .customer-finance-applications, .customer-cars {
  background: white;
  float: left;
  width: 100%;
  padding: 20px 50px 20px 50px;
  border-bottom: 1px solid #EFEFEF;
  display: block;

  .container-title {
    display: flex;
    align-items: center;
    width: 100%;

    .label-title-customer {
      padding: 0 0 0 15px;
      margin: 0;
      font-weight: $font-weight-bold;
      font-size: 18px;
      color: #787878;
      font-family: $font-family;
    }
  }

  .car-header {
    display: flex;
    align-items: center;
    padding: 0 15px 0 15px;
  }

  .body-customer-info {
    padding: 15px 0;

    label {
      color: #787878;
    }

    .flex-row {
      padding-top: 10px;

      .flex-colR1 {
        padding-right: 10px;
        width: 13%;
        flex: none;

        .item {
          .form-control {
            > div {
              display: initial !important;
              position: initial !important;
            }
          }

          .display-date {
            margin: 0 0 10px;
          }

          div {
            p {
              float: left
            }

            .label-error {
              color: red;
              padding-left: 2px;
            }
          }
        }
      }

      .flex-colR20 {
        padding-right: 10px;
        width: 20%;
        flex: none;
      }

      .container-icon-calendar {
        padding-top: 30px;

        .calendar-dob {
          > div {
            height: 30px;

            svg {
              width: 25px;
              height: 30px;
              fill: #A4AFB7;
            }
          }
        }
      }
    }

    .finance-input > div {
      width: auto !important;
      height: auto !important;

      > div hr {
        border: 0 !important;
      }
    }
  }

  .inputs-row-2 {
    width: 100%;
    display: flex;

    .flex-colR2 {
      padding-right: 10px;
      width: 26%;
      flex: none;
    }
  }

  .container-edit-button-save {
    padding-top: 30px;
    width: 48%;
    float: right;

    .btn-save {
      float: right;
      width: 75px;
      margin-right: 0;
      height: 34px;
      color: #ffffff;
      border-radius: 5px;
      border-color: transparent;
    }
  }

  input {
    color: #2194F1 !important;
  }
}

.customer-finance-applications {
  padding: 20px 0 20px 0 !important;

  .rowUser {
    cursor: pointer !important;
    height: 52px;
  }

  .container-title {
    padding-left: 50px;
  }

  .title-forms-list {
    .title-finance {
      padding-left: 100px;
      width: 30%;
    }

    .title-date {
      width: 20%;
    }

    .title-status {
      width: 20%;
    }

    .title-assigned {
      width: 30%;
    }
  }

  .body-finance {
    padding-left: 100px;
  }

  .container-message-info {
    padding: 0 50px;
  }

}

// remove when migrating to new DPMessage
.message-info {
  border-radius: 10px;
  width: 100%;
  border: 1px solid #ffe37e;
  display: flex;
  padding: 10px;

  label {
    margin-left: 10px;
    color: #787878;
    margin-bottom: 0;
    font-weight: $font-weight-bold;
  }

  .icon-info {
    width: 20px;
    height: 20px;
  }
}

.small-select {
  width: 100px;
}

.medium-select {
  width: 130px;
  margin-left: 10px;
}

.large-select {
  width: 160px;
  margin-left: 10px;
}

.small-select, .medium-select, .large-select {
  -webkit-box-shadow: 0 2px 5px 0 rgba(237, 237, 237, 1);
  -moz-box-shadow: 0 2px 5px 0 rgba(237, 237, 237, 1);
  box-shadow: 0 2px 5px 0 rgba(237, 237, 237, 1);
}

#id-options-attributes-sort {
  left: 0;
  top: 52px;
  width: 190px;
}

.btn-message-dp, .btn-inventory-dp, .btn-template-dp {
  float: left;
  color: #b3b3b3;
  background-color: #fff;
  border: 1px #dddee0 solid;
  border-radius: 3px;
  font-weight: 700;
  text-align: left;
  padding: 5px 3px;

  span {
    font-size: 12px;
    color: #6d6e72;
    font-weight: $font-weight-regular;
    margin-left: 5px;
  }

  i {
    color: #A4AFB7;
    float: right;
    padding-right: 2px;
    padding-top: 2px;
  }
}

.btn-message-task {
  float: left;
  color: #424355;
  background-color: #fff;
  border-color: #dddee0;
  border-radius: 5px;
  font-weight: 700;
  padding: 5px;
  width: 140px;

  span {
    font-size: 14px;
    color: #6d6e72;
    padding: 0 10px;
    font-weight: $font-weight-regular;
  }

  i {
    color: #a7aeb4;
  }
}

.pagination-wrapper {
  flex-grow: 1;
  width: 10%;

  .customer-pagination {
    float: right;
    padding-top: 12px;
  }
}

.location-filter, .title-container {
  padding: 20px 10px 17px 50px;
  width: 160px;
  position: relative;

  .title-add-location {
    margin-bottom: 0;
    color: #787878;
    font-size: 15px;
  }
}

.title-container {
  padding: 10px 0 0 25px;
  width: 100%;
  display: inline-flex;

  .left-container {
    float: left;
    width: 100%;

    .title-label-invoice {
      color: #787878;
      font-size: 1.9rem;
      font-weight: 600;
    }
  }

  .right-container {
    float: right;
    padding-right: 20px;
  }
}

.table-location-setting, .table-invoices-setting {
  width: 100%;
  padding: 13px 0;

  .table-locations, .table-invoices {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    color: #8F979D;

    .rowCostumer {
      td {
        padding: 9px 0 9px 50px;
      }

      .options {
        padding-top: 8px;
      }
    }

    .title-location-list, .title-invoice-list {
      background-color: #f5f6fa;
      color: #a6a8b4;
      font-weight: 900;
      font-size: 14px;

      th {
        padding: 15px 0 15px 50px;
      }
    }

    .title-invoice-list {
      th {
        padding: 15px 0 15px 25px;
        color: #787878;
      }
    }

    .title-date-invoice, .title-type-invoice, .title-invoice-number {
      padding-left: 25px;
      width: 200px;
    }

    .title-invoice-description {
      padding-left: 70px;
      width: 350px;
    }

    .title-balance-invoice, .title-amount-invoice, .title-payment-due-date {
      padding-left: 70px;
      width: 150px;
    }

    .title-paid-invoice-status {
      padding-left: 70px;
      width: 150px;
      color: #4AD991;
    }

    .title-unpaid-invoice-status {
      padding-left: 70px;
      width: 150px;
      color: $button-color-red;
    }

    .title-pending-invoice-status {
      padding-left: 70px;
      width: 150px;
      color: #F3A200;
    }

    .title-name-location {
      padding-left: 50px;
      width: 300px;
    }

    .title-address-location {
      padding-left: 70px;
      width: 350px;
    }

    .title-phone-location {
      padding-left: 70px;
      width: 200px;
    }

    .title-status-location {
      padding-left: 50px;
      width: 180px;

      .select-location {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #D7DAE2;
        border-radius: 4px;
        padding: 4px 5px 5px 8px;

        .icon-arrow-content {
          padding: 0 7px;

          .icon-arrow-expand {
            height: $icon-arrow-height-svg;
          }
        }

        label {
          margin-bottom: 0;
          font-weight: 500;
          font-size: $text-size-select;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .title-graph-location, .title-icon-invoice {
      padding-left: 70px;
      padding-top: 20px;
      padding-right: 80px;
      display: flex;
      justify-content: flex-end;

      .option-invoice {
        .icon-receipt, .download-icon {
          width: 16px;
          height: auto;
          fill: #A4AFB7;
        }

        .icon-receipt {
          width: 20px;
          margin-left: 5px;
          margin-right: 5px;
          &:hover {
            g path {
              fill: $color-button-all;
            }
          }
        }

        .download-icon {
          width: 20px;

          &:hover {
            fill: $light-blue-color-1;
          }
        }
      }
    }

    .title-icon-invoice {
      margin-left: auto;
      justify-content: flex-end;
    }

    tbody tr td, thead tr th {
      border-top: 0;
      padding: 10px 0;
    }

    thead tr th {
      font-weight: 600;
    }

    tbody tr td {
      font-weight: $font-weight-regular;
      font-size: 13px;
    }
  }

  .table-invoices {
    .rowCostumer {
      td {
        padding: 9px 20px 9px 26px;
        border-bottom: 1px solid #f6f6f6;
      }
    }
  }

  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    .loader {
      border: 7px solid #f3f3f3; /* Light grey */
      border-top: 7px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 64px;
      height: 64px;
      animation: adjust-hue 1s linear infinite;
    }

    @keyframes adjust-hue {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.table-invoices-setting {
  padding: 8px 0;
}

.modal-error-message {
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.location-status-popover {
  display: flex;
  flex-direction: column;
  position: relative;

  div {
    margin: 5px;
  }

  div:hover {
    cursor: pointer;
  }
}

.location-status-container {
  border: 1px solid #d7dae3;
}

.container-header {
  display: flex;
  background: white;
  position: fixed;
  @include shadow-header;
  height: $height-container-header;
  z-index: 5;
  left: 50px;
  right: 0;
  transition: all 0.3s ease;
  align-items: center;

  .left-container {
    display: flex;
    position: relative;
    float: left;
    flex: 1;
    align-items: center;
    height: $height-container-header;

    .arrow-right {
      margin-left: 10px;
      color: #787878;
      font-size: 20px;
      font-weight: 600;
      line-height: 15%;
    }

    .arrow-right-task {
      margin-left: 5px;
    }

    .arrow-back {
      cursor: pointer;
      margin-left: 30px;
      margin-right: 20px;

      > div {
        display: flex;
        align-items: center;
      }

      .icon-back {
        width: 13.5px;
        fill: #2194F1;

        .a, .c {
          fill: #2194F1;
        }
      }
    }

    .arrow-back-edit {
    }

    .title {
      float: left;
      font-size: 21px;
      color: #787878;
      font-weight: $font-weight-bold;
      width: 105px;
      margin-right: 0;
      margin-left: 38px;
    }

    .subtitle {
      float: left;
      font-size: 21px;
      color: #787878;
      font-weight: $font-weight-bold;
      margin: 0 12px 0 12px;
    }

    .data {
      font-size: 18px;
      color: #9b9b9b;
      margin: 0 12px 0 12px;
    }

    .action {
      svg {
        width: 44px;
        height: 47px;
        fill: #4ad991;

        g {
          cursor: pointer;
        }
      }
    }
  }

  .customer-import, .customer-export {
    margin: 0 0 0 10px;
  }

  .export-inventory {
    margin: 0 10px;
  }

  .right-container {
    display: flex;
    align-items: center;
    height: $height-container-header;
    justify-content: end;

    .option-edit {
      float: right;
      padding-right: 30px;

      .btn-default {
        background-color: transparent;
        border-color: transparent;
        padding: 7px;
        font-size: 11px;
      }

      .btn-cancel {
        background-color: transparent;
        border-color: transparent;
        margin-right: 5px;
        font-size: 14px;
        color: $button-color-red;
        float: left;

      }

      .btn-save {
        float: left;
        background-color: #BC1B1B;
        color: #ffffff;
        border-radius: 5px;
        border-color: transparent;
        height: 30px;
        width: 60px;
        min-width: 60px;
        font-family: $font-family;
        font-weight: $font-weight-regular;
      }
    }

    .mode-view {
      float: left;
      display: flex;

      @mixin header-menu-option() {
        margin-top: 9px;
        cursor: pointer;
        margin-left: 10px;
        .a {
          fill: #787878 !important;
        }
      }

      .header-menu-option {
        @include header-menu-option;
      }

      .boxes-view, .list-view {
        font-size: 18px;
        margin: 10px 0;

        .header-menu-option-active {
          @include header-menu-option;

          .a {
            fill: #2194f1 !important;
          }
        }
      }

      .btn-default {
        background-color: transparent;
        border-color: transparent;
        padding: 0;
        font-size: 18px;
      }

      #buttonOption {
        display: block;
        padding-right: 53px;
        margin: 10px 0;

        .header-menu-option-active {
          @include header-menu-option;

          .a {
            fill: #2194f1 !important;
          }
        }
      }
    }

    .right-container-no-edit {
      display: flex;
      align-items: center;

    }

    .quantity-cars {
      float: left;
      @include item-center;
      padding-right: 15px;
      color: #787878;

      .icon-eye-container {
        height: 18px;
        padding-right: 7px;
        fill: #787878 !important;

        .icon-eye-svg {
          height: 17px;
          fill: #787878 !important;;
        }
      }

      .text-eye-container {
        font-family: $font-family;
        font-weight: $font-weight-regular;
        font-size: 13px;
        color: #787878;
      }
    }
  }

  .icon-add-task {
    display: flex;
    width: 44px;
    height: 47px;

    > div {
      margin-top: 3px;
    }
  }

  .tab-form {
    height: 36px;
  }

  .container-filters {
    @include col100;
    padding: 1% 1% 1% 0;
    border-bottom: 1px solid #EFEFEF;
    border-top: 1px solid #EFEFEF;
  }
}

/****** Cost *****/

.body-cost, .sub-total-cost {
  float: left;
  width: 100%;
  padding: 15px 15px 15px 20px;

  .body-cost-header {
    height: 24px;
    padding-bottom: 0 !important;
    display: flex;

    .info-description {
      padding-bottom: 5px;
    }
  }

  .body-cost-fields {
    display: flex;
    padding-top: 0 !important;
    padding-bottom: 10px;
    padding-right: 15px;

    .info-icon {
      margin-top: 10px !important;
    }
  }

  .info-column-tit-desc {
    display: flex;
    flex-direction: column;
    flex: 1;

    .info-title, .info-description {
      display: flex;
      flex-direction: column;
      color: #787878;
    }

    .cost-input {
      border: 1px solid #D9D9D9;
      float: left;
      background-color: #fff;
      border-radius: 5px;
      width: 98%;
      padding: 10px;
      outline: none;
    }

    .cost-input:focus {
      border: 1px solid #7eafff;
    }
  }

  .info-column {
    display: flex;
    flex: 1;
    flex-direction: column;

    .info-status-type {
      padding-bottom: 25px;
    }

    .info-conf {
      display: flex;
      flex-wrap: wrap;
    }
  }

  h5 {
    padding-bottom: 12px;
    margin: 0;
    font-weight: bold;
    font-size: 21px;
    color: #787878;
    font-family: 'Open Sans', sans-serif;
  }

  .currencyinput {
    position: absolute;
    left: 0;
    margin: 11px 20px;
    color: #979797;
  }

  .cost-total {
    pointer-events: none;
  }

  .info-price {
    margin-right: 6px;
    width: 120px;
    position: relative;

    input[type="text"] {
      border: 1px solid #D9D9D9;
      float: left;
      background-color: #fff;
      border-radius: 5px;
      width: 95%;
      padding: 10px 6px 10px 30px;
      outline: none;
    }

    input:focus {
      border: 1px solid #7eafff;
    }
  }

  .info-icon {
    width: 24px;
    margin-top: 30px;
    margin-left: 10px;

    .icon-arrow-content {
      width: 26px;
      fill: #4AD991;
      height: auto;
    }

    .icon-task-content {
      fill: #4AD991;
      width: 24px;
      height: auto;
    }

    .icon-gray {
      fill: #D7DAE2 !important;
      width: 17.5px;
      height: auto;
    }

    .icon-task-content, .icon-has-task {
      fill: #D7DAE2 !important;
      width: 15.5px !important;
      margin-top: -0.3px;
    }

    .icon-has-task {
      fill: #2294f1 !important;
    }

    svg {
      fill: #D9D9D9;
      height: auto;
      cursor: pointer;
    }

    .icon-action-content-delete {
      width: 15.5px;
      margin-left: -2px;

      svg:hover {
        fill: #fc9a9c;
      }
    }
  }

  .title {
    color: #787878;
    font-weight: $font-weight-regular;
    font-size: 13px;
    padding-left: 10px;
  }

  .footer-cost {
    font-size: 13px;
    display: flex;
    float: left;
    width: 100%;
    flex-wrap: wrap;

    .add-cost {
      color: #2194F1;

      span {
        cursor: pointer;
      }

      padding-top: 8px;
      text-align: left;
      flex: 1 1;
    }

    .sub-total-cost {
      color: #787878;
      text-align: right;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      flex: 1 1;
      padding: 2px 2px 2px 348px !important;

      .label-sub-total {
        padding-top: 8px;
      }

      .sub-total {
        margin-left: 21px;
        outline: none;
        display: flex;
        float: left;

        input {
          background-color: #f5f6fa;
          border: 1px solid #d9d9d9;
        }
      }
    }
  }
}

@mixin button-style() {
  float: left;
  border-radius: 5px;
  border: #D7DAE2 1px solid;
  font-size: 13px;
  height: 30px;
  width: 60px;
  margin-left: 5px;
  font-family: $font-family;
  font-weight: $font-weight-regular;
}

@mixin task-bars() {
  float: right;
  font-size: 15px;
  width: 375px;
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 50px;

  .left {
    width: 60px;
    font-weight: $font-weight-regular;
    font-size: 13px;
  }
  .right {
    width: 265px;
  }

  .button-priority {
    @include button-style
  }
}

.container-filter-priority {
  @include item-center;
  height: 87px;
  width: 100%;
  background: white;
  z-index: 200;
  box-sizing: content-box;
  position: absolute;
  margin-top: 62px;

  .task-bars {
    @include task-bars
  }
}

.task-name {
  font-size: 21px;
  float: left;
  margin-left: 5px;
  color: #787878;
  width: 55px;
  font-weight: $font-weight-bold;
}


.task-section-header-vertical {
  position: relative;
  display: grid;
  left: 0 !important;
  width: 100% !important;

  .container-section-task {
    min-width: 100% !important;
  }
}


@mixin container-column() {
  width: 33.33%;
  float: left;
}

.container-column-todo {
  @include container-column;
  padding-right: 20px;
}

.container-column-inprogress {
  @include container-column;
  padding: 0 10px;
}

.container-column-done {
  @include container-column;
  padding-left: 20px;
}

.card {
  @include shadow-body;
  transition: 0.3s;
  display: flex;
  background: white;
  height: 40px;
  //border-radius: 10px;

  .icon-arrow-content {
    padding: 23px 0 0 20px;

    .icon-arrow-expand {
      height: 10px;
      fill: #979797;
    }
  }
}

.card-name {
  float: left;
  //padding: 15px 15px 15px 25px;
  width: 100%;
  color: #979797;
  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    font-weight: $font-weight-bold;
    font-family: $font-family;
    color: #787878;
    font-size: 18px;
  }
}

.card-task {
  float: right;
  width: 25%;
  color: white;
  display: flex;
  border-radius: 0 10px 10px 0;
  position: relative;
}

.card-inProgress {
  float: right;
  background: #2194f1;
  width: 45%;
  color: white;
  padding-left: 20px;
  display: flex;
  border-radius: 0 10px 10px 0;
  position: relative;
}

.card-done {
  float: right;
  background: #4ad991;
  width: 45%;
  color: white;
  padding-left: 20px;
  display: flex;
  border-radius: 0 10px 10px 0;
  position: relative;
}


.list-task-delete {
  display: none;
  padding-top: 10px;
  color: #e0afae;
  font-size: 20px;
  position: relative;
  z-index: 0;

  .options-archived-removed {
    display: inline-flex;
  }

  i {
    cursor: pointer;
  }

  .icon-inactive {
    fill: $button-color-red;
    height: 18px;
    cursor: pointer;
    width: 24px;
  }

  .icon-add {
    cursor: pointer;
    height: 18px;
    width: 20px;
    fill: #4ada90;
  }

  .btn-delete {
    padding-top: 1px;
    padding-left: 6px;
    fill: $button-color-red;
    height: 21px;
    width: 22px;
  }
}

.container-list-task:hover {
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);

  .list-task-assigned {
    display: none;
  }

  .list-task-delete {
    display: block;
  }
}

.list-task {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.task-bar {
  display: flex;
  border-left: 5px $button-color-red solid;
  height: 40px;
  float: left;
  border-radius: 0 7px 7px 0;
}

.task-dots {
  display: flex;
  width: 10px;
  border-left: 5px dotted #a8b2b8;
  height: 20px;
  float: left;
  margin-left: 10px;
}

.list-task-left {
  float: left;
  width: 100%;
  padding: 5px 8px;
  height: 100%;
  position: relative;
  font-size: 13px;
  font-weight: $font-weight-regular;
  font-family: $font-family;

  h6 {
    margin: 3px 0 0 0;
    font-weight: $font-weight-regular;
    position: absolute;
    bottom: 10px;
    font-family: $font-family;

    .priority-repair {
      width: 100%;
      float: left;
      padding-bottom: 5px;
    }

    .show-overdue {
      color: $button-color-red;
      font-weight: $font-weight-bold;
    }
  }

  h5 {
    margin: 3px 0 0 5px;
    font-weight: $font-weight-regular;
    font-size: 16px;
  }

  .task-separator {
    padding: 0 7px;
  }
}

.list-task-right {
  float: right;
  width: auto;
  text-align: right;
  padding-right: 10px;
  height: 100%;
  position: relative;

  h6 {
    margin: 0;
    font-weight: $font-weight-regular;
    white-space: nowrap;
  }
}

.btn-basic {
  padding: 6px 12px;
}

.btn-pagination {
  border: 1px #e5e6e9 solid;
  border-right: 1px transparent solid;
  color: #7c7b80;
  background-color: #fff;
}

.btn-p-first {
  border-radius: 4px 0 0 4px;
}

.btn-p-last {
  border-radius: 0 4px 4px 0;
  border-right: 1px #e5e6e9 solid;
}

.container-pagination {
  .btn-p-first.active.focus, .btn-p-first.active:focus, .btn-p-first.focus, .btn-p-first:active.focus, .btn-p-first:active:focus, .btn-p-first:focus,
  .btn-basic.active.focus, .btn-basic.active:focus, .btn-basic.focus, .btn-basic:active.focus, .btn-basic:active:focus, .btn-basic:focus {
    border: 1px #e5e6e9 solid !important;
    border-right: 1px transparent solid !important;
  }

  .btn-p-last.active.focus, .btn-p-last.active:focus, .btn-p-last.focus, .btn-p-last:active.focus, .btn-p-last:active:focus, .btn-p-last:focus {
    border: 1px #e5e6e9 solid !important;
    border-left: 1px transparent solid !important;
  }
}


.container-all-applicant {
  box-shadow: 0 2px 6px #00000026;
  border-radius: 10px;

  .div-container-sub {
    .container-header-view-app {
      height: 50px;
    }
  }
}

.btn-message-plus {
  background-color: #4ad991;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.btn-plus-container {
  float: left;
  @include item-center;

  .icon-add-task {
    width: 44px;
    height: 47px;

    > div {
      margin-top: 3px;
    }
  }

  svg {
    width: 44px;
    height: 47px;
    fill: #4ad991;

    g {
      cursor: pointer;
    }
  }
}

.message-pie {
  float: right;
  margin-top: 40px;
  left: -55px;
  position: relative;
}



#icon-message-dropdown {
  float: right;
  padding-top: 3px;
}

.row-icon-message {
  padding: 10px 0;
}

.row-table-finance, .row-icon-message {
  .container-check {
    margin-left: 82px;
  }
}

#row-message-count {
  height: 0;
  top: -21px;
  position: relative;
}

.layout-pane {
  overflow-x: hidden !important;
}

.container-label-message-detail {
  border-left: 2px #d4eaf9 solid;
}

#preview-message-talk {
  left: 30px;
  position: relative;
  display: inline-flex;
  top: 10px;
}

.splitter-layout {
  position: relative !important;
}

.finance-left {
  float: left;
  width: 100%;

  .header-finance-left {
    display: flex;
    position: relative;
  }
}

#option-reply-icons {
  top: 260px;
}

.btn-message-replay:hover, .btn-message-forward:hover {
  color: #0096f8 !important;
  background-color: white;
  border: 3px solid #0096f8 !important;
}


#view-app-eye:hover, #view-app-trash:hover {
  color: #0096f8 !important;
}

#view-app-eye {
  font-size: 18px !important;
  color: #dadada !important;
}

#header-left-finance {
  padding-top: 0;
}

.layout-splitter {
  background-color: #ffffff !important;
  width: 2px !important;
}

.container-check, .container-check-form {
  display: block;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 42px;;
  float: left;

  .i-check {
    position: absolute;
    font-size: 15px;
    color: #4695d4;
    left: -15px;
    top: 3px;
  }
}

.container-check-form {
  margin-left: 0
}

/* Hide the browser's default checkbox */
.container-check input, .container-check-form input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: -17px;
  z-index: 100;
  width: 20px;
  height: 20px;
  margin: 0;
}

.container-check-form input {
  width: 18px;
  height: 18px;
}

/* Create a custom checkbox */
.checkmark, .checkmark-form {
  position: absolute;
  top: 0;
  left: -18px;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1.4px #dde4f0 solid;
  border-radius: 5px;
}

.checkmark-form {
  height: 18px;
  width: 18px;
  top: 7px;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  color: #a8c1eb;
  background-color: #a8c1eb;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after, .container-check-form input:checked ~ .checkmark:after {
  display: block;
}

#data-head-from {
  width: 35%;
}



.dot-message {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 1px #9bcdb5 solid;
  float: left;
  margin-left: 30px;
  margin-top: 2px;
  position: relative;

  .i-check {
    font-size: 8px;
    color: #4AD991;
    position: absolute;
    left: 2px;
    top: 2px;
  }
}

.subject-title {
  font-weight: 700;
}

.subject-text {
  width: 50%;
  position: absolute;
  display: table-caption;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.subject-text2:before {
  content: '&nbsp;';
  visibility: hidden;
}

.subject-text2 {
  position: relative;

  span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.icon-message {
  float: left;
  padding-left: 30px;
  font-size: 14px;
}

.fa-clock:before {
  content: "\f017";
}

.btn-tab-message {
  position: absolute;
  background-color: #fff;
  left: 50px;
  border: 0;
  width: 70px;
  height: 50px;
  padding: 6px 0 6px 10px;
  box-shadow: 0 10px 15px -9px #dddee3, -5px 0 15px -4px #dddee3, 0 -5px 15px -9px #dddee3;
  color: #fff;
  text-align: left;
}

.last-message-conversation {
  display: flex;
}

#last-message-talk {
  height: inherit;
}

#last-message-pre-preview {
  height: 52px;
}

.dot-message-user-little {
  left: -7.01px;
  position: relative;
  border-radius: 16px 16px 16px 16px;
  border: 1px #D7DAE2 solid;
  background-color: #F5F6FA;
  width: 22.96px;
  height: 22.96px;
  float: left;
  box-shadow: 0 2px 3px #000000;
  text-align: center;
}

#dot-message-preview-talk {
  left: 15px;
}

#dot-message-pre-preview-talk {
  top: 14.26px;
  left: 22.31px;
}

#dot-greater-four {
  top: -22px;
  left: 22.31px;
}



.initialNames {
  padding-top: 1px;
  font-family: $font-family;
  line-height: 20px;
  color: #A4AFB7;
  height: 10.76px;
  width: 17.94px;
  font-size: 10px;
}

.label-message-repair {
  margin: 0;
  padding: 0 15px;
  color: #bdc2c5;
  font-weight: $font-weight-regular;
  font-family: $font-family;
  font-size: 13px;
}

#label-message-preview-talk {
  overflow: hidden;
  margin-left: 10px;
}

#label-message-pre-preview-talk {
  top: 8.22px;
  position: relative;
  overflow: hidden;
  color: #A4AFB7;
  left: 22px;
  height: 38px;
}

.label-message-repair-last {
  color: #4D4F5C;
  padding-left: 10.53px;
  padding-right: 26.62px;
  width: 100%;
  margin: 0;
  font-weight: $font-weight-regular;
  font-family: $font-family;
  font-size: 13px;
}

#label-message-last-talk {
  position: relative;
  overflow: hidden;
}

.label-message-repair-little {
  height: 75px;
  color: #4D4FB7;
  padding-left: 10.53px;
  padding-right: 26.62px;
  width: 365.59px;
  margin: 0;
  font-weight: $font-weight-light;
  font-family: $font-family;
  font-size: 13px;
}


.btn-message-selected {
  border: 1.5px #388ed5 solid;
  color: #388ed5;
  font-size: 15px;
  width: 24px;
  height: 24px;
  text-align: center;
  margin-left: 2px;
  border-radius: 5px;
  background-color: #fff;
  font-weight: $font-weight-bold;
}

.btn-container-message {
  position: absolute;
  left: 65px;
  padding-top: 9px;
}

.label-message-repair {
  margin: 0;
  padding: 0 15px;
  color: #bdc2c5;
  font-weight: $font-weight-light;
  font-family: $font-family;
  font-size: 13px;
}

.message-download {
  background-color: #edf0ff;
  border-radius: 5px;
  width: fit-content;
  margin-right: 10px;
  padding: 10px;
  display: grid;
  font-family: $font-family;

  i {
    padding: 0 5px;
    color: #4f7afd;
  }

  label {
    padding: 0 5px;
    margin: 0;
    color: #4f7afd;
    font-weight: 300;
    font-size: 13px;
  }

  span {
    float: left;
    padding-left: 26px;
    color: #dce2f6;
  }

  a {
    padding-left: 26px;
    font-weight: 300;
    text-decoration: underline;
    color: #8098d4;
  }
}

.message-content-new {
  border: 1px #f3f3f3 solid;
  border-radius: 10px;
  float: left;
  height: 225px;
  margin: 10px 10px 0 10px;
  width: 95%;

  .send-header {
    border-bottom: 1px #f3f3f3 solid;
    padding: 10px;
    color: #859ccd;
    float: left;
    width: 100%;

    label {
      font-weight: 300;
      float: left;
      margin: 0;
    }

    input {
      margin: 0 0 0 8px;
      font-weight: 300;
      border: none;
      resize: none;
      width: 70%;
    }

    input:focus {
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: none;
    }

    .message-email {
      background-color: #edf0ff;
      border: 1px #d9def3 solid;
      border-radius: 5px;
      float: left;
      margin: 0 5px 0 10px;
      padding: 3px 5px;

      span {
        font-size: 12px;
        float: left;
      }

      i {
        float: left;
        padding-left: 10px;
      }
    }
  }

  .send-body {
    color: #7b7b80;
    float: left;
    width: 100%;

    .DraftEditor-root {
      border: 1px solid transparent;
      width: 100%;

      .DraftEditor-editorContainer {
        width: 100%;
      }
    }
  }
}

.content-files, .content-files-new {
  display: inline-flex;
  padding: 10px 0 0 0;
}

.content-files-new {
  padding-left: 25px;
}

.footer-reply, .footer-new {
  width: 97%;
  margin: 10px 10px 0 10px;
  float: left;

  .options-reply, .contentDiv {
    display: inline-flex;
    padding-top: 14px;
    width: 80%;

    .drop-image {
      border-style: none;
      margin-left: 2px;
      width: 13px;
      height: 22px;
    }

    .message-display {
      display: inline-flex;
      margin: 5px 0 0 50px;
    }

    .btn-format-bold, .btn-format-italic, .btn-format-stk, .btn-underline, .btn-format-color, .btn-list-bullet, .btn-insert-link,
    .btn-image-photography, .btn-attach-file, .btn-align {
      margin: 8px 4px 0 0;
      width: 17px;
      cursor: pointer;

      div {
        pointer-events: none;

        svg {
          pointer-events: none;
        }
      }
    }

    .btn-align {
      width: 15px;
    }

    .btn-format-bold, .btn-format-italic {
      width: 11.5px;
    }

    .btn-format-italic {
      width: 12.5px;
    }

    .btn-underline {
      width: 12px;
    }

    .btn-attach-file {
      margin-top: 7px;
      width: 8px;
    }

    .btn-image-photography {
      width: 18px;
    }

    .font-family {
      border: 1px solid #efefef;
      border-radius: 6px;
      height: 31px;

      .select-font-family {
        cursor: pointer;
        font-family: $font-family;
        font-size: 13px;
        width: 100px;
        height: 29px;
        border-color: transparent;
      }

      .select-font-family:focus {
        outline: 0;
      }
    }
  }
}

.footer-new {
  padding: 0 16px 0 26px;

}

.btn-reply {
  padding-right: 10px;
  float: right;
  width: 11%;

  .btn-message-send {
    border-radius: 50%;
    background-color: #2493f1;
    color: white;
    width: 32px;
    height: 32px;
    border: 0;
    float: right;
    margin-top: 10px;

    div {
      pointer-events: none;

      div {
        pointer-events: none;

        svg {
          pointer-events: none;
        }
      }
    }
  }

  .disabled {
    background-color: #8F979D;
  }
}

.btn-message-send-icon {
  fill: #ffffff;
  width: 17px;
  height: 16px;
  margin-top: 5px;
  margin-left: 2px;
}

.message-content-location {
  float: right;
  padding-right: 40px;

  i {
    color: #f4f4f4;
    padding-right: 5px;
  }

  span {
    color: #f4f4f4;
  }
}

.container-list-task:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-number {
  float: left;
  width: 55%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  h1 {
    float: right;
    margin: 0;
    font-size: 28px;
    font-weight: $font-weight-regular;
    font-family: $font-family;
  }
}

.card-images {
  width: 65px;
  position: absolute;
  right: 0;

  img {
    width: 100%;
    height: 100%;
  }
}

.list-task-img {
  float: right;
  margin-left: 15px;
  margin-top: 9px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
  border: #fff 1px solid;
  width: 20px;
  height: 20px;

  img {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
}

.list-task-number {
  margin-top: 15px;
  float: right;
  position: absolute;
  bottom: 10px;
  right: 36px;
}

.task-second-body {
  float: left;
  width: 100%;
  padding: 20px 50px;

}

.container-pagination-header {
  float: right;
  margin-top: 20px;
  margin-right: 30px;
}

.container-pagination-footer {
  float: right;
  margin-right: 130px;
}

.task-second-container {
  float: left;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.task-second-container-left {
  width: 100%;
}

.tasks-border-left {
  border-left: 5px $button-color-red solid;
  float: left;
  margin-left: 30px;
}

.tasks-left-dots {
  width: 10px;
  border-left: 5px dotted #a7b0b6;
  height: 20px;
  float: left;
  margin: 5px 0 5px 8px;
}

.task-button {
  @include col30;
  display: flex;
  align-items: center;

  .container-task-button-add {
    float: left;
    padding-right: 10px;
    margin-left: 20px;

    .icon-add-task {
      display: flex;
      width: 44px;
      height: 47px;

      > div {
        margin-top: 3px;
      }

      svg {
        width: 44px;
        height: 47px;
        fill: #4ad991;

        g {
          cursor: pointer;
        }
      }
    }
  }

  .container-icon-user {
    float: left;
    padding: 15px 20px;
    height: 60px;
    position: relative;
  }

  .task-container-drop-down {
    width: 120px;
    margin-top: 10px;
    float: right;
  }
}

.btn-drop-down-task {
  @include item-center;
  border-radius: 5px;
  border: 1px solid #DADDE3;
  color: #6b6c78;
  background-color: #fff;
  height: 36px;
  position: relative;
  width: 120px;

  .btn-drop-down {
    width: 100%;
    padding: 0 10px;
  }

  .label-value {
    float: left;
    margin: 0;
  }

  .icon-arrow-content {
    float: right;
  }

  .icon-arrow-expand {
    width: 14px;
    fill: #A4AFB7;
  }

  .popover-pagination-box {
    @include col100;
    padding: 10px 10px 5px 10px;
    color: #979797;
    text-align: center;
    position: relative;

    .row-option {
      padding-bottom: 5px;
    }

    .row-option:hover {
      cursor: pointer;
    }
  }

  .btn-drop-down:hover {
    cursor: pointer;
  }

}


.task-button-add {
  background-color: #4ad991;
  border: none;
  color: white;
  text-decoration: none;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 10px;
  width: 36px;
  height: 36px;
  @include item-center;

  .icon-add-svg {
    height: 12px;
    width: 12px;
  }

  .icon-add-task {
    fill: #fff;
  }
}

.icon-user-task, .icon-user-task-arrow, .icon-user-task-popover {
  width: 30px;
  height: 30px;
  float: left;
  cursor: pointer;
  position: absolute;

  img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    box-shadow: 0 0 10px #A4AFB7;
  }

  img[title] {
    background-color: #2193f1;
    color: #fff;
  }
}

.icon-user-task-selected {
  border: 2px solid #0f5d84;
  border-radius: 50%;
  height: 38px;
  width: 38px;
  top: 11px;
  @include item-center;
}

.icon-user-task-arrow, .icon-user-task-popover {
  border-radius: 50%;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 10px #A4AFB7;
  justify-content: center;
  @include transition-all;
}

.icon-user-task-arrow {
  border: #2194F1 1px solid;

  label {
    margin-bottom: 0;
    color: #2194F1;
    cursor: pointer;
  }
}

.icon-user-task-popover {
  border: #787878 1px solid;

  label {
    margin-bottom: 0;
    color: #787878;
    cursor: pointer;
  }
}

.icon-next-user, .icon-back-user {
  padding-left: 7px;

  svg {
    &:hover {
      fill: white;
    }
  }

}

.icon-back-user {
  svg {
    transform: rotate(180deg);
  }
}

.icon-next-user-svg, .icon-back-user-svg {
  width: 14px;
  height: 20px;
  float: left;
  cursor: pointer;
  fill: #2194F1;
}

.task-bars {
  float: right;
  font-size: 15px;
  width: 375px;
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 50px;

  .left {
    width: 60px;
    font-weight: $font-weight-regular;
    font-size: 13px;
  }

  .right {
    width: 265px;

    #medium {
      width: 65px;
    }
  }

  .button-priority {
    @include button-style;
  }
}

.task-filter-type {
  @include item-center;
  width: 34%;
  float: left;
  height: 100%;
  display: flex;

  .button-filter {
    @include button-style;
  }

  .container-select-filter {
    display: inline-flex;
    margin: 4px;
    color: #787878;
    font-size: 13px;
    font-weight: $font-weight-regular;

    .label-select {
      padding: 5px 14px;
    }

    .select-filter {
      @include border-box;
      height: 30px;
      margin-right: 5px;
      min-width: 130px;

      .icon-arrow-content {
        float: right;
        padding: 1px 1px 1px 0;
      }
    }
  }
}

.rowTasks {
  display: table-row;
}

.rowTasks:hover {
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.2), 0 5px 5px -5px rgba(0, 0, 0, 0.2);

  .customerActions {
    display: table;
  }

  .task-status {
    display: none;
    font-size: 14px;
  }
}

.row-customer {
  width: 100%;
  padding-left: 115px;
  position: relative;
  height: 60px;
  z-index: 100;
}

.panel-customer {
  width: 100%;
  background-color: #fff;
  @include shadow-body;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.12);

  .message-not-found h3 {
    margin: 0;
    padding: 5px 20px;
  }

  .row-customer-container {
    width: 100%;
    position: relative;
    height: 60px;
    display: flex;

    .btn-container-dp-user {
      height: 100%;
      padding: 14px 0 14px 14px;

      .btn-message-dp {
        margin-left: 0;
      }
    }

    .customer-pagination {
      padding: 14px;

      .dp-pagination-container {
        float: left;

        button {
          height: 32px;
        }
      }
    }
  }
}

.customerRight {
  float: right;
}

.customer-left {
  float: left;
  margin-top: 0;
}

.rowCostumer {
  display: table-row;
  font-size: 13px;

  td {
    font-size: $text-size-select;
    color: $text-color-table;

    .options {
      display: none;

      i {
        cursor: pointer;
        margin: 0 12px 0 0;
      }
    }
  }

  td:hover .options {
    display: flex;
  }
}

.table-form-setting {
  .row-attribute-setting {
    td {
      padding: 14px 0;
    }

    .attribute-col-detail-icons {
      height: 50px;
    }
  }

  .row-form-setting {
    .title-name-forms-list, .title-code-forms-list {
      padding-left: 70px !important;
      font-size: 13px;
      color: $text-color;
    }

    .title-entity-forms-list, .title-created-by-forms-list, .title-created-by-forms-list, .title-date-forms-list {
      font-size: 13px;
      color: $text-color;
    }
  }

  .tr-sale:hover {
    cursor: pointer;
  }

  .container-message-info {
    padding: 10px 50px;
  }
}

.row-costumer-hover {
  box-shadow: 0 3px 18px 4px rgba(0, 0, 0, 0.2);

  .title-photo-users-list {
    position: relative;
    background: white;
  }

  .customerActions {
    display: table;

    span {
      cursor: pointer;
      margin-left: 6px;
    }
  }

  .Applicants_date {
    display: none;
  }

  td {
    .options {
      display: flex;
    }
  }
}

.rowUser {
  display: table-row;

  td {
    .options, .options-testimonial, .options-user {
      display: flex;
      right: 12px;

      i {
        cursor: pointer;
        margin: 0 12px 0 0;
      }

      svg {
        height: 18px;
        width: 18px;
        padding: 0 2px;
        cursor: pointer;
      }

      .icon-user-inactive {
        fill: $button-color-red;
        cursor: pointer;

        g {
          path {
            fill: $button-color-red;
          }
        }
      }

      #imgDelete {
        svg {
          height: 16px;
          width: 16px;
        }
      }

      .icon-edit {
        margin: 0 10px;
        fill: $light-blue-color-1;
        cursor: pointer;
      }

      .icon-delete {
        fill: $button-color-red;
        cursor: pointer;
        margin-left: 7px;
      }

      .icon-deactivate-user {
        width: 42px;
        height: 20px;
        cursor: pointer;
        margin-top: -1px;
      }
    }
  }

  td:hover .options {
    display: flex;
  }

  td:hover .options-testimonial {
    display: flex;
  }

  td:hover .options-user {
    display: flex;
  }

  .superuser-actions {
    display: none;
  }

  //refactor: remove this, currently needed in setting actions
  .superuser-actions, .td-status-superuser {
    .icon-right {
      display: flex;
      width: 30%;
    }

    .icon-left {
      float: left;
      width: 70%;
    }

    .icon-right, .icon-left {
      svg {
        height: 16px;
        width: 16px;
      }

      span {
        div {
          padding: 0 2px
        }
      }

      .icon-edit {
        fill: $light-blue-color-1;
      }

      .icon-cancel {
        fill: $button-color-red;
      }

      .icon-check {
        .a {
          fill: $green-color;
        }
      }

      .icon-delete {
        fill: $button-color-red;
      }

      .ic-inactive {
        fill: $button-color-red;
        height: 20px;
        width: 60px;

        .c, .d {
          fill: none;
        }
      }

      span {
        div {
          padding: 0 2px
        }
      }

      .icon-edit {
        fill: $light-blue-color-1;
      }

      .icon-cancel {
        .a {
          fill: $button-color-red;
        }
      }

      .icon-check {
        .a {
          fill: $green-color;
        }
      }

      .icon-delete {
        .a {
          fill: $button-color-red;
        }
      }

      .ic-inactive {
        .a {
          fill: $button-color-red !important;
        }

        height: 20px;
        width: 60px;
      }
    }
  }

  .td-status-superuser {
    padding: 16px;

    .icon-left {
      #imgInactive {
        div {
          height: 20px;
        }
      }
    }
  }

  .pill-box {
    margin: 0;
    position: relative;
  }

}

.customerActions {
  display: none;
}

.Applicants_date {
  float: right;
  margin-right: 30px;
}

#row-finance-date {
  color: #dadada;
}

#row-finance-view {
  color: #939393;
}

.attribute-col-detail-type {
  padding: 14px 70px !important;
}

.attribute-col-detail-icons {
  color: #f3f3f3;
  padding-left: 25px !important;
  float: left !important;
}


.rowCostumer:hover, .tr-customer:hover {
  cursor: pointer;
  box-shadow: 0 0 10px #E7E7E7;

  .title-photo-users-list {
    position: relative;
    background: white;
  }

  .customerActions {
    display: table;

    span {
      cursor: pointer;
      margin-left: 6px;
    }
  }

  .Applicants_date {
    display: none;
  }

  td {
    .options {
      display: flex;
    }
  }
}

.rowUser:hover {
  box-shadow: 0 0 10px #EFEFEF;
  cursor: pointer;
  height: 52px;

  .title-photo-users-list {
    background: white;
  }

  .customerActions {
    display: table;

    span {
      cursor: pointer;
      margin-left: 6px;
    }
  }

  .Applicants_date {
    display: none;
  }

  td {
    .options, .options-testimonial, .option-user {
      display: flex;
    }
  }

  .superuser-actions {
    display: table;
    float: right;

    .icon-edit, .icon-cancel, .icon-check, .icon-delete {
      cursor: pointer;
    }
  }

  .pill-box {
    display: flex;
  }
}

.user-modal-container, .rol-permission-modal {
  @include item-center;
  display: flex !important;

  .user-modal {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    height: 100%;
    flex-direction: column;
    z-index: 200;
    position: relative;

    .close-modal-icon {
      width: 32px;
      position: absolute;
      top: -15px;
      right: -13px;

      &:hover {
        cursor: pointer;
      }
    }

    .user-modal-body {
      display: inline-flex;
      width: 100%;
      height: 100%;
      float: left;

      .seccion-1 {
        padding: 25px;
        width: 45%;
        border-right: 1px solid #c1c3c5;

        .user-modal-image {
          .photo-user-img-circle {
            height: 88px;
            object-fit: cover;
          }

          @include item-center;

          img {
            border: 1px solid #FFFFFF;
            border-radius: 100%;
            box-shadow: 0 0 14px 0 #A4AFB7;
            width: 88px;;
          }
        }

        .user-modal-text {
          display: block;
          margin-top: 10px;
          text-align: center;

          .user-name {
            margin: 0 40px 0 40px;
            border-bottom: .4px solid #a4afb7e0;
            padding-bottom: 8px;

            .pill-box {
              margin: 0 auto 10px !important;
              width: max-content;
              float: none;
              top: 5px;
              position: relative;

              label {
                padding-bottom: 0;
              }
            }
          }

          .user-detail {
            margin-top: 15px;

            h6 {
              color: $text-color;
              font-size: 13px;
              font-weight: 300;
              line-height: 18px;
            }
          }

          .title-main {
            line-height: 14px;
            color: #787878;
            font-weight: 900;
            font-size: 13px;
          }

          .title-secondary {
            color: #4AD991;
            font-size: 12px;
            font-weight: lighter;
          }
        }

        .user-modal-button {

          @include item-center;

          a {
            width: 80px;
            height: 30px;
            color: white;
            border: 1px solid #D7DAE2;
            border-radius: 4px;
            font-size: 12px;
            background-color: #2194F1;
            @include item-center;
          }
        }
      }

      .seccion-2 {
        padding: 25px;
        width: 55%;

        .user-options {
          a {
            line-height: 10px;
            color: #787878;
            font-weight: 700;
            font-size: 13px;
            padding-bottom: 10px;
            margin-right: 20px;
          }

          a.selected {
            color: #2194F1;
            border-bottom: 1.5px solid #2194F1;
            margin-right: 20px;
            font-weight: 700;
          }

          a:hover, a:focus {
            text-decoration: none;
          }
        }

        .user-description {
          ul {
            padding-left: 0;
            list-style: none;
            margin-top: 25px;
          }

          b {
            font-size: 11px;
            font-weight: lighter;
            color: #2194F1;
          }

          label {
            margin-left: 5px;
            font-size: 9px;
            font-weight: 400;
            color: $text-color;
          }

          em {
            font-style: initial;
            text-decoration: underline;
          }
        }

        .user-permission {
          margin-top: 15px;

          .option {
            .header-section {
              width: 100%;
              float: left;
              height: 19px;
              display: flex;
              align-items: center;
              margin-bottom: 10px;

              .circle-class {
                div {
                  display: flex;
                }
              }
            }
          }

          ul {
            padding-left: 0;
            list-style: none;
          }

          .btn-expand {
            background-color: #2194F1;
            border: none;
            color: white;
            text-align: center;
            text-decoration: none;
            border-radius: 50%;
            width: 22px;
            height: 22px;
          }

          .title-main {
            line-height: 10px;
            color: #787878;
            font-weight: $font-weight-regular;
            font-size: 13px;
            float: left;
            height: 19px;
            padding-left: 10px;
            display: flex;
            align-items: center;
            padding-bottom: 5px;
          }

          svg {
            width: 19px;
            height: 19px;
            background: #FFF;

            path {
              fill: $light-blue-color-1;
            }
          }

          label {
            color: $text-color;
            font-size: 13px;
            font-weight: $font-weight-regular;
            line-height: 18px;
            margin-left: 30px;
          }
        }
      }

      .top-bottom {
        padding-top: 30px !important;
      }

      .seccion-1-edit {
        padding: 17px;
        width: 33%;

        .title-new-user {
          color: $text-color;
          font-size: 15px;
          padding-bottom: 15px;
          font-weight: 700;
        }

        .container-dropzone-image {
          width: 100%;
          height: 96px;
          @include item-center;

          #dropzone-modal, .dropzone-container-user {
            width: 96px !important;
            height: 96px !important;
            border-style: none !important;
            border-radius: 50%;
            border-width: 0;

            .user-modal-image {
              height: 100%;
              width: 100%;
              @include item-center;

              .new-user-photo-container-modal {
                left: 0;
                position: relative;
                height: 100%;
                width: 100%;
              }

              .container-image-user {
                height: 100%;
                width: 100%;

                .img-customer {
                  cursor: pointer;
                  @include item-center;
                  height: 28px;
                  width: 28px;
                  border-radius: 50%;
                  background: #2194F1;
                  padding: 0;
                  float: right;
                  position: relative;
                  z-index: 64;
                  top: -28px;

                  div {
                    @include item-center;

                    svg {
                      height: 13px;
                      width: 13px;
                      fill: white;
                      margin: 0;
                    }
                  }
                }
              }

              .photo-user-img-circle {
                width: 100%;
                height: 100%;

                .img-customer {
                  cursor: pointer;
                  @include item-center;
                  height: 28px;
                  width: 28px;
                  border-radius: 50%;
                  background: #2194F1;
                  padding: 0;
                  float: right;
                  position: relative;
                  z-index: 64;
                  top: -28px;

                  .div {
                    @include item-center;

                    svg {
                      height: 13px;
                      width: 13px;
                      fill: white;
                    }
                  }
                }
              }
            }
          }
        }

        .user-modal-image {
          div {
            width: 100%;
            height: 100%;

          }

          .photo-user-img-circle {
            height: 88px;
            object-fit: cover;

            &:hover {
              cursor: pointer;
            }
          }

          @include item-center;

          img {
            border: 1px solid #FFFFFF;
            border-radius: 100%;
            box-shadow: 0 0 14px 0 #A4AFB7;
            width: 88px;;
          }

          .load-image {
            background: #2194F1;
            height: 25px;
            width: 25px;
            border-radius: 100%;
            border: 1px solid #FFFFFF;
            position: absolute;
            margin-left: 27px;
            margin-top: 35px;
            box-shadow: 0 0 14px 0 #A4AFB7;
          }

          svg {
            fill: white;
            width: 10px;
            margin-top: 6px;
            margin-left: 6px;
          }
        }

        .user-modal-text {
          display: block;
          margin-top: 20px;

          .user-name {
            margin: 0;
            width: 100%;
            padding: 10px 6% 0;
            border-top: .5px solid #D7DAE2;

            .pill-box {
              float: none;
              margin: 10px auto 10px !important;
              width: max-content;
              padding: 0 8px;
            }
          }

          .user-detail {
            margin-top: 5px;
            padding: 0 6%;

            label {
              font-size: 9px;
              font-weight: lighter;
              color: #A4AFB7;
              margin-bottom: 8px;
            }

            .btn-form-sort {
              width: 82%;
              height: 31px;
              padding-top: 8px;
            }

            .container-roles span {
              font-size: 9px;
              color: #787878;
              font-weight: 300;
            }
          }

          .title-main {
            line-height: 10px;
            color: #787878;
            font-weight: $font-weight-regular;
            font-size: 13px;
          }

          .title-secondary {
            color: #4AD991;
            font-size: 12px;
            font-weight: lighter;
          }
        }

        .user-modal-button {
          display: block;
          margin-top: 130px;
          margin-left: 13%;

          a {
            width: 80px;
            height: 30px;
            color: white;
            border: 1px solid #D7DAE2;
            border-radius: 4px;
            font-size: 12px;
            background-color: #2194F1;
            @include item-center;
          }
        }
      }

      .seccion-2-edit {
        padding: 20px 23px 25px 12px;
        width: 68%;

        .user-inputs {
          float: left;
          width: 100%;
        }

        .carInfoLabel label {
          color: #787878;
          padding-left: 0;
          font-size: 9px;
        }

        .classInputCarInfo {
          width: 100%;
          float: left;
          padding: 0 20px 0 0;
          position: relative;
        }

        .classInputCarInfo input {
          border-radius: 4px;
          height: 38px;
          border-color: #EFEFEF;
          box-shadow: none;
          color: #787878 !important;
        }

        .form-control {
          font-size: 13px;
        }

        .user-inputs {
          width: 100%;
          float: left;
        }

        .user-modal-button, .user-modal-button-save {
          margin: 20px 0 20px 0;

          a {
            width: 80px;
            height: 30px;
            color: white;
            border: 1px solid #D7DAE2;
            border-radius: 4px;
            font-size: 12px;
            background-color: #4AD991;
            @include item-center;
          }
        }

        .user-modal-button-save {
          float: right;
          margin-right: 20px;
        }

      }

      .user-modal-button {
        margin: 20px 0 20px 0;

        a {
          width: 155px;
          height: 38px;
          color: white;
          border: 1px solid #D7DAE2;
          border-radius: 7px;
          font-size: 14px;
          background-color: #2194F1;
          @include item-center;
        }
      }
    }
  }

  .modal-body {
    padding: 0;
    height: 100%;
  }

  .modal-dialog {
    width: 45vw !important;
    height: auto !important;
  }

  .modal-content {
    box-shadow: 0 2px 10px #00000027 !important;
    border: none;
    border-radius: 15px;
  }

  .user-modal-pie {
    float: left;
    width: 100%;
    display: inline-flex;

    .user-modal-button {
      margin: 0;
      padding: 20px;
      width: 100%;

      a {
        width: 80px;
        height: 30px;
        color: white;
        border: 1px solid #D7DAE2;
        border-radius: 4px;
        font-size: 12px;
        background-color: #4AD991;
        @include item-center;

        &:hover {
          text-decoration: none;
        }
      }

      .btn-save {
        float: right;
        border: none;
      }

      .btn-back {
        float: left;
        background-color: #2194F1;
        border: none;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .loader-container {
    position: absolute;
    z-index: 300;
  }
}

.rol-permission-modal {
  .modal-dialog {
    width: 60vw !important;
  }
}

.user-info-social-icon {
  display: flex;
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  font-size: 19px;
}

.photo-user-without-img-circle {
  border: 1px solid #FFFFFF;
  border-radius: 100%;
  box-shadow: 0 0 14px 0 #A4AFB7;
  width: 88px;
  height: 88px;
}

#user-modal-button-back {
  float: left;
  width: 50%;

  a {
    background-color: #e60000;
  }
}

.customer-button {
  padding: 10px 0 10px 20px;

  .icon-add-task {
    display: flex;
    width: 40px;
    height: 40px;

    > div {
      margin-top: 3px;

      svg {
        width: 40px;
        height: 40px;
        fill: #4ad991;

        g {
          cursor: pointer;
        }
      }
    }
  }
}

.customer-button-add {
  background-color: #4ad991;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 25px;
  border-radius: 50%;
  margin-left: 20px;
  margin-top: 10px;
  width: 35px;
  height: 35px;
}

.customer-title {
  font-size: 21px;
  float: left;
  background-color: #fff;
  border-color: #dddee0;
  border-radius: 5px;
  font-weight: $font-weight-bold;
  color: #787878;
  flex: 1;
  @include item-center;
  padding-left: 50px;
  justify-content: flex-start;
  width: 155px;
  padding-right: 21px;

  span {
    font-size: 14px;
    color: #6d6e72;
    padding: 0 10px;
    font-weight: $font-weight-regular;
  }
}

@mixin container-right-buttons() {
  padding-right: 52px;
  display: flex;
  position: absolute;
  right: 0;
}

.container-right-buttons {
  @include container-right-buttons;
}

@mixin buttons-next-done() {
  width: 70px;
  height: 32px;
  border-radius: 5px;
  color: #FFF;
}

.buttons-next-done {
  @include buttons-next-done;
}

.container-button-next {
  @include container-right-buttons;

  .container-label-reject, .container-message {
    padding-right: 20px;
    display: flex;
    align-items: center;

    label {
      font-weight: 500;
      margin: 0;

      &:hover {
        cursor: pointer;
      }
    }

    .label-show-modal {
      color: $button-color-red;
      text-decoration: underline;
      font-size: 10px;
    }

    .btn-cancel-sale {
      color: #787878;
      background-color: #ffffff;
      border: none;
    }
  }

  .box-button {
    padding-right: 5px;

    .btn-next {
      @include buttons-next-done;
      border: 1px solid $light-blue-color-1;
      background-color: $light-blue-color-1;

      &:focus {
        border: 1px solid $light-blue-color-1 !important;
      }
    }

    .btn-done {
      @include buttons-next-done;
      width: 114px;
      border: 1px solid $green-color;
      background-color: $green-color;

      &:focus {
        border: 1px solid $green-color !important;
      }
    }
  }
}

.customer-button-add {
  background-color: #4ad991;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 25px;
  border-radius: 50%;
  margin-left: 20px;
  margin-top: 10px;
  width: 35px;
  height: 35px;
}

.format-setting-button-add {
  background-color: #4ad991;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 21px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: relative;

  span {
    position: absolute;
    top: -2px;
    left: 11px;
  }

  .icon-next {
    > div {
      @include item-center;

      svg {
        width: 18px;
        fill: #FFF;
      }
    }
  }
}

.close-modal {
  background-color: #ff6564;
}

.format-setting-button-confirm {
  background-color: #4ad991;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  border-radius: 50%;
  margin-left: 18px;
  margin-top: 10px;
  width: 35px;
  height: 35px;
}

.format-setting-button-cancel {
  background-color: $button-color-red;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  border-radius: 50%;
  margin-left: 18px;
  margin-top: 10px;
  width: 35px;
  height: 35px;
}

.format-setting-button-save {
  background-color: #2194F1;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  border-radius: 50%;
  margin-left: 18px;
  margin-top: 10px;
  width: 35px;
  height: 35px;
}

.container-customer-check {
  display: inline-block;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 70px;

  .i-check {
    position: absolute;
    font-size: 15px;
    color: #4695d4;
    top: 3px;
    left: -15px;
  }
}

.container-customer-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  z-index: 100;
  width: 20px;
  height: 20px;
  margin: 0;
}

.check-task {
  top: 4px;

  i {
    top: 5px;
  }
}


.popover-20::after, .popover-20:before {
  left: 85% !important;
}

.popover-20 {
  margin-left: -68vw;
}

.popover-r80::after, .popover-r80:before {
  left: 70% !important;
}

.popover-r80 {
  margin-left: -93px;
}

.popover-r25::after, .popover-r25:before {
  left: 52% !important;
}

.popover-r25 {
  margin-left: -6vw;
}

.popover-16-1::after, .popover-16-1:before {
  left: 55% !important;
}

.popover-16-1 {
  margin-left: -14vw;
}

.popover-16-2::after, .popover-16-2:before {
  left: 75% !important;
}

.popover-16-2 {
  margin-left: -14vw;
}

.popover-16-3::after, .popover-16-3:before {
  left: 88% !important;
}

.popover-16-3 {
  margin-left: -70vw;
}

.popover-basic {
  position: absolute;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 10px #ccc;
}

.menu-popover-attribute {
  margin-left: -30%;
  margin-top: -180px;
  position: absolute;
  width: 60%;
  height: 360px;
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 10px #ccc;
  z-index: 1;
}

.popover-basic::after, .popover-basic:before {
  width: 0;
  height: 0;
  content: "";
  display: block;
  position: absolute;
  bottom: 100%;
  left: 72px;
}

.popover-basic::after {
  border-bottom: 15px solid #fff;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.popover-basic::before {
  border-bottom: 15px solid #000;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.popover-task {
  width: 40%;
  left: 50px;
  top: 80px;
  height: auto;
  z-index: 200;
  padding: 25px;
}

.popover-edit-basic {
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 10px #ccc;
}

.popover-edit-basic::after, .popover-edit-basic::before {
  width: 0;
  height: 0;
  content: "";
  display: block;
  position: absolute;
  bottom: 50%;
  left: -15px;
}

.popover-edit-form-basic {
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 10px #ccc;
  width: 150px;
  margin-left: 304px;
  margin-top: 31px;
  z-index: 1;
}

.popover-edit-form-basic::after, .popover-edit-form-basic::before {
  width: 0;
  height: 0;
  content: "";
  display: block;
  position: absolute;
  bottom: 100%;
  left: 60px;
}

.popover-edit-form-basic::after {
  border-bottom: 13px solid #fff;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}

.popover-edit-form-basic::before {
  border-bottom: 12px solid #000;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}

.popover-edit-task {
  width: 100%;
  left: 106%;
  top: -230px;
  padding: 20px 20px 20px 20px;
  z-index: 100;
}

.popover-edit-task-Done {
  width: 100%;
  left: -106%;
  top: -230px;
  padding: 20px 20px 20px 20px;
  z-index: 100;
}

.popover-edit-basic-Done {
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px #ccc;
}

.popover-edit-basic-Done::after {
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 15px solid #fff;
}

.popover-edit-basic-Done::after, .popover-edit-basic-Done::before {
  width: 0;
  height: 0;
  content: "";
  display: block;
  position: absolute;
  bottom: 50%;
  right: -15px;
}

.btn-container-right-botton-message {
  border: 1px solid #abaeb9;
}

.btn-container-right-botton {
  display: flex;
  float: left;
}

.btn-container-right-line {
  width: 100%;
  border-bottom: 2px #2493f1 solid;
  margin-top: 10px;
  margin-bottom: 10px;
  float: left;
}

.EditTaskHeader {
  display: flex;
  height: 30px;
  margin-bottom: 10px;
}

.EditTasksTitle {
  width: 50%;
  text-align: left;
  border-bottom: 3px #2493f1 solid;
}

.EditTasksIconMessage {
  width: 50%;
  text-align: right;
  border-bottom: 3px #2493f1 solid;
  display: flex;
}

.btn-container-right-botton-message {
  float: left;
  width: 250px;
  border-radius: 35px;
  margin-top: 15px;
}

.EditTaskBodyRight {
  height: 550px;
  padding: 10px;
}

.btn-container-EditPay {
  border: 1px solid #7eafff;
  float: left;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
}

.btn-container-EditLorem {
  margin-top: 10px;
  float: left;
  border: 1px solid #abaeb9;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}

.btn-container-type {
  float: left;
}

.btn-container-assigned {
  float: left;
}

.EditTaskTypeAssigned {
  display: flex;
}

.EditTaskType {
  width: 40%;
}

.EditTaskAssigned {
  width: 50%;
}

.container-Due {
  margin-top: 10px;
  height: auto;
  float: left;
  width: 100%;
}

.edit-date-selected {
  float: left;
  width: 20%;
  border: 1px solid #d7dae2;
  border-radius: 5px;
  padding: 5px;
  margin-right: 10px;
}

.edit-date-image {
  float: left;
  width: 15%;
}

.Edit-check {
  float: left;
  display: flex;
}

.container-edit-date {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.container-edit-repeat {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.edit-repeat {
  width: 25%;
}

.edit-repeat-number {
  width: 10%;
  border: 1px solid #d7dae2;
  border-radius: 5px;
  padding: 5px;
  margin-right: 10px;
}

.edit-eds {
  margin-top: 10px;
}

.edit-ocurrences {
  display: flex;
  margin-top: 10px;
}

.edit-ocurrences-period {
  width: 50%;
}

.edit-form-check {
  display: flex;
  margin: 0.5rem;

  input[type="radio"] {
    position: absolute;
    opacity: 0;

    + .radio-label {
      &:before {
        content: '';
        background: #f4f4f4;
        border-radius: 100%;
        border: 1px solid darken(#f4f4f4, 25%);
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        top: -0.2em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }

    &:checked {
      + .radio-label {
        &:before {
          background-color: #3197EE;
          box-shadow: inset 0 0 0 4px #f4f4f4;
        }
      }
    }

    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: #3197EE;
        }
      }
    }

    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px #f4f4f4;
          border-color: darken(#f4f4f4, 25%);
          background: darken(#f4f4f4, 25%);
        }
      }
    }

    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.edit-button-cancel {
  float: left;

  .btn-cancel {
    background-color: $button-color-red;
  }
}

.edit-button-accept {
  float: right;
}

.edit-ocurrences-date-month {
  display: flex;
}

.edit-date-month-day {
  float: left;
  border: 1px solid #d7dae2;
  border-radius: 5px;
  padding: 5px;
}

.edit-date-month-number {
  float: left;
  border: 1px solid #d7dae2;
  border-radius: 5px;
  padding: 5px;
  margin-right: 10px;
}

.edit-date-month {
  display: flex;
  margin-top: 10px;
}

.user-table {
  min-height: 720px;

  .thead-setting-user {
    background-color: #EFEFEF;
  }

  .tbody-user {
    .rowUser {
      .td-actions-user {
        color: #f3f3f3;
        width: 9%;
        padding-right: 15px;
      }
    }
  }
}

.table-form-setting, .table-repairs {
  width: 100%;
  padding: 0;

  thead {
    tr {
      background-color: #f5f6fa;
      color: #a6a8b4;
      font-weight: 900;
      font-size: 14px;

      th {
        border-bottom: 0;
        padding: 15px 0;
      }
    }
  }

  tbody {
    tr {
      color: #b8bdc2;
      font-size: 14px;
      font-weight: $font-weight-regular;

      td {
        border-top: 0;
        padding: 10px 0;
      }
    }
  }

  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    .loader {
      border: 7px solid #f3f3f3; /* Light grey */
      border-top: 7px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 64px;
      height: 64px;
      animation: adjust-hue 1s linear infinite;
    }

    @keyframes adjust-hue {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.table-form-setting {
  padding: 10px 0;
}

.table-repairs {
  padding: 0;
}

.table-cars {
  th {
    text-align: left;
    color: #787878 !important;
    font-weight: 600 !important;
  }

  thead > tr > :nth-child(1) {
    width: 3.5%;
  }

  tbody > tr > td {
    color: "#4D4F5C";
  }

  .th-container-setting {
    text-align: -webkit-right;
    padding-right: 20px;

    .icon-settings {
      float: right;
    }
  }

  .td-options {
    min-width: 132px;
    text-align: -webkit-center;
    padding-right: 40px;
    float: right;
  }

  .options {
    padding-left: 0 !important;
    padding-top: 0 !important;
    margin-left: 0;

    .icon-copy, .icon-edit, .icon-delete {
      padding: 0 5px;

      div {
        @include item-center;

        svg {
          width: auto;
          height: 100%;
        }
      }

      .icon-copy-svg {
        fill: $light-blue-color-2;
      }

      .icon-edit-svg {
        fill: $green-color
      }

      .icon-delete-svg {
        fill: $button-color-red;
      }

      .icon-copy-svg, .icon-delete-svg, .icon-edit-svg {
        width: $icon-options-width;
        height: $icon-options-height;
      }
    }
  }

  .icon-settings {
    width: 19px;
    fill: #787878;

    &:hover {
      cursor: pointer;
      fill: #777777;
    }
  }

  .icon-fields {
    width: 9px;
    height: 9px;
    fill: #D7DAE2;
    margin: 0 auto;
  }

  .icon-check {
    fill: #C3DAFF !important;
  }

  .filters-table {
    td {
      padding: 5px 20px !important;
    }

    .icon-arrow-expand-filters {
      float: left;
      width: 13px;
      margin-top: 6px;

      path {
        fill: white;
      }
    }

    h4 {
      color: white;
      font-size: 13px;
      padding-left: 28px;
      font-weight: 600;
    }

    .container-number-value {
      display: flex;
      align-items: center;
      padding: 0;
      height: 24px;

      span {
        font-size: 15px;
        color: white;
        padding-right: 10px;
        font-weight: 700;
      }

      h4 {
        padding: 0;
        margin: 0;
        font-size: 15px;
      }
    }


  }

  .popover-table {
    border: 1px solid #D7DAE2;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    left: unset !important;
    right: 20px;
  }

  .popover-body-locations {
    padding: 11px 0 !important;
  }

  .table {
    tr {
      border-bottom: 0;
    }
  }
}

.table-data {
  float: left;
  width: 100%;
  padding: 0;
  background: white;

  table {
    thead {
      tr {
        background-color: #f5f6fa;
        color: #a6a8b4;
        font-weight: 900;
        font-size: 16px;

        th {
          border-bottom: 0;
          padding: 15px 0;
        }
      }
    }

    tbody {
      tr {
        color: #b8bdc2;
        font-size: 16px;
        font-weight: $font-weight-regular;

        td {
          border-top: 0;
          padding: 20px 0;

          .border-left-div {
            border-left: 5px solid rgb(252, 102, 101);
            float: left;
            height: 30px;
            margin-left: 30px;
            margin-top: 4px;
          }

          .options {
            display: none;
            padding-left: 28px;
            padding-top: 6px;
            width: 50%;

            i {
              cursor: pointer;
              margin: 0 12px 0 0;
            }
          }
        }
      }

      tr:hover {
        box-shadow: 0 0 10px #E7E7E7;
      }

      tr:hover {
        td {
          .options {
            display: flex;
          }
        }
      }
    }
  }
}

.row-repair {
  display: table-row;
}

.repair-actions {
  display: none;
}

.row-repair:hover {
  box-shadow: 0 2px 14px 2px rgba(0, 0, 0, 0.2);

  .repair-actions {
    span {
      margin-right: 8px;

      i {
        cursor: pointer;
      }
    }

    display: table;
  }

  .repair-status {
    display: none;
  }
}

.setting-body {
  float: left;
  width: 100%;
  padding: 20px 50px 20px 150px;
  position: relative;

  .btn-tab {
    width: 100px;
    padding-left: 20px;
    cursor: pointer;

    span {
      margin-top: 10px;
      float: left;
      padding: 4px 8px;
    }
  }

  a:hover {
    text-decoration: none;
    color: #fff;
  }
}

.btn-tab-inventory {
  top: 80px;
}

.btn-tab-customer {
  top: 140px;
}

.btn-tab-task {
  top: 200px;
}

.btn-tab-user {
  top: 260px;
}

.btn-tab-form {
  top: 320px;
}

.setting-general-left {
  padding: 30px;
  float: left;
  width: 30%;
}

.setting-form-left {
  padding: 5px;
  float: left;
  width: 30%;
  margin-left: 35px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a5afb8;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border: 2px #2193f1 solid;
}

input:checked + .slider {
  background-color: #2193f1;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2193f1;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 6px;
}

.slider.round:before {
  border-radius: 6px;
}

.slider.round.off:before {
  border: 2px #a5afb8 solid;
}

.check-label-on {
  color: #fff;
  position: absolute;
  top: 6px;
  left: 5px;
  font-size: 10px;
  font-weight: $font-weight-regular;
  cursor: pointer;
}

.check-label-off {
  color: #fff;
  position: absolute;
  top: 6px;
  right: 5px;
  font-size: 10px;
  font-weight: $font-weight-regular;
  cursor: pointer;
}

.row-setting {
  float: left;
  width: 100%;
  padding-top: 15px;
}

.row-form {
  float: left;
  width: 100%;
  font-size: 15px;

  label {
    float: left;
    color: rgb(189, 194, 199);
  }

  input:disabled {
    background: white;
  }

  .showNotify {
    opacity: 1;
    height: 30px;
    color: #35c8c3;
    border: none;
    float: right;
  }
}

.col-setting-label {
  float: left;
  width: 70%;
  padding-left: 30px;
  font-size: 18px;

  label {
    float: left;
    color: #787878;
    font-weight: $font-weight-regular;
  }
}

.col-setting-option {
  float: left;
  width: 30%;
}

.setting-label-aspect {
  padding-left: 30px;

  label {
    color: #bcc0c5;
    font-weight: 300;
  }
}

.btn-setting-op {
  padding: 5px 0 10px 30px;
}

.setting-general-right {
  padding: 30px;
  float: left;
  width: 70%;
  border-left: 1px #eff0f3 solid;

  .setting-general-dealer {
    padding-bottom: 1px;

    label {
      color: #bcc0c5;
      font-weight: 300;
    }
  }

  .setting-general-dealer-img {
    border: 1px #949494 solid;
    width: 250px;
    border-radius: 5px;
    padding: 20px;

    img {
      width: 100%;
    }
  }

  .setting-general-dealer-icon {
    width: 250px;

    i {
      float: right;
      padding: 8px 8px 0 8px;
      color: #eff0f3;
      font-size: 18px;
    }
  }

  .setting-label-theme {
    padding-top: 15px;

    label {
      color: #bcc0c5;
      font-weight: 300;
    }
  }
}

.input-setting-dealer {
  border: 1px #a2a3a6 solid;
  border-radius: 5px;
  height: 40px;
  padding: 10px 15px;
  color: #a4a4a4;
  width: 250px;
}

.btn-message-dp:focus {
  border: 1px #dddee0 solid !important;
}

.btn-setting-inv-dp {
  float: left;
  color: #c1c5ca;
  background-color: #fff;
  border: 1px #7ca3be solid;
  border-radius: 5px;
  font-weight: $font-weight-regular;
  display: flex;
  height: 40px;
  width: 100%;

  a {
    color: #c2c6cb;
    padding: 9px 15px;
    width: 100%;
  }

  a:hover {
    color: #c2c6cb;
    cursor: pointer;
  }

  .dot {
    height: 15px;
    width: 15px;
    background-color: #2193f1;
    border-radius: 50%;
    float: left;
    margin-top: 2px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 30px 0 rgba(0, 0, 0, 0.1);
  }

  span {
    padding: 0 10px;
  }

  i {
    float: right;
    margin-top: 2px;
  }
}

.setting-inv-left {
  float: left;
  width: 30%;
  padding: 30px;

  .setting-inv-title {
    h3 {
      float: left;
      margin: 0;
      color: #818181;
    }
  }
}

.setting-inv-details {
  float: left;
  width: 70%;
  padding: 30px;

  .setting-inv-option {
    float: left;
    width: 40%;
  }

  .setting-inv-aspect {
    float: left;
    width: 60%;
  }
}

.setting-sub-category {
  display: block;
  float: left;
  padding-left: 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;

  .btn-setting-inv-dp {
    border: 0;
    padding: 10px;
  }
}

.hide-sub-category {
  display: none;
}

.modal-customize {
  position: fixed;
  z-index: 100;
  background-color: #fff;
  opacity: 0.3;
  width: 100%;
  height: 100%;
}

.container-opacity {
  background-color: #fff;
  opacity: 0.4;
}

.label-drag {
  color: #d2d3d8;
  position: relative;
  @include item-center;
  flex-direction: column;

  label {
    font-weight: $font-weight-regular;
    position: absolute;
    margin: auto;
    top: 30px;
  }
}

.label-drop {
  color: #a7cdea;
  display: table-cell;
  vertical-align: middle;
  text-align: center;

  label {
    font-weight: $font-weight-regular;
  }
}

.task-status {
  padding-left: 15px;
  display: block;
}

.btn-inventory-dp {
  width: 100%;
  height: 38px;

  span {
    padding: 4px 0 0 0;
    font-size: 13px;
    float: left;
  }

  i {
    float: right;
    padding-top: 5px;
  }

}

.btn-inventory-dp-new, .btn-form-sort {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #DADDE3;
  float: left;
  color: #6b6c78;
  background-color: #fff;
  cursor: pointer;
  height: 40px;
  padding-top: 10px;

  span {
    padding: 0;
    font-size: 12px;
    float: left;
    color: #787878;
    font-weight: normal !important;
  }

  i {
    float: right;
    padding-top: 2px;
    color: #A4AFB7;
  }

}

.btn-form-sort {
  width: 100%;
  height: 31px;
  padding-top: 6px;
  position: relative;

  .icon-arrow-content {
    position: absolute;
    right: 5px;
    top: 4px;
  }
}

.popover-inventory-dp {
  max-height: 300px;
  position: absolute;
  z-index: 100;
  border: 1px #dddee0 solid;
  border-radius: 10px;
  width: 100%;
  top: 40px;
  background-color: #fff;
  padding: 5px 1px;

  .popover-inventory-dp-sub {
    max-height: 280px;
    overflow: auto;

    .change-submenu-item {
      &:hover {
        background-color: #efefef;
      }
    }
  }

  u {
    text-decoration: none;

    li {
      list-style: none;
      padding: 8px;
      cursor: pointer;
    }
  }
}

.popover-inventory-dp-new-modal {
  max-height: 400px;
  position: absolute;
  z-index: 100;
  background-color: #fff;
  top: 25px;
  width: 100%;
  border: 1px solid rgb(218, 221, 227);
  border-top-width: 0;
  border-image: initial;
  border-radius: 0 0 5px 5px;
  color: rgb(137, 137, 137);

  .popover-inventory-dp-sub {
    max-height: 280px;
    overflow: auto;

    .change-submenu-item {
      &:hover {
        background-color: #efefef;
      }

      span {
        background-color: #efefef;
        height: 100%;
        width: 100%;
      }
    }

    u {
      li {
        padding-left: 13px;
      }
    }
  }

  u {
    text-decoration: none;

    li {
      list-style: none;
      padding: 8px;
      cursor: pointer;
    }
  }
}

#id-popover-inventory-dp-new {
  width: 100%;
  top: 33px;
}

.popover-inventory-dp-new {
  max-height: 300px;
  position: absolute;
  z-index: 100;
  background-color: #fff;
  top: 37px;
  border: 1px solid rgb(218, 221, 227);
  border-top-width: 0;
  border-image: initial;
  border-radius: 0 0 5px 5px;
  color: rgb(137, 137, 137);

  .popover-inventory-dp-sub {
    max-height: 280px;
    overflow: auto;

    .change-submenu-item {
      &:hover {
        background-color: #efefef;
      }

      span {
        height: 7px;
        width: 7px;
        background-color: #2193f1;
        border-radius: 50%;
        float: right;
        margin-top: 7px;
      }
    }

    u {
      li {
        padding-left: 13px;
      }
    }
  }

  u {
    text-decoration: none;

    li {
      list-style: none;
      padding: 8px;
      cursor: pointer;
    }
  }
}

.popover-sort {
  top: 26px;
  width: 100%;
}

.popover-inv-add {
  width: 75%;
  left: 76px;
  top: 85px;
  height: 500px;
  z-index: 200;
  padding: 10px 0 70px 0;
}

.row-inv-header {
  float: left;
  width: 100%;
  padding: 15px 10px 15px 25px;

  h3 {
    float: left;
    margin: 10px 20px;
    color: #787878;
  }

  .btn-draft {
    float: right;
    width: 12%;
    padding: 5px 20px 0 0;

    a {
      height: 35px;
      text-align: center;
      border: 2px #0093f8 solid;

      span {
        padding: 6px 0;
        width: 100%;
        color: #0093f8;
      }
    }
  }
}

.row-inv-info {
  background-color: #f5f6fa;
  height: 60px;
  display: flex;
  width: 100%;
  float: left;
  justify-content: center;

  i {
    font-size: 20px;
    color: #787878;
    padding-right: 10px;
  }

  label {
    color: #787878;
    margin-bottom: 5px;
    padding-left: 8px;
  }

  .inv-info-label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
  }

  .icon-check-circle {
    height: 20px;
    width: 20px;
    fill: #787878;
  }
}

.body-add-car {
  float: left;
  width: 100%;
  position: relative;
  padding: 1% 8% 2%;

  .car-info {
    background: white;
    float: left;
    width: 100%;
    padding: 10px 0 0 16px;
  }

  .body-car-info {
    .panel-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .btn-container-dot {
    .btn-primary-dot {
      float: right;
    }

    .btn-primary-dot-border {
      float: left;
    }
  }

  .bottom-car-info {
    .panel-body {
      padding-top: 0;
    }
  }

  .car-pricing {
    background: white;
    float: left;
    width: 100%;
    padding: 15px 0 0 0;
  }

  .car-info-finished {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .car-img {
    width: 200px;
    padding: 60px 0 40px 0;

    img {
      width: 100%;
    }
  }
}

.inv-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  float: left;
  margin-top: 7px;
  margin-right: 8px;
  background-color: #fff;
}

.btn-primary-dot {
  border-radius: 50%;
  background-color: #2193f1;
  color: white;
  width: 40px;
  height: 40px;
  border: 0;
}

.btn-success-dot {
  border-radius: 50%;
  background-color: #4ad991;
  color: white;
  width: 40px;
  height: 40px;
  border: 0;
}

.btn-primary-dot-border {
  border-radius: 50%;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border: 1px #2193f1 solid;

  .icon-arrow-back {
    fill: #2193f1;
    height: 14px;
  }
}

.btn-primary-dot-border:focus {
  border: 1px #2193f1 solid !important;
}

.btn-inv-go {
  background-color: #2193f1;
  color: #fff;
  border-radius: 5px;
  border: 1px #2193f1 solid;
}

.btn-container-dot {
  padding: 10px;
}

.body-car-info {
  float: left;
  width: 100%;
}

.btn-container-repair {
  padding: 5px 10px;
  float: left;

  .btn-success-dot {
    width: 30px;
    height: 30px;
  }

}

.inv-mechanical-left {
  float: left;
  width: 55%
}

.inv-mechanical-right {
  float: left;
  width: 45%;
  border-left: 1px #f3f3f3 solid;
  padding: 0 20px;
}

.repair-title-input {
  input {
    height: 38px;
    border-color: #84858a;
  }
}

.row-repair {
  float: left;
  width: 100%;

  span {
    color: #787878;
  }

  h4 {
    float: left;
    color: #818181;
  }
}

.btn-repair-dp {
  width: 100%;
  float: left;
  padding: 0 10px 10px 0;
  position: relative;
}

.repair-label-dp {
  label {
    font-size: 12px;
    color: #787878;
    font-weight: $font-weight-regular;
  }
}

.repair-due-input {
  input {
    float: left;
    width: 70%;
    height: 32px;
    border: 1px #dddee0 solid;
  }

  i {
    float: right;
    font-size: 20px;
    padding-top: 5px;
    color: #787878;
  }
}

.btn-container-dot-repair {
  float: right;
  padding: 20px 0 0 0;
}

.repair-adjustment {
  padding: 5px 0;

  i {
    color: #8db7d8;
    padding-right: 10px;
  }

  label {
    color: #787878;
    font-weight: $font-weight-regular;
  }
}

.pie-inv {
  position: absolute;
  bottom: 20px;
  width: 100%;

  .btn-container-inv-dot {
    padding: 0 30px;
  }

  .btn-primary-dot-border {
    float: left;
  }

  .btn-primary-dot {
    float: right;

    .icon-arrow-next {
      fill: #ffffff;
      height: 14px;
    }
  }

  .icon-arrow {
    padding-top: 4px;
  }
}

.inv-pricing-left {
  float: left;
  width: 100%;
  padding: 0 20px;
  border-right: 1px #f3f3f3 solid;
}

.price-due-input {
  width: 100%;
  float: left;
  padding: 0 10px 10px 0;
  position: relative;

  .due-input {
    float: left;
    width: 70%;
    border: 1px #dddee0 solid;
  }

  .paid {
    border: 1px #bec2c4 solid;
  }

  i {
    float: right;
    font-size: 20px;
    padding-top: 5px;
    color: #787878;
  }
}

.price-number {
  width: 100%;
  float: left;
  padding: 0 10px 0 0;
  position: relative;

  i {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #939393;
  }

  input {
    padding-left: 20px;
  }
}

.row-pricing {
  float: left;
  width: 100%;
  padding-top: 10px;

  h4 {
    color: #8d8d8d;
  }

  .price-asking {
    width: 60%;
  }
}

.pricing-input-radio {
  width: 100%;
  float: left;

  .price-number {
    width: 60%;
    float: left;
    padding-top: 2px;

    i {
      top: 12px;
    }
  }

  .radio-label {
    width: 40%;
    float: left;
    font-size: 12px;
    margin-top: -4px;

    label {
      color: #9b9b9b;
      font-weight: $font-weight-regular;
    }

    .pricing-radio-customer {
      margin: 0;
    }

    span {
      padding-left: 5px;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      margin: 2px;
    }

    [type="radio"]:checked + span,
    [type="radio"]:not(:checked) + span {
      position: relative;
      padding-left: 23px;
      cursor: pointer;
      line-height: 12px;
      display: inline-block;
    }

    [type="radio"]:checked + span:before,
    [type="radio"]:not(:checked) + span:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 15px;
      height: 15px;
      border: 2px solid #529bda;
      border-radius: 100%;
      background: #fff;
    }

    [type="radio"]:checked + span:after,
    [type="radio"]:not(:checked) + span:after {
      content: '';
      width: 7px;
      height: 7px;
      background: #3c85fb;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked) + span:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    [type="radio"]:checked + span:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

  }
}

.pricing-label-dp {
  padding-top: 5px;

  label {
    margin: 0;
    font-size: 12px;
    color: #787878;
    font-weight: $font-weight-regular;
  }
}

.popover-edit-basic::after {
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-right: 15px solid #fff;
}

.car-info {
  .images-left {
    float: left;
    width: 40%;

    .image-container {
      text-align: center;
      background-color: #fff;

      .without-images {
        height: 160px;
        background-color: #F5F6FA;
        @include item-center;

        .title-container-without-images {
          color: #A4AFB7;
          font-size: 15px;
          padding: 0 150px 0;
        }
      }

      .current-image {
        max-height: 245px;
      }
    }

    .radio-button-cover-image {
      div > div > div > svg {
        fill: #3B86FF !important;
      }

      div > label {
        color: #787878 !important;
        font-size: 11px !important;
      }

      div > div {
        margin-right: 4px !important;
      }
    }

    .title-cover-image {
      font-family: $font-family;
      font-size: 10px;
      padding: 0 0 0 5px;
      color: #787878;
      font-weight: $font-weight-light;
    }
  }

  .images-right {
    float: right;
    width: 60%;
    padding: 0 20px 0 60px;

    .uploaded-images {
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      max-height: 295px;

      .current-image-container {
        @include item-center;
        width: 32.3%;
        padding: 2px;
        margin-right: 1%;
        margin-bottom: 8px;
        background-color: #ffffff;

        .current-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .image-box {
        @include item-center;
        width: 25%;
        padding: 0 10px 10px 0;
        background-color: #ffffff;

        .current-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .drop-zone-container {
      padding-top: 8px;
    }

    .upload-image-logo {
      @include item-center;
      height: inherit;

      .container-images {
        .cloud-upload-content {
          margin-bottom: -10px;
        }

        .cloud-upload {
          width: 24px;
          height: 24px;
          fill: #a9aeb2;
        }

        .label-upload-logo {
          color: #a9aeb2;
          font-weight: $font-weight-light;
          font-size: 14px;
          font-family: $font-family;

          .label-upload-file {
            color: #2194F1;
            text-decoration: underline;
            font-family: $font-family;
            font-size: 14px;
            font-weight: $font-weight-light;
          }
        }
      }
    }
  }
}

/*************** SETTING *********/

#cke_1_contents {
  height: 30px !important;
}


.tab-billing-payment {
  width: 165px !important;
}

.tab-user {
  #user {
    display: none;
  }

  &:hover {
    #user {
      display: block;
    }
  }
}

.tab-form {
  #form {
    display: none;
  }

  &:hover {
    #form {
      display: block;
    }
  }
}

.tab-marketing {
  #marketing {
    display: none;
  }

  &:hover {
    #marketing {
      display: block;
    }
  }
}

.btn-dp-form {
  float: left;
  height: 100% !important;
  padding: 14px 10px 17px 10px;

  input[type="checkbox"] {
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #3B86FF !important;
    color: white !important;
    width: 15px;
    height: 15px;
    z-index: 1;
  }

  .checkbox-show-inactive:active, .checkbox-show-inactive:checked:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: #3B86FF !important;
  }

  .checkbox-show-inactive:checked {
    background-color: #3B86FF;
    border: 1px solid #1e08c0;
    color: #99a1a7;
  }

  .checkbox-show-inactive:after {
    background-color: #3B86FF;
    font-size: 14px;
    position: relative;
    top: -3px;
    left: -1px;
    color: #3B86FF;
  }

  input {
    cursor: pointer;
    left: -17px;
    z-index: 100;
    width: 20px;
    height: 20px;
    margin: 0;
  }

  .i-check {
    position: absolute;
    font-size: 17px;
    color: #4695d4;
    left: -18px;
    top: 8px;
  }
}

#id-btn-dp-form {
  width: 210px;
  position: relative;
  left: -15px;
  margin-right: -13px;

  a {
    height: 38px;
    top: -2px;
    position: relative;
  }
}

.btn-dp-form-testimonials {
  float: left;
}

.btn-dp-form-check {
  float: left;
  height: 100% !important;
  padding: 20px 10px 14px 10px !important;
}

.form-setting-button {
  float: left;
  padding: 14px 10px;

  .close-modal-icon {
    height: 32px;
    width: 32px;

    &:hover {
      cursor: pointer;
    }

    float: left;
    z-index: 500;

    svg {
      z-index: 500;
    }
  }

  .icon-add-user {
    fill: #4ad991;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    svg {
      position: absolute;
      top: -2px;
      left: -5px;
    }
  }
}

#id-form-setting-button {
  padding-left: 33px;
  padding-right: 22px;
}

.tab-form {
  background-color: #fff;
}

.tab-form-1 > div {
  padding: 0 20%;
}

.tab-compliant {
  width: 20% !important;
  text-align: center !important;

  a {
    color: white;
  }

  a:active {
    background-color: white !important;
    color: #555;
  }

  a:hover {
    border-bottom-color: transparent !important;
  }

  a:not(.active):hover {
    background-color: transparent;
    text-decoration: none !important;
    border-color: transparent !important;
  }
}

.tab {
  > div:first-child {
    button {
      width: 12% !important;
    }
  }

  > div:first-child + div {
    height: 0 !important;
    position: absolute;
    width: 10%;
    padding-left: 30px;
    padding-right: 230px;

    div {
      bottom: 2px !important;
      height: 3px !important;
    }
  }
}

@mixin sale-setting-update-form() {
  background: #FFFFFF 0 0 no-repeat padding-box;
  border: 1px solid #D7DAE2;
  border-radius: 5px;
  opacity: 1;
  float: left;
  width: 100%;
  padding: 11px 12px;
  margin: 10px 0;

  .form-field {
    width: 100%;
    float: left;
    margin: 0 0 12px 0;

    .info-label {
      .title {
        font-size: 13px;
        font-weight: 600;
        color: $text-color;
      }
    }
  }

  .classInput {
    input {
      box-shadow: none;

      &:focus {
        box-shadow: none;
      }
    }

    .help-block {
      padding-top: 3px;
      font-size: 11px;
      color: $button-color-red
    }
  }

  .edit-form-footer {
    float: right;
    padding-top: 11px;
    display: flex;

    .cancel-button {
      @include cancel-button($margin: 0 14px 0 0)
    }
  ;

    .save-button {
      @include classic-button($width: 70px)
    }
  }
}

@mixin setting-column() {
  width: 100%;
  float: left;
  background-color: #FFFFFF;
  box-shadow: 0 0 6px #D7DAE257;
  border-radius: 7px;
  opacity: 1;
  padding: 11px 20px;
  position: relative;
  .column-header {
    width: 100%;

    .header-title {
      text-align: left;
      font: Bold 21px/30px Source Sans Pro;
      letter-spacing: 0;
      color: #787878;
      opacity: 1;
      margin: 0;
      width: 80%;
      float: left;

      .title-desc {
        float: left;
        position: relative;

        .icon-info {
          position: absolute;
          float: left;
          width: 16px;
          height: 16px;
          cursor: pointer;
          margin: 7px 0 7px 5px;
        }
      }
    }

    .header-options {
      width: 20%;
      float: right;

      .add-option {
        float: right;
        width: 11px;
        height: 11px;
        cursor: pointer;
        fill: #2194F1;
        margin: 10px 0 9px 0;
      }
    }
  }
  .column-section {
    width: 100%;
    float: left;

    .section-header {
      text-align: left;
      font: Regular 13px/18px Source Sans Pro;
      letter-spacing: 0;
      color: #787878;
      opacity: 1;
      margin: 10px 0 10px 0;
    }

    .section-header-2 {
      background-color: $text-color;
      color: #FFFFFF;
    }

    .section-header-reg-fees {
      background-color: $text-color;
      color: #FFFFFF;
    }

    .section-body {
      width: 100%;
      float: left;
      margin: 10px 0 10px 0;

      .displayed-element-row {
        width: 100%;
        background-color: white;
        margin-bottom: 10px;
        float: left;

        .container-icon {
          width: 4%;
          float: left;
          height: 14px;

          .icon-arrow-content {
            height: 30px;
            width: 100%;
            padding: 0;

            .icon-arrow {
              opacity: 1;
              float: left;
              height: 12px;
              width: 12px;
              border: none;
              margin-top: 1px;
              margin-bottom: 2px;

              .icon-arrow-svg {
                height: 12px;
                width: 12px;
                fill: #D7DAE2;
                cursor: pointer;
              }
            }
          }
        }

        .element-detailed-info {
          width: 78%;
          float: left;
          margin-left: 10px;

          .element-title {
            text-align: left;
            font: Regular 13px/16px Source Sans Pro;
            letter-spacing: 0;
            color: #787878;
            opacity: 1;
            height: 18px;
          }

          .element-subtitle {
            text-align: left;
            font: Semilight 9px/13px Segoe UI;
            letter-spacing: 0;
            color: #A4AFB7;
            opacity: 1;
            height: 12px;
          }
        }

        .element-options {
          width: 15%;
          float: right;
          visibility: hidden;

          .edit-option {
            float: left;
            width: 14px;
            height: 14px;
            cursor: pointer;
            fill: #2194F1;
            margin: 8px 0 8px 10px;
          }

          .delete-option {
            float: left;
            width: 14px;
            height: 14px;
            cursor: pointer;
            fill: $button-color-red;
            margin: 8px 0 8px 10px;
          }
        }
      }

      .edit-form {
        @include sale-setting-update-form
      }

      .container-checklist, .container-accessory {
        &:focus {
          outline: none;
        }

        display: flex;

        .element-detailed-info {
          display: flex;
          align-items: center;

          .element-title {
            font-size: 13px;
            height: auto;
          }
        }
      }

      .container-more-settings {
        button[disabled] {
          background: $icon-arrow-color !important;
          border-color: $icon-arrow-color !important;
        }

        .element-detailed-info {
          .element-title {
            font-size: 13px;
          }

          .element-subtitle {
            font-size: 11px;
          }

          .info-label {
            float: left;

            label {
              color: $text-color;
              font-weight: 400;
              margin-bottom: 0;
              font-size: 13px;
              margin-top: 7px;
            }
          }

          .inputSale {
            float: right;
            position: relative;
            width: 100%;

            .dollar-icon {
              position: absolute;
              left: 10px;
              top: 8px;
              color: black;
            }

            .form-control {
              @include form-control;
              width: 93%;
              padding: 6px 20px;
              float: left;
            }

            .icon-question-content {
              float: left;
              padding: 5px 5px;
              width: 7%;
            }
          }
        }

        #state-tax-wrapper {
          .panel {
            border: none;
            box-shadow: none;

            .panel-body {
              padding: 0;

              .subtitle {
                font-size: 11px;
              }

              .container-input {
                width: 80px;
                float: right;
                position: relative;
                border: none;

                .percentage {
                  @include item-center;
                  position: absolute;
                  z-index: 2;
                  right: 6px;
                  top: 0;
                  height: 34px;
                }
              }

              .container-tax {
                width: 100%;
                float: left;

                .container-text {
                  width: calc(100% - 80px);
                  float: left;
                  height: 34px;
                  display: flex;
                  align-items: center;
                }
              }

              .location-tax-setting {
                width: 100%;
                float: left;

                .container-delete-icon {
                  @include item-center;
                  float: left;
                  cursor: pointer;
                  width: 40px;
                  height: 34px;
                  border: none;

                  svg {
                    &:hover {
                      fill: $button-color-red;
                    }
                  }
                }
              }

              .container-save-button {
                width: 100%;
                display: flex;
                flex-direction: row-reverse;
                padding: 20px 0 0 0;

                .save-button {
                  @include classic-button($width: 80px)
                }
              }
            }
          }
        }
      }

      .container-file {
        .element-detailed-info {
          .element-title {
            font-size: 13px;
          }

          .element-subtitle {
            font-size: 11px;
          }
        }
      }

      .container-accessory {
        position: relative;

        .element-detailed-info {
          width: calc(85% - 14px);

          .element-title {

          }

          .element-price {
            font-size: 13px;
            height: auto;
            color: $text-color;
            margin-left: auto;
          }
        }
      }
    }

    .section-footer {
      clear: both;
      padding: 5px 0;

      .simple-line {
        @include simple-line-separator;
      }
    }
  }
  .edit-form {
    @include sale-setting-update-form;

    .inputSale {
      position: relative;

      .dollar-prefix {
        @include item-prefix-input($left: 10px, $color: #979797, $height: 34px);
      }

      .form-control {
        box-shadow: none;
      }

      .help-block {
        padding-top: 3px;
        font-size: 11px;
        color: $button-color-red;
      }
    }
  }
}

@mixin body-setting() {
  padding: $padding-container-center;
  float: left;
  width: 100%;
  min-height: 100%;
  .setting-container {
    .setting-form-header-header {
      .row-setting-user {
        padding-left: 5px;

        .btn-general-message-dp {
          width: 160px;
        }

        .form-setting-button {
          width: 8%;
          padding-left: 20px;
        }
      }

      .popover-new-user {
        .title-role-user {
          color: rgb(165, 165, 165);
          font-size: 13px;
          font-weight: $font-weight-regular;
          padding-left: 11px;
        }
      }
    }
  }
  .container-setting-column {
    padding: 0 10px 20px 10px;
    min-width: 395px;
    width: 33.33%;
    float: left;
    display: block;
  }

  .setting-column {
    @include setting-column;
  }
  .setting-column-accessory {
    @include setting-column;
    padding: 10px 0;

    .column-header {
      padding: 0 20px;
    }

    .column-section {
      padding-top: 5px;

      .section-header-2 {
        height: 32px;
        display: flex;
        align-items: center;
        padding-left: 44px;
        font-size: 13px;

        .title-2 {
          margin-left: auto;
          padding-right: 18px;
        }
      }

      .section-header-reg-fees {
        height: 32px;
        display: flex;
        align-items: center;
        padding-left: 32px;
        font-size: 13px;

        .title-2 {
          margin-left: auto;
          padding-right: 33px;
        }
      }

      .section-body {
        margin: 0;
        padding-bottom: 10px;

        .container-accessory {
          padding-left: 20px;
          min-height: 36px;

          .element-detailed-info {
            transition: 0.5s;
            width: calc(100% - 14px);
            margin: 0;
            padding: 0 18px 0 10px;

            .element-price {
              display: block;
              opacity: 1;
            }
          }

          .element-options {
            display: none;
            min-width: 48px;;
          }

          &:hover {
            .element-detailed-info {
              width: calc(85% - 14px);
              transition: 0.5s;

              .element-price {
                transition: 0.5s;
                opacity: 0
              }
            }
          }
        }

        .row-color {
          background-color: #F5F6FA;
        }

        .displayed-element-row {
          margin: 0 !important;
        }

        .container-edit-form {
          padding: 0 20px;
        }
      }
    }

    .container-edit-form {
      padding: 0 20px;
    }
  }
}

.body-setting {
  @include body-setting;
  //--height: auto !important;
}

.sale-setting-column-title-popover {
  text-align: left;
  font: Regular 13px/16px Source Sans Pro;
  letter-spacing: 0;
  color: #787878;
  opacity: 1;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 0 15px #D7DAE2;
  border-radius: 5px;
  padding: 18px 18px 25px 18px;
  font-size: 13px;
}

.sale-setting-update-form {
  @include sale-setting-update-form;
}

.body-setting-customer {
  @include body-setting;
}

.sales-container {
  @include body-setting;

  .sales-header {
    background: white;
    float: left;
    width: 100%;
    padding: 20px 0 20px 0;
    border-bottom: 1px solid #EFEFEF;
    display: flex;
    align-items: center;

    .titles-container {
      @include item-center;
      flex-direction: row;
      width: 100%;

      .sales-title, .sales-title-clickable {
        display: flex;
        align-items: center;
        margin-right: 10px;

        .check-icon-container {
          margin-right: 7px;
        }

        .check-icon {
          width: 15px;
        }

        .label-title-sales {
          font-size: 15px;
          padding-right: 10px;
        }

        .label-title-sales-end {
          font-size: 15px;
          padding-right: 0;

          &:hover {
            cursor: pointer;
          }
        }

        .dots-icon-container {
          height: 26px;
        }
      }

      .sales-title-clickable:hover {
        cursor: pointer;

        .label-title-sales:hover {
          cursor: pointer;
        }
      }
    }

    .sales-back-icon-container {
      width: 50px;
      @include item-center;

      .icon-sales-back-icon {
        fill: #2194F1;
        width: 15px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .sales-body {
    background: white;
    float: left;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #EFEFEF;

    .sales-icons-container {
      @include item-center;

      .sales-icon-container {
        @include item-center;
        padding: 13px;
        width: 270px;
        height: 270px;
        border: 1px solid #D7DAE2;
        border-radius: 15px;
        margin-right: 65px;
        flex-direction: column;

        &:hover {
          cursor: pointer;
        }

        .icon-sales-content {
          @include item-center;
          width: 100%;

          .icon-sales {
            height: 203px;
            width: 201px;
          }
        }

        .sales-titles {
          color: #787878;
          font-size: 20px;
        }
      }
    }

    .inventory-container-sales {
      display: flex;
      width: 100%;
      flex-direction: column;

      .filters-container {
        height: 70px;
        align-items: center;
        border-bottom: 1px solid #EFEFEF;
        padding: 15px 50px 15px 50px;

        .sales-car-menu-search {
          border: 1px solid #EFEFEF;
          padding: 7px;
          border-radius: 8px;
          width: 24%;
          justify-content: space-between;
          display: flex;
          float: left;

          #search-car-keyword {
            border: 0;
            outline: none;
          }

          a {
            color: #BCBCCB;
            font-size: 15px;
          }
        }

        .sales-error-message {
          color: #ff0725;
          font-size: 15px;
        }

        .action-add-car {
          float: left;
          padding-left: 10px;

          .icon-add-task {
            display: flex;
            width: 44px;
            height: 47px;

            > div {
              svg {
                width: 44px;
                height: 47px;
                fill: #4ad991;

                g {
                  cursor: pointer;
                }
              }
            }
          }

          .icon-add-svg {
            height: 12px;
            width: 12px;
          }
        }
      }

      .cars-container {
        flex-wrap: wrap;
        display: flex;
        width: 100%;
        padding: 20px 50px 0 50px;

        .sales-car-container {
          width: 25%;
          padding-right: 15px;
          padding-left: 0;
          padding-top: 2px;

          .sales-car {
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
            border: 1px solid #EFEFEF;
            margin-bottom: 25px;

            .border-top {
              position: relative;
              left: 33%;
              width: 30%;
            }

            .info-car {
              width: 100%;
              text-align: left;
              font-size: 14px;
              padding: 12px 12px 6px;
              background-color: #fff;
              display: flex;
              align-items: center;

              .info-car-title {
                color: #a9aeb4;
                font-weight: 500;
                padding-left: 4px;
              }
            }

            .car-image-container {
              position: relative;
            }

            .car-more-info-container {
              display: flex;

              .more-info {
                width: 70%;
                flex-direction: column;
                display: flex;
                margin-left: 25px;
                height: 65px;
                justify-content: center;

                .car-status {

                }

                .car-price-mile {
                  font-size: 11px;
                  color: #43425D;
                  font-weight: 500;
                }
              }

              .sales-car-select {
                width: 30%;
                @include item-center;

                .select-car {
                  @include item-center;
                  font-size: 13px;
                  background-color: #2194F1;
                  border: 1px solid #D7DAE2;
                  border-radius: 4px;
                  width: 75%;
                  height: 30px;
                  color: #FFFFFF;

                  &:hover {
                    cursor: pointer;
                  }

                  .container-icon-exclamation {
                    padding-right: 5px;

                    .icon-error div {
                      @include item-center;

                      .svg-icon-error {
                        height: 16px;
                        width: 16px;
                        fill: white;
                      }
                    }
                  }
                }
              }
            }
          }

          .sales-car:hover .border-top {
            left: 0;
            width: 100%;
          }
        }

        .loader-container {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;

          .loader {
            border: 7px solid #f3f3f3; /* Light grey */
            border-top: 7px solid #3498db; /* Blue */
            border-radius: 50%;
            width: 64px;
            height: 64px;
            animation: adjust-hue 1s linear infinite;
          }

          @keyframes adjust-hue {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }

        .message-cars-not-found {
          width: 100%;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin-top: 70px;

          .container-image {
            width: 100%;

            img {
              vertical-align: middle;
            }

            h3 {
              color: #787878;
              font-weight: 600;
              font-size: 21px;
              font-family: $font-family;
            }
          }

          .container-btn-add-car {
            width: 100%;
            padding: 20px 0 70px 0;

            .btn-add-car {
              color: #ffffff;
              background-color: #2194F1;
              border-radius: 4px;
            }
          }
        }
      }
    }

    .box-tax-exemption, .box-sale-agreement {
      .container-buttons {
        position: fixed;
        right: 80px;
        display: flex;
        bottom: 50px;
        z-index: 1;

        button {
          border-radius: 50%;
          height: 40px;
          width: 40px;
          background-color: #2194F1;
          border: none;

          .icon-save, .icon-print {
            display: flex;
            align-items: center;
            justify-content: center;

            div {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .check-ark {
          margin-right: 10px;

          .icon-check {
            border-radius: 50%;
            height: 40px;
            width: 40px;
            background-color: #4AD991;
            border-color: #4AD991;

            div {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;

              svg {
                height: 18px;
              }
            }
          }
        }

        .save {
          margin-right: 10px;
        }

        svg {
          height: 18px;
          fill: white;
        }
      }
    }

    .box-sale-agreement {
      .has-error {
        border-color: #a94442;
        box-shadow: none;
      }

      .container-section-agreement {
        background: white;
        float: left;
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid #EFEFEF;

        .body-section-agreement {
          padding-bottom: 20px;
          float: left;
          width: 100%;

          label {
            color: #797979;
            font-weight: 500;
          }

          .container-buttons {
            float: right;
            display: flex;

            .box-button {
              padding: 0 5px;

              .btn-print-pdf, .btn-save-continue {
                float: left;
                border-radius: 5px;
                height: 30px;
                border: 1px solid #2194f1;
              }

              .btn-print-pdf {
                background-color: white;
                color: #2194f1;
                width: 135px;

                &:focus {
                  border: 1px solid !important;
                }
              }

              .btn-save-continue {
                background-color: #2194f1;
                color: white;
                width: 170px;
              }
            }
          }

          .container-input-labels {
            padding-top: 25px;
            width: 100%;
            float: left;

            .box-input {
              width: 90px;
              display: inline-block;
              padding: 0 5px;

              input {
                color: #2194F1 !important;
                padding: 6px 10px;
              }

              .inputSale {
                padding-right: 0;
              }
            }
          }

          .separator-container {
            padding-right: 10px;

            .line-separator-container {
              width: 100%;
              float: left;
              border-bottom: 1px solid #ccc;
              padding-top: 15px;
            }
          }

          .container-title {
            .title {
              font-weight: 700;
              font-size: 16px;
            }
          }

          .container-others-terms {
            .class-input {
              float: left;
              padding: 0 10px 0 0;
              position: relative;
              width: 77.6%;
            }
          }

          .title-new {
            padding-top: 20px;

            label {
              font-weight: 700;
              font-size: 16px;
              color: #797979;
            }
          }

          .container-trades-in {
            padding-right: 10px;

            .container-trade {
              display: flex;
              padding: 4px 0;

              label {
                margin-bottom: 0;
                display: flex;
                align-items: center;
                font-weight: 600;
              }

              .box-trade-in {
                border-radius: 5px;
                width: 100%;
                padding-left: 10px;

                .trade-in {
                  border: 1px solid #ccc;
                  border-radius: 5px;
                  padding: 0 10px;
                  height: 34px;
                  display: flex;
                  align-items: center;
                  position: relative;

                  label {
                    margin-bottom: 0;
                    width: 97%;
                    height: 34px;
                    cursor: pointer;
                  }

                  i {
                    font-size: 24px;
                    color: red;
                    right: 15px;
                    position: absolute;
                    cursor: pointer;
                  }

                  .label-trade-in {
                    width: 50%
                  }

                  .container-trade-allowance {
                    width: 20%;
                    float: left;
                    margin: 0 5px 0 0;

                    .container-label-allowance {
                      border-radius: 5px;
                      border: 1px solid #2194f1;
                      float: left;
                      height: 26px;
                      padding: 1px 3px;
                      background: #2194f1;
                      display: flex;
                      align-items: center;
                      position: relative;
                      width: 100%;

                      .left {
                        float: left;
                        width: 60%;
                        display: block;

                        span {
                          font-size: 10px;
                          width: 100%;
                          color: white;
                          font-weight: 500;
                          position: absolute;
                        }

                        .trade-in-span {
                          top: 1px;
                        }

                        .allowance-span {
                          top: 10px;
                        }
                      }

                      .right {
                        float: right;
                        width: 40%;

                        span {
                          width: 100%;
                          color: white;
                          font-weight: 600;
                          font-size: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .first-container {
            float: left;
            width: 100%;
          }

          .second-container {
            float: left;
          }

          .third-container {
            float: left;

            .btn-add-trade-in {
              float: left;
              background-color: white;
              border-radius: 5px;
              height: 30px;
              width: 200px;
              color: #2194f1;
              border: 1px solid #2194f1;

              &:focus {
                border: 1px solid !important;
              }
            }
          }
        }
      }
    }

    .container-general-tax {
      .loader-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 500px;

        .loader {
          border: 7px solid #f3f3f3; /* Light grey */
          border-top: 7px solid #3498db; /* Blue */
          border-radius: 50%;
          width: 80px;
          height: 80px;
          animation: adjust-hue 1s linear infinite;
        }

        @keyframes adjust-hue {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }

    .box-tax-exemption {
      padding: 20px;
      width: 100%;
      height: 100%;
      float: left;

      .col100 {
        padding-top: 10px;
        width: 100%;
        float: left;
      }

      label {
        color: #797979;
        font-weight: 500;
        font-size: 14px;
      }

      .section-row-message {
        padding: 0;
      }

      .container-tax-exemption {
        float: left;
        width: 100%;
        padding-top: 20px;

        .first-row {
          float: left;
          width: 100%;
        }

        .second-row {
          padding-top: 30px;
          float: left;
          width: 100%;
          @mixin container-info-purchase() {
            width: 40%;
            float: left;
            padding-right: 20px;
            border-right: 1px solid #D9D9D9;
            .title-info {
              font-size: 18px;
              font-weight: 800;
            }
          }

          .container-info-purchase {
            @include container-info-purchase;
          }

          .container-info-dealer {
            @include container-info-purchase;
            border: none;
            padding-left: 30px;
          }
        }

        .third-row {
          padding-top: 30px;
          float: left;
          width: 100%;

          p {
            color: #797979;
            margin: 0 0 3px;
          }

          .second-paragraph {
            padding-left: 15px;
          }

          .third-paragraph {
            padding-left: 30px;
          }
        }
      }
    }

    .box-check-list {
      width: 100%;
      padding: 30px;
      float: left;

      label {
        color: $icon-color;
      }

      .container-left {
        float: left;
        width: 25%;

        .container-car-info {
          float: left;
          width: 100%;
          border-radius: 5px;
          border: 1px solid #EFEFEF;

          .container-title {
            padding: 13px 0 13px 13px;
          }

          .container-image {
            img {
              width: 100%;
              object-fit: cover;
            }
          }

          .container-price {
            padding: 13px 0 13px 13px;

            label {
              font-weight: 500;
            }
          }
        }

        .container-selling {
          height: 40px;
          float: left;
          margin-top: 20px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid #EFEFEF;
          padding: 10px;
          position: relative;

          .icon-getting-finance {
            width: 30px;
            float: left;
            position: absolute;
            top: 5px;
          }

          .svg-icon-getting-finance {
            width: 26px;
            height: 28px;
          }

          label {
            padding-left: 30px;
          }
        }

      }

      .container-right {
        float: left;
        width: 75%;
        padding-left: 30px;

        .content-check {
          height: 35px;

          .checkbox {
            width: 20px !important;
            float: left;
            margin-top: 0;
          }

          .container-label {
            .check-description {
              float: left;
              color: $icon-color;
              margin: 2px 0 0 0;
            }

            .check-required {
              float: left;
              color: $button-color-red;
              margin: 0;
            }
          }
        }

        .container-required-items {
          float: left;
          padding-left: 32px;

          .required-items {
            color: $button-color-red;
            font-size: 11px;
            font-weight: 500;
          }
        }
      }
    }

    .car-header {
      display: flex;
      align-items: center;
      padding: 0;
      width: 100%;
    }

    .col22 {
      width: 22%;
      float: left;
    }

    .col11 {
      width: 11%;
      float: left;
    }

    .inputSale {
      padding-right: 10px;

      input {
        color: #2194F1 !important;
      }
    }

    .label-error {
      padding-left: 2px;
      color: red;
    }

    .col48 {
      width: 48%;
      float: left;
    }

    .col45 {
      width: 45%;
      float: left;
    }

    .col24 {
      width: 24%;
      float: left;
      padding-left: 10px;
      position: relative;

      .dollar-prefix {
        position: absolute;
        width: 8px;
        z-index: 1;
        height: 100%;
        display: flex;
        align-items: center;
        left: 22px;
        font-size: 14px;
        color: #2194F1;
        top: 1px;
      }

      input {
        padding-left: 19px;
        padding-right: 4px;
      }
    }

    .input-purchase {
      color: #2194F1 !important;
    }

    .container-input-phone {
      input {
        color: #2194F1 !important;
      }
    }

    .flex-colR1 {
      padding-right: 10px;

      .item {
        .display-date {
          p {
            margin: 0 0 5px;
          }
        }

        .form-control {
          input {
            color: #2194F1 !important;
          }

          div {
            display: initial !important;

            div {
              hr {
                display: none;
              }
            }
          }
        }
      }
    }

    .col42 {
      width: 42%;
      float: left;
    }

    .col20 {
      width: 20%;
      float: left;
    }

    .col23 {
      width: 23%;
      float: left;
    }

    .col52 {
      width: 52%;
      float: left;

      label {
        color: #797979;
        font-weight: 900;
      }

      .label-number {
        font-weight: 900 !important;
        padding-right: 5px;
      }

      .label-number-input {
        font-weight: 900 !important;
      }
    }

    .col11-select {
      float: left;
      width: 11%;
      padding-right: 10px;
    }

    .col25-select {
      float: left;
      width: 25%;
      padding-right: 10px;
    }

    @mixin popover() {
      overflow: auto;
      max-height: 110px;
      box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
      border-radius: 2px;
      padding: 5px 0;
    }

    .popover {
      @include popover;
    }

    .popover-make {
      @include popover;
    }

    .container-option {
      padding: 0 10px;
      cursor: pointer;

      &:hover {
        background: #F5F6FA;
      }

      .option-label {
        font-weight: $font-weight-regular;
        cursor: pointer;
      }
    }

    .select {
      display: flex;
      position: relative;
      height: 34px;
      border-radius: 5px;
      padding: 5px;
      border: 1px solid #ccc;
      cursor: pointer;

      .container-icon {
        right: 10px;
        position: absolute;

        i {
          color: #797979;
        }
      }

      .container-label {
        label {
          color: #2194F1 !important;
        }
      }
    }

    .row-element {
      padding-top: 10px;

      .container-input-element {
        padding-left: 5px;
        width: 100%;
      }
    }

    .section-message, .section-row-message {
      padding-right: 10px;
      width: 100%;
      float: left;

      .container-icon-body-title {
        float: left;
        border: 1px solid #f4be52;
        display: flex;
        border-radius: 5px;
        padding: 10px;

        .container-message {
          padding-left: 15px;

          .title {
            font-weight: 600;
          }
        }

        .container-icon-exclamation {
          .icon-error {
            div {
              display: flex;
            }
          }

          .svg-icon-error {
            fill: #ffca83;
            border: 1px solid #fff4e5;
            border-radius: 50px;
            width: 22px;
            height: 22px;
          }
        }
      }
    }

    .section-row-message {
      padding: 15px 20px 0 20px;

      .container-icon-body-title {
        width: 100%;
        padding: 5px;
      }

      .container-message {
        padding-left: 15px;

        .title-message {
          font-weight: 500;
          margin-bottom: 0;
          color: #797979;
        }
      }
    }

    .section-agreement {
      padding-top: 15px;

      .label-purchaser-agreement {
        padding: 10px 0;
      }

      .container-input-phone {
        padding-right: 10px;

        .item {
          p {
            margin: 0 0 5px;
            float: left;
          }
        }

        div {
          .label-error {
            color: red;
            padding-left: 2px;
          }
        }
      }

      .container-status {
        padding-right: 10px;

        @mixin btn-status() {
          float: right;
          width: 32%;
          margin-right: 0;
          height: 34px;
          border-radius: 5px;
          border: 1px solid #ccc;
          background: white;
          font-weight: 600;
          color: #797979;
        }

        .btn-status {
          @include btn-status;
        }

        .btn-status-selected {
          @include btn-status;
          color: white;
          background: #2194f1;
        }

        .middle {
          margin: 0 2%;
        }
      }

      .info-label {
        .title {
          color: #797979;
          font-weight: 500;
        }
      }
    }

    .classInput {
      width: 100%;
      float: left;
      padding: 0 10px 0 0;
      position: relative;

      input {
        color: #2194F1 !important;
      }

      .help-block {
        padding-bottom: 10px;
      }
    }

    .container-sale-files {
      height: 100%;
      padding: 20px 30px;
      width: 100%;

      .first-row-sale-files {
        height: 288px;
        width: 100%;
      }

      .sale-file {
        height: 100%;
        width: 24%;
        float: left;
        margin: 0.5%;
        border-radius: 15px;
        border: 1px solid #D7DAE2;

        .container-image-file {
          height: 65%;
          border-radius: 15px 15px 0 0;
          border-bottom: 1px solid #D7DAE2;
          @include item-center;

          .iframe {
            height: 100%;
            width: 100%;
            border-radius: 15px 15px 0 0;
            border: none;
          }

          .image-sale-file {
            object-fit: cover;
            height: 100%;
            width: 100%;
            border-radius: 15px 15px 0 0;
            border: none;

            body {
              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
              }
            }
          }

          .dropzone-file {
            &:hover {
              cursor: pointer;
            }
          }

          .icon-sales-file {
            height: 100%;
          }

          .loader-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .loader {
              border: 7px solid #f3f3f3; /* Light grey */
              border-top: 7px solid #3498db; /* Blue */
              border-radius: 50%;
              width: 64px;
              height: 64px;
              animation: adjust-hue 1s linear infinite;
            }

            @keyframes adjust-hue {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }
        }

        .container-type-buttons {
          height: 35%;
          float: left;
          width: 100%;
          border-radius: 0 0 15px 15px;
          padding: 10px;

          .container-type {
            label {
              font-weight: 500;
              font-size: 13px;
              color: $text-color-table;
            }
          }

          .container-buttons {
            .upload, .print, .print-disabled {
              height: $height-box-input;
              border-radius: 5px;
            }

            .upload {
              width: 100%;;
              border: 1px solid $light-blue-color-1;
              background: #FFFFFF;
              color: $light-blue-color-1;

              &:focus {
                border: 1px solid $light-blue-color-1 !important;
              }

              .icon-attach-file {
                width: 17px;
                float: left;
                padding-right: 10px;
                transform: rotate(180deg);

                .icon-attach {
                  fill: $light-blue-color-1;
                }
              }

              .icon-upload-file {
                width: 15px;
                float: left;
                margin-right: 10px;
                display: flex;
                padding-top: 2px;

                div {
                  @include item-center;

                  .icon-upload {
                    height: 14px;
                    fill: #2194F1;

                    path {
                      fill: #2194F1;
                    }
                  }
                }
              }
            }

            .print, .print-disabled {
              width: 25%;
              min-width: 64px;
              color: #FFFFFF;
              background: #D7DAE2;
            }

            .print {
              border: 1px solid #2194F1;
              background: #2194F1;
            }

            .print-disabled {
              background: #D7DAE2;
              border: 1px solid #D7DAE2;
            }

            .dropzone-file {
              width: 30%;
              float: left;
              margin-right: 10px;
              min-width: 86px;
            }
          }
        }
      }

      .second-row-sale-files {
        height: 288px;
      }
    }

    .title-forms-list {
      .title-finance {
        padding-left: 100px;
        width: 30%;
      }

      .title-date {
        width: 20%;
      }

      .title-status {
        width: 20%;
      }

      .title-assigned {
        width: 30%;
      }
    }

    .body-finance {
      padding-left: 100px;
    }
  }
}

.modal.in .modal-dialog {
  transform: none;
}

.modal-dialog {
  height: $height-modal;

  .modal-content {
    height: 100%;

    .modal-body {
      height: 100%;

      .panel-left-custom, .panel-left {
        height: 100%;
      }

      .panel-right {
        height: 100%;

        .container-elements {
          max-height: 120px;
          overflow: auto;
          width: 60%;
          margin: auto;

          .row-info-modal {
            padding: 2px;
          }
        }

        .modal-buttons {
          margin-top: 20px;
          @include item-center;

          .btn-save {
            width: 130px;
            font-family: $font-family;
            font-weight: $font-weight-regular;
          }
        }
      }
    }
  }
}

#tab-setting-form {
  ul {
    display: -moz-box;
    border-bottom: 1px #f1f1f1 solid;
    padding: 10px 30% 0 30%;

    .active {
      border-bottom: 2px #2193f1 solid !important;

      a {
        color: #2193f1;
      }
    }

    li {
      margin-top: 7px;

      a {
        bottom: 7px;
      }
    }
  }
}

.tab-form {
  a:active {
    border-bottom: 1px #2193f1 !important;
    color: #2193f1;
  }
}

.form-header-left, .attribute-header-left, .attribute-header-right {
  position: absolute;
  padding: 0 20px;
  color: #A4AFB7;

  h4 {
    margin: 0;
    padding: 20px 0;
  }
}

.attribute-header-left {
  border-bottom: 1px #f1f1f1 solid;
  display: inline-flex;
  float: left;
  padding: 0 32px;
  position: relative;
  width: 100%;
}

.attribute-header-right {
  border-bottom: 1px #f1f1f1 solid;
  display: inline-flex;
  float: right;
  padding: 0 32px;
  position: relative;
  width: 100%;
}

.form-header-right {
  position: absolute;
  right: 48px;
}

.container-add-attribute {
  display: flex;
  height: 585px;
  position: relative;
  width: 100%;
  float: left;

  .info-attribute, .info-attribute2 {
    float: left;
    width: 60%;

    .form-modal-new-attribute {
      padding: 30px 23.47px 0 32.47px !important;
    }

    .form-property {
      border-bottom: 1px #f1f1f1 solid;
      padding: 30px 10px 0 30px;

      .row-form {
        .attribute-name-form-new {
          width: 52.3% !important;
          padding-right: 13.77px !important;

          .content-check {
            margin-top: 5px;
          }

          .container-check {
            margin-left: 20px;
          }
        }

        .attribute-value-form-new {
          width: 47.7% !important;
          padding-right: 0 !important;
        }

        .attribute-field {
          float: left;
          padding-right: 20px;

          .form-input {
            input {
              height: 40px;

            }
          }

          span {
            color: #787878;
            font-weight: 300;
          }

          width: 50%;
        }
      }

      #border-attribute-option {
        display: inline-flex;
        float: left;
        width: 79%;
        height: 43px;
        margin-top: 0;
        padding-right: 10px;

        .tittle-option, .check-option {
          width: 100%;

          input {
            width: 2% !important;
            margin-top: 14px;
            margin-left: 15px !important;
          }

          img {
            margin: 15px 0 0 16px;
            position: relative;
          }

          span {
            position: absolute;
            float: left;
            display: inline-flex;
            margin: 10px 0 0 12px;
          }
        }

        .check-option {
          img {
            margin: 10px 0 0 16px;
            position: relative;
          }
        }

        .tittle-list {
          width: 95%;
          margin-top: 9px;
        }

        .icon-options {
          opacity: 0.5;
          width: 5%;
        }

        .icon-option-delete {
          margin-right: 14px;
        }
      }

      #border-attribute-info {
        display: inline-flex;
        float: left;
        width: 19%;
        height: 43px;
        margin-top: 0;
        margin-left: 10px;

        img {
          margin-left: 12px;
        }
      }
    }

    .attribute-content {
      color: #A4AFB7;
      float: left;
      margin-top: 15px;
      padding: 10px 28px 0 30px;
      width: 100%;

      .select-attribute {
        background-color: #edf7ff;
        border: 1.5px dashed #c1c9cd;
        cursor: pointer;
        float: left;
        height: 40px;
        padding-top: 7px;
        text-align: center;
        width: 100%;
      }

      .form-property {
        padding: 0;
      }

      h4 {
        color: #A4AFB7;
      }
    }
  }

  .info-attribute2 {
    width: 100% !important;

    .attribute-content {
      .row-form {
        margin: 0;

        .form-header-left {
          padding: 0;
        }

        .multi-selected {
          border: 2px solid rgb(243, 243, 243);
          border-radius: 5px;
          display: inline-flex;
          width: 100px;
        }
      }

      .form-property {
        float: left;
        padding: 0;
        width: 100%;
      }
    }
  }
}

.multi-selected {
  border: 2px solid rgb(243, 243, 243);
  border-radius: 5px;
  display: inline-flex;
  width: 100px;
  height: 32px;
  padding: 7px;

  input {
    outline: 0
  }
}

.dot-form-close {
  border-radius: 50%;
  background-color: $button-color-red;
  color: white;
  width: 30px;
  height: 30px;
  border: 0;
}

.dot-form-save {
  border-radius: 50%;
  background-color: #2193f1;
  color: white;
  width: 30px;
  height: 30px;
  border: 0;
}

.dot-form-inactive {
  border-radius: 50%;
  background-color: #efefef;
  color: white;
  width: 30px;
  height: 30px;
  border: 1px #d8dbe1 solid;
}

.form-button-header {
  cursor: pointer;
  float: left;
  padding: 8px 5px;

  .icon-close {
    width: 50px;
    height: auto;
  }

  .icon-save, .icon-disable {
    width: 37px;
    margin-top: 7px;
  }
}

.tab-form a {
  color: #e4e7eb;
}

.form-property {
  float: left;
  width: 100%;
  padding: 30px;

  .row-form {
    margin: 0;
    padding-bottom: 10px;
  }

  .form-input-content {
    float: left;
    width: 30%;
    padding-right: 20px;

    .form-input {
      input {
        height: 40px;

      }
    }

    span {
      color: #787878;
    }
  }

  .btn-form-dp {
    width: 100%;
    float: left;

    .btn-inventory-dp {
      height: 40px;
      padding-top: 10px;
      border: 1px #f3f3f3 solid;
    }
  }
}

.build-form-container {
  float: left;
  width: 100%;
  padding: 20px;

  .form-sec {
    float: left;
    width: 100%;
    border: 1.5px dashed #c1c9cd;
    margin-bottom: 15px;
  }
}

.form-section-header {
  padding: 15px;

  label {
    color: $text-color;
  }
}

.form-section-button {
  text-align: center;
  padding: 10px 0 20px 0;
}

.popover-build {
  width: 25%;
  left: 37%;
  height: auto;
  z-index: 200;
  padding: 10px 0 25px 0;
}

.popover-build::after, .popover-build:before {
  left: 50%;
}

.row-build {
  float: left;
  width: 100%;
  padding: 15px 10px 0 32px;

  label {
    color: #9a9a9a;
    font-weight: $font-weight-regular;
  }
}

.box-dashed-content {
  width: 100%;
  float: left;
  padding-right: 20px;
  cursor: pointer;
}

.box-dashed {
  height: 27px;
  border: 1px #e6e6e6 dashed;
  border-radius: 3px;
}

.box-dashed-divided {
  padding-right: 2px;
}

.box-attribute {
  height: 42px;
  border: 1px #f5f6fa dashed;
  border-radius: 5px;
  background-color: #f5f6fa;

  a:hover {
    cursor: pointer;
  }
}

.col-attribute-space {
  padding-right: 20px;

  .box-attribute-content {
    position: relative;
    width: 100%;
    float: left;
  }
}

.row-attribute-space {
  padding-left: 20px;
  padding-bottom: 20px;
}

.form-add-sec {
  float: left;
  width: 100%;
  padding: 15px 0 20px 0;
  text-align: center;

  a:hover {
    color: #4ad991;
    text-decoration: none;
    cursor: pointer;
  }
}

.btn-new-sec {
  background-color: #f5f6fa;
  color: #4ad991;
  border: 1px #4ad991 solid;
  border-radius: 5px;
  padding: 10px;
}

.form-new-attribute {
  float: left;
  padding-right: 20px;

  img {
    width: 18px;
  }
}

.popover-form-attribute {
  width: 30vw;
  padding: 20px;
  z-index: 1;
}

.popover-100::after, .popover-100:before {
  left: 50%;
}

.popover-100 {
  position: absolute;
  left: 64%;
}

.popover-50-1 {
  margin-left: 0;
}

.popover-50-2 {
  margin-left: -103%;
}

.popover-50-1::after, .popover-50-1:before {
  left: 20%;
}

.popover-50-2::after, .popover-50-2:before {
  left: 75%;
}

.popover-33-1 {
  margin-left: 0;
}

.popover-33-2 {
  margin-left: -104%;
}

.popover-33-3 {
  margin-left: -210%;
}

.popover-33-1::after, .popover-33-1:before {
  left: 15%;
}

.popover-33-2::after, .popover-33-2:before {
  left: 50%;
}

.popover-33-3::after, .popover-33-3:before {
  left: 85%;
}

.popover-25-1 {
  margin-left: 0;
}

.popover-25-2 {
  margin-left: -107%;
}

.popover-25-3 {
  margin-left: -213%;
}

.popover-25-4 {
  margin-left: -28%;
  position: absolute;
  left: 13px;
}

.popover-25-1::after, .popover-25-1:before {
  left: 10%;
}

.popover-25-2::after, .popover-25-2:before {
  left: 35%;
}

.popover-25-3::after, .popover-25-3:before {
  left: 62%;
}

.popover-25-4::after, .popover-25-4:before {
  left: 69%;
}

.row-attribute {
  float: left;
  width: 100%;
  padding-bottom: 15px;
  padding-left: 8px;

  .options-btn {
    float: right;
    position: relative;
    display: inline-flex;
    margin-right: 10px;

    .btn-edit-atr {
      background-color: black;
      color: white;
      float: right;
      margin-right: 10px;
    }
  }
}

#id-row-attribute {
  height: 104px;
  overflow-y: hidden;
  position: absolute;
  top: 231px;
  width: 98.5%;
}

#id-row-field {
  height: 125px;
  overflow-y: hidden;
  position: absolute;
  top: 72px;
  width: 98.5%;

  .content {
    display: inline-flex;
    float: left;
    width: 100%;
  }
}

#id-row-attribute-search {
  top: 9px;
  width: 98%;
  left: 12px;
  position: absolute;
}

#id-row-attribute-search-button {
  left: -5px;
  position: relative;
  float: right;
  top: 9px;
}

.form-new-attribute-group {
  float: left;
  width: 100%;
  padding-bottom: 8px;

  img {
    width: 16px;
  }
}

.box-at-content {
  padding-right: 8px;
}

.box-at {
  height: 35px;
  border: 1px #f4f4f4 solid;
  border-radius: 5px;
  padding: 5px 15px;
  width: 100%;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  label {
    color: #afafaf;
    font-weight: $font-weight-regular;
    margin-left: 5px;
    font-size: 11px;
  }
}

.form-search-attribute {
  padding-right: 8px;
  padding-top: 15px;
  position: relative;

  input {
    height: 40px;
  }
}

.form-attribute-label {
  color: black;
  font-weight: 500;
  width: 40%;
}

.form-field-label {
  color: black;
  font-weight: 500;
  width: 40%;
  top: 18px;
  position: absolute;
}

.form-search-img {
  position: absolute;
  top: 25px;
  right: 25px;

  img {
    width: 18px;
  }
}

.title-attribute-selected {
  max-width: 100%;
  margin-bottom: 5px;
  border: none;
  font-weight: 700;

  .icon-option {
    float: right;
    margin-right: 10px;
    margin-top: 10px;
    width: 16px;
  }
}

.box-attribute-selected, .box-attribute {
  height: 42px;
  border: 1px #f4f4f4 solid;
  border-radius: 5px;

  img {
    width: 16px;
  }

  .icon-option {
    float: right;
    margin-right: 10px;
    margin-top: 10px;
  }

  .icon-option:hover {
    cursor: pointer;
  }

  .icon-waste {
    margin-right: 20px !important;
  }

  .icon {
    margin-left: 15px;
    margin-top: 10px;
    float: left;
  }

  label {
    margin-top: 10px;
    margin-left: 10px;
    color: #9e9e9e;
    font-weight: $font-weight-regular;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    width: 70%;
  }
}

.colR16, .colR20, .col16, .col25 {
  .box-attribute-selected, .box-attribute {
    label {
      width: 50%;
    }
  }
}

.col33 {
  .box-attribute-selected, .box-attribute {
    label {
      width: 78%;
    }
  }
}

.col50 {
  .box-attribute-selected, .box-attribute {
    label {
      width: 86%;
    }
  }
}

.colR80 {
  .box-attribute-selected, .box-attribute {
    label {
      width: 90%;
    }
  }
}

.form-tab-new {
  span {
    font-size: 16px;
    padding-left: 5px;
  }
}

.row-form-img {
  float: left;
  width: 100%;
  text-align: center;
  padding: 8% 0;

  .form-img-content {
    display: inline-block;
  }
}

.line-dashed {
  height: 1px;
  width: 80px;
  border: 1.5px #e5e8ec dashed;
  float: right;
  margin: 20px 20px 0 20px;
}

.form-sec-img {
  float: right;
  font-size: 18px;
  position: relative;
}

.popover-sec-menu {
  width: 150px;
  right: -25px;
  top: 30px;
  height: auto;
  z-index: 200;
  padding: 15px 10px 5px 10px;

  .row-sec-build {
    float: left;
    width: 100%;
    padding: 0 10px 10px 10px;

    a {
      color: #acacac;
      float: left;

      span {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 300;
        margin-right: 5px;
      }
    }

    a:hover {
      text-decoration: none;
      cursor: pointer;
    }

    .icon-close {
      width: 30px;
    }

    .icon-success {
      width: 22px;
    }

    .icon-close:hover, .icon-success:hover {
      cursor: pointer;
    }

  }
}

.popover-sec-menu::after, .popover-sec-menu:before {
  left: 110px;
}

.popover-sec-menu.open {
  width: 250px;
}

.popover-sec-menu.open::after, .popover-sec-menu.open:before {
  left: 210px;
}

.form-input {

  input {
    border: 1px solid #f3f3f3;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.form-input input:focus {
  border: 1px solid #4AD991 !important;
  box-shadow: 0 0 1px #4AD991 !important;
  -moz-box-shadow: 0 0 1px #4AD991 !important;
  -webkit-box-shadow: 0 0 1px #4AD991 !important;
}
.comment-finance-right{
  padding-bottom: 20px;
  display: flex;
  align-items: center;

  .comment {
    display: flex;
    border: 1px solid #EFEFEF;
    color: #A4AFB7;
    min-height: 50px;
    position: relative;
    width: 95%;
    margin-right: 17px;

    label{
      display: flex;
      padding: 10px;
      word-wrap: break-word;
      margin: 0;
    }
  }

  .comment:after, .comment:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .comment:after {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: #FFFFFF;
    border-width: 8px;
    margin-top: -8px;
  }

  .comment:before {
    border-color: rgba(215, 218, 226, 0);
    border-left-color: #D7DAE2;
    border-width: 9px;
    margin-top: -9px;
  }

  .initials {
    @include item-center;
    width: 33px;
    height: 33px;
    background-color: #F5F6FA;
    border: 1px solid #D7DAE2;
    border-radius: 16px;
    color: #A4AFB7;
    font-size: 17px;
    font-weight: 600;
  }
}
.comment-finance-left{
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  .comment{
    display: flex;
    border: 1px solid #EFEFEF;
    color: #A4AFB7;
    min-height: 50px;
    position: relative;
    width: 95%;
    flex-direction: row-reverse;
    margin-right: 0;
    margin-left: 17px;

    label{
      display: flex;
      padding: 10px;
      word-wrap: break-word;
      margin: 0;
    }
  }

  .comment:after, .comment:before {
    right: 100% ;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .comment:after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #FFFFFF;
    border-width: 8px;
    margin-top: -8px;
  }

  .comment:before {
    border-color: rgba(215, 218, 226, 0);
    border-right-color: #D7DAE2;
    border-width: 9px;
    margin-top: -9px;
  }

  .initials {
    @include item-center;
    width: 33px;
    height: 33px;
    background-color: #F5F6FA;
    border: 1px solid #D7DAE2;
    border-radius: 16px;
    color: #A4AFB7;
    font-size: 17px;
    font-weight: 600;
  }
}

//Checkbox List attributes
#id-search-form {
  display: inline-flex;
  width: 24%;
  padding: 18px 0 0 0;
}

.btn-container-attribute {
  left: 55px;
  padding: 14px 0 14px 0;
  width: 25%;
  height: 100%;

  button {
    float: left;
    color: white;
    font-size: 17px;
    cursor: pointer;

    i {
      color: #DADDE3;
      margin-left: -12px;
    }
  }

  input {
    height: 32px;
  }
}

#id-input-btn-search {
  width: 100%;
  height: 38px;
  border: 1px solid #DADDE3;
}

.btn-search-text-form {
  background-color: transparent;
  border: none;
  position: relative;
  left: -34px;
}

.btn-attribute-selected {
  border: 1.5px #388ed5 solid;
  color: #388ed5;
  font-size: 16px;
  padding: 1px 8px;
  height: 25px;
}

.icon-message-attribute {
  float: left;
}

#img-attrib-options {
  background-image: url('../../../public/assets-images/ic_swap_horiz_24px.png');
  background-repeat: no-repeat;
  cursor: pointer;
  height: 20px;
  background-size: 20px;
  background-position: center;
  margin-top: 11px;
}

#img-create-attrib-options {
  background-image: url('../../../public/assets-images/waste-can.png');
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  background-size: 30px;
  background-position: center;
  margin-top: 10px;
}

.text-attribute-option {
  font-size: 9px;
  margin-bottom: 4px;
}

.label-attribute-option {
  font-size: 12px;
  margin-top: 5px;
  color: #787878;
}

#border-attribute-option, #border-attribute-info {
  border: 1px solid rgb(239, 239, 239);
  border-radius: 6px;
  margin-top: 55px;
  margin-bottom: 10px;
  height: 44px;

  .view-type-option {
    width: 90%;
    font-size: 11px;
    margin-top: 11px;
  }
}

.radio-attribute-options {
  border: 0;
  width: 3%;
  height: 21px;
  text-align: left;
}

.tags-attribute-option {
  font-size: 11px;
  color: #9B9B9B;
  border-bottom: 0 !important;
}

.format-square-setting-button-add {
  background-color: #4ad991;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  border-radius: 0;
  width: 35px;
  height: 35px;
}

.form-input {
  .attribute-control {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border-radius: 8px;
  }

  .attribute-control:focus {
    -webkit-box-shadow: rgb(74, 217, 145);
    box-shadow: rgb(74, 217, 145);
  }
}

.btn {
  .btn-secondary {
    .btn-sm {
      background-color: black;
      color: white;
    }
  }
}

.modal-label-attribute-option {
  font-size: 10px;
  margin-top: 5px;
  color: #787878;
}

.modal-switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 21px;
  float: right;
}

.modal-check-label-on {
  color: #fff;
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 9px;
  font-weight: $font-weight-regular;
  cursor: pointer;
}

.modal-check-label-off {
  color: #fff;
  position: absolute;
  top: 6px;
  right: 5px;
  font-size: 10px;
  font-weight: $font-weight-regular;
  cursor: pointer;
}

.modal-col-setting-option {
  float: right;
  width: 25%;
}

.container-no-messages {
  height: 190px;
  padding: 30px;
}

.container-icon-no-messages {
  width: 60px;
  margin: auto;
  height: 60px;
}

.container-notify-no-messages {
  width: 160px;
  margin: auto;
  padding-top: 20px;
  height: 50px;
}

.notify-no-messages {
  color: rgb(165, 176, 184);
  font-size: 19px;
}

/******* FINISH SETTING ******/

/*******BEGIN CSS To AttributeEditor********/
.contentDiv {
  display: flex;
  width: 79%;
  height: 100%;
  padding-top: 18px;
  padding-bottom: 25px;
}

.contentFontFamily {
  border: 1px solid #efefef;
  border-radius: 6px;
  height: 32px;
}

.selFontFamily {
  font-family: $font-family;
  font-size: 15px;
  width: 150px;
  height: 29px;
  border-color: transparent;
}

.selFontFamily:focus {
  outline: 0;
}

.contentFontSize {
  position: relative;
  display: inline-block;
  height: 32px;
  padding: 4px 0 0 7px;
  width: 40px;
  cursor: pointer;

  .btn-expand-more {
    opacity: 0.8;
    padding: 0 0 0 2px;
    width: 12px;
  }
}

.contentOfFontSize {
  margin-top: 1px;
  padding-right: 3px;
}

.imgSeparatorImage {
  border: none;
  width: 5px;
  height: 30px;
}

.btnStrikethrough {
  background-image: url('../../../public/assets-images/strikethrough.png');
  background-repeat: no-repeat;
  border: none;
  width: 30px;
  background-position: center;
  background-color: transparent;
}

.btnStrikethrough-imgSourceStrikethrough {
  background-image: url('../../../public/assets-images/strikethroughpress.png');
  background-repeat: no-repeat;
  border: none;
  width: 30px;
  background-position: center;
  background-color: transparent;
}

.btnStrikethrough, .btnStrikethrough-imgSourceStrikethrough:focus {
  outline: 0;
}

.btnBoldImage {
  background-image: url('../../../public/assets-images/bold.png');
  background-repeat: no-repeat;
  border: none;
  width: 30px;
  background-position: center;
  background-color: transparent;
}

.btnBoldImage, .btnBoldImage-imgSourceBold:focus {
  outline: 0;
}

.btnBoldImage-imgSourceBold {
  background-image: url('../../../public/assets-images/boldpress.png');
  background-repeat: no-repeat;
  border: none;
  width: 30px;
  background-position: center;
  background-color: transparent;
}

.btnItalicImage {
  background-image: url('../../../public/assets-images/italic.png');
  background-repeat: no-repeat;
  border: none;
  width: 30px;
  background-position: center;
  background-color: transparent;
}

.btnItalicImage-imgSourceItalic {
  background-image: url('../../../public/assets-images/italicpress.png');
  background-repeat: no-repeat;
  border: none;
  width: 30px;
  background-position: center;
  background-color: transparent;
}

.btnItalicImage, .btnItalicImage-imgSourceItalic:focus {
  outline: 0;
}

.btnUnderlineImage {
  background-image: url('../../../public/assets-images/underline.png');
  background-repeat: no-repeat;
  border: none;
  width: 30px;
  background-position: center;
  background-color: transparent;
}

.btnUnderlineImage-imgSourceUnderline {
  background-image: url('../../../public/assets-images/underlinepress.png');
  background-repeat: no-repeat;
  border: none;
  width: 30px;
  background-position: center;
  background-color: transparent;
}

.btnUnderlineImage, .btnUnderlineImage-imgSourceUnderline:focus {
  outline: 0;
}

.btnAlignLeft {
  background-image: url('../../../public/assets-images/left.png');
  background-repeat: no-repeat;
  border: none;
  width: 30px;
  background-position: center;
  background-color: transparent;
}

.btnAlignLeft-imgSourceLeft {
  background-image: url('../../../public/assets-images/leftpress.png');
  background-repeat: no-repeat;
  border: none;
  width: 30px;
  background-position: center;
  background-color: transparent;
}

.btnAlignLeft, .btnAlignLeft-imgSourceLeft:focus {
  outline: 0;
}

.btnAlignCenter {
  background-image: url('../../../public/assets-images/center.png');
  background-repeat: no-repeat;
  border: none;
  width: 30px;
  background-position: center;
  background-color: transparent;
}

.btnAlignCenter-imgSourceCenter {
  background-image: url('../../../public/assets-images/centerpress.png');
  background-repeat: no-repeat;
  border: none;
  width: 30px;
  background-position: center;
  background-color: transparent;
}

.btnAlignCenter, .btnAlignCenter-imgSourceCenter:focus {
  outline: 0;
}

.btnAlignRight {
  background-image: url('../../../public/assets-images/right.png');
  background-repeat: no-repeat;
  border: none;
  width: 30px;
  background-position: center;
  background-color: transparent;
}

.btnAlignRight-imgSourceRight {
  background-image: url('../../../public/assets-images/rightpress.png');
  background-repeat: no-repeat;
  border: none;
  width: 30px;
  background-position: center;
  background-color: transparent;
}

.btnAlignRight, .btnAlignRight-imgSourceRight:focus {
  outline: 0;
}

.DraftEditor-root {
  border: 1px solid #efefef;
  border-radius: 6px;
  width: 79%;
}

.DraftEditor-editorContainer {
  overflow-y: auto;
  width: 95%;
  height: auto;
  padding: 10px;
}

.public-DraftEditor-content {
  height: 120px;
}

.btnSizeImage {
  border: none;
  cursor: pointer;
  width: 18.5px;
  opacity: 0.8;
  padding-right: 1px;
  background-position: center;
  background-color: transparent;
}

.btnSizeImage:focus {
  outline: 0;
}

.list-fontsize {
  max-height: 300px;
  position: fixed;
  z-index: 100;
  background-color: #fff;
  top: 37px;
  border: 1px solid rgb(218, 221, 227);
  border-top-width: 0;
  border-image: initial;
  width: 150px;
  border-radius: 0 0 5px 5px;
  box-shadow: rgb(233, 233, 234) 0 15px 20px 0;
  color: rgb(137, 137, 137);
  height: 120px;
}

#list-name-font-size {
  top: -128px;
}

.list-name-fontsize {
  max-height: 320px;
  overflow: auto;
  height: 120px;
}

.change-fontsize:hover {
  background-color: rgb(238, 238, 238);
}

.change-fontsize {
  list-style: none;
  cursor: pointer;

}

.list-name-fontsize u {
  text-decoration: none;
}

.list-name-fontsize u li {
  padding-left: 13px;
}

.list-sort {
  position: absolute;
}

.checkFontSize {
  background-image: url('../../../public/assets-images/check_black.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 20px;
}

/*******END CSS To MyEditor Up********/
/*******BEGIN CSS To MyEditor Down********/
.DraftEditor-rootContainer {
  .DraftEditor-root {
    width: 100%;

    .DraftEditor-editorContainer {
      width: 100%;
      padding-top: 13px;
      padding-bottom: 13px;
      padding-left: 33px;
      height: 190px;
      padding-right: 23px;
      color: #4D4F5C;
      line-height: 17px;
      font-family: $font-family;
    }
  }
}

.contentDivDown {
  display: flex;
  width: 79%;
  height: 65px;
  padding-top: 15px;
  padding-bottom: 39px;
  margin-top: -3px;
  margin-left: -1px;
  padding-left: 33px;
  border: 1px solid rgb(214, 215, 221);
  background-color: white !important;
  border-right: none;
  border-radius: 0 0 0 10px;
}


.font-family:hover {
  color: #0096f8 !important;
}

.btnSizeImage:hover {
  fill: #0096f8 !important;
}

.btn-format-stk:hover {
  fill: #0096f8 !important;
}

.btn-format-bold:hover {
  fill: #0096f8 !important;
}

.btn-format-italic:hover {
  fill: #0096f8 !important;
}

.btn-underline:hover {
  fill: #0096f8 !important;
}

.btn-align:hover {
  fill: #0096f8 !important;
}

.btn-attach-file:hover {
  fill: #0096f8 !important;
}

.btn-image-photography:hover {
  fill: #0096f8 !important;
}

/*******END CSS To MyEditor Down********/
/*******View Applicant********/


#bodyToPrint {
  top: 84px;
  left: 0;
  position: absolute;
  width: 98.5%;
}

.container-dp-action-finance {
  position: relative;
  padding: 30px 10px 15px 110px;
  float: left;

  a {
    i {
      padding-top: 3px !important;
    }
  }
}

.row-table-finance {
  padding: 10px 0;
}

.container-header-view-app {
  display: flex;
  align-items: center;
  border-bottom: 1px #cacaca solid;
  height: 75px;

  .header-view-app-left {
    padding: 0 20px;
    color: #000000;
    width: 40%;
  }

  a {
    i {
      float: right;
      padding-top: 3px;
    }
  }

  .header-view-app-right {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;

    .label-assign {
      color: #787878;
    }

    .icons-options {
      display: flex;

      .button-customer-info {
        @include item-center;
        border-radius: 4px;
        border: 1px solid #2194f1;
        width: 109px;
        background-color: #2194f1;
        color: white;
        height: 32px;
        cursor: pointer;
        margin-left: 10px;
      }

      .icon-user-container {
        .btn-promote {
          height: 32px;
          width: 170px;
          border-radius: 4px;
          font-size: 13px;
          border: 1px $light-blue-color-1 solid;
          background-color: $light-blue-color-1;
          color: #ffffff;
          display: flex;
          padding: 1px 5px;
          margin-left: 15px;
          justify-content: center;
          align-items: center;
          font-family: $font-family;
          font-weight: $font-weight-regular;
        }
      }
    }

    .save-button {
      margin-left: 10px;
      background-color: $green-color;
      color: #fff;
      width: 67px;
      height: 32px;
      text-align: center;
      padding: 5px;
      border: 1px $green-color solid;
      border-radius: 4px;
      font-size: 13px;
      cursor: pointer;
      font-family: $font-family;
      font-weight: $font-weight-regular;
    }

    .icon-info {
      height: 30px;
    }

    .header-add-user {
      fill: #ffffff;
      cursor: pointer;
      padding: 0 5px 0 0;
      display: flex;
      align-items: center;

      svg {
        height: 16px;
        width: 16px;
        margin-top: 2px;
      }
    }


    .header-icon-print:hover {
      fill: #0096f8;
    }

    .header-download-icon:hover {
      fill: #0096f8;
    }

    .header-icon-print {
      fill: #939393;
      cursor: pointer;
      padding-left: 15px;
      display: flex;
      align-items: center;

      svg {
        height: 16px;
      }
    }

    .header-download-icon {
      fill: #939393;
      cursor: pointer;
      padding-left: 15px;
      display: flex;
      align-items: center;

      svg {
        height: 16px;
      }
    }
  }

  .viewApplicantToPrint {
    display: none;
    float: left;
    width: 100% !important;
    padding: 0 30px 30px;
  }

  .header-view-app-right-sale {
    width: 100%;

    .icons-options .button-customer-info {
      width: 130px;
    }
  }

  .container-change-salesman {
    a {
      display: flex;
      align-items: center;
      padding: 5px 3px 5px 5px;

      .user-img {
        height: 20px;
        width: 20px;
        object-fit: cover;
        border-radius: 50%;
        box-shadow: 0 0 10px #A4AFB7B5;
      }

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-left: 5px;
        margin-left: 0;
      }

      .not-select-user {
        width: calc(100% - 17px);
      }

      .select-user {
        width: calc(100% - 37px);
      }
    }
  }
}

/*******END Header View Applicant********/


/******* START SETTING TEMPLATE *************/

.template-container {
  width: 100%;
  float: left;
  padding: 50px 20px 50px 50px;

  .template-row {
    float: left;
    width: 100%;
    display: flex;
    padding-bottom: 30px;
  }

  .template-col {
    width: 33.33%;
    float: left;
    padding-right: 30px;

    .template-box {
      float: left;
      width: 100%;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 30px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      height: 100%;
    }

    .col-image {
      border-bottom: 1px solid #eff0f3;
      height: 80%;
    }

    .col-detail {
      float: left;
      width: 100%;
      padding: 15px;
      height: 20%;
    }

    .template-info {
      float: left;

      .label-name {
        margin: 0;
        padding-top: 5px;
        color: #bdbec2;
        font-weight: 300;
        font-size: 18px;
      }

      .label-active {
        margin: 0;
        color: #4ed4a2;
        font-weight: 300;
        float: left;
        width: 100%;
        font-size: 12px;
      }
    }

    .template-btn {
      float: right;

      .btn-select {
        border: 1px solid #57a3d0;
        border-radius: 5px;
        color: #57a3d0;
      }

      .btn-select:hover {
        background-color: #4ad991;
        border: 1px solid #4ad991;
        color: #ffffff;
        cursor: pointer;
      }

      .btn[disabled] {
        opacity: 1;
      }

      .btn-select-active {
        background-color: #4ad991;
        border: 1px solid #4ad991;
        color: #ffffff;
        cursor: default;
      }

      .btn-customize {
        background-color: #0093f8;
        border: 1px solid #0093f8;
        color: #ffffff;
        cursor: pointer;
        margin-right: 5px;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.setting-container-template {
  float: left;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  height: 720px;

  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: white;
    z-index: 200;
  }
}

.template-customize {
  width: 25%;
  float: left;
  border-right: 1px solid #dedfe2;

  .tabs {
    height: 100%;

    .tab-content {
      overflow: auto;
      max-height: calc(100% - 35px);
      height: calc(100% - 35px);
    }
  }

  .tab-hide{
    .checkbox{
      padding-left: 2px;
      >div{
        align-items: center;

        >div{
          height: 15px !important;
          width: 15px !important;
        }
      }
    }
    .a{
      fill: none;
    }

    .icon-check, .icon-uncheck{
      height: 15px;
      width: 15px;
      fill: #CACACA !important;
    }
    label{
      color: #3B86FF !important;
      font-family: 'Open Sans', san-serif !important;
      font-size: 10px;
      font-weight: 300;
      padding-left: 0px;
    }
  }

  .row-template {
    float: left;
    width: 100%;
    border-bottom: 1px solid #EFEFEF;
    height: 50px;
    cursor: pointer
  }

  .btn-setting-sec {
    float: left;
    width: 100%;
    padding: 8px 10px;
  }

  .template-image-style {
    width: 20px;
    fill: #dddee0;
  }

  .icon-go-back {
    width: 20px;
    fill: #EFEFEF;
  }

  .icon-go-back-active {
    width: 20px;
    fill: #dddee0;
  }

  .btn-setting-back {
    padding: 15px 10px;
    cursor: pointer;
    width: 50px;
    border-right: 1px solid #EFEFEF;
    height: inherit;
    float: left;
  }

  .arrow-next {
    width: 20px;
    fill: #A4AFB7;
  }

  .icon-menu {
    width: 18px;
    height: 18px;
    fill: #a4afb7;
  }

  .icon-menu-content {
    float: left;
    padding: 10px 3px 6px 3px;
  }

  .menu-list {
    color: #A4AFB7;
    height: inherit;
    width: 100%;
    display: inline-block;
    padding: 15px;

    label {
      font-weight: $font-weight-regular;
    }

    label:hover {
      cursor: pointer;
    }
  }

  .icon-arrow {
    float: right;
  }

  .row-sec {
    width: 100%;
    float: left;
    padding: 0 20px 5px 20px;

    .btn-message-dp {
      width: 100%;
      display: flex;
      border: 1px #EFEFEF solid;
    }

    .border-sec {
      border-radius: 5px 5px 0 0;
    }
  }

  .title-sec {
    float: left;
    padding: 10px 20px;
    color: #A4AFB7;
  }

  .col-sec {
    padding: 6px 10px;
    border-left: 1px solid #EFEFEF;
    width: 100%;
  }

  .label-sec {
    float: left;
    margin: 0;
    color: #A4AFB7;
    font-weight: $font-weight-regular;
    padding-top: 4px;
  }

  .icon-action-content, .icon-action-content-delete, .icon-action-edit {
    float: right;
    padding-left: 6px;
    padding-top: 6px;
    width: 12%;
  }

  .icon-action-content-delete {
    width: 20px;
    fill: $button-color-red;
  }

  .icon-action-content:hover, .icon-action-edit:hover {
    cursor: pointer;

    .icon-action {
      fill: #4AD991
    }
  }

  .icon-action-content-delete:hover {
    cursor: pointer;

    .icon-action {
      fill: $button-color-red;
    }
  }

  .icon-action {
    width: 16px;
    height: 16px;
    fill: #A4AFB7;
  }

  .icon-plus-content {
    float: left;
    text-align: center;
    width: 100%;
    padding: 10px 0;
  }

  .icon-plus {
    width: 24px;
    height: 24px;
  }

  .header-tab {
    float: left;
    padding: 15px 10px;

    label {
      margin: 0;
      color: #A4AFB7;
      font-weight: $font-weight-regular;
      font-size: 12px;
    }
  }

  .logo-action-content {
    float: right;
    padding-left: 10px;
  }

  .logo-action-content:hover {
    cursor: pointer;
  }

  .logo-action {
    width: 16px;
    height: 16px;
    fill: #A4AFB7;
  }

  .logo-info {
    float: left;
    width: 100%;
    padding: 10px 0;
  }

  .logo-info-height {
    float: left;
    padding-right: 10px;
    display: flex;

    label {
      float: left;
      margin: 0;
      padding: 5px 10px 0 0;
      color: #979797;
      font-weight: 300;
      font-size: 12px;
      width: 51px;
    }

    .btn-message-dp {
      padding: 5px;
      color: #979797;
      font-weight: $font-weight-regular;
      float: left;
      border: 1px #EFEFEF solid;
      font-size: 12px;
      width: 46px;
      position: relative;

      .icon-percent {
        position: absolute;
        color: #979797;
        font-size: 12px;
        right: 5px;
      }
    }

    > div {
      display: flex;
    }

    .info-label .title {
      width: auto;
    }

    .info-box {
      width: 70px;
    }

    span {
      color: #979797;
      font-size: 12px;
      padding-top: 5px;
      padding-left: 5px;
    }
  }

  .logo-info-icon {
    float: right;
  }

  .row-title {
    float: left;
    width: 100%;
    padding: 0 20px 20px 20px;
  }

  .browser-icon {
    float: left;
    padding-top: 5px;
  }

  .row-color-default {
    float: left;
    width: 100%;
    padding: 12px 20px 6px 20px;
    border-bottom: 1px solid #EFEFEF;

    .label-color-default {
      float: left;
      margin: 0;
      color: #A4AFB7;
      font-weight: 300;
    }

    .color-switch {
      float: right;

      label {
        margin: 0;
      }
    }
  }

  .body-colors {
    float: left;
    width: 100%;
    position: relative;

    .color-options-container {
      height: 95px;
      display: flex;
      padding: 15px 12px 0 12px;
      position: relative;
      width: 100%;

      .color-options-border {
        justify-content: flex-end;
        border: 1px solid #EFEFEF;
        border-radius: 9px;
        flex-direction: column;
        display: flex;
        flex: 1;

        .color-options {
          display: flex;
          align-items: center;
          padding: 0 8px 0 8px;
          justify-content: space-between;

          .color-option {
            height: 25px;
            width: 17%;
            border-radius: 3px;

            &:hover {
              cursor: pointer;
            }
          }
        }

        .select-buttons {
          display: flex;
          justify-content: space-between;
          padding-top: 5px;

          .color-cancel-button {
            color: $button-color-red;
            box-shadow: none !important;
          }

          .color-select-button {
            color: #4AD991;
            box-shadow: none !important;
          }
        }
      }
    }
  }

  .row-color {
    float: left;
    width: 100%;
    padding: 10px 20px 0 20px;
    position: relative;

    .color-button {
      height: 27px;
      float: right;
      width: 50px;
      border-radius: 5px;

      &:hover {
        cursor: pointer;
      }
    }

    .label-color {
      float: left;
      margin: 0;
      color: #A4AFB7;
      font-weight: 300;
      padding-top: 5px;
    }
  }

  .color-palette {
    float: left;
    width: 100%;
    padding: 10px 0;

    .sketch-picker {
      width: initial !important;
      margin: 0 20px;
    }
  }

  //.row-typo {
  //  float: left;
  //  width: 100%;
  //  padding: 15px 20px 0 20px;
  //
  //  .btn-setting-typo {
  //    float: left;
  //    width: 100%;
  //
  //    .btn-message-dp {
  //      width: 100%;
  //      padding: 10px;
  //      border: 1px solid #EFEFEF;
  //      span {
  //        float: left;
  //        color: #A4AFB7;
  //      }
  //      i {
  //        float: right;
  //      }
  //    }
  //  }
  //
  //  .label-typo {
  //    color: #A4AFB7;
  //    font-weight: 300;
  //    margin: 0;
  //    font-size: 12px;
  //  }
  //  .select{
  //    float: left;
  //    width: 100%;
  //    border: 1px solid #EFEFEF;
  //    border-radius: 5px;
  //    height: 34px;
  //    align-items: center;
  //    display: flex;
  //    padding-left: 10px;
  //    position: relative;
  //    &:hover{
  //      cursor: pointer;
  //      label{
  //        cursor: pointer;
  //      }
  //    }
  //  }
  //  .container-label label{
  //    margin: 0;
  //    font-weight: 400;
  //    font-size: 12px;
  //    color: #A4AFB7;
  //  }
  //  .container-icon{
  //    position: absolute;
  //    right: 10px;
  //    i{
  //      color: #A4AFB7;
  //    }
  //  }
  //  div{
  //    .label-make{
  //      color: #A4AFB7;
  //      font-size: 12px;
  //    }
  //  }
  //}

  //.popover-for-select{
  //  overflow: auto;
  //  max-height: 145px;
  //  box-shadow: 0 1px 6px rgba(0,0,0,.12), 0 1px 4px rgba(0,0,0,.12);
  //  border-radius: 2px;
  //  padding: 5px 0;
  //}
  //
  //.popover-body-for-select{
  //  padding: 0;
  //  margin: 0;
  //  color: #979797;
  //  z-index: 100;
  //  position: relative;
  //  .option-label-for-select{
  //    font-size: 13px;
  //    font-weight: 400;
  //  }
  //  .container-option-for-select, .container-option-for-select-checked{
  //    padding: 0 10px;
  //    &:hover{
  //      cursor: pointer;
  //      label{
  //        cursor: pointer;
  //      }
  //    }
  //  }
  //}

  .row-menu {
    width: 100%;
    float: left;
    padding: 0 20px 8px 20px;

    .btn-message-dp {
      width: 100%;
      display: flex;
      border: 1px solid #EFEFEF;
    }

    .border-link {
      border-radius: 5px 5px 0 0;
    }

    .row-menu-link {
      float: left;
      width: 100%;
      padding: 5px 10px 10px 10px;
      border-left: 1px solid #EFEFEF;
      border-right: 1px solid #EFEFEF;
      border-bottom: 1px solid #EFEFEF;
      border-radius: 0 0 5px 5px;
    }

    .header-link {
      float: left;
      width: 100%;
      text-align: center;
    }

    .label-link {
      margin: 0;
      font-size: 9px;
      font-weight: $font-weight-regular;
      color: #bec1c5;
      padding: 0 5px 10px 5px;
    }

    .label-link:hover {
      cursor: pointer;
    }

    .link-active {
      color: #5aa0c7;
    }

    //.icon-edit {
    //  fill: #4AD991;
    //}
    //
    //.icon-delete {
    //  fill: #fc9a9c;
    //  width: 16px;
    //  height: 16px;
    //}

    .icon-delete:hover {
      cursor: pointer;
    }

    .btn-menu-link {
      float: left;
      width: 100%;

      .btn-message-dp {
        padding: 7px;
        display: block;
        border: 1px solid #EFEFEF;

        span {
          float: left;
          color: #A4AFB7;
        }

        i {
          float: right;
          padding: 3px 3px 0 0;
        }
      }
    }
  }

  .body-header {
    float: left;
    width: 100%;
    overflow: auto;
    height: 579px;
  }

  .row-social {
    width: 100%;
    float: left;
    padding: 0 20px 8px 20px;

    .btn-message-dp {
      width: 100%;
      border: 1px solid #EFEFEF;
    }

    .col-social {
      float: left;
      padding: 6px 8px;
    }

    .icon-facebook {
      width: 18px;
      height: 18px;
      fill: #0097e4;
    }

    .icon-instagram {
      width: 18px;
      height: 18px;
      fill: #9e4b95;
    }

    .icon-twitter {
      width: 18px;
      height: 18px;
      fill: #8fd4ff;
    }

    .icon-social-content {
      float: left;
      padding: 10px 0 6px 0;
      margin-left: -3px;
    }

    .icon-delete-content {
      float: right;
      padding: 10px 10px 8px 10px;
    }

    .icon-delete-content:hover {
      .icon-action {
        fill: #fc9a9c
      }
    }

    .label-new-window {
      float: left;
      margin: 0;
      color: #bec1c5;
      font-weight: 300;
    }

    .window-switch {
      float: right;
    }

    .label-social {
      float: left;
      margin: 0;
      color: #bec1c5;
      font-weight: 300;
      padding-top: 4px;
      font-size: 13px;
    }
  }

  .body-banner {
    float: left;
    width: 100%;
    padding: 10px 20px 0 20px;
    overflow: auto;
    max-height: 620px;
  }

  .container-footer {
    .container-label {
      padding: 10px 20px;

      label {
        margin-bottom: 0;
        font-weight: 500;
        color: #A4AFB7;
      }
    }

    .body-sec-details {
      .row-sec-info {
        border-bottom: none
      }
    }

    .container-label {
      display: flex;
      align-items: center;
      position: relative;

      .sec-switch {
        height: 26px;
        position: absolute;
        right: 20px;
      }
    }

    .first-column {
      float: left;
      width: 100%;
    }

    .second-column {
      float: left;
      width: 100%;
    }

    .third-column {
      float: left;
      width: 100%;
    }
  }

  .row-banner {
    float: left;
    width: 100%;
    padding-bottom: 20px;

    .img-banner-main {
      border: 1px solid #EFEFEF;
      border-radius: 10px;
      text-align: center;
      width: 100%;
      float: left;

      .img-main {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: contain;
      }

      .img-second {
        border-radius: 8px;
      }
    }

    .img-banner-main-not-available {
      text-align: left;
      width: 100%;
      float: left;

      .overlapped-not-available {
        color: #A4AFB7;
        font-weight: 300;
        font-size: 11px;
        margin-left: 15px;
      }
    }

    .label-banner {
      color: #A4AFB7;
      font-weight: $font-weight-regular;
      margin: 0;
      padding-bottom: 5px;
    }

    .span-banner {
      color: #A4AFB7;
      padding-bottom: 10px;
      font-size: 11px;
      float: left;
      font-weight: 300;
    }

    .banner-info {
      float: left;
      width: 100%;
      padding: 10px 0 0 0;
    }

    .banner-info-opacity {
      float: left;
      width: 56%;
    }
  }

  .row-sec-details {
    .label-sec {
      color: #bec1c5;
      font-weight: 300;
      margin: 0;
      padding-bottom: 3px;
      padding-top: 10px;
      font-size: 11px;
    }

    .sec-switch {
      float: right;
      padding-top: 8px;

      .switch {
        margin: 0;
      }
    }
  }

  .body-main {
    float: left;
    width: 100%;
    overflow: auto;
    height: 579px;
  }

  .row-sec-info {
    float: left;
    width: 100%;
    padding: 0 10px 10px 10px;
    border-bottom: 1px solid #EFEFEF;
  }

  .row-step {
    float: left;
    width: 100%;
    padding: 5px 10px 0 10px;

    .btn-message-dp {
      margin-bottom: 7px;
      border: 1px solid #EFEFEF;
    }

    .icon-step {
      height: 14px;
      width: 14px;
      fill: #A4AFB7;
    }

    .icon-menu-content {
      padding: 9px 0 0 3px;

      .icon-menu {
        width: 14px;
        height: 14px;
        fill: #A4AFB7;
      }
    }

    .icon-step-content {
      float: left;
      padding: 9px 7px 0 0;
    }

    .col-sec {
      padding: 3px 0;

      .panel-left {
        width: 78%;
        padding: 0 10px;
        float: left;

        .title-sec, .description-sec {
          margin: 5px 0;
          float: left;
          width: 100%;
          font-size: 12px;
          font-weight: 400;
          color: #979797;
          padding: 0;
        }

        .description-sec {
          margin: 10px 0 5px 0;
        }
      }

      .panel-right {
        width: 22%;
        height: 100%;
        float: left;

        .icon-action-content, .icon-action-edit {
          width: 100%;
          align-items: flex-end;
          display: flex;
          height: 50%;
          justify-content: center;
          position: relative;
        }

        .icon-action-edit {
          align-items: flex-start;
        }
      }
    }

    .label-sec {
      font-weight: 300;
      font-size: 11px;
      padding-bottom: 5px;
    }
  }

  .img-close {
    float: right;
    width: 42px;
    margin-top: -5px;
  }

  .img-success {
    float: right;
    width: 32px;
  }

  .label-page {
    color: #A4AFB7;
    margin-right: 30px;
  }

  .menu-item-setting {
    color: #A4AFB7 !important;
  }

  .form-control {
    color: #787878;
    font-weight: $font-weight-regular;
  }

  .input-left {
    width: 49%;
    float: left;
  }

  .input-right {
    width: 49%;
    float: right;
  }

  .input-header-footer {
    display: inline-block;
    border: none;
    width: 80%;
    margin: 0;
    font: inherit;
    outline: none; /* remove focus ring from Webkit */
    line-height: 1.2;
    -webkit-appearance: none !important; /* remove the strong OSX influence from Webkit */
    -webkit-box-shadow: none !important;
    box-shadow: none;
    color: #A4AFB7 !important;
    font-weight: $font-weight-regular;
  }

  .label-line-footer {
    width: 100%;
  }

  .btn-action {
    float: right;

    .btn-success {
      color: #fff;
      font-size: 12px;
      background-color: #4AD991;
      border-radius: 5px;
      height: 27px;
      width: 45px;
      border: none #4AD991;
    }

    .btn-close {
      font-size: 12px;
      color: #fff;
      background-color: $button-color-red;
      border-radius: 5px;
      height: 27px;
      width: 45px;
      border: none $button-color-red;
      margin-right: 5px;


    }
  }

  .row-template-finance {
    float: left;
    width: 100%;
    padding: 10px 20px 0 20px;

    .label-form-view {
      label {
        margin: 0;
        font-size: 10px;
        color: #A4AFB7;
      }
    }

    .btn-form-view {
      float: left;
      width: 100%;
      padding: 5px 0;

      .btn-message-dp {
        width: 100%;

        .label-page {
          float: left;
          padding-left: 5px;
        }

        i {
          float: right;
          padding-top: 3px;
          color: #A4AFB7;
        }
      }
    }
  }

  .row-template-sm {
    border: 0;
    padding: 15px;
    height: auto;
    cursor: inherit;

    .social-media-title {
      color: #A4AFB7;
      height: inherit;
      width: 100%;
      font-weight: $font-weight-regular;
      margin-bottom: 10px;
    }

    .btn-message-dp {
      float: left;
      width: 100%;
      height: 40px;
      padding: 8px 3px;
      border-radius: 5px;
      border-color: #EFEFEF;
      margin-bottom: 10px;
    }

    .icon-menu-content {
      padding: 3px 0 0 0;
    }

    .col-sec {
      padding: 0;
      border-left: 0;
      position: relative;
    }

    .input-header-footer {
      height: 24px;
      padding: 0 0 0 7px;
    }

    .icon-action-content-delete {
      width: auto;
      padding: 0;
      position: absolute;
      right: 4px;
      top: 4px;
    }

    .icon-menu-facebook {
      width: 18px;
      height: 18px;
      fill: #2194F1;
    }

    .icon-menu-instagram {
      width: 18px;
      height: 18px;
      fill: #AF02B0;
    }

    .icon-menu-twitter {
      width: 18px;
      height: 18px;
      fill: #9FD4FF;
    }

    .icon-action {
      fill: #EFEFEF;
    }

    .row-social {
      padding: 5px 0 0 0;
    }
  }
}

.input-steps {
  float: left;
  width: 100% !important;
}

.message-template {
  left: 45%;
  top: 50%;
}

.comments-edit {
  color: #979797;
  width: 100%;
  font-size: 10px;
  border: 1px solid #CCC;
  padding: 6px 12px;
  border-radius: 4px;
  background-color: transparent;
  outline: none;
  resize: none;
  margin-left: 0;
  font-weight: $font-weight-regular;
}

.template-view {
  //transform: scale(0.88);
  //width: 80%;
  transform: scale(0.98);
  width: 75%;
  float: left;
  overflow: auto;
  position: relative;

  .layout-footer {
    .container-footer-background {
      .footer-right {
        .title-one, .title-two {
          padding-left: 30px;
        }
      }

      .footer-left {
        padding-left: 5%;
        padding-right: 5%;
      }
    }
  }


  .layout-inventory-container {
    height: inherit;

    .layout-car {
      padding: 0;
      @include col100;

      .layout-car-bg {
        padding: 0 40px 0 40px;
        position: relative;
        float: left;
        width: 100%;

        .layout-car-content {

          .layout-car-filter {
            float: left;
            width: 25%;
            padding: 70px 0 0 10px;
          }

          .layout-car-body {
            float: left;
            width: 75%;
            padding-top: 50px;
            padding-bottom: 30px;
          }
        }
      }
    }
  }


  .icon-expand-more {
    float: right;
    transform: rotate(180deg);

    svg {
      fill: #CACACA;
      height: 8px;
    }
  }

  .banner-container {
    .banner-title {
      padding: 0 10% 0 10% !important;
      text-align: left;

      h1 {
        font-size: 44px !important;
        word-break: break-word;
        width: 100%;
      }
    }

    .btn-search-car {
      left: 37%;
    }
  }

  .navbar-default {
    background-color: transparent;
    border-color: transparent;
    float: left;
  }

  .container-form-get-in-touch {
    .title-get-in-touch {
      font-size: 55px !important;
      word-break: break-word;
    }
  }

  .icon-message {
    padding-left: 0;
  }

  .carousel-content-text-0 {
    height: 140px !important;
    width: calc(100% - 49px) !important;
    padding-left: 55px !important;
  }

  .carousel-content-text-1 {
    height: 150px !important;
    width: calc(100% - 54px) !important;
    padding-left: 65px !important;
  }

  .carousel-content-text-2 {
    height: 160px !important;
    width: calc(100% - 59px) !important;
    padding-left: 75px !important;
  }

  .carousel-content-text-3 {
    height: 150px !important;
    width: calc(100% - 54px) !important;
    padding-left: 65px !important;
  }

  .carousel-content-text-4 {
    height: 140px !important;
    width: calc(100% - 49px) !important;
    padding-left: 55px !important;
  }

  .carousel-content-text-5 {
    height: 130px !important;
    width: calc(100% - 44px) !important;
    padding-left: 45px !important;
  }

  .carousel-content-image-0 {
    padding-top: 22px !important;
    left: -49px !important;
  }

  .carousel-content-image-1 {
    padding-top: 22px !important;
    left: -54px !important;
  }

  .carousel-content-image-2 {
    padding-top: 22px !important;
    left: -59px !important;
  }

  .carousel-content-image-3 {
    padding-top: 22px !important;
    left: -54px !important;
  }

  .carousel-content-image-4 {
    padding-top: 22px !important;
    left: -49px !important;
  }

  .carousel-content-image-5 {
    padding-top: 22px !important;
    left: -44px !important;
  }

  .carousel-content-image-0 img {
    height: 98px !important;
    width: 98px !important;
  }

  .carousel-content-image-1 img {
    height: 108px !important;
    width: 108px !important;
  }

  .carousel-content-image-2 img {
    height: 118px !important;
    width: 118px !important;
  }

  .carousel-content-image-3 img {
    height: 108px !important;
    width: 108px !important;
  }

  .carousel-content-image-4 img {
    height: 98px !important;
    width: 98px !important;
  }

  .carousel-content-image-5 img {
    height: 88px !important;
    width: 88px !important;
  }

  .layout-customer-testimonial .label-experience {
    padding-top: 30px !important;
  }

  .carousel-content-text-0 {
    div > h4 {
      float: left;
      margin-top: 15px !important;
    }

    .carousel-content-social-network {
      padding-top: 25px !important;
    }
  }

  .carousel-content-text-1 {
    div > h4 {
      float: left;
      margin-top: 15px !important;
    }

    .carousel-content-social-network {
      padding-top: 25px !important;
    }
  }

  .carousel-content-text-2 {
    div > h4 {
      float: left;
      margin-top: 10px !important;
    }

    .carousel-content-social-network {
      padding-top: 25px !important;
    }
  }

  .carousel-content-text-3 {
    div > h4 {
      float: left;
      margin-top: 10px !important;
    }

    .carousel-content-social-network {
      padding-top: 25px !important;
    }
  }

  .carousel-content-text-4 {
    div > h4 {
      float: left;
      margin-top: 5px !important;
    }

    .carousel-content-social-network {
      padding-top: 20px !important;
    }
  }

  .carousel-content-text-5 {
    div > h4 {
      float: left;
      margin-top: 0 !important;
    }

    .carousel-content-social-network {
      padding-top: 10px !important;
    }
  }

  .semi-circle-up {
    left: 53% !important;
  }

  .semi-circle-down {
    top: 160px !important;
    left: 53% !important;
  }

  .carousel-content-0 {
    margin-top: 65px !important;
  }

  .carousel-content-1 {
    margin-top: 115px !important;
  }

  .carousel-content-2 {
    margin-top: 165px !important;
  }

  .carousel-content-3 {
    margin-top: 235px !important;
  }

  .carousel-content-4 {
    margin-top: 305px !important;
  }

  .carousel-content-5 {
    margin-top: 365px !important;
  }

  .container-get-in-touch {
    height: 560px !important;
  }

  .layout-customer-testimonial {
    height: 560px !important;

    .testimonials-title {
      width: 100%;
      @include item-center;
      font-size: 25px;
    }
  }

  .layout-customer-testimonial .content-up-down {
    top: 165px !important;
  }

  #layLocation1 {
    top: -400px;
  }

  .layout-car .layout-car-content {
    top: -310px;
  }

  #carCarouselBody .car-item .container-car-image {
    height: auto;
  }

  .car-carousel {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .icon-message-location {
    width: 32px;
    height: 32px;
    border-radius: 24px;
    padding-top: 6px;
    text-align: center;
  }

  .layout-finance-container {
    .container-testimonial {
      .container-title-5 {
        padding: 15px;
        width: 100%;
        text-align: center;
        font-size: 24px;
        text-transform: uppercase;
      }
    }
  }

  .container-testimonial {
    .body-customer-section {
      .col-info {
        label {
          @include ellipsis-text(6);
          padding-right: 15%;
        }
      }
    }
  }

  .layout-finance .body-left-position {
    padding: 15%;
  }
}

.view-previous-home {

  .layout-finance {
    height: auto !important;

    .body-right-position {
      padding-top: 85px !important;
      padding-bottom: 40px !important;
    }
  }

  .car-image {
    aspect-ratio: 1.33;

    img {
      object-fit: contain !important;
    }
  }

  .layout-finance5 {
    padding: 50px 0;
  }

  .car-carousel-template5 {
    padding-bottom: 50px;
  }

  .layout-car, .layout-car-easy-navigation-b {
    .layout-car-content, .layout-car-content-easy-navigation-b {
      .container-car-image {
        aspect-ratio: unset;
      }
    }
  }

  .arrow-icon-style {
    .a {
      stroke: inherit;
    }

    .a, .d {
      fill: inherit;
    }
  }

  .icon-search {
    padding: 0;
  }


}

.view-previous-home-b {
  .car-search-container-settings-easy-navigation {
    .car-search-box {
      .car-search-row {
        input, .car-search-select-easy-navigation {
          border-color: #CACACA !important;
        }
      }
    }
  }

  .car-search-container .car-search-row input, .layout-container-main-b .car-search-container .car-search-row .car-search-select {
    border-color: #CACACA !important;
  }
}

.view-previous-home, .view-previous-finance {
  .lay-get-in-touch {
    .container-get-in-touch {
      flex: 0 0 50%;
    }
  }

  .container-get-in-touch {
    padding-top: 70px;
    height: auto !important;
  }

  .lay-get-in-touch {
    padding-top: 0 !important;
  }

  .layout-customer-testimonial {
    padding-top: 70px;
    height: 630px !important;
    width: 50%;
  }

  .container-testimonial {
    .container-title-5 {
      padding: 15px;
      width: 100%;
      text-align: center;
      font-size: 24px;
    }
  }
}

.layout-finance-container {
  .lay-get-in-touch {
    .container-get-in-touch {
      flex: 0 0 50%;
      height: auto !important;
    }
  }
}

.view-previous-finance {

  #getInTouch, #contactSection {
    //top: 130px;
    padding-top: 130px;
    position: relative;

    .lay-get-in-touch {
      min-height: inherit;
    }
  }

  .content-separator {
    position: relative;
  }

  .form-control {
    background-color: #ffffff;
  }

  .header-content .background {
    margin-top: 0;
  }

  .header-content {
    position: absolute;
    width: 100%;
  }

}

.view-previous-home {

  .layout-customer-testimonial {
    .container-carousel-testimonials {
      height: 560px;
    }
  }

  .banner-container, .banner-container-5 {
    z-index: 102;
    position: relative;

    .car-search {
      .car-search-input {
        height: 40px;
        padding: 10px 20px;
      }
    }

    .form-control-search {
      height: auto;
      background-color: transparent;
      font-size: 12px;
      padding: 3px 0 0 0;
    }

    .car-search-icon {
      img {
        width: 16px;
      }
    }
  }

  #carCarouselBodyTemplate5 .row-carousel {
    padding: 0 25px 0 40px;
  }

  #carCarouselBodyTemplate5 .col-car {
    padding-right: 15px;
    padding-bottom: 15px;
  }

  .car-item-details .btn-car-info {
    font-size: 10px;
  }

  .header-content {
    .car-separator1, .car-separator2 {
      @include col100;
      z-index: 101;
      position: relative;
    }
  }
}

.view-previous-about-us {

  .header-content {
    position: relative;
    padding: 95px 40px 40px 40px;

    .layout-about{
      @include item-center;
      top: unset;
      position: relative;
      z-index: 2;
    }
    .background{
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      margin: 0;
    }
  }

  .layout-about-5 .layout-about-box{
    display: flex;
  }

  .carousel-dealer-images-container, .testimonials-container{
    display: flex;
    width: 100%;
    height: auto;
    padding: 20px 130px;

    .control-back, .control-next{
      border-radius: 10px;
    }
    .control-center .bar-progress .segments-container .segment{
      width: 70px;
      margin: 0 8px;
    }
  }

  .dealer-image-item{
    width: 100%;
    aspect-ratio: calc(100/65);
    border-radius: 10px;
  }

  // testimonial item
  .testimonial-header {
    text-align: left;
    padding: 20px 130px;
  }
  .section-testimonial {
    border-radius: 20px;
    height: 90%;
    width: 100%;
    padding: 25px;
  }

  .row-customer {
    height: 100%;
    display: grid;
    grid-gap: 0;
    grid-template-rows: 4fr 1fr;
    grid-template-columns: 1fr 4fr;
    justify-content: center;
    align-items: start;
    justify-items: start;
    padding: 0;

    .item-a {
      grid-row: 1/2;
      grid-column: 1/3;
      height: 100%;
      width: 100%;
    }

    .item-b {
      grid-row: 2/3;
      grid-column: 1/2;
      align-self: center;
    }

    .item-c {
      grid-row: 2/3;
      grid-column: 2/4;
      padding-right: 10px;
      align-self: center;
      padding-left: 5px;
    }

    .testimonial-content-5 {
      font-family: 'Inter', sans-serif;
      margin: auto;
    }
    .testimonial-content-5.item-a {
      font-family: 'Inter', sans-serif;
      font-size: 1vw;
      font-weight: $font-weight-regular;
    }
    .item-c {
      font-weight: $font-weight-semi-bold;
    }
  }


  .content-image {
    border-radius: 50%;
    max-width: 108px;
    max-height: 108px;
    margin: auto;

    img {
      width: 50px;
      height: 50px;
    }
  }


  .container-label-name {
    .label-name {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: $font-weight-semi-bold;
      margin: 0;
      padding-right: 0;
    }
  }

  .container-label-social-network {
    .label-social {
      font-weight: $font-weight-semi-bold;
      color: #CACACA;
      font-size: 12px;
      text-transform: capitalize;
    }
  }

  .container-label-info {
    .label-info {
      background-color: transparent;
      font-size: 11px;
      font-weight: 200;
    }
  }
  .testimonial-content-5.item-a {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

}

.btn-container-dp-user {
  float: left;
  padding: 14px 5px 14px 0;

  .popover-inventory-dp-new {
    margin-top: 9px;
    margin-left: 10px !important;
  }

  .btn-message-dp:hover {
    text-decoration: none;
    color: #fff;
  }

  span {
    margin-left: 10px;
    font-size: $text-size-select;
    color: #4D4F5C;
    font-weight: normal !important
  }

  i {
    margin-right: 8px;
  }
}

.modal-user {
  @include item-center;
  display: flex !important;

  .icon-close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: -12px;
    right: -12px;
    border-radius: 50%;
    background: $button-color-red;
    display: flex;
    justify-content: center;
    font-size: 15px;
    color: white;

    &:hover {
      cursor: pointer;
    }
  }

  .modal-dialog {
    width: 60%;
    height: 386px;

    .modal-content {
      .modal-body {
        padding: 20px;

        .loader-container {
          width: 94%;
          height: 90%;
          @include item-center;
          position: absolute;
          z-index: 1;

          .loader {
            border: 7px solid #f3f3f3; /* Light grey */
            border-top: 7px solid #3498db; /* Blue */
            border-radius: 50%;
            width: 64px;
            height: 64px;
            animation: adjust-hue 1s linear infinite;
          }

          @keyframes adjust-hue {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }

        .div {
          display: flex;
        }
      }
    }
  }
}

.user-pagination {
  padding: 13px 10px 13px !important;
}

.filter-user {
  .category-status {
    @include border-box;
    height: $height-box-input;
    width: 155px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);

    i {
      padding-left: 25px;
      display: inline-table;
      font-size: 22px !important;
      cursor: pointer;
    }

    label {
      color: #4D4F5C;
      font-weight: $font-weight-regular;
      font-size: 14px !important;

      &:hover {
        cursor: pointer;
      }
    }

    .icon-arrow-content {
      float: right;
      padding: 1px 5px;
    }

    .icon-arrow-expand {
      height: $icon-arrow-height-svg;
      fill: #A4AFB7;
    }
  }
}

.title-forms-list, .tbody-user {
  .filter-letter {
    display: inline-grid;
    position: absolute;
    background: white;
    z-index: 20;
    padding: 24px 23px 0 25px;

    a {
      color: #D7DAE2;
      padding-top: 4px !important;
      font-weight: lighter;
      font-size: 12px;
      text-decoration: none;
    }

    a:hover {
      color: #2194F1;
      text-decoration: none;
      cursor: pointer;
    }

    .activeLetter {
      color: #2194F1;
    }
  }

  .rowUser {
    td {
      vertical-align: middle !important;
      font-size: $text-size-select;
      font-weight: $font-weight-regular;

      .options {
        position: relative !important;
        margin-top: 0 !important;
      }

      .options-testimonial, .options-user {
        position: absolute;
        right: 4.5%;
        margin-top: -8px;

        i {
          padding-left: 5px;
        }
      }

      .options-user {
        margin-top: 1px;
        right: 12px;
        position: relative;
      }
    }

    .btn-website-status {
      span {
        font-size: $text-size-select;
      }
    }
  }

  .title-photo-users-list {
    padding-left: 20px;
    width: 7%;
    font-size: 15px;
    color: #A3A6B4;
    font-weight: 600;

    .photo-user-img-circle {
      width: 32px;
      height: 32px;
      border: 1px solid #FFFFFF;
      border-radius: 24px;
      box-shadow: 0 0 10px #A4AFB7;
      object-fit: cover;
    }

    img {
      border: 1px solid #FFFFFF;
      border-radius: 24px;
      box-shadow: 0 0 10px #A4AFB7;
    }
  }

  .title-name-users-list {
    width: 16%;
    padding-left: 15px;
    font-size: 15px;
    color: #A3A6B4;
    font-weight: 600;
  }

  .th-status-superuser {
    width: 10%;
    padding-left: 15px;
  }

  @mixin title-email-users-list() {
    padding-left: 60px;
    width: 15%;
    font-size: 15px;
    color: #A3A6B4;
    font-weight: 600;
    b {
      font-weight: normal;
      text-decoration: underline;
      color: #2194F1;
    }
  }

  .title-email-users-list, .title-testimonial {
    @include title-email-users-list;
  }

  .title-email-users-list {
    padding-left: 15px;
  }

  .testimonials-users {
    @include title-email-users-list;

    p {
      text-decoration: none;
      color: $text-color-table;
      text-align: justify;
    }

    width: 30%;
  }

  .title-phone-users-list, .title-posting-date {
    padding-left: 30px;
    width: 15%;
    font-size: 15px;
    color: #A3A6B4;
    font-weight: 600;
  }


  .title-role-users-list, .title-website-status {
    padding-left: 20px;
    font-size: $text-size-select;
    color: $text-color-table;

    .category-status {
      @include border-box;
      height: $height-box-input;
      width: 150px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);

      i {
        padding-left: 25px;
        display: inline-table;
        font-size: 22px !important;
        cursor: pointer;
      }

      label {
        color: $text-color;
        font-weight: $font-weight-regular;

        &:hover {
          cursor: pointer;
        }
      }

      .icon-arrow-content {
        float: right;
        padding: 1px 5px;
      }

      .icon-arrow-expand {
        height: $icon-arrow-height-svg;
        fill: #A4AFB7;
      }
    }

    .btn-dp-form {
      width: 120px;
      position: relative;
      padding: 0;
    }

    //refactor??
    .loader-container-status {
      width: 100%;
      display: flex;
      justify-content: center;

      .loader {
        border: 7px solid #f3f3f3; /* Light grey */
        border-top: 7px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 36px;
        height: 36px;
        animation: adjust-hue 1s linear infinite;
      }

      @keyframes adjust-hue {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .container-pill {
      width: 155px;

      .pill-box {
        left: 0;
        display: flex;
        width: auto;
        min-width: max-content;
        padding: 0 4px;
      }
    }
  }

  .title-website-status {
    padding-right: 70px;
    color: $icon-arrow-color !important;
  }

  .title-role-users-list {
    width: 16%;
    padding-left: 15px;
    font-size: 15px;
    color: #A3A6B4;
    font-weight: 600;

    .container-pill {
      width: 100%
    }
  }

  .td-status-user {
    .icon-left {
      display: flex;
      align-items: center;

      span {
        height: 20px;
      }
    }

    //.ic-inactive{
    //  .a{
    //    fill:$button-color-red;
    //  }
    //  .b{
    //    .c, .d{
    //      fill: none;
    //    }
    //  }
    //  height: 18px;
    //  width: 56px;
    //}
  }

  .title-name-forms-list {
    padding-left: 70px;
    width: 300px;
    font-size: $text-size-table;
    color: $text-color-table-head;
  }

  .title-code-forms-list {
    padding-left: 70px;
    width: 200px;
    font-size: $text-size-table;
    color: $text-color-table-head;
  }

  .title-entity-forms-list {
    width: 150px;
    font-size: $text-size-table;
    color: $text-color-table-head;
  }

  .title-created-by-forms-list {
    text-align: left;
    width: 100px;
    font-size: $text-size-table;
    color: $text-color-table-head;
  }

  .title-date-forms-list {
    width: 200px;
    text-align: left;
    font-size: $text-size-table;
    color: $text-color-table-head;
  }

  .title-graph-forms-list {
    width: 9%;
  }

  .title-recommendation-type {
    padding-left: 20px;
    width: 10%;
    font-size: $text-size-table;
    color: $text-color
  }
}

.user-table {
  .table {
    .thead-setting-user {
      .title-forms-list {
        .title-graph-forms-list {
          min-width: 82px;
        }
      }
    }
  }
}

#id-title-name-users-list, #id-title-phone-users-list {
  padding-left: 15px;
}

.slider-modal {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a5afb8;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-modal:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 26px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border: 2px #2193f1 solid;
}

input:checked + .slider-modal {
  background-color: #2193f1;
}

input:focus + .slider-modal {
  box-shadow: 0 0 1px #2193f1;
}

input:checked + .slider-modal:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider-modal.round {
  border-radius: 6px;
}

.slider-modal.round:before {
  border-radius: 6px;
}

.slider-modal.round.off:before {
  border: 2px #a5afb8 solid;
}

.classInputNewCar {
  width: 100%;
  float: left;
  padding: 0 15px 10px 0;
  position: relative;

  .form-control {
    height: 38px !important;
    padding: 0 12px !important;

    div {
      height: 38px !important;
      width: 100% !important;

      div {
        display: none;
      }
    }

    div + div {
      display: none;
    }
  }

  input {
    border-radius: 3px;
    height: 38px;
    border-color: #84858a;
    color: #787878;
    font-size: 13px !important;
    font-weight: $font-weight-regular !important;
    font-family: $font-family !important;
  }

  select {
    height: 30px !important
  }
}

#id-content-check {
  display: flex;
}


.content-check, .content-radio {
  float: left;
  width: 100%;
  margin-top: 2px;

  .icon-check {
    fill: #3B86FF;
    height: 24px;

    > g > g {
      fill: white;
    }
  }

  .icon-uncheck {
    fill: #C3DAFF;
    height: 24px;
  }

  .check-disabled {
    svg {
      fill: #C3DAFF;
    }
  }

  .icon-check-user {
    fill: #FFFFFF;
    height: 24px;
  }

  .change-submenu-item {
    &:hover {
      background-color: #EFEFEF;
    }
  }

  .item-selected {
    background-color: #3B86FF;
    color: white;
  }

  label {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: $font-weight-regular;
    font-size: 13px;
  }
}

@mixin image-object-fit() {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.image-object-fit {
  @include image-object-fit;
}

.car-image-container {
  .image-object-fit-no-pointer {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@mixin image-object-contain() {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-object-contain {
  @include image-object-contain;
}

.image-object-contain-pointer {
  @include image-object-contain;
  cursor: pointer;
}

.container-total {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
}

.image-cover {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  @include item-center;
  background-color: #2194F1;
  color: #fff;
}

.car-image-target {
  @include item-center;
  width: 25%;
  padding: 0 10px 10px 0;
}

.customer-content {
  .container-center {
    padding: $padding-container-center;
  }

  .container-button-save {
    float: right;
    position: absolute;
    right: 40px;

    .btn-save {
      float: right;
      width: 75px;
      margin-right: 0;
      height: 30px;
      background-color: #4ad991;
      color: #ffffff;
      border-radius: 5px;
      border-color: transparent;
    }
  }

  .arrow-back {
    padding-left: 25px;
    float: left;

    svg {
      width: 16px;
      fill: #2194F1;

      .a {
        fill: #2194F1;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: white;
    z-index: 200;
  }
}

/*********Marketing**********/
.testimonial-container {
  display: block;

  .row-facebook-pagination {
    display: table-header-group;
    height: 60px;
    width: 100%;
    padding: 5px 5px 5px 65px;
    float: left;
    align-items: center;

    .row-facebook {
      display: flex;
      height: 100%;
      float: left;
      width: 50%;
      align-items: center;
      padding: 1px;

      .facebook-box {
        float: left;
        height: 33px;
        width: 35px;
        padding: 4px;
        border-radius: 4px;
        background-image: linear-gradient(#006BF6, #00A8FF);

        .icon-facebook {
          @include item-center;
          padding-top: 3px;

          svg {
            height: 18px;
            fill: white;
            width: 9px;
          }
        }
      }

      .container-icon-reset {
        .icon-reset {
          @include item-center;
          margin: 0 15px;
          padding-top: 5px;

          svg {
            height: 28px;
            width: 29px;
            fill: #A4AFB7;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .container-last-update {
        width: 80%;

        .label-last-update {
          padding-right: 8px;
        }

        label {
          font-weight: 500;
          color: $text-color-table;
          margin-bottom: 0;
        }
      }

    }

    .pagination-testimonials {
      position: absolute;
      right: 10px;
      padding: 20px 10px 17px 10px;
      font-size: 12px;

      .pagination-right {
        .btn-center {
          span {
            font-size: 12px;
          }

          .label-page-selected {
            padding-top: 2px;
          }
        }
      }
    }

    .inventory-pagination {
      position: relative !important;
      padding: 0 !important;
      font-size: 14px;
      float: right;
      display: flex;
      align-items: center;
      height: 100% !important;
    }
  }

  .table-form-setting {
    .table {
      .background-white {
        background-color: #EFEFEF;
      }
    }

    .image-user {
      width: 35px;
    }

    .btn-website-status {
      position: relative;
      width: 120px;
      height: 34px;
      margin-left: 0;
      border-radius: 5px;
      border: 1px solid #DADDE3;
      float: left;
      color: #6b6c78;
      background-color: #fff;
      padding: 8px 8px 6px 12px;

      span {
        position: absolute;
        top: 6px;
      }

      .icon-arrow-content {
        position: absolute;
        right: 2px;
        top: 4px;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .td-website-status {
      color: #f3f3f3;

      > div {
        position: absolute;
        right: 4.5%;

        i {
          color: $button-color-red;
          padding-left: 5px;
        }
      }
    }
  }

  .testimonial-page-error {
    @include item-center;

    .container-images-button {
      width: 100%;

      .marketing-graphics {
        padding: 0 40px;
        @include item-center;
        flex: 3;

        .graphics-container {
          display: flex;
          width: 90%;
          align-items: center;
          justify-content: space-around;

          .graphic-text {
            @include item-center;
            flex-direction: column;

            img {
              width: 250px;
              height: auto;
              margin-bottom: 20px;
            }

            label {
              color: #A4AFB7;
              font-size: 21px;
              font-weight: 600;
            }
          }
        }

        .arrow-marketing {
          width: 50px;
          height: 50px;
          fill: #415B91;
        }

      }

      .container-button-link {
        @include item-center;
        padding-top: 100px;

        button {
          border-radius: 4px;
          border: 1px solid $light-blue-color-1;
          color: #FFF;
          background-color: $light-blue-color-1;
          width: 200px;
          height: 32px;
          font-size: 14px;
          font-weight: $font-weight-regular;
          font-family: $font-family;
        }
      }
    }
  }

}

.task-user-title {
  @include item-center;
  background-color: #0f5d84;
  width: max-content;
  position: absolute;
  left: -30px;
  top: 40px;
  height: 25px;
  border-radius: 5px;
  color: #fff;
  padding: 0 10px;
  display: none;
}

/*********Marketing**********/

/*********Superusers**********/
.modal-edit-user {
  display: flex !important;
  @include item-center;
  //.loader-container{
  //  width: 94%;
  //  height: 90%;
  //  @include item-center;
  //  position: absolute;
  //  z-index: 1;
  //
  //  .loader {
  //    border: 7px solid #f3f3f3; /* Light grey */
  //    border-top: 7px solid #3498db; /* Blue */
  //    border-radius: 50%;
  //    width: 64px;
  //    height: 64px;
  //    animation: adjust-hue 1s linear infinite;
  //  }
  //
  //  @keyframes adjust-hue {
  //    0% { transform: rotate(0deg); }
  //    100% { transform: rotate(360deg); }
  //  }
  //}
  .modal-dialog {
    min-height: 400px;

    .modal-content {
      box-shadow: 0 0 10px #D7DAE2;

      .modal-body {
        padding: 25px;

        .close-icon {
          width: 32px;
          position: absolute;
          top: -14px;
          right: -14px;

          &:hover {
            cursor: pointer;
          }
        }

        .container-panels {
          width: 100%;
          float: left;
          height: 100%;
          padding-top: 15px;
        }

        .panel-left {
          width: 30%;
          float: left;

          .container-photo {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .container-customer-image {
              position: relative;
              width: 120px;
              height: 120px;
              float: left;
              border-radius: 50%;

              .customer-image {
                width: 100%;
                border-radius: 50%;
                height: 100%;
                object-fit: cover;
                border: 1px solid #FFFFFF;
                box-shadow: 0 0 10px #A4AFB7;

                &:hover {
                  cursor: pointer;
                }
              }

              .img-customer {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                background: #2194F1;
                padding: 0;
                float: right;
                bottom: 0;
                position: absolute;
                z-index: 64;
                right: 0;
                top: 88px;

                div {
                  @include item-center;

                  svg {
                    height: 15px;
                    width: 15px;
                    fill: white;
                  }
                }
              }

            }
          }
        }

        .panel-right {
          width: 70%;
          float: right;
          padding-left: 25px;

          .label-error {
            color: $button-color-red;
            padding-left: 2px;
          }

          .error-message {
            color: $button-color-red;
            font-size: 10px;
            font-weight: $font-weight-light;
          }

          .first-row, .second-row, .third-row, .fourth-row, .fifth-row {
            width: 100%;
            padding-bottom: 10px;
            float: left;
            display: flex;
          }

          .fifth-row {
            .container-button-save {
              width: 100%;
              float: right;
              padding: 10px;
            }
          }
        }

        .form-control {
          box-shadow: unset;
        }
      }
    }
  }
}

/*********Superusers**********/

/*********Dealers**********/
.dealers-content, .superusers-content, .plans-content{
  .container-header{
    z-index: 30;
    @include shadow-header;
    display: block;
    padding-left: 50px;
    div{
      height: 100%;
    }
    .left{
      float: left;
      display: flex;
      align-items: center;
      label{
        font-size: 21px;
        color: $icon-color;
        font-weight: $font-weight-bold;
      }
    }
  }
  .container-center{
    padding: 90px 50px 0 50px;
    .panel-dealers, .panel-superusers{
      min-height: 100%;
      background: white;
      border-radius: 7px;
      @include shadow-body;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.12);
      .header-dealer, .header-superusers{
        width: 100%;
        position: relative;
        height: 60px;
        .container-right{
          float: right;
          display: flex;
          .superusers-buttons{
            padding: 14px;
            button{
              width: 140px;
              float: left;
              margin: 0 5px;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              label{
                font-weight: $font-weight-regular;
              }
            }
            .active{
              color: #FFF;
              background-color: $button-color-red;
              border: 1px solid $button-color-red;
            }
            .inactive{
              border: 1px solid $border-button-color-pagination !important;
              background-color: #FFF;
              color: $button-color-red;
            }
            .icon-active, .icon-inactive{
              div{
                @include item-center;
              }
              .svg-inactive{
                .a{
                  fill: #ffffff;
                }
              }
            }
            .all{
              color: $color-button-all;
            }
            .inactive, .active{
              .dp-icon{
                height: 17px;
                width: 20px;
                margin-right: 2px;
              }
              //svg{
              //  height: 15px;
              //  width: 15px;
              //  margin-right: 5px;
              //}
              label{
                margin-bottom: 0;
                font-weight: $font-weight-regular;
                font-family: $font-family;
                font-size: 13px;
              }
              &:hover{
                label{
                  cursor: pointer;
                }
              }
            }
          }
        }
        .btn-container-select{
          height: 100%;
          padding: 14px 0 14px 40px;
          float: left;
          display: flex;
          .btn-select-dealers, .btn-select-superusers{
            padding: 0 10px;
            width: 130px;
            text-align: inherit;
            height: $height-box-input;
            border-radius: 5px;
            border: 1px solid $border-button-color-pagination;
            background: #ffffff;
            &:focus{
              border: 1px solid $border-button-color-pagination !important;
            }
            span{
              color: $text-color;
              font-weight: $font-weight-regular;
              font-family: $font-family;
              font-size: 13px;
            }
          }
          .dealer-button-add, .superusers-button-add{
            display: flex;
            align-items: center;
            padding-left: 10px;
            .icon-add-dealer, .icon-add-superuser{
              svg{
                fill: #4ad991;
                g:hover{
                  cursor: pointer;
                }
              }
              align-items: center;
              display: flex;
              margin-top: 7px;
            }
          }
        }
        .dealers-pagination, .superusers-pagination{
          float: right;
          padding: 14px;
          .dp-pagination-container{
            .pagination-left{
              .btn-page-group{
                border-radius: 4px;
                height: $height-box-input;
              }
            }
            .pagination-right{
              display: flex;
              .btn-back{
                border-radius: 4px 0 0 4px;
                height: $height-box-input;
              }
              .btn-center{
                border-top: 1px solid $border-button-color-pagination;
                border-bottom: 1px solid $border-button-color-pagination;
                border-left: none;
                border-right: none;
                height: $height-box-input;
              }
              .btn-next{
                border-radius: 0 4px 4px 0;
                height: $height-box-input;
              }
            }
          }
        }
      }
      //.container-table-dealers{
        //.loader-container{
        //  width: 94%;
        //  height: 90%;
        //  @include item-center;
        //  position: absolute;
        //  z-index: 1;
        //
        //  .loader {
        //    border: 7px solid #f3f3f3; /* Light grey */
        //    border-top: 7px solid #3498db; /* Blue */
        //    border-radius: 50%;
        //    width: 64px;
        //    height: 64px;
        //    animation: adjust-hue 1s linear infinite;
        //  }
        //
        //  @keyframes adjust-hue {
        //    0% { transform: rotate(0deg); }
        //    100% { transform: rotate(360deg); }
        //  }
        //}
        //.table{
        //  height: 100%;
        //  .thead-dealer{
        //    background: $table-thead-color;
        //  }
        //  .tr-dealer-header, .tr-superuser-header{
        //    color: #a6a8b4;
        //    font-weight: 600 !important;
        //    font-size: 15px;
        //    th{
        //      height: 50px;
        //      vertical-align: middle;
        //      border: 0;
        //    }
        //    .th-logo-dealer{
        //      padding-left: 40px;
        //      width: 10%;
        //    }
        //    .th-image-superuser{
        //      padding-left: 70px;
        //      width: 10%;
        //      font-weight: 600;
        //    }
        //    .th-name-superuser{
        //      width: 15%;
        //      font-weight: 600;
        //    }
        //    .th-email-superuser{
        //      width: 20%;
        //      font-weight: 600;
        //    }
        //    .th-phone-superuser{
        //      width: 12%;
        //      font-weight: 600;
        //    }
        //    .th-actions-superuser{
        //      width: 24%;
        //      padding-right: 35px;
        //    }
        //    .th-name-dealer, .th-phone-dealer, .th-status-dealer{
        //      width: 15%;
        //      font-weight: 600;
        //    }
        //    .th-address-dealer{
        //      width: 25%;
        //    }
        //    .th-actions-dealer{
        //      width: 8%;
        //      min-width: 68px;
        //    }
        //    .th-public-page{
        //      width: 22%;
        //    }
        //  }
        //  .tbody-dealer{
        //    .filter-letter{
        //      display: inline-grid;
        //      position: absolute;
        //      background: white;
        //      z-index: 20;
        //      padding: 14px 25px;
        //      .activeLetter{
        //        color: #2194F1;
        //      }
        //      a{
        //        color: #D7DAE2;
        //        padding-top: 4px !important;
        //        font-weight: lighter;
        //        font-size: 12px;
        //        text-decoration: none;
        //        &:hover{
        //          color: #2194F1;
        //          text-decoration: none;
        //          cursor: pointer;
        //        }
        //      }
        //    }
        //    .dealer-actions, .superuser-actions{
        //      display: none;
        //    }
        //    .tr-dealer, .tr-superuser{
        //      color: #a6a8b4;
        //      font-size: 13px;
        //      font-weight: $font-weight-regular;
        //      .td-image-superuser{
        //        padding: 8px 8px 8px 70px;
        //        border: none;
        //        vertical-align: middle;
        //        .container-image{
        //          .image-superuser{
        //            height: 40px;
        //            width: 40px;
        //            vertical-align: middle;
        //            object-fit: cover;
        //            border: 1px solid #FFFFFF;
        //            border-radius: 50%;
        //            box-shadow: 0 0 10px #A4AFB7;
        //          }
        //        }
        //      }
        //      .td-status-superuser{
        //        .icon-left{
        //          display: flex;
        //          align-items: center;
        //          span{
        //            height: 20px;
        //          }
        //        }
        //        //.ic-inactive{
        //        //  .a{
        //        //    fill:$button-color-red;
        //        //  }
        //        //  .b{
        //        //    .c, .d{
        //        //      fill: none;
        //        //    }
        //        //  }
        //        //  height: 20px;
        //        //  width: 60px;
        //        //}
        //      }
        //      &:hover{
        //        box-shadow: 0 0 10px #EFEFEF;
        //        cursor: pointer;
        //        .dealer-actions, .superuser-actions{
        //          display: table;
        //          height: 21px;
        //        }
        //        .superuser-actions{
        //          float: right;
        //          display: flex;
        //          .icon-right{
        //            float: right;
        //            display: flex;
        //            width: 30%;
        //          }
        //          .icon-left{
        //            float: left;
        //            width: 70%;
        //          }
        //          //.icon-right, .icon-left{
        //          //  svg{
        //          //    height: 20px;
        //          //    width: 20px;
        //          //  }
        //          //  span{
        //          //    div{
        //          //      padding: 0 2px
        //          //    }
        //          //  }
        //          //  .icon-edit{
        //          //    fill: $light-blue-color-1;
        //          //  }
        //          //  .icon-cancel{
        //          //    fill:$button-color-red;
        //          //  }
        //          //  .icon-check{
        //          //    .a{
        //          //      fill: $green-color;
        //          //    }
        //          //  }
        //          //  .icon-delete{
        //          //    fill:$button-color-red;
        //          //  }
        //          //  .ic-inactive{
        //          //    fill:$button-color-red;
        //          //    height: 20px;
        //          //    width: 60px;
        //          //  }svg{
        //          //     height: 20px;
        //          //     width: 20px;
        //          //   }
        //          //  span{
        //          //    div{
        //          //      padding: 0 2px
        //          //    }
        //          //  }
        //          //  .icon-edit{
        //          //    fill: $light-blue-color-1;
        //          //  }
        //          //  .icon-cancel{
        //          //    .a{
        //          //      fill:$button-color-red;
        //          //    }
        //          //  }
        //          //  .icon-check{
        //          //    .a{
        //          //      fill: $green-color;
        //          //    }
        //          //  }
        //          //  .icon-delete{
        //          //    .a{
        //          //      fill:$button-color-red;
        //          //    }
        //          //  }
        //          //  .ic-inactive{
        //          //    .a{
        //          //      fill:$button-color-red;
        //          //    }
        //          //    height: 20px;
        //          //    width: 60px;
        //          //  }
        //          //}
        //        }
        //      }
        //      td{
        //        border: none;
        //        vertical-align: middle;
        //        .btn-select-status{
        //          display: flex;
        //          align-items: center;
        //          justify-content: space-between;
        //          border: 1px solid $border-button-color-pagination;
        //          border-radius: 4px;
        //          padding: 4px 5px 5px 8px;
        //          width: 130px;
        //          height: 32px;
        //          float: left;
        //          position: relative;
        //          &:hover{
        //            cursor: pointer;
        //          }
        //          .btn-website-status{
        //            .icon-arrow-content{
        //              position: absolute;
        //              right: 2px;
        //              top: 4px;
        //            }
        //            &:hover{
        //              text-decoration: none;
        //            }
        //          }
        //        }
        //        .container-image{
        //          height: 40px;
        //          width: 60px;
        //          .logo-dealer{
        //            height: 100%;
        //            width: 100%;
        //            object-fit: contain;
        //          }
        //        }
        //      }
        //      .td-logo-dealer{
        //        padding-left: 40px;
        //        height: 56px;
        //      }
        //    }
        //    .tr-superuser{
        //      &:hover{
        //        .td-actions-superuser{
        //          padding-right: 35px;
        //        }
        //      }
        //    }
        //  }
        //}

      //}
    }
  }
}
/*********Dealers**********/
/******* FINISH SETTING TEMPLATE ******/

.popover-general-dp {
  max-height: 290px;
  z-index: 100;
  border-radius: 4px;
  width: 100%;
  overflow: auto;
  background-color: #fff;
  position: relative;

  .popover-general-dp-sub {
    max-height: 280px;
    overflow: auto;

    .change-submenu-item {
      &:hover {
        background-color: #efefef;
      }
    }

    .item-selected {
      background-color: #3B86FF;
      color: white;
    }
  }

  u {
    text-decoration: none;

    li {
      list-style: none;
      padding: 2px 7px 2px 7px;
      cursor: pointer;
    }
  }
}

.popover-box-general {
  border: 1px solid #D7DAE2;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.17);
  top: 40px;
}

.sales-content {
  .customer-title {
    width: auto;
  }
}


.popover-box-message {
  border: 1px solid #D7DAE2;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.17);
}

@mixin user-image() {
  vertical-align: middle;
  height: 25px;
  width: 25px;
  object-fit: cover;
  border-radius: 50%;
}

.popover-general-message {
  max-height: 290px;
  z-index: 100;
  border-radius: 4px;
  width: 100%;
  background-color: #fff;
  position: relative;
  overflow: auto;
  padding: 10px 0;

  .user-image {
    @include user-image;
  }

  .popover-general-message-sub {
    max-height: 280px;

    .change-submenu-item {
      &:hover {
        background-color: #EFEFEF;
      }

      .salesman-image {
        @include user-image;
      }

      .salesman-not-image {
        @include user-image;
      }

      .icon-check {
        float: left;
        padding-right: 5px;

        div {
          height: 100%;
          display: flex;
        }

        .icon-check-svg {
          width: 15px;

          .cls-1 {
            //fill: #D7DAE2;
            fill: white;
          }
        }

        .icon-check-svg-completed {
          width: 15px;

          .cls-1 {
            fill: #4ad991;
          }
        }
      }

    }

    .change-submenu-item.low {
      color: $light-blue-color-1;
    }

    .change-submenu-item.medium {
      color: $orange-color;
    }

    .change-submenu-item.high {
      color: $button-color-red;
    }

    .item-selected {
      background-color: #3B86FF;
      color: white;
    }

    .change-submenu-item, .item-selected {
      .user-img {
        width: 21px;
        height: 21px;
        border-radius: 50%;
        border: 1px solid #FFFFFF;
        box-shadow: 1px 1px 4px 2px #a4afb7;
        margin-right: 8px;
      }

      label {
        font-family: $font-family;
        margin-bottom: 0;
        font-size: 12px;
        font-weight: $font-weight-regular;
      }
    }
  }

  u {
    text-decoration: none;

    li {
      list-style: none;
      padding: 2px 7px 2px 7px;
      cursor: pointer;
      color: $text-color;
    }
  }
}


#sidebar-task {
  .icon-container {
    padding: 13px 17px;
  }

  svg {
    width: 16px;
  }
}

#sidebar {
  .left-navigation {
    overflow: hidden;
    outline: none;
    width: inherit;

    .menu-sidebar-1 {
      float: left;
    }
  }
}

.button-sale-next {
  @include item-center;
  border-radius: 4px;
  border: 1px solid #2194f1;
  width: 109px;
  background-color: #2194f1;
  color: white;
  height: 32px;
  cursor: pointer;
  float: right;
  margin: 20px;
}

#back_to_right, #back_to_left, #back_to_left_invert {
  color: #cdcdcd;
  height: auto;
  position: fixed;
  margin: 0;
  z-index: 5;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  top: 90%;
  display: none;

  #circleStack {
    background-color: #2194f1;

    > div svg {
      fill: white;
    }
  }

  &:hover {
    cursor: pointer;

    #circleStack {
      background-color: #2194f1;

      div svg {
        fill: #fff;
      }
    }
  }
}

#back_to_right {
  right: 50px;
  display: block;
}

#back_to_left {
  left: 85px;
}

#back_to_left_invert {
  left: 100px;
  display: none;
}


.container-list-task-body {
  @include col100;
}


.task-box-header-vertical {
  height: 100%;
  position: relative;
  top: 0;
  background-color: transparent !important;
  width: auto !important;
  padding: 10px 0 10px 26px;
  min-width: 100% !important;
}

.container-change-status, .container-change-salesman {
  a:hover {
    cursor: pointer;
  }
}

.close-popover-superuser {
  background-color: #4ad991;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 21px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: relative;
  margin-left: 5px;

  span {
    position: absolute;
    top: -2px;
    left: 11px;
  }
}

.btn-save {
  width: 67px;
  height: 32px;
  border-radius: 4px;
  padding: 4px 0;
  text-align: center;
  float: right;
  color: #fff;
  background-color: #4AD991;
  cursor: pointer;
  border: none;
  font-family: $font-family;
  font-weight: $font-weight-regular;
  font-size: 13px;
}

//.popover-new-superuser {
//  width: 40%;
//  height: auto;
//  z-index: 20;
//  top: 170px;
//  left: 245px;
//  padding: 0 0 40px;
//
//  .popover-new-superuser-header{
//    display: block;
//    border-bottom: 1px solid #D7DAE2;
//
//    .save-new-superuser {
//      width: 67px;
//      height: 32px;
//      border-radius: 4px;
//      padding: 4px 0;
//      text-align: center;
//      float: right;
//      margin: 14px 20px;
//      color: #fff;
//      background-color: #4AD991;
//      cursor:pointer;
//      border: none;
//      font-family: $font-family;
//      font-weight: $font-weight-regular;
//      font-size: 13px;
//    }
//
//    .new-superuser-title {
//      font-size: 15px;
//      font-weight: $font-weight-bold;
//      padding: 20px;
//      color: $text-color;
//    }
//  }
//
//  .popover-new-superuser-content {
//    width: 95%;
//    margin: 10px auto;
//
//    .new-superuser-left-content {
//      width: 30%;
//      float:left;
//
//      .new-superuser-photo-container{
//        width: 100%;
//        height: 100%;
//        padding-bottom: 20px;
//        padding-top: 30px;
//
//        .new-superuser-photo-circle {
//          width: 102px;
//          height: 102px;
//          border-radius: 50%;
//          background: #2194F1;
//          margin: 10px auto;
//          @include item-center;
//          border: 1px solid #FFF;
//          -webkit-box-shadow: 0 0 10px 0 rgba(164,175,183,1);
//          -moz-box-shadow: 0 0 10px 0 rgba(164,175,183,1);
//          box-shadow: 0 0 10px 0 rgba(164,175,183,1);
//
//          .img-customer{
//            cursor: pointer;
//            >div{
//              @include item-center;
//              svg{
//                height: 32px;
//                width: 32px;
//                fill: #ffffff;
//              }
//            }
//          }
//
//          .image-customer{
//            width: 102px;
//            height: 102px;
//            border-radius: 51px;
//            padding: 0;
//          }
//        }
//      }
//    }
//
//    .new-superuser-right-content {
//      width: 70%;
//      float: left;
//
//      .new-superuser-form {
//        margin-left: 20px;
//
//        .flex-colR1 {
//          padding-right: 0;
//          padding-left: 10px;
//        }
//
//        .item{
//          margin-top: 10px;
//          span {
//            font-size: 14px;
//            color:#787878;
//
//          }
//
//          .label-error{
//            color: red;
//            padding-left: 2px;
//          }
//
//          input {
//            margin-top: 8px;
//            height: 40px;
//          }
//
//          .error-message{
//            font-size: 8px;
//            color: red;
//          }
//        }
//      }
//    }
//  }
//}

//.loader-container{
//  width: 100%;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  height: inherit;
//
//  .loader {
//    border: 7px solid #f3f3f3; /* Light grey */
//    border-top: 7px solid #3498db; /* Blue */
//    border-radius: 50%;
//    width: 72px;
//    height: 72px;
//    animation: adjust-hue 1s linear infinite;
//  }
//
//  @keyframes adjust-hue {
//    0% { transform: rotate(0deg); }
//    100% { transform: rotate(360deg); }
//  }
//}

.logo-container {
  height: 30px;
  width: 30px;
  float: right;

  .logo-dealer {
    @include image-object-contain;
  }
}

.user-active-buttons {
  float: left;
  margin-left: -4px;
  padding: 14px 0 14px 0;

  button {
    div {
      div {
        display: flex;
      }
    }
  }

  @mixin active() {
    width: 140px;
    border: 1px solid #D7DAE2 !important;
    float: left;
    margin: 0 5px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: $button-color-red;
    background: #FFF;
    label {
      padding-left: 5px;
      margin-bottom: 3px;
      font-weight: $font-weight-regular;
      font-size: $text-size-select;
    }

    .svg-inactive {
      .a {
        fill: #ffffff;
      }
    }

    &:hover {
      cursor: pointer;

      label {
        cursor: pointer;
      }
    }
  }

  .active {
    @include active;
  }

  .inactive {
    @include active;
    color: #FFF;
    background-color: $button-color-red;
    border: none !important;
  }
}

@mixin base-pagination() {
  float: left;
  padding-right: 20px;
  .dp-pagination-container {
    position: relative;

    .icon-menu-list {
      height: 10px;
      fill: #A4AFB7;
    }

    .icon-unfold {
      height: 10px;
      fill: #A4AFB7;
    }

    .icon-arrow {
      width: 12px;
      fill: #A4AFB7;

      .a {
        fill: #A4AFB7;
      }
    }

    .btn-page-group {
      background-color: transparent;
      border: 1px solid #D7DAE2;
      border-radius: 5px;
      padding: 5px 10px;
      height: 34px;
    }

    .label-page-selected {
      float: left;
      padding: 0 5px 0 5px;
      color: $text-color;
      font-family: $font-family !important;
      font-size: 13px;
      font-weight: $font-weight-regular;
    }

    .pagination-right {
      float: right;
      padding-left: 10px;
    }

    .icon-container, .pagination-left {
      float: left;
    }

    .btn-back, .btn-next {
      background-color: transparent;
      border: 1px solid #D7DAE2;
      padding: 7px 12px;
      height: 34px;
    }


    .btn-back {
      border-radius: 5px 0 0 5px;
    }

    .btn-next {
      border-radius: 0 5px 5px 0;
    }

    .btn-center {
      background-color: transparent;
      border-color: #D7DAE2;
      height: 34px;
      border-right: 0;
      border-left: 0;
      padding: 7px;

      .icon-container {
        padding-left: 5px;
      }
    }

    .btn-center:hover {
      cursor: default;
    }

    .label-page-off {
      padding: 0 5px 0 0;
      color: #787878;
      font-family: $font-family !important;
      font-size: 13px;
      font-weight: $font-weight-regular;
    }

    .btn-page-group:focus, .btn-back:focus, .btn-next:focus {
      border: 1px solid #D7DAE2 !important;
    }

    .btn-center:focus {
      border-top: 1px solid #D7DAE2 !important;
      border-bottom: 1px solid #D7DAE2 !important;
    }

    .popover-pagination-box {
      float: left;
      width: 100%;
      padding: 10px 10px 5px 10px;
      color: #979797;
      text-align: center;
      position: relative;
    }

    .items-by-page {
      padding-bottom: 5px;
    }

    .items-by-page-value:hover {
      cursor: pointer;
    }
  }
}

.setting-user-pagination {
  @include base-pagination;
  padding: 14px;
  font-size: 12px;
  float: right;

  .dp-pagination-container {
    .pagination-left, .pagination-right {
      height: 32px;

      button {
        height: 100%;
      }
    }

    .pagination-right {
      display: flex;
    }
  }
}

.icon-user-profile {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.pill-box {
  width: 90px;
  height: 18px;
  border: 1px solid #2194F1;
  border-radius: 3px;
  float: left;
  padding: 0 5px;
  margin: 4px 0 0 15px;
  @include item-center;

  label {
    margin: 0;
    color: #2194F1;
    font-size: 12px;
    font-weight: $font-weight-bold;
    //padding-bottom: 2px;
  }
}

.modal-setup-dealer {
  @include item-center;
  display: flex !important;

  .modal-dialog {
    height: auto !important;
    display: flex;
    flex-direction: column;
  }

  .modal-content {
    width: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .modal-body {
    flex-grow: 1;
    display: block !important;
    padding: 0 !important;
    margin-bottom: 20px;

    .panel-header-setup {
      width: 100%;
      border-bottom: 1px solid #EBEBEB;
      padding-bottom: 10px;

      .panel-slice {
        padding: 10px 20px;

        .label-header {
          color: #787878;
          margin-bottom: 0;
          font-size: 13px;
          font-weight: $font-weight-bold;
        }

        .slider-container {
          padding: 0;
          width: 20%;
        }

        .slider-value {
          padding: 0;

          label {
            float: left;
            font-size: 9px;
            color: #787878;
            margin: 0;
            font-weight: $font-weight-bold;
          }
        }

        .input-range__slider {
          background: #2194F1;
          border: none;
          height: 0;
          width: 0;
        }
      }
    }

    .panel-body {
      width: 100%;
      padding-bottom: 10px;

      .panel-header-body {
        width: 100%;
        padding-left: 5px;

        .title {
          color: #787878;
          font-size: 15px;
          font-weight: $font-weight-bold;
        }
      }

      .panel-content-body {
        width: 100%;
        height: 80%;
        display: flex;

        .panel-left {
          border: 10px;
          width: 50%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .panel-right {
          width: 50%;
          height: 100%;

          .container-body-panel {
            width: 75%;
            padding-top: 15px;

            .subtitle {
              color: #787878;
              font-size: 13px;
              font-weight: 600;
            }
          }

          .container-password {
            width: 90%;
            display: block;

            .container-new-password {
              width: 60%;
              float: left;
              padding-top: 10px;

              .message-error {
                top: 2px !important;
                text-align: left;
                float: left;
              }

              .label-select-status {
                font-size: 12px;
                color: #A4AFB7;
                font-weight: $font-weight-regular;
                text-align: left;
                padding: 8px 0 2px 11px;
              }

              .input-password {
                width: 100%;
                border: 1px solid #ccc;
                border-radius: 4px;
                height: $height-box-input;
                padding: 4px 5px 5px 22px;
                text-align: left;
                background-color: white;
                outline: none;

                .label-select {
                  font-size: 13px;
                  color: $text-color;
                  font-weight: 500;
                }

                &:focus {
                  border: 1px solid #ccc !important;
                }
              }
            }

            .container-btn-create {
              width: 100%;
              float: left;

              .btn-create-password {
                background: #2194F1;
                color: white;
                float: right;
                border-radius: 5px;
                border: #D7DAE2 1px solid;
                font-size: 12px;
                height: 36px;
                width: 102px;
                margin-left: 5px;
                font-family: $font-family;
                font-weight: $font-weight-regular;
              }
            }
          }
        }
      }
    }

    .panel-complete {
      width: 100%;
      padding-top: 25px;

      .container-image {
        width: 100%;
        @include item-center;

        img {
          width: 189px;
          height: 153px;
        }
      }

      .title-complete, .sub-title-complete, .container-btn-complete {
        width: 100%;
        @include item-center;

        label {
          font-size: 21px;
          color: #787878;
        }
      }

      .sub-title-complete {
        label {
          font-size: 13px;
        }
      }

      .container-btn-complete {
        padding-top: 20px;

        .btn-complete {
          background: #2194F1;
          color: white;
          border-radius: 5px;
          border: #D7DAE2 1px solid;
          font-size: 12px;
          height: 36px;
          width: 102px;
        }
      }
    }
    .update-location-container {
      padding: 20px;
    }
  }
}

.popover-users {
  .user-image {
    @include user-image;
  }

  @mixin user-name-text() {
    display: block;
    width: calc(100% - 25px);
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    text-overflow: ellipsis;
    padding-left: 5px;
  }

  .user-name-text {
    @include user-name-text
  }

  .popover-general-message {
    max-height: 300px;

    .popover-general-message-sub {
      max-height: 280px;
      overflow: auto;

      .change-submenu-item {
        display: flex;
        align-items: center;

        .salesman-image {
          @include user-image;
        }

        .salesman-not-image {
          @include user-image;
        }

        .user-name {
          @include user-name-text;
        }
      }

      .item-selected {
        display: flex;
        align-items: center;

        .salesman-image {
          @include user-image;
        }

        .salesman-not-image {
          @include user-image;
          padding: 1px;
          background: #FFF;
        }

        .user-name {
          @include user-name-text;
        }
      }
    }

    u {
      li {
        padding: 5px 7px;
      }
    }
  }
}

.dropzone-container {
  outline: none;
}

.dropzone-container-user {
  outline: none;
  width: 96px !important;
  height: 96px !important;
  border-style: none !important;
}

.list-task-vertical {
  @include col100;
  padding-top: 80px;
}

.container-customer-image {
  img {
    width: 100%;
  }
}

.form-control {
  @include form-control;

  ::placeholder {
    color: #ccc !important;
  }
}

.subsection {
  label {
    color: $text-color;
  }
}

.container-tasks {
  float: left;
  width: 100%;

  .content-check {
    display: flex;
    align-items: center;
    width: 100%;
    height: 28px;
    margin-top: 0;

    .checkbox {
      width: 20px !important;
      float: left;
      margin-top: 0;
      margin-bottom: 0;
    }

    .container-label {
      display: flex;
      align-items: center;

      p {
        margin: 0;
      }
    }
  }
}

.rotate {
  transform: rotate(270deg);
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  -webkit-transform: rotate(270deg);
  /* Firefox */
  -moz-transform: rotate(270deg);
  width: 180px;
  position: absolute;
}

.applicant-joint-credit-title {
  width: 50px;
  float: left;
  @include item-center;
  background-color: #F5F6FA;
  border-radius: 0 0 0 10px;

  label {
    color: $text-color;
    font-size: 15px;
    margin-bottom: 0;
    bottom: calc(50% - 10px);
  }

  .img-delete {
    position: absolute;
    top: 15px;
    width: 15px;
    height: 15px;
    left: 18px;
    fill: $icon-color;
  }

  .img-delete:hover {
    cursor: pointer;
    fill: $button-color-red;
  }
}

.applicant-section-wrapper-joint {
  width: calc(100% - 50px);
}

.applicant-joint-credit {
  @include col100;
  display: flex;
  position: relative;
  border-top: 1px #cacaca solid
}

.container-component-without-scroll {
  @include container-component;
  padding-bottom: 20px;
}

.body-view-application {
  float: left;
  overflow: auto;
  width: 100%;

  .container-add-cosigner {
    @include col100;
    text-align: center;
    border-bottom: 1px solid #cacaca;
    border-top: 1px solid #cacaca;

    .btn-add-cosigner {
      height: 32px;
      width: 170px;
      border-radius: 4px;
      font-size: 13px;
      border: 1px $light-blue-color-1 solid;
      background-color: $light-blue-color-1;
      color: #ffffff;
      padding: 1px 5px;
      margin: 20px;
      justify-content: center;
      align-items: center;
      font-family: $font-family;
      font-weight: $font-weight-regular;
    }
  }
}

.container-pill-box {
  float: right;
  padding-top: 10px;
  padding-right: 7px;
}

.view-previous-vehicles {

  .layout-inventory-5 .row-inventory .car-item {
    display: flex;

    .car-image {
      aspect-ratio: 1.33;
    }
  }

  .layout-car-pagination {
    padding: 10px 30px 10px 0;
    width: 90%;
  }

  .layout-finance5 {
    padding: 0 0 50px 0;
  }

}

/**********media queries ************************/

@media screen and (max-width: 1650px) {
  .view-previous-home {
    .banner-container-5 {
      .banner-title-setting-modern-view {
        //top: 260px;
        top: 17%;

        h1 {
          font-size: 70px;
        }
      }

      .container-car-search-input {
        .car-search-input {
          width: 60%;

          .form-control-search {
            width: calc(100% - 145px);
          }
        }
      }

      .banner-title-setting-easy-navigation {
        top: 420px;
      }

      .btn-search-car-content-easy-navigation {
        width: 170px;
      }
    }
  }

  .template-view {
    .layout-footer {
      .container-footer-background {
        .footer-right {
          .title-one, .title-two {
            padding-left: 30px;
          }
        }

        .footer-left {
          padding-right: 30px;
        }
      }
    }
  }
}

@media (max-width: 1480px) {

  .view-previous-home {
    .banner-container-5 {
      .banner-title-setting-modern-view {
        //top: 240px;
        top: 17%;

        h1 {
          font-size: 60px;
        }
      }

      .banner-title-setting-easy-navigation {
        top: 360px;
      }
    }
  }

  .container-footer {
    .container-footer-item {
      .body-footer-items .container-line .row-value .container-select-icon .section-icon {
        padding: 7px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .view-previous-home {
    .banner-container-5 {
      .banner-title-setting-modern-view {
        //top: 200px;
        top: 17%;
        width: 65%;

        h1 {
          font-size: 60px;
        }
      }

      .container-car-search-input {
        .car-search-input {
          width: 65%;
        }
      }
    }

  }

}

@media (max-width: 1310px) {

  .view-previous-home {
    .banner-container-5 {
      .banner-title-setting-modern-view {
        //top: 160px;
        top: 17%;

        h1 {
          font-size: 50px;
        }
      }

      .banner-title-setting-easy-navigation {
        top: 260px;
      }
    }
  }
}

@media (max-width: 1280px) {

  .view-previous-home {
    .banner-container-5 {
      .banner-title-setting-modern-view {
        //top: 160px;
        top: 15%;
        width: 47%;

        h1 {
          font-size: 40px;
        }
      }

      .btn-apply-content-settings-modern-view {
        top: 180px;;
      }

      .banner-title-setting-easy-navigation {
        top: 260px;
      }
    }
  }

  .modal-label-attribute-option {
    font-size: 10px;
  }

  .modal-switch {
    width: 88%;
    height: 20px;
  }

  .slider-modal:before {
    height: 20px;
    width: 20px;
  }

  .modal-check-label-on {
    top: 4px;
  }

  .modal-check-label-off {
    top: 4px;
  }

}

.form-setting-cont {
  width: 22px;
  border-left: 5px dotted #a8b2b8;
  height: 19px;
  float: left;
  margin-top: 11px;
  margin-left: 10px;
}

.form-setting-div {
  float: left;
  width: 100%;
  padding-top: 8px;
  height: 40px;
  border: 1.5px solid #EFEFEF;
  border-radius: 5px;
  color: #787878;
  display: flex;
}

.form-setting-div-multiSelect {
  float: left;
  width: 100%;
  padding-top: 8px;
  height: 40px;
  border-radius: 5px;
  color: #787878;
  display: flex;
}

.form-search-setting {
  border: 0;
  width: 300px;
  padding: 5px;

  a {
    font-size: 18px;
    padding: 17px 15px 17px 30px;
    float: left;
    color: #bebcc7;
  }

  input {
    border: none;
    margin-top: 20px;
  }
}

.no-form-setting-fields {
  float: left;
  width: 40%;
  padding-top: 8px;
  height: 40px;
  border-radius: 5px;
  color: #787878;
  margin-right: 40px;
  margin-bottom: 10px;
}

.form-setting-fields {
  float: left;
  width: 40%;
  padding-top: 8px;
  height: 40px;
  border: 1.5px solid #EFEFEF;
  border-radius: 5px;
  color: #787878;
  cursor: pointer;
  background-color: white;
  margin-right: 40px;
  margin-bottom: 10px;
}

.form-create-setting-fields {
  float: left;
  width: 20%;
  padding-top: 8px;
  height: 40px;
  border: 1.5px solid #EFEFEF;
  border-radius: 5px;
  color: #787878;
  cursor: pointer;
  background-color: white;
  margin-right: 40px;
  margin-bottom: 10px;
}

.form-field-title {
  border-bottom: 5px #2194F1 solid;
  color: #787878;
  margin-left: 10px;
  width: 17%;
}

.form-field-view1 {
  width: 80%;
  margin: 60px auto auto;
  display: block;
}

.attribute-common-field {
  background-color: #F5F6FA;
  float: right;
  height: -webkit-fill-available;
  padding: 10px 0 0 30px;
  position: relative;
  width: 40%;
  height: auto;

  .header-common {
    float: left;
    display: inline-flex;
    margin: 18px 0 18px 0;
    position: relative;
    width: 100%;

    .title {
      width: 90%;
      font-weight: $font-weight-bold;
    }

    .icon {
      cursor: pointer;
      width: 10%;
    }
  }
}

.form-create-attribute {
  height: -webkit-fill-available;
  padding: 0 23.47px 0 25px;
  position: relative;
  width: 100%;

  .header-common {
    float: left;
    display: inline-flex;
    margin: 18px 0 18px 0;
    position: relative;
    width: 100%;

    .title {
      width: 90%;
    }

    .icon {
      cursor: pointer;
      width: 10%;
    }
  }
}

.form-field-img {
  display: inline-block;
  width: 18px;
  margin-left: 15px;
  margin-right: 12px;
}

#icon-attribute {
  width: 14px !important;
}

.form-field-view3 {
  width: 100%;
  position: relative;
  height: 20px;
  margin-top: 10px;
}

.form-setting-fields-view3 {
  width: 93%;
  padding-top: 8px;
  height: 40px;
  border: 1.5px solid #EFEFEF;
  border-radius: 5px;
  color: #787878;
  background-color: white;
  margin-right: 10px;
  margin-bottom: 10px;
}

.form-setting-fields-view_4 {
  width: 93%;
  padding-top: 8px;
  height: 43px;
  border: 1.5px solid #EFEFEF;
  border-radius: 5px;
  color: #787878;
  background-color: white;
  margin-right: 10px;
  margin-left: 6px;
  margin-bottom: 6px;
}

.form-setting-div-option-foot {
  float: left;
  width: 73%;
  padding-top: 8px;
  height: 40px;
  border: 1.5px solid #4AD991;
  border-radius: 5px;
  color: #787878;
  display: flex;
  margin-left: 39px;

  div {
    margin-left: 5px;
  }
}

.form-modal {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 10px #ccc;
  width: 160%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  .modal-header {
    background-color: white;
    color: #787878;
  }

  .section-title {
    margin-bottom: 20px;
  }

  .subsection-title {
    margin: 20px 0 0 10px;
  }

  .btn-form-preview-close {
    top: -18px;
    right: -51px;
    position: absolute;
  }

  .section-box {
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px 20px 50px 20px !important;
    margin: 10px 10px 0 0;

    .section-row {
      float: none;
    }
  }

  .attribute-name {
    margin-top: 10px;
    margin-left: 10px;
    color: #9e9e9e;
    font-weight: $font-weight-regular;
    float: left;
  }
}

.form-modal-fade {
  opacity: 0.00008%;
}

.btn-form-preview-close, .btn-form-preview-close:hover {
  background-color: #ff6564;
  border: none;
  color: white !important;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.hideMessageTab, .showMessageTab {
  display: none;
  position: fixed;
  text-align: center;
  left: 35%;
  top: 35%;
  z-index: 1000;
}

.hideMessageTab {
  left: 32%;
}

.showMessageTab {
  display: block;
}

.popover-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.height-change-row {
  float: left;
  width: 40%;

  .height-change {
    float: left;
    width: 46px;
    position: relative;

    .icon-px {
      position: absolute;
      right: 4px;
      font-size: 12px;
      color: #979797;
      top: 6px;
    }
  }

  .label-height {
    float: right;
    margin: 0;
    padding: 5px 0 0 1px;
    color: #bec1c6;
    font-weight: 300;
    font-size: 12px
  }

  .form-control {
    height: 28px;
    font-size: 12px;
    padding: 5px;
    display: initial;
    border: 1px #EFEFEF solid;
  }
}

.label-inv-info {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 9px;
  font-size: 14px;
}

.label-inv-info {
  font-weight: $font-weight-regular;
}

.label-inv-info:after {
  content: "\00b7 \00b7 \00b7";
  padding-left: 5px;
}

.dp-pagination-container {

  .icon-menu-list-test {
    height: 18px;
    fill: #A4AFB7;
  }

  .icon-arrow {
    width: 16px;
    fill: #A4AFB7;
  }
}

.btn-drop-down-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #DADDE3;
  color: #6b6c78;
  background-color: #fff;
  height: 36px;
  position: relative;

  .btn-drop-down {
    width: 100%;
    padding: 0 10px;
  }

  .label-value {
    float: left;
    margin: 0;
  }

  .icon-arrow-content {
    float: right;
  }


  .icon-arrow-expand {
    width: 14px;
    fill: #A4AFB7;
  }

  .popover-pagination-box {
    float: left;
    width: 100%;
    padding: 10px 10px 5px 10px;
    color: #979797;
    text-align: center;
    position: relative;

    .row-option {
      padding-bottom: 5px;
    }

    .row-option:hover {
      cursor: pointer;
    }
  }

  .btn-drop-down:hover {
    cursor: pointer;
  }

}

.icon-arrow-roles {
  left: 90%;
  position: relative;
  top: -94%;
  width: 20px;
}

@mixin popover-box-category() {
  border: 1px solid #D7DAE2;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding: 10px;
}

.popover-box-category {
  @include popover-box-category;
}

.input-range__slider {
  background: #ffffff;
  border: 1px solid #D7DAE2;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 14px;
  margin-left: -7px;
  margin-top: -11px;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 14px;
}

.input-range__slider:active {
  transform: scale(1.3);
}

.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}

.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
  transition: left 0.3s ease-out;
}

.input-range__label {
  color: #aaaaaa;
  font-family: $font-family;
  font-size: 13px;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min, .input-range__label--max {
  bottom: -20px;
  position: absolute;
}

.input-range__label--min {
  left: -4px;
  display: none;
}

.input-range__label--max {
  right: -4px;
  display: none;
}

.input-range__label--value {
  position: absolute;
  top: 10px;
  left: -15px;
  display: none;
}

.input-range__label-container {
  position: relative;
}

.input-range__track {
  background: #D7DAE2;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  height: 8px;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}

.input-range--disabled .input-range__track {
  background: #D7DAE2;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: #2194F1;
}

.input-range {
  height: 20px;
  position: relative;
  width: 100%;
}

.btn-content-filter-pie {
  padding: 0 4px 0 4px;
  float: right;
  position: relative;

  a {
    color: #A4AFB7;
    text-decoration: none;
    font-size: 11px;
    margin-right: 11px;
  }

  a:hover {
    color: #2194F1;
    cursor: pointer;
  }

  button {
    float: right;
    border-radius: 4px;
    background-color: #2194F1;
    border: 1px solid #D7DAE2;
    box-shadow: 0 2px 3px #0000000D;
    font-size: 9px;
    padding: 2px 10px;
    margin-top: 3px;
  }
}

.slider-container {
  padding: 0 10px;
}

.slider-value {
  padding: 10px 5px 0 5px;
  float: left;
  width: 100%;

  label {
    float: left;
    font-size: 13px;
    color: #787878;
    margin: 0;
  }

  .value-max {
    float: right;
  }
}


/******* FINISH SETTING TEMPLATE ******/

/**************Message Modal Start***************/

.content-message-arrow {
  padding-left: 68px;
  margin-right: 0;
  margin-left: 0;
  height: 10px;
  line-height: 0;
  left: 0;
  padding-right: 0;
  position: relative;
  width: 761px;

  .arrow-behind {
    display: inline-block;
    width: 34px;
    height: 11px;
    position: relative;
    padding-top: 0;
    z-index: 2;
    border-left: 17px solid transparent;
    border-right: 17px solid transparent;
    border-bottom: 10px solid #d0cbcb;
    left: 22px;

    .arrow-on {
      display: inline-block;
      width: 34px;
      position: absolute;
      height: 10px;
      padding-top: 0;
      z-index: 1;
      border-left: 17px solid transparent;
      border-right: 17px solid transparent;
      border-bottom: 10px solid rgb(255, 255, 255);
      top: 2px;
      left: -17px;
    }
  }
}

.content-message-title {
  margin-left: 0;
  margin-right: 0;
  background-color: white;
  height: 71.34px;
  border-radius: 10px 10px 0 0;
  border: 0.6px solid rgb(214, 215, 221);
  border-bottom: none;
  width: 761px;
  border-image: initial;
  box-shadow: 0 2px 10px #d9d9d9;

  .content-message-title-left {
    height: 28px;
    left: 41.4px;
    width: 133px;
    position: relative;
    text-align: left;
    color: rgb(120, 120, 120);
    line-height: 30px;
    top: 25.34px;
    font-size: 21px;
    font-family: $font-family;
    padding-left: 0;
    padding-right: 0;
    min-height: 0;
  }

  .content-message-title-right {
    position: relative;
    top: 22.34px;
    left: 674px;
    width: 63px;
    height: 32px;
    text-align: center;

    .new-message-trash {
      position: absolute;
      top: -37px;
      left: 37px;
      width: 51px;
      height: 21px;
      color: #dadada;

      i {
        font-size: 18px !important;
      }

      i:hover {
        color: rgb(0, 150, 248);
        cursor: pointer;
      }
    }
  }
}

.content-message-from {
  height: 25.35px;
  top: 71.34px;
  border: 0.6px solid rgb(214, 215, 221);
  border-top: none;
  border-bottom: none;
  border-image: initial;
  background-color: white;
  left: 0;
  width: 761px;
  margin-left: 0;
  margin-right: 0;

  span {
    left: 33px;
    font-family: $font-family;
    font-size: 13px;
    color: rgb(120, 120, 120);
    width: 37px;
    float: left;
    line-height: 17px;
    position: relative;
  }

  input {
    left: 33px;
    font-family: $font-family;
    font-size: 13px;
    color: rgb(120, 120, 120) !important;
    width: 164.69px;
    float: left;
    line-height: 17px;
    position: relative;
    border: none;
    top: -1px;
  }
}

.content-message-subject {
  height: 25.35px;
  top: 104.69px;
  border: 0.6px solid rgb(214, 215, 221);
  border-top: none;
  border-bottom: none;
  border-image: initial;
  background-color: white;
  margin-left: 0;
  margin-right: 0;
  width: 761px;
  position: absolute;

  span {
    left: 33px;
    font-family: $font-family;
    font-size: 13px;
    color: rgb(120, 120, 120);
    width: 49px;
    float: left;
    line-height: 17px;
    position: relative;
  }

  input {
    left: 33px;
    font-family: $font-family;
    font-size: 13px;
    color: rgb(120, 120, 120) !important;
    width: 164.69px;
    float: left;
    line-height: 17px;
    position: relative;
    border: none;
    top: -1px;
  }
}

.content-message-to {
  height: 58.89px;
  top: 131.04px;
  border: 0.6px solid rgb(214, 215, 221);
  border-top: none;
  border-image: initial;
  position: absolute;
  background-color: white;
  margin-left: 0;
  margin-right: 0;
  width: 761px;

  .to-title {
    left: 33px;
    font-family: $font-family;
    font-size: 13px;
    color: rgb(120, 120, 120);
    width: 27px;
    float: left;
    line-height: 17px;
    position: relative;
  }

  .content-list-to {
    border: none;
    width: 107%;
    border-radius: 10px;
    float: left;

    .list-to {
      top: 0;
      left: 57px;
      width: 550px;
      margin-top: -3px;
      position: absolute;
      overflow-y: auto;
      height: 46px;
      color: #859ccd;
      float: left;

      .to {
        padding: 1px 7px;
        border-radius: 3px;
        float: left;
        margin: 0 5px 0 10px;
        font-family: $font-family;
        font-size: 13px;
        color: #4F7CFF;
        line-height: 17px;
        position: relative;
        border: 0.5px solid #4F7CFF;
        opacity: inherit;
        height: 21px;
        max-height: 21px;
        text-align: left;
        background-color: #EDF1FF;

        span {
          font-size: 12px;
          float: left;
        }

        i {
          float: left;
          padding-left: 10px;
          padding-top: 5px;
          font-size: 9px;
        }
      }

      input {
        margin: 0;
        font-weight: 300;
        border: none;
        resize: none;
        outline: 0;
        width: 300px;
        font-size: 12px;
        height: 21px;
      }
    }
  }
}

.content-message-send {
  width: 161px;
  height: 64px;
  padding-top: 0;
  cursor: pointer;
  left: 600px;
  background-color: white;
  top: 359px;
  border-image: initial;
  position: absolute;
  border-radius: 0 0 10px 0;
  border: 1.6px solid rgb(214, 215, 221);
  border-left-style: none;

  .send-icon {
    height: 32px;
    cursor: pointer;
    width: 32px;
    left: 90px;
    top: 10px;
    position: absolute;
  }
}

.footer-reply-new-message {
  background-color: white;
  height: 63px;
  padding-left: 15px;
  border: 1px solid rgb(214, 215, 221);
  width: 760px;
  border-radius: 0 0 10px 10px;
  margin-left: -1px;
  float: left;

  .options-reply, .contentDiv {
    display: inline-flex;
    padding-top: 14px;
    width: 80%;

    .drop-image {
      border-style: none;
      margin-left: 2px;
      width: 13px;
      height: 22px;
    }

    .btn-format-bold, .btn-format-italic, .btn-format-stk, .btn-underline, .btn-format-color, .btn-list-bullet, .btn-insert-link,
    .btn-image-photography, .btn-attach-file, .btn-align {
      margin: 8px 4px 0 0;
      width: 17px;
      cursor: pointer;

      div {
        pointer-events: none;

        svg {
          pointer-events: none;
        }
      }
    }

    .btn-align {
      width: 15px;
    }

    .btn-format-bold, .btn-format-italic {
      width: 11.5px;
    }

    .btn-format-italic {
      width: 12.5px;
    }

    .btn-underline {
      width: 12px;
    }

    .btn-attach-file {
      margin-top: 7px;
      width: 8px;
    }

    .btn-image-photography {
      width: 18px;
    }

    .font-family {
      border: 1px solid #efefef;
      border-radius: 6px;
      height: 31px;

      .select-font-family {
        cursor: pointer;
        font-family: $font-family;
        font-size: 13px;
        width: 100px;
        height: 29px;
        border-color: transparent;
      }

      .select-font-family:focus {
        outline: 0;
      }
    }
  }
}

.content-draft-editor-rootContainer {
  border: 1px solid rgb(214, 215, 221);
  @include col100;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 10px #d9d9d9;
  background: white;
  padding-bottom: 10px;
  position: relative;
  top: 81px;

  .DraftEditor-rootContainer {
    border-bottom: 0.6px solid #d6d7dd;

    .DraftEditor-root {
      border: none;
    }
  }
}

@mixin popover-body-status() {
  padding: 0;
  margin: 0;
  color: #979797;
  z-index: 100;
  position: relative;

  li {
    list-style: none;
    padding-bottom: 5px;
  }

  li:hover {
    cursor: pointer;
  }

  .header {
    display: flex;
    justify-content: right;
    width: 100%;

    a {
      width: auto !important;
    }
  }
}

.popover-body-status {
  @include popover-body-status;
}

.popover-body-locations {
  @include popover-body-status;
}

.user-pagination {
  position: absolute;
  right: 10px;
  font-size: 12px;
  padding: 13px;
}

.inventory-pagination, .customer-pagination, .sales-pagination {
  @include base-pagination;
}

.modal-car, .modal-deactivate, .modal-delete, .modal-cancel-sale, .modal-delete-sale, .modal-delete-purchaser {
  @include item-center;
  display: flex !important;

  .modal-dialog {
    width: 60%;
  }

  .container-message-delete-task-in, .container-message-activate-task-in {
    text-align: left;
    color: #787878;
    font-size: 18px;

    .title {
      display: block;
      font-size: 15px;
      font-weight: $font-weight-bold;
      padding: 0 10% 10px;
    }

    .sub-title {
      display: block;
      padding: 0 10%;
      font-size: 13px;
    }
  }

  .container-message-activate-task-in {
    padding-top: 20px;

    .title {
      padding: 0;
    }

    .sub-title {
      padding: 0;
    }
  }

  .btn-delete-task {
    height: 36px;
    border-radius: 4px;
    position: absolute;
    left: 10%;
    background-color: #ff565e;
    border: 1px solid #D7DAE2;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100px;
    font-size: 13px;
  }

  .modal-content {
    @include col100;
    padding: 0;
    border-radius: 0;
  }

  .modal-body {
    min-height: 300px;
    float: left;
    padding: 0;
    width: 100%;
    @include item-center;
  }

  .panel-left {
    float: left;
    width: 40%;
    background-color: #ff565e;
    min-height: 300px;
    @include item-center;
    position: relative;
    overflow: hidden;

    .car-run {
      position: absolute;
      bottom: 20px;
      right: 0;
    }

    .ic-car-run {
      fill: #ffffff;
    }

    h2 {
      margin: 0;
      color: #ffffff;
      font-family: $font-family;
      font-weight: $font-weight-regular;
    }

    .icon-delete {
      position: absolute;
      bottom: -20px;
      left: -18px;
    }

    .icon-delete-svg {
      width: 100px;
      height: 100px;
      fill: #FF9C9C;
    }

    .icon-close-content {
      position: absolute;
      top: 25px;
      right: 25px;
    }

    .icon-close {
      width: 32px;
      height: 32px;
      fill: #ffffff;
    }
  }

  .panel-left-custom {
    width: 40%;
    background-color: #F5F6FA;
    height: 300px;
    @include item-center;
    overflow: hidden;

    .car-run {
      bottom: 20px;
      right: 0;

      svg {
        width: 200px;
        height: auto;

        g {
          path {
            stroke: none;
          }
        }
      }
    }

    .ic-car-run {
      fill: #ffffff;
    }

    h2 {
      margin: 0;
      color: #ffffff;
    }

    .icon-delete {
      position: absolute;
      bottom: -20px;
      left: -18px;
    }

    .icon-delete-svg {
      width: 100px;
      height: 100px;
      fill: #daa4a2;
    }

    .icon-close-content {
      position: absolute;
      top: 25px;
      right: 25px;
    }

    .icon-close {
      width: 32px;
      height: 32px;
      fill: #ffffff;
    }
  }

  .panel-right {
    float: left;
    width: 60%;
    position: relative;
    min-height: 300px;
    padding: 94px 0;
    text-align: center;

    .car-run, .car-run-right {
      position: absolute;
      bottom: 27px;
      left: 0;
    }

    .ic-car-run {
      fill: #ff565e;
    }
  }

  .panel-right-modal-dealer {
    width: 60%;

    .container-message {
      padding: 0 60px;

      p {
        color: #787878;
      }
    }

    .container-button {
      padding: 40px 60px 0 60px;

      button {
        border-radius: 4px;
        width: 100%;
        color: #FFFFFF;
        height: $height-box-input;
        border: none;
      }

      .btn-deactivate {
        background-color: $button-color-red;
        border: none;

        &:focus {
          border: 1px solid $button-color-red !important;
        }
      }

      .btn-activate {
        background-color: $green-color;
        border: none;

        &:focus {
          border: 1px solid $green-color !important;
        }
      }
    }
  }

  .btn-not-save-custom {
    height: 35px;
    border-radius: 4px;
    background-color: #FFFFFF;
    border: 1px solid #2194F1;
    color: #2194F1;
    margin-right: 10px;
  }

  .btn-not-save-custom:focus {
    border: 1px solid #2194F1 !important;
  }

  .btn-save-custom {
    height: 36px;
    border-radius: 4px;
    background-color: #2194F1;
    border: 1px solid #D7DAE2;
    color: #FFFFFF;
    font-family: $font-family;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 92px;
    font-weight: $font-weight-regular;
  }

  .btn-not-save {
    height: 35px;
    background-color: #fff;
    color: #787878;
    border-radius: 5px;
    border: 1px solid #787878;
    margin-right: 10px;
  }

  @mixin btn-save() {
    height: 36px;
    border-radius: 5px;
    background-color: #00de8a;
    border: 1px solid #00de8a;
    color: #ffffff;
  }

  .btn-save {
    @include btn-save;
  }

  .icon-close-content {
    background: white;
    border-radius: 17px;
    height: 30px;
    position: absolute;
    top: 25px;
    right: 25px;
  }

  .icon-close {
    width: 32px;
    height: 32px;
    fill: #ff565e;
  }

  .icon-close:hover {
    cursor: pointer;
  }

  .panel-message-custom {
    padding: 40px 0;
    text-align: center;
  }

  .panel-message {
    padding: 0 20px 20px 0;
    text-align: left;

    div {
      label {
        letter-spacing: 0;
        color: $text-color;
        font-weight: 600;
      }

      .link-vin-exist {
        font-size: 10px;

        span {
          cursor: pointer;
          color: #2194F1;
        }
      }

      span {
        float: left;
        font-size: 13px;
        color: $text-color;
        margin-bottom: 10px;
        width: 100%;
        font-weight: $font-weight-regular;
      }
    }

    a {
      cursor: pointer;
    }

    .modal-update-status-header {
      font-size: 15px;
      color: #787878;
    }

    .modal-update-status-boxes {
      display: flex;
      padding-top: 20px;

      .box {
        padding: 7px 12px;
        border-radius: 4px;
        border: 1px solid #D7DAE2;
        font-size: 13px;
      }

      .sold-box {
        color: $button-color-red;
      }

      .new-state-box {
        color: #2194F1;
      }

      .icon-arrow {
        width: 13px;
        margin: 10px 10px 0;
      }
    }

    .has-sale-message {
      margin-top: 20px;
    }
  }


  .btn-delete {
    @include btn-save;
    background-color: #ff565e;
    border: 1px solid #ff565e;
    font-family: $font-family;
    font-weight: $font-weight-regular;
  }

  .icon-close-cancel-sale, .icon-close-cancel-sale-left {
    width: 30px;
    position: absolute;
    top: -15px;
    right: -15px;

    .icon-close-svg {
      width: 32px;
      height: 32px;
      fill: #ffffff;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .icon-close-cancel-sale-left {
    display: none;
  }

  .panel-right-cancel-sale {
    float: left;
    width: 60%;
    position: relative;
    min-height: 328px;
  }

  .label-cancel {
    padding-bottom: 10px;

    label {
      color: #787878;
      font-family: $font-family;
      margin-bottom: 0;
      font-weight: $font-weight-regular;
    }
  }

  .label-required {
    float: left;
    color: $button-color-red;
    font-size: 12px;
    width: 100%;
    font-family: $font-family;
    font-weight: $font-weight-light;
  }
}

.modal-car, .modal-cancel-sale, .modal-info {
  .modal-content {
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 30px #00000029;

    .modal-body {
      .panel-left, .panel-left-custom {
        border-radius: 10px 0 0 10px;
      }

      .panel-right {
        text-align: inherit;

        .icon-close-content {
          top: -16px;
          right: -16px;

          div {
            height: 32px;
            border-radius: 50%;
            background-color: #FFF;
          }
        }

        .label-sure {
          text-align: center;
          color: $text-color;
          padding: 0 20px;
          font-weight: $font-weight-bold;
        }

        .container-message-delete {
          padding: 0 50px;

          .label-message {
            font-family: $font-family;
            font-weight: $font-weight-bold;
            color: $text-color;
            font-size: 15px;
            width: 100%;
          }

          .label-sub-message {
            font-family: $font-family;
            font-weight: $font-weight-regular;
            color: $text-color;
            font-size: 13px;
          }
        }

        .modal-buttons {
          width: 100%;
          float: left;
          margin-top: 30px;
          padding: 0 30%;

          button {
            width: 130px;
          }

          .btn-not-save {
            background-color: $button-color-red;
            color: #FFF;
            border: 1px solid $button-color-red;
            font-family: $font-family;
            font-weight: $font-weight-regular;
          }

          .btn-not-save-custom {
            float: left;
          }

          .btn-save-custom {
            float: right;
          }
        }

        .modal-buttons-delete {
          text-align: center;
          padding-top: 30px;

          .btn-delete {
            width: 120px;
          }
        }
      }

      .panel-add-car-to-inventory {
        padding: 90px 5%;

        .icon-close-content {
          width: 30px;

          &:hover {
            cursor: pointer;
          }
        }

        .titles {
          width: 100%;

          .title {
            width: 100%;
            display: block;
            font-size: 15px;
            color: $text-color;
            font-weight: $font-weight-bold;
          }

          .subtitle {
            width: 100%;
            display: block;
            font-size: 13px;
            color: $text-color;
            padding-top: 2px;
          }
        }

        .btn-save-custom {
          width: 135px;
          margin-top: 20px;
          background-color: $green-color;
          border: none;
        }
      }
    }
  }
}

.modal-car {
  .modal-body {
    .loader-container {
      position: absolute;
      z-index: 100;
    }
  }

  .loader-container {
    background: white;
  }
}

.modal-deactivate {
  .modal-dialog {
    width: 602px;
    height: 302px;
  }

  .modal-content {
    border-radius: 10px;
    border: 0;
  }

  .panel-left {
    background-color: #F5F6FA;
    border-radius: 10px 0 0 10px;
    overflow: hidden;

    .car-run {
      bottom: 20px;
      right: 0;
    }

    .ic-car-run {
      fill: #ffffff;
    }

    h2 {
      margin: 0;
      color: #ffffff;
    }

    .icon-warning-svg {
      width: 150px;
      height: 150px;
      fill: #daa4a2;

      .b {
        stroke: #FFF;
      }
    }

    .icon-close-content {
      position: absolute;
      top: -13px;
      right: -13px;
    }

    .icon-close {
      width: 30px;
      height: 30px;
      fill: #ffffff;
    }
  }

  .panel-right {
    float: left;
    width: 60%;
    position: relative;
    min-height: 300px;
    margin: 0 auto;
    vertical-align: middle;
    padding: 85px 10px 0 35px;
    text-align: center;

    .icon-close-content {
      position: absolute;
      top: -13px;
      right: -13px;

      &:hover {
        cursor: pointer;
      }
    }

    .icon-close {
      width: 30px;
      height: 30px;
      fill: #ffffff;
      border: 1px solid #D7DAE2;
      border-radius: 16px;
    }

    .btn-action {
      text-align: left;

      .btn-delete {
        font-size: 13px;
        background-color: #2194F1;
        border: 1px solid #D7DAE2;
        height: 36px;
        width: 140px;
        border-radius: 4px;
      }
    }

    .delete-superuser {
      padding-top: 15%;
      display: flex;
      color: $button-color-red;
      font-size: 11px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.modal-delete, .modal-delete-sale {
  .modal-dialog {
    width: 602px;
    height: 302px;
  }

  .modal-content {
    border-radius: 10px;
    border: 0;
  }

  .panel-left {
    h2 {
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      width: 98px;
    }
  }

  .panel-left-delete {
    background-color: $button-color-red;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    width: 38%;

    h2 {
      margin: 0;
      color: #ffffff;
      padding-left: 38px;
      padding-right: 38px;
      text-align: center;
      font-size: 28px;
    }

    .icon-delete-svg {
      fill: #FF9C9C;
      width: 120px;
      height: 120px;
    }

    .icon-warning-svg {
      width: 150px;
      height: 150px;
      fill: #daa4a2;
    }

    .icon-close-content {
      position: absolute;
      top: -13px;
      right: -13px;
    }

    .icon-close {
      width: 30px;
      height: 30px;
      fill: #ffffff;
    }
  }

  .panel-right-delete {
    float: left;
    width: 62%;
    position: relative;
    min-height: 300px;
    margin: 0 auto;
    vertical-align: middle;
    text-align: left;
    padding: 40px 30px 10px 50px;

    .icon-close-content {
      position: absolute;
      top: -13px;
      right: -13px;
    }

    .icon-close {
      width: 30px;
      height: 30px;
      fill: #ffffff;
      border: 1px solid #D7DAE2;
      border-radius: 16px;
    }

    .panel-body-delete {
      position: relative;
      width: 100%;
      float: left;

      .message-error {
        left: 0;
      }

      .text-confirmation {
        text-align: left;
        padding-bottom: 5px;

        .title-confirmation {
          font: Semibold 15px/18px Segoe UI;
          letter-spacing: 0;
          color: #787878;
          font-size: 15px;
          font-weight: $font-weight-bold;
          margin: 0;
        }

        .title-sub-message {
          font-family: $font-family;
          font-weight: $font-weight-regular;
          color: $text-color;
          font-size: 13px;
        }
      }

      .btn-action {
        text-align: left;

        .btn-delete {
          background-color: #2194F1;
          border: 1px solid #D7DAE2;
          height: 36px;
          width: 130px;
          border-radius: 4px;
        }
      }

      .container-new-password {
        width: 100%;
        float: left;
        padding-bottom: 20px;

        .message-error {
          top: 2px !important;
          text-align: left;
          float: left;
        }

        .label-select-status {
          font-size: 12px;
          color: #A4AFB7;
          font-weight: 500;
          text-align: left;
          padding: 8px 0 2px 11px;
        }

        .icon-padlock {
          position: absolute;
          width: 25px;
        }

        .icon-open-padlock {
          height: 18px;
          margin-top: 7px;
          margin-left: 8px;
          fill: #A4AFB7;
        }

        .input-password {
          width: 100%;
          border: 1px solid #ccc;
          border-radius: 4px;
          height: $height-box-input;
          padding: 4px 5px 5px 22px;
          text-align: left;
          background-color: white;
          outline: none;

          .label-select {
            font-size: 13px;
            color: $text-color;
            font-weight: 500;
          }

          &:focus {
            //border: 1px solid #ccc !important;
          }
        }
      }

    }

    .btn-save-custom {
      height: 36px;
      width: 90px;
      border-radius: 4px;
      background-color: $button-color-red;
      border: 1px solid #D7DAE2;
      color: #FFFFFF;
    }

    .container-icon-sub-title {
      display: flex;
      text-align: left;
      width: 100%;
      float: left;
      padding-top: 28px;

      .container-icon-exclamation {
        padding: 0;

        .icon-error div {
          svg {
            height: 22px;
            width: 22px;
            fill: $icon-exclamation-color;
            border: 1px solid $border-color-icon-exclamation;
            border-radius: 50px;
          }
        }
      }

      .container-sub-title {
        .sub-title-coming-soon {
          padding: 2px 6px 0 0;
          color: $text-color;

          span {
            font-family: $font-family;
            font-weight: $font-weight-bold;
          }
        }
      }
    }

  }

  .panel-right-delete-user {
    .container-button-delete {
      @include item-center;
    }

    .container-button-delete-sale {
      padding: 30px 50px;
    }
  }
}

.modal-delete-sale, .modal-delete-purchaser {
  .modal-dialog {
    width: 40%;
  }
}

.modal-delete-payment-method {
  .panel-right {
    .panel-message {
      span {
        font-size: 12px;
      }
    }

    .btn-action {
      .btn-delete-payment {
        background-color: $button-color-red;
        font-size: 12px;
        border: none;
      }
    }
  }
}

.line-separator-status {
  border-top: 1px solid #d1d2d5;
  padding-top: 5px;
}

.flex-row {
  display: flex;
}

@mixin flex-item($flex:1) {
  flex-grow: $flex;
  flex-basis: 0;
}

.flex-colR1 {
  flex: 1 1 0;
  padding-right: 10px;
}

.flex-colR2 {
  flex: 2 2 0;
  padding-right: 10px;
}

.flex-colR3 {
  flex: 3 3 0;
  padding-right: 10px;
}

.flex-col-r15 {
  width: 15%;
  padding-right: 10px;
}

.flex-col-r18 {
  width: 18%;
  padding-right: 10px;
}

.item-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-message-header {
  position: absolute;
  left: 80px;

  .icon-back-content {
    @include item-center;
    float: left;
    height: 53px;
    position: absolute;
  }

  .icon-back-content:hover {
    cursor: pointer;
  }

  .icon-back {
    width: 20px;
    padding-top: 5px;
    fill: #2194F1;
  }
}

.tab-message-container {
  margin: auto;
  @include item-center;
  height: 100%;

  .btn-tab {
    border-left: none;
    color: #2194f1;
    z-index: 10;
    height: 100%;
    @include item-center;
    width: 80px;
  }

  .btn-tab:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.box-edit-car {
  @include col100;
  @include shadow-body;
}

.toggle-circle {
  background-color: #2194F1;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  float: left;
  position: relative;
  cursor: pointer;
}

.label-check-image-main {
  float: left;
  padding-left: 10px;
  color: #787878;
  font-weight: $font-weight-regular;
}

.container-tile-subtitle {
  .container-title-switch {
    display: flex;
    height: 28px;

    .box-title {
      h5 {
        font-size: 20px;
        color: #787878;
        font-weight: 600;
      }
    }

    .box-switch {
      padding-left: 20px
    }
  }

  .container-sub-title {
    padding-left: 15px;
  }
}

.label-message-to-save {
  font-size: 11px;
  color: #43425D;
  font-weight: $font-weight-regular;
  padding: 7px 0 0 7px;
}

.modal-backdrop {
  background-color: #fff;
}

.pill-container, .pill-container-group, .pill-container-inventory {
  background-color: #F5F6FA;
  color: #ffffff;
  border-radius: 4px;
  width: 60px;
  float: left;
  margin-right: 2px;
  position: relative;
  z-index: 1;
  padding: 0 1px 2px;

  a {
    padding-left: 4px;
    color: #D7DAE2;
    font-size: 10px;
  }

  a:hover {
    cursor: pointer;
    text-decoration: none;
    color: #2194F1;
  }

  .label-value {
    padding-top: 1px;
    margin: 0;
    font-size: 11px;
    position: absolute;
    top: 2px;
    left: 14px;
    color: #787878 !important;
  }
}

.pill-container-group {
  width: 72px;
}

.page-on-build {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.popover-setting {
  width: 170px;
  top: 60px;
  z-index: 200;

  ul {
    padding: 0 5px;
    text-align: left;

    li {
      list-style: none;
      color: #787878;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.tab-testimonials, .tab-inventory, .tab-location, .tab-customer, .tab-user, .tab-form, .tab-marketing, .tab-template, .tab-sales {
  @include tab-general;
}



/* Setting - Marketing */
.setting-marketing {
  display: flex;
  flex: 1;

  .social-media-buttons {
    border-right: 2px solid #D7DAE2;

    a {
      padding: 6px 0;
      margin-bottom: 18px;
      width: 167px;
      height: 38px;
      border: 1px solid transparent;
      border-radius: 7px;
      font-size: 13px;
      color: white;
      display: flex;
      align-items: center;
    }

    .facebook-button {
      background: linear-gradient(180deg, #006BF6, #00A8FF);
    }

    .instagram-button {
      background: linear-gradient(180deg, #F121D5, #9C39FF);
    }

    .twitter-button {
      background: linear-gradient(180deg, #8FCCFD, #4EB0FF);
    }

    .google-button {
      background: linear-gradient(180deg, #F91414, #FF5D15);
    }

    .facebook-icon {
      width: 8px;
      margin: 0 15px;
    }

    .instagram-icon, .twitter-icon {
      width: 14px;
      fill: white;
      margin: 0 12px;
    }

    .google-icon {
      width: 9px;
      margin: 0 15px;
    }

    .google-icon-svg {
      width: 13px;
      fill: white;
      height: auto;
    }
  }

  .social-media-container-items {
    border-right: 1px solid #D7DAE2;
    width: 269px;

    .social-media-item {
      width: 100%;
      height: 42px;
      border-bottom: 1px #D7DAE2 solid;
      color: #8F979D;
      display: flex;
      align-items: center;
      position: relative;

      &:hover {
        cursor: pointer;
        background-color: #F5F6FA;
      }

      .social-media-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 100%;

        div {
          height: 25px;
          width: 25px;

          svg {
            height: 100%;
            width: 100%;
          }
        }
      }

      .social-media-icon-rectangle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 100%;

        div {
          height: 25px;
          width: 40px;

          svg {
            height: 100%;
            width: 100%;
          }
        }
      }

      .social-media-text {
        width: 65%;
      }

      .check-icon {
        fill: #4AD991;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 100%;

        div {
          height: 15px;
          width: 15px;

          svg {
            height: 100%;
            width: 100%;
          }
        }
      }

      .loader-container{
        .loader{
          width: 32px;
          height: 32px;
          border: 4px solid #f3f3f3;
          border-top: 4px solid #3498db;
        }
      }
    }

  }

  .social-media-login {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    flex: 1;
    border-right: 1px solid #D7DAE2;

    .instagram-settings-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;

      .user-info {
        display: flex;
        align-items: center;
        width: 88%;
        margin-top: 10px;
        flex-direction: column;

        .instagram-logo {
          svg {
            height: 35px;
            width: 104px;
          }
        }
      }

      .instagram-info-container {
        width: 88%;
        margin: 15px 0;

        label {
          font-family: $font-family;
          font-weight: $font-weight-light;
          font-size: 9px;
          padding-left: 10px;
        }
      }

      .instagram-error-container {
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
          height: 128px;
          width: 157px;
          margin: 20px 0;
        }

        p {
          text-align: center;
          font-family: $font-family;
          font-weight: $font-weight-light;
          font-size: 13px;
        }
      }

      .instagram-btn-content {
        @include col100;
        text-align: center;
        padding-top: 50px;

        .button-sign-out {
          width: 100px;
          background-color: $button-color-red;
          color: white;
          border: 1px solid $button-color-red;
          border-radius: 4px;
          padding: 6px 0;
        }
      }
    }

    .google-settings-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;

      .google-logo {
        svg {
          height: 35px;
          width: 104px;
        }
      }
      .user-info {
        display: flex;
        align-items: center;
        width: 88%;
        margin-top: 10px;
        flex-direction: column;

        img {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          box-shadow: 0 0 10px #A4AFB7;
          object-fit: cover;
        }
      }

      .google-info-container {
        width: 88%;
        margin: 15px 0;

        label {
          font-family: $font-family;
          font-weight: $font-weight-light;
          font-size: 9px;
          padding-left: 10px;
        }
      }

      .google-btn-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;

        .sign-out {
          color: $button-color-red;
          cursor: pointer;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }

    .facebook-login-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;

      .facebook-title {
        color: #2194F1;
        font-size: 21px;
        font-weight: 600;

        label {
          font-weight: $font-weight-regular;
        }
      }

      .button-face {
        width: 100px;
        background-color: #2194F1;
        color: white;
        border: 1px solid #D7DAE2;
        border-radius: 4px;
        padding: 6px 0;
      }

      .login {
        background-color: #2194F1;
      }

      .sign-out {
        background-color: $button-color-red;
        border-color: $button-color-red;
      }

      .user-info {
        display: flex;
        align-items: center;
        width: 88%;
        margin-top: 10px;
        flex-direction: column;

        .google-logo {
          svg {
            height: 35px;
            width: 104px;
          }
        }

        img {
          border-radius: 25px;
          box-shadow: 0 0 10px #A4AFB7;
        }

        label {
          margin: 0;
          padding-top: 5px;
          color: #787878;
          font-weight: $font-weight-regular;
        }
      }

      .facebook-info-container {
        width: 88%;
        margin: 15px 0;

        label {
          font-family: $font-family;
          font-weight: $font-weight-light;
          font-size: 9px;
          padding-left: 10px;
        }
      }

      .password-container {
        display: flex;
        align-items: center;
        width: 88%;
        margin-top: 16px
      }

      .input-container {
        width: 100%;
        padding-left: 7px;

        ::placeholder {
          color: #D7DAE2;
        }
      }

      .input-container-disabled {
        width: 100%;
        padding-left: 7px;
        pointer-events: none;
      }

      .login-icons {
        width: 18px;
        fill: #A4AFB7;
      }

      .token-input {
        width: 88%;
        padding-left: 25px;
        margin-top: 35px;

        ::placeholder {
          color: #D7DAE2;
        }
      }

      .user-icon-svg {
        width: 17px;
        height: auto;
      }

      .facebook-switch-options {
        width: 88%;
        padding-top: 20px;

        .facebook-switch-option {
          display: flex;
          justify-content: space-between;
          padding-top: 5px;
          color: #787878;

          label {
            font-weight: $font-weight-regular;
          }
        }
      }

      .fb-btn-content {
        @include col100;
        text-align: center;
        padding-top: 50px;
      }

      .fb-page {
        background-color: #2194F1;
        border-color: #2194F1;
        margin-right: 10px;
      }
    }

    .carfax-settings-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;

      .user-info {
        display: flex;
        align-items: center;
        width: 88%;
        margin-top: 10px;
        flex-direction: column;

        .google-logo {
          svg {
            height: 35px;
            width: 104px;
          }
        }
      }

      .carfax-review-url-container {
        width: 88%;
        margin: 15px 0;

        label {
          font-family: $font-family;
          font-weight: $font-weight-light;
          font-size: 9px;
          padding-left: 10px;
        }

        p {
          font-family: $font-family;
          font-weight: $font-weight-regular;
          font-size: 13px;
          color: #787878;
        }

        textarea {
          font-family: $font-family;
          font-weight: $font-weight-regular;
          font-size: 13px;
        }

        img {
          width: 100%;
        }
      }

      .carfax-btn-content {
        @include col100;
        text-align: center;
        padding-top: 15px;

        .button-save {
          width: 100px;
          background-color: #4AD991;
          color: white;
          border: 1px solid #4AD991;
          border-radius: 4px;
          padding: 6px 0;
        }
      }
    }
  }

  .marketing-graphics {
    @include item-center;
    flex: 3;

    .graphics-container {
      display: flex;
      width: 90%;
      align-items: center;
      justify-content: space-around;

      .graphic-text {
        @include item-center;
        flex-direction: column;

        img {
          width: 360px;
          height: 180px;
          margin-bottom: 20px;
        }

        label {
          color: #A4AFB7;
          font-size: 21px;
          font-weight: 600;
        }
      }
    }

    .arrow-marketing {
      width: 50px;
      height: 50px;
      fill: #415B91;
    }

  }

  .successfully-linked-social-media {
    width: 30%;
    left: 35%;
    height: 362px;
    z-index: 200;
    top: 30%;
    border: 1px solid #A4AFB7;
    border-radius: 4px;
  }
}

@mixin marketing-modal-container() {
  @include item-center;
  display: flex !important;
  .marketing-modal {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    height: 100%;
    flex-direction: column;
    z-index: 200;
    position: relative;

    .marketing-modal-title {
      flex: 1;
      @include item-center;
      font-size: 16px;
      color: #FFFFFF;
      width: 100%;
      background-color: #4AD991;
      min-height: 50px;
    }

    .close-modal-icon {
      width: 32px;
      position: absolute;
      top: -15px;
      right: -13px;

      &:hover {
        cursor: pointer;
      }
    }

    .marketing-modal-body {
      flex: 4;
      display: flex;
      flex-direction: column;
      overflow: auto;
      width: 100%;
      padding: 20px;
      align-items: center;

      .marketing-modal-image {
        flex: 2;
        @include item-center;

        img {
          width: 100px;
          height: 70px;
        }
      }

      .marketing-modal-text {
        flex: 1;
        @include item-center;

        label {
          color: #787878;
          text-align: center;
          font-size: 13px;
          width: 45%;
        }
      }

      .marketing-modal-button {
        flex: 2;
        @include item-center;

        a {
          width: 155px;
          height: 38px;
          color: white;
          border: 1px solid #D7DAE2;
          border-radius: 7px;
          font-size: 14px;
          background-color: #2194F1;
          @include item-center;
        }
      }

      .btn-facebook-page {
        margin-bottom: 15px;
        height: 40px;
        border-radius: 5px;
      }
    }
  }
  .modal-body {
    padding: 0;
    height: 100%;
  }
  .modal-dialog {
    height: 42%;
    width: 25%;
  }
  .modal-content {
    height: 100%;
    min-height: 350px;
  }
}

.marketing-modal-container {
  @include marketing-modal-container;
}

.image-invalid {
  font-size: 12px;
  color: #D43F3B;
  display: flex;
  justify-content: center;
}

/* Finish Setting - Marketing */

/* Add user popover */

.popover-new-user {
  width: 100%;
  height: auto;
  z-index: 100;
  left: 373px;
  padding: 0 0 40px;

  .popover-new-user-header {
    display: block;
    padding-bottom: 20px;

    div {
      color: $text-color;
    }

    #save-new-user {
      width: 67px;
      height: 32px;
      border-radius: 4px;
      padding: 4px 0;
      text-align: center;
      float: right;
      margin: 14px 20px;
      color: #fff;
      background-color: #4AD991;
      cursor: pointer;
      border: none;
    }

    #new-user-title {
      font-size: 15px;
      font-weight: 600;
    }
  }

  .popover-new-user-content {
    width: 100%;
    float: left;

    .new-user-left-content {
      width: 30%;
      float: left;

      .container-new-user-photo {
        @include item-center;
        border-bottom: 1px solid #D7DAE2;
        padding-bottom: 20px;

        .photo-user {
          height: 102px;
          width: 102px;
          object-fit: cover;
          border-radius: 50%;
          -webkit-box-shadow: 0 0 10px 0 #a4afb7;
          -moz-box-shadow: 0 0 10px 0 #a4afb7;
          box-shadow: 0 0 10px 0 #a4afb7;
          border: 1px solid #FFF;
        }
      }

      .container-dropzone {
        @include item-center;
        padding-bottom: 20px;
        border-bottom: 1px solid #D7DAE2;

        div {
          width: 102px !important;
          height: 102px !important;
          border: none !important;
        }
      }

      .new-user-photo-container {
        .new-user-photo-circle {
          width: 102px;
          height: 102px;
          border-radius: 50%;
          background: #2194F1;
          -webkit-box-shadow: 0 0 10px 0 rgba(164, 175, 183, 1);
          -moz-box-shadow: 0 0 10px 0 rgba(164, 175, 183, 1);
          box-shadow: 0 0 10px 0 rgba(164, 175, 183, 1);
          color: #fff;
          text-align: center;
          padding-top: 40px;
          border: 1px solid #FFF;
        }

        .photo-user {
          width: 200px;
          height: 200px;
          -webkit-box-shadow: 0 0 10px 0 #a4afb7;
          -moz-box-shadow: 0 0 10px 0 #a4afb7;
          box-shadow: 0 0 10px 0 #a4afb7;
          border: 1px solid #FFF;
        }
      }

      #roles {
        width: 95%;
        padding-top: 10px;
        z-index: 1;

        span {
          font-size: 8px;
          color: #787878;
        }
      }
    }

    .new-user-right-content {
      width: 70%;
      float: left;

      .new-user-form {
        margin-left: 20px;
        height: 280px;

        .flex-colR1 {
          padding-right: 0;
          padding-left: 10px;
        }

        span {
          font-size: 8px;
          color: #787878;
        }

        input {
          margin-top: 5px;
          height: 35px;
        }

        .error-message {
          font-size: 10px;
          color: #f95606;
        }
      }

      .footer-new-user {
        float: left;
        width: 100%;

        .container-button-save {
          float: right;
          padding-top: 20px;

          .btn-save {
            color: #ffffff;
            border-radius: 5px;
            border-color: transparent;
            height: 30px;
            width: 60px;
            background: #4ad991;
          }
        }
      }
    }
  }

  select {
    cursor: pointer;
    background: url('../../../public/assets-images/ic_expand_more.png') no-repeat right;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-size: 14px;
    background-position-x: calc(100% - 7px);
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    margin-top: 5px;
  }

  select:focus {
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
}

.new-user-photo-container-modal {
  left: 13px;
  border: 1px solid #FFFFFF;
  border-radius: 100%;
  box-shadow: 0 0 14px 0 #A4AFB7;
  width: 88px;
  top: 0;
  position: absolute;
  height: 88px;
  cursor: pointer;
  background-color: #2194F1;

  .new-user-photo-circle {
    text-align: center;
    padding-top: 32px;
    z-index: 200;

    i {
      text-align: center;
      color: #FFF;
    }

    img {
      border: 3px solid #FFFFFF !important;
      z-index: -1;
      position: absolute;
      right: -1px;
      top: -1px;
      box-shadow: none !important;

      &:hover {
        cursor: pointer;
      }
    }

    i, img {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.modal-car-search {
  @include item-center;
  display: flex !important;

  .modal-dialog {
    width: 772px;
    height: 340px;
  }

  .modal-content {
    @include col100;
    border: 1px solid #D7DAE2;
    -webkit-box-shadow: 0 2px 10px #00000027;
    -moz-box-shadow: 0 2px 10px #00000027;
    box-shadow: 0 2px 10px #00000027;
    height: 400px;
  }

  .car-header {
    @include col100;
    padding: 10px 15px 10px 15px;
    border-bottom: 1px solid #D7DAE2;
  }

  input {
    outline: none;
    float: left;
    height: 37px;
    border: 0;
    width: 300px;
    font-size: 12px;
    color: #A4AFB7 !important;
  }

  .car-row-search {
    @include col33;
    padding: 0 20px 22px 0;
  }

  .ic-car-search {
    width: 20px;
    height: 20px;
    fill: #D7DAE2;
  }

  .close-modal-icon {
    float: right;
    width: 32px;
    height: 32px;
    border-radius: 17px;
    background: white;
    position: absolute;
    right: -16px;
    top: -16px;

    &:hover {
      cursor: pointer;
    }
  }

  .car-search {
    float: left;
    padding: 10px 10px 0 0;
  }

  .car-image {
    float: left;
    height: 41px;
    width: 50px;

    img {
      @include image-object-fit;
      border-radius: 7px;
    }
  }

  .car-info {
    float: left;
    width: 174px;
    padding: 0 0 0 8px;

    label {
      @include col100;
      font-size: 12px;
      color: #4D4D4D;
    }

    .label-title {
      font-size: 13px;
      font-weight: lighter;
      font-family: 'Open Sans', sans-serif;
    }

    .label-mil {
      font-weight: $font-weight-light;
      font-size: 9px;
      margin-bottom: 0;
    }
  }

  .modal-body {
    @include col100;
    padding: 0;
  }

  .car-body {
    @include col100;
    padding: 20px 0 30px 20px;
    max-height: 340px;
    overflow: auto;

    .filter-letter {
      background: white;
      z-index: 20;
      float: left;
      width: 5%;

      a {
        color: #D7DAE2;
        padding-top: 4px !important;
        font-weight: lighter;
        font-size: 12px;
        text-decoration: none;
      }

      a:hover {
        color: #2194F1;
        text-decoration: none;
        cursor: pointer;
      }

      .active-letter {
        color: #2194F1;
      }
    }

    .filter-car {
      float: left;
      width: 100%;
    }
  }
}

.popover-car-search {
  @include popover-box-category;
  padding: 0;
  border: 1px solid #A4AFB7;
  box-shadow: 0 0 10px #D7DAE2;

  .ic-car-search {
    width: 16px;
    height: 16px;
    fill: #D7DAE2;
  }

  .car-search {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  input {
    outline: none;
    height: 37px;
    border: 0;
    padding-left: 35px;
    width: 100%;
    border-radius: 5px;
  }

  .menu-item-search {
    border-bottom: 1px solid #eff0f3;
  }

  .popover-body-car {
    @include popover-body-status;
  }

  .menu-item-list {
    max-height: 300px;
    overflow: auto;
    padding: 10px 15px 0 15px;
    margin-bottom: 10px;

    a {
      @include col100;
      color: #787878;
      padding-bottom: 5px;

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}

.filter-customer {
  width: 100%;
  padding-left: 20px;
  position: relative;
  height: 60px;
  z-index: 100;
}

.customer-modal-container {
  @include marketing-modal-container;
  color: #787878;
  font-family: $font-family;

  .customer-modal {
    @include item-center;
    flex: 1;
    width: 100%;
    height: 100%;
    z-index: 200;
    box-sizing: border-box;
    overflow: hidden;

    .close-modal-icon {
      width: 24px;
      position: absolute;
      top: -15px;
      right: -13px;

      &:hover {
        cursor: pointer;
      }
    }

    .customer-modal-body {
      text-align: center;
      max-width: 100%;
    }

    .customer-info {
      padding: 20px;
      border-bottom: none;

      label {
        @include col100;
        margin: 0;
        font-weight: $font-weight-light;

        p {
          margin: 0;
        }
      }
    }

    .customer-name {
      label {
        margin: 0;
        padding: 10px 0;
        border-bottom: 1px solid #A4AFB7;
        width: 200px;
        font-weight: $font-weight-bold;
      }
    }
  }

  .modal-dialog {
    height: 42%;
    width: 300px;
  }
}

.customer-modal-body {
  text-align: center;

  .container-button-edit {
    @include item-center;
    float: left;
    width: 100%;
    padding: 10px;

    .btn-edit-customer {
      height: 36px;
      border-radius: 4px;
      background-color: #2194F1;
      border: 1px solid #2194F1;
      color: #FFFFFF;
      width: 90px;
      font-family: $font-family;
      font-weight: $font-weight-regular;
    }
  }

  .customer-image {
    img {
      border-radius: 50%;
      width: 84px;
      height: 84px;
      object-fit: cover;
      padding: 1px;
      box-shadow: 0 0 10px #A4AFB7;
    }
  }

  .customer-info {
    border-bottom: none;

    label {
      @include col100;
      margin: 0;
      font-weight: $font-weight-light;

      p {
        margin: 0;
      }
    }
  }

  .customer-name {
    label {
      margin: 0;
      padding: 10px 0;
      border-bottom: 1px solid #A4AFB7;
      width: 200px;
      font-weight: 900;
    }
  }
}

.customer-pagination {
  float: right;
  padding: 15px 25px 15px 10px;
}

.button-add {
  background-color: #4ad991;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.button-close {
  background-color: $button-color-red;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.button-black {
  background-color: #3A3A53;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.button-blue {
  background-color: #2194F1;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 13px;
  border-radius: 4px;
  width: 100%;
  padding: 7px 10px !important;
  height: 35px;
}

.button-white {
  background-color: #FFFFFF;
  border: 1px solid #2194F1 !important;
  color: #2194F1;
  text-align: center;
  text-decoration: none;
  font-size: 13px;
  border-radius: 4px;
  width: 100%;
  height: 35px;
  padding: 7px 10px !important;
}

.button-disabled {
  background-color: #D7DAE2;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.searchable-box {
  @include popover-box-category;
  max-height: 290PX;
  overflow: auto;
  padding: 0;

  .searchable-box-dp {
    max-height: 280PX;
    overflow: auto;

    .change-submenu-item {
      &:hover {
        background-color: #efefef;
      }
    }

    .item-selected {
      background-color: #3B86FF;
      color: white;
    }

    label {
      width: 100%;
      color: #777777;
      padding: 2px 7px;
      margin: 0;
      font-family: $font-family;
      font-weight: $font-weight-regular;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.searchable-filter {
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  color: #4D4F5C;

  .search {
    position: absolute;
    top: 3px;
    left: 10px;
  }

  .ic-search {
    fill: #dddee0;
    height: 16px;
    width: 16px;
  }

  input {
    width: 100%;
    border: 1px #dddee0 solid;
    padding: 6px 30px;
  }

  .ic-arrow-up {
    position: absolute;
    right: 15px;
    top: 11px;
    color: #787878;
  }
}

.superuser-modal-body-container {
  padding: 25px;
  width: 100%;

  .superuser-modal-image {
    .photo-superuser-img-circle {
      height: 88px;
      object-fit: cover;
    }

    @include item-center;

    img {
      border: 1px solid #FFFFFF;
      border-radius: 100%;
      box-shadow: 0 0 14px 0 #A4AFB7;
      width: 88px;;
    }
  }

  .superuser-modal-text {
    display: block;
    margin-top: 15px;
    text-align: center;

    .superuser-name {
      margin: 0 40px 0 40px;
      border-bottom: .4px solid #a4afb7e0;

      .title {
        line-height: 15px;
        color: #787878;
        font-weight: $font-weight-bold;
        font-size: 13px;
      }
    }

    .superuser-detail {
      margin-top: 15px;

      .subtitle, .subtitle-description {
        color: #787878c9;
        font-size: 13px;
        font-weight: $font-weight-regular;
        line-height: 18px;
        @include item-center;
        margin-bottom: 0;
      }

      .subtitle-description {
        margin-top: 10px;
      }
    }

    .superuser-modal-button {
      @include item-center;
      margin-top: 15px;

      .btn-edit {
        width: 80px;
        height: 30px;
        color: white;
        border: 1px solid #D7DAE2;
        border-radius: 4px;
        font-size: 12px;
        background-color: #2194F1;
        font-family: $font-family;
        font-weight: $font-weight-regular;
      }
    }
  }
}

#icon-arrow-user-setting {
  float: right;
  padding: 1px 5px;
  display: inline-table;
  cursor: pointer;
  font-size: 17px !important;
}

.modal-promote, .modal-message {
  @include item-center;
  display: flex !important;
  border-radius: 10px;

  .modal-dialog {
    width: 60%;
  }

  .modal-content {
    @include col100;
    border-radius: 10px;
  }

  .modal-body {
    min-height: 300px;
    float: left;
    padding: 0;
    width: 100%;
  }

  .panel-top {
    float: left;
    min-height: 50px;
    width: 100%;
    background-color: #ffffff;
    position: relative;
    border-bottom: 1px #cacaca solid;
    border-radius: 10px 10px 0 0;

    .icon-close-content {
      position: absolute;
      top: 15px;
      right: 25px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #ff565e;
    }

    .icon-close {
      width: 28px;
      height: 28px;
      fill: #ffffff;
    }

    .icon-close:hover {
      cursor: pointer;
    }

    .label-sure {
      padding: 15px 0 15px 25px;

      label {
        color: #A4AFB7;
      }
    }
  }

  .panel-bottom {
    float: left;
    min-height: 250px;
    width: 100%;
    position: relative;
    padding: 20px 20px 0 20px;

    .container-button {
      margin-top: 0;
      width: 100%;
      float: left;
      padding: 20px 0;
      @include item-center;

      .btn-save {
        height: 36px;
        border-radius: 5px;
        background-color: #2194F1;
        border: 1px solid #D7DAE2;
        color: #ffffff;
        width: 120px;
        font-size: 13px;
        font-weight: $font-weight-regular;
      }
    }

    .container-list-customer {
      width: 100%;
      float: left;

      .list-customer {
        width: 50%;
        float: left;
        padding: 10px;
        cursor: pointer;
      }

      .container-info-customer {
        width: 100%;
        float: left;
        border: 1px solid #D7DAE2;
        border-radius: 10px;
      }

      .current-image-container {
        border-radius: 10px;
        @include item-center;
        width: 30%;
        background-color: #ffffff;
        float: left;
        padding: 10px;

        .current-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          box-shadow: 0 0 10px #A4AFB7;
        }
      }

      .customer-data {
        width: 70%;
        float: left;
        padding: 10px 15px;

        .customer-name, .customer-other {
          width: 100%;

          label {
            font-size: 12px;
            color: #787878;
          }
        }

        .customer-name {
          label {
            font-weight: 600;
          }
        }

        .customer-other {
          label {
            font-weight: 500;
          }
        }
      }
    }
  }
}

.modal-promote {
  .panel-bottom {
    overflow: auto;
    height: calc(100% - 56px);

    .btn-save {
      border: 1px solid $light-blue-color-1 !important;
    }
  }
}

.modal-message {
  .modal-dialog {
    width: 40%;
  }

  .modal-content {
    padding: 0;
  }

  .panel-bottom {
    .container-list-customer {
      display: flex;
      align-items: center;
      justify-content: center;

      .image-successfully {
        width: 40%;
        height: 40%;
      }
    }
  }

  .panel-top {
    @include item-center;
    background-color: #4AD991;

    .label-sure {
      padding: 0;

      label {
        color: #ffffff;
      }
    }

    .icon-close-content {
      top: -15px;
      right: -15px;
    }
  }
}

.toolTip-finance {
  @include item-center;
  border-radius: 5px;
  border: 1px solid #D7DAE2;
  font-size: 0.8em;
  padding: 10px 10px;
  position: absolute;
  z-index: 100;
  display: none;
  width: max-content;
  left: -30px;
  top: 30px;
  height: 25px;
  color: #ffffff;
  background-color: #0f5d84;
}

.verify-email {
  width: 800px;
  display: table;
  margin: 0 auto;

  .container-box-1 {
    padding: 30px 45px;
    background-color: #FAFAFA;
    width: 100%;
    height: 250px;

    .greetings {
      text-align: center;
      justify-content: center;
      margin-top: 35px;
      border: 1px solid transparent;

      h3 {
        font-size: 56px;
        color: #2194F1;
        margin-bottom: 0;
        font-weight: $font-weight-bold;
      }

      h6 {
        margin-top: 0;
        font-weight: lighter;
        font-size: 24px;
        color: #000000;
      }
    }
  }

  .container-box-2 {
    padding: 30px 55px;
    background-color: #2194F1;
    width: 100%;
    height: 240px;

    .container-mail-body {
      margin-top: -75px;
      text-align: center;
      background-color: #FFFFFF;
      border-radius: 7px;
      box-shadow: 0 3px 30px rgba(192, 192, 192, 0.16);
      padding: 25px 0;

      p {
        font-size: 14px;
        font-weight: lighter;
        color: #000;
      }

      button {
        margin: 20px 0;
        border-radius: 4px;
        text-align: center;
        padding: 7px 45px;
        border: 1px solid #D7DAE2;
        background-color: #2194F1;
        color: white;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
      }
    }

    .container-mail-footer {
      text-align: center;
      color: white;
      font-size: 10px;

      p {
        padding: 17px 0 10px 0;
        font-weight: lighter;

        a {
          color: white;
          text-decoration: underline !important;
        }
      }

      b {
        padding: 20px 0;
        font-weight: 500;
      }
    }
  }

}

.modal-delete-purchaser .modal-dialog .modal-content .modal-body {
  .panel-right {
    padding-top: 115px;

    .container-button-delete {
      @include item-center;
      padding-top: 30px;
    }
  }
}

.modal-import {
  .modal-dialog {
    height: 302px;
  }

  .modal-body {
    flex-direction: column;
  }

  .icon-close-content {
    top: -15px;
    right: -16px;
    z-index: 10;
  }

  .panel-body-csv {
    width: 100%;
    height: 100%;
    padding: 0;

    .panel-top {
      height: 35px;
      border-bottom: 1px solid #D7DAE2;
      padding: 7px 0 0 15px;

      label {
        font-size: 15px;
        font-weight: $font-weight-bold;
        color: $text-color;
      }
    }

    .panel-middle {
      height: 232px;

      .panel-icon {
        height: 173px;
        padding-top: 45px;
        display: flex;
        justify-content: center;

        .img-customer {
          &:hover {
            cursor: pointer;
          }
        }
      }

      .panel-text {
        display: flex;
        justify-content: center;

        .upload-text {
          font-size: 13px;
          font-weight: $font-weight-regular;
          display: flex;

          label {
            font-size: 13px;
            font-weight: $font-weight-regular;
            color: #2194F1;
            padding: 0 3px;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    .panel-down {
      @include item-center;
      height: 30px;
      border-top: 1px solid #D7DAE2;

      .container-icon {
        padding-right: 5px;

        .icon-help {
          div {
            display: flex;
          }
        }

        .icon-help-svg {
          width: 16px;
          height: 16px;

          &:hover {
            circle {
              fill: #787878;
            }

          }
        }

        .svg-icon-download {
          fill: #D7DAE2;
          width: 16px;
          height: 16px;
        }

        &:hover {
          cursor: pointer;
        }

      }

      label {
        font-size: 13px;
        padding-right: 35px;
        font-weight: $font-weight-regular;
        margin: 0;
      }
    }
  }

  .popover-csv {
    height: 115px;
    width: 275px !important;
    padding: 15px;

    label {
      font-size: 12px;
      font-weight: $font-weight-regular;
    }
  }
}

.modal-info {
  @include item-center;
  display: flex !important;
  text-align: justify;

  .icon-close-content {
    top: -16px;
    right: -16px;
    position: absolute;

    div {
      height: 32px;
      border-radius: 50%;
      background-color: #FFF;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .icon-close {
    width: 32px;
    height: 32px;
    fill: #ff565e;
  }

  .modal-dialog {
    width: 435px;
    height: 310px;

    .img-car-info {
      height: 180px;
    }

    .car-info-label {
      display: flex;
      font-size: 13px;
      margin-top: 10px;
      color: $text-color;
    }
  }
}

.modal-info.fbmp-modal-info {
  @include item-center;
  display: flex !important;
  text-align: justify;

  .icon-close-content {
    top: -16px;
    right: -16px;
    position: absolute;

    div {
      height: 32px;
      border-radius: 50%;
      background-color: #FFF;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .icon-close {
    width: 32px;
    height: 32px;
    fill: #ff565e;
  }

  .modal-dialog {
    width: 460px;
    height: 240px;

    .fbmp-info-comment {
      display: flex;
      font-size: 13px;
      margin-top: 10px;
      color: $text-color;
    }

    .fbmp-info-detail-container {
      display: flex;
      font-size: 12px;
      margin-top: 10px;
      color: $text-color;

      div {
        width: 50%;
        text-align: left;
      }
    }

    @mixin marketing-icon-svg() {
      height: auto;
      width: 180px;
      cursor: pointer;
    }

    .marketing-icon-svg {
      @include marketing-icon-svg;
    }

    .selected-marketing-icon-svg {
      @include marketing-icon-svg;

      path {
        fill: #2194f1;
      }
    }
  }
}

/**********media queries ************************/

@media (max-width: 1600px) {
  .body-add-car {
    padding: 0 30px 0 60px;
  }

  .btn-drop-down-container .label-value {
    font-size: 12px;
  }

  .modal-delete .panel-right-delete .container-icon-sub-title .container-icon-exclamation {
    padding: 1px 5px 0 0;
  }

  .dashboard-body .dashboard-graphic .dashboard-statistic .user-settings-column {
    width: 100%;
  }

  .template-view {
    .view-previous-about-us .layout-about .about-testimonial .layout-customer-testimonial .content-up-down .semi-circle-up {
      left: 12% !important;
    }
  }

}


@media (max-width: 1480px) {

  .template-view {
    .banner-container {
      .banner-content-img {
        top: 0;
      }

      .btn-search-car {
        left: 35%;
      }
    }
  }

  .testimonial-container {
    .table-form-setting {
      .title-posting-date {
        width: 13%;
      }

      .testimonials-users {
        padding-left: 10px;
        width: 35%;
      }
    }

  }

  .modal-car {
    .modal-content {
      .modal-body {
        .panel-right {
          .modal-buttons {
            padding: 0 25%;
          }
        }
      }
    }
  }

  .tab-billing-payment {
    width: 165px !important;
  }


  .sales-container {
    .sales-body {
      .inventory-container-sales {
        .cars-container {
          .sales-car-container {
            .sales-car {
              .car-more-info-container {
                .more-info {
                  width: 65%;
                }

                .sales-car-select {
                  width: 35%;
                }
              }
            }
          }
        }
      }
    }
  }

  .car-image-container {
    .image-object-fit-no-pointer {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

}

@media (max-width: 1400px) {
  .banner-container .banner-content-img {
    top: -60px;
  }

  .template-view {

    .banner-container .banner-title h1 {
      font-size: 36px !important;
    }

    .banner-container-5 {
      .banner-title {
        h1 {
          font-size: 60px;
        }
      }

      .btn-apply-content {
        top: 440px;
      }

      .btn-search-car-content {
        width: 30%;
      }
    }

    .finance-body .finance-step-content, .step-effect .finance-step-content {
      padding-right: 20px;
    }

    .finance-body .finance-body-step, .step-effect .finance-body-step {
      padding: 30px;
      position: relative;
      float: left;

      .finance-icon {
      }

      .finance-label {
        label {
          font-size: 20px;
          word-wrap: break-word;
        }
      }

      .finance-des {
        padding-top: 10px;
      }
    }

    .finance-body .finance-icon-step, .step-effect .finance-icon-step {
      height: 30px;
    }

    .finance-body .finance-label label, .step-effect .finance-label label {
      font-size: 20px;
      word-wrap: break-word;
    }

    .finance-body .finance-image {
      max-width: 100%;
      margin: auto;
      position: relative;
      height: 151px;
      float: left;
    }

    .finance-body .finance-image::before {
      display: block;
      position: absolute;
      background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0, #fff 100%);
      height: 100%;
      width: 100%;
      content: '';
    }

    .view-previous-about-us .layout-about .about-testimonial .layout-customer-testimonial .content-up-down .semi-circle-up {
      left: 11% !important;
    }

  }

  .view-previous-home {
    .car-item-details {
      .btn-car-content {
        padding-right: 3px;
      }

      .btn-car-info {
        font-size: 9px;
        padding: 3px 5px;
      }
    }

    .layout-get-in-touch .get-in-touch-tile {
      padding: 0 1%;

      h1 {
        font-size: 28px;
      }
    }
  }

  .bottom-car .select {
    width: 60%;
  }

  .car-image-column:hover .bottom-car .options {
    display: block;
  }
  .marketing-modal-container {
    .modal-dialog {
      width: 30%;
    }
  }

  .user-modal-container, .rol-permission-modal {
    .modal-dialog {
      width: 60% !important;
    }
  }

  .modal-delete-sale, .modal-delete-purchaser {
    .modal-dialog {
      width: 42%;
    }
  }
}

.menu-dealer-content {
  float: right;
  margin: 20px 0;

  .menu-dealer {
    span {
      padding: 0 5px 0 20px;
      color: #787878;
      font-weight: $font-weight-bold;
      font-size: 14px;
    }
  }
}

.modal-delete-sale {
  .panel-right-delete {
    padding: 25px 30px 10px 50px;
  }
}

.receipts-images {
  .container-image {
    z-index: 100;
    position: absolute;
    top: 60px;
    right: 0;
    bottom: 0;
    width: 100%;

    .image-object-fit-no-pointer {
      width: 100% !important;
      height: 100% !important;
      -o-object-fit: cover !important;
      object-fit: scale-down;
      position: absolute;
    }
  }

  .container-children{
    .left, .right{
      svg{
        width: 40px;
      }
    }
  }

}

@media (max-width: 1300px) {
  .btn-basic {
    padding: 6px 3px;

    span {
      padding: 2px;
    }
  }

  .template-customize .btn-setting-sec {
    padding: 8px 5px;
  }

  .title-forms-list {
    font-size: 14px;
  }

  .title-name-forms-list {
    padding-left: 30px;
    width: 25%;
    font-size: 14px;
  }

  .title-code-forms-list {
    padding-left: 20px;
    width: 25%;
    font-size: 14px;
  }

  .title-entity-forms-list {
    width: 10%;
    font-size: 14px;
  }

  .title-created-by-forms-list {
    width: 10%;
    font-size: 14px;
  }

  .title-date-forms-list {
    width: 20%;
    font-size: 14px;
  }

  .title-graph-forms-list {
    width: 150px;
    font-size: 14px;
  }

  .searchable-filter .ic-arrow-up {
    right: 7px;
  }

  .popover-box-general {
    padding: 7px 3px 3px 3px;
  }

  .modal-dialog {
    .modal-content {
      .modal-body {
        .panel-right {
          .container-elements {
            width: 80%;
          }
        }
      }
    }
  }

  .modal-car {
    .modal-content {
      .modal-body {
        .panel-right {
          .modal-buttons {
            padding: 0 20%;
          }
        }
      }
    }
  }

  .modal-message .panel-bottom .container-list-customer .image-successfully {
    width: 50%;
    height: 50%;
  }

  .body-setting {
    .setting-container {
      .setting-form-header-header {
        .row-setting-user {
          padding-left: 30px;
        }

        .btn-general-message-dp {
          width: 130px !important;
        }
      }
    }
  }

  .template-view .layout-footer .container-footer-background .footer-right .title-two {
    padding-left: 20px;
  }

  .template-view .layout-footer .container-footer-background .footer-right .title-one, .title-two {
    padding-left: 20px;
    min-width: 220px !important;

    label {
      font-size: 20px;
    }
  }

  .view-previous-about-us .layout-about .about-testimonial .layout-customer-testimonial .content-up-down {
    .semi-circle-up {
      left: 12% !important;
    }

    .semi-circle-down {
      left: 77% !important;
    }
  }

  .template-view .lay-get-in-touch .layout-customer-testimonial {
    .carousel-content-0 .carousel-content-text-0 div > h4,
    .carousel-content-1 .carousel-content-text-1 div > h4,
    .carousel-content-2 .carousel-content-text-2 div > h4,
    .carousel-content-3 .carousel-content-text-3 div > h4,
    .carousel-content-4 .carousel-content-text-4 div > h4,
    .carousel-content-5 .carousel-content-text-5 div > h4 {
      font-size: 15px;
    }
  }

  .view-previous-about-us .layout-about .about-testimonial .layout-customer-testimonial {
    .carousel-content-0 .carousel-content-text-0 div > h4,
    .carousel-content-1 .carousel-content-text-1 div > h4,
    .carousel-content-2 .carousel-content-text-2 div > h4,
    .carousel-content-3 .carousel-content-text-3 div > h4,
    .carousel-content-4 .carousel-content-text-4 div > h4,
    .carousel-content-5 .carousel-content-text-5 div > h4 {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 1270px) {
  .modal-car {
    .panel-left, .panel-left-custom {
      width: 50%;
    }

    .panel-left-delete {
      width: 38%;
    }

    .panel-right {
      width: 50%;
    }

    .panel-right-delete {
      width: 62%;
    }
  }

  .bottom-car .options {
    .ic-delete {
      width: 13px;
      height: 15px;
    }

    .ic-deactivate {
      width: 14px;
      height: 11px;
      margin-right: -9px;
    }
  }
}

@media screen and (max-width: 1260px) {
  .info-car {
    .label-car-information {
      padding-left: 1px;
      font-size: 12px;
    }
  }

  .container-center .panel-filter .rowFilter .category-status .icon-arrow-content {
    padding: 1px;
  }

  .container-center .panel-filter .rowFilter .category .icon-arrow-content {
    padding: 1px;
  }

  .rowUser {
    td {
      font-size: 13px !important;
    }

    &:hover {
      #imgCheck {
        padding: 0 1px;
      }
    }

    .superuser-actions .icon-right {
      svg {
        height: 18px;
        width: 18px;
      }

      span {
        div {
          padding: 0 1px
        }
      }
    }

    .td-actions-user {
      padding-right: 10px;
    }

    .tbody-user {
      .title-role-users-list {
        .category-status {
          width: 140px;
        }
      }

      .rowUser {
        .td-actions-user {
          padding-right: 10px;
        }
      }
    }
  }

  .tab-billing-payment {
    width: 136px !important;
  }

  .tab-form {
    height: 34px !important;
  }
}

@media (max-width: 1230px) {

  .title-forms-list {
    font-size: 13px;
  }

  .title-name-forms-list {
    padding-left: 30px;
    width: 25%;
    font-size: 13px;
  }

  .title-code-forms-list {
    padding-left: 20px;
    width: 25%;
    font-size: 13px;
  }

  .title-entity-forms-list {
    width: 10%;
    font-size: 13px;
  }

  .title-created-by-forms-list {
    width: 10%;
    font-size: 13px;
  }

  .title-date-forms-list {
    width: 20%;
    font-size: 13px;
  }

  .title-graph-forms-list {
    width: 150px;
    font-size: 13px;
  }

  .modal-delete .panel-right-delete .container-icon-sub-title .container-icon-exclamation {
    padding: 10px 5px 0 0;
  }
}

@media screen and (max-width: 1210px) {
  .bottom-car {
    padding: 8px 10px 8px 2px;

    .options {
      i {
        padding-left: 15px;
      }
    }
  }

  .title-graph-forms-list {
    width: 50px;
    font-size: 11px;
  }
}

@media screen and (max-width: 1200px) {
  .container-center .panel-filter .rowFilter .category-status, .category-locations {
    padding: 4px 1px 5px 7px;

    label {
      font-size: 11px;
    }
  }

  .title-graph-forms-list {
    width: 50px;
    font-size: 11px;
  }

  .container-center .panel-filter .rowFilter .select-categories {
    padding: 0 0 0 10px;
  }

  .container-center .panel-filter .rowFilter .category label {
    font-size: 11px;
  }



  .wrapper-header {
    .menu-search {
      #menu-search {
        width: 233px;
      }
    }
  }

  .menu-pay{
    margin-top: 14px;
    .button-icon{
      font-size: 11px;
      padding: 8px;
      .description-button-container .icon-class{
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1190px) {
  .container-body-task .task-section-body {
    width: auto;
  }
}

@media (max-width: 1165px) {
  .title-forms-list {
    font-size: 12px;
  }

  .title-name-forms-list {
    padding-left: 30px;
    width: 25%;
    font-size: 11px;
  }

  .title-code-forms-list {
    padding-left: 20px;
    width: 25%;
    font-size: 11px;
  }

  .title-entity-forms-list {
    width: 10%;
    font-size: 11px;
  }

  .title-created-by-forms-list {
    width: 10%;
    font-size: 11px;
  }

  .title-date-forms-list {
    width: 25%;
    font-size: 11px;
  }

  .title-graph-forms-list {
    width: 50px;
    font-size: 11px;
  }

  .subject-text2 {
    width: calc(45% - 200px);
  }

  .rowCustomer {
    td {
      .options {
        i {
          font-size: 16px;
        }
      }
    }
  }

}

@media screen and (max-width: 1160px) {
  .car-image-row {
    padding-right: 10px;
  }
  .modal-car {
    .modal-content {
      .modal-body {
        .panel-right {
          .modal-buttons {
            padding: 0 10%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .subject-text2 {
    width: calc(45% - 200px);
  }

  .info-car {
    padding: 12px 6px 0 10px
  }

  .data-mileage-car {
    padding: 0 6px 8px 10px;
  }

  .bottom-car {
    .options i {
      padding-left: 12px;
    }
  }
}

@media screen and (max-width: 1140px) {
  .bottom-car {
    .select {
      width: 40%;
    }

    .info-attribute-car {
      width: 60%;
    }
  }
  .modal-car {
    .panel-left {
      .car-run {
        width: 85%;
      }
    }
  }

  .subject-text2 {
    width: calc(30% - 100px);
  }

  .container-body-task {
    padding: 80px 0 20px 20px;

    .container-column-todo {
      padding-right: 5px;
    }

    .container-column-inprogress {
      padding: 0 5px;
    }

    .container-column-done {
      padding-left: 5px;
    }
  }

  .task-name {
    //--margin-left: 20px;
  }

  .task-bars {
    margin-right: 0;
    padding-right: 0;
    width: 340px;
  }


  .task-box-header {
    //top: 130px;
    //padding: 10px 0 10px 20px;
    padding: 20px 0 0 20px;
    height: 85px;

    .container-section-task {
      padding: 0;
      margin-right: 20px;
    }
  }

  .task-button {
    width: 300px;

    .container-task-button-add {
      margin-left: 15px;
    }
  }

  .task-filter-type {
    width: 335px;

    .container-select-filter .label-select {
      padding: 5px 7px 5px 3px;
    }
  }
}

@media (max-width: 1110px) {

  .subject-text2 {
    width: calc(30% - 100px);
  }

  .row-inv-info {

    i {
      padding-right: 5px;
    }
  }

  .title-graph-forms-list {
    width: 50px;
    font-size: 11px;
  }

  .rowCustomer {
    td {
      .options {
        i {
          font-size: 16px;
        }
      }
    }
  }

}

@media screen and (max-width: 1100px) {
  .container-header-view-app {
    .header-view-app-left {
      width: 30%;
    }

    .header-view-app-right {
      width: 70%;
    }
  }
}

@media (max-width: 1090px) {
  .btn-inventory-dp {
    span {
      font-size: 12px;
    }
  }

  .rowCustomer {
    td {
      .options {
        i {
          font-size: 18px;
        }
      }
    }
  }

  .container-header-task {
    .task-filter-type {
      .task-container-drop-down {
        font-size: 12px;

        .btn-drop-down-task {
          .btn-drop-down {
            .icon-arrow-content {
              padding: 1px;
            }
          }
        }
      }

      .container-icon {
        margin-left: 10px !important;
      }
    }
  }
  .subject-text2 {
    width: calc(30% - 100px);
  }
}

@media screen and (max-width: 1060px) {
  .subject-text2 {
    width: calc(35% - 100px);
  }
}

@media screen and (max-width: 1040px) {
  .menu-dealer-content, .menu-notification {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper-header {
    z-index: 0;

    .menu-notification {
      display: none
    }

    .menu-user {
      margin: 0;

      .menu-user-a {
        border: 0;
        border-left: none !important;

        .user-name {
          display: none;
        }

        a {
          display: none;
        }
      }

      .menu-user-u {
        padding: 5px 30px 5px 0 !important;
      }
    }
  }

  .container-collapse {
    padding-left: 50px;
  }

  .dashboard-body {

    .dashboard-item {
      .right {
        display: none;
      }
    }

    .dashboard-item-theme {
      display: none;
    }

    .dashboard-graphic {
      .dashboard-container-user {
        .icon-user-content {
          #dropzone-modal-dashboard {
            @include item-center;
          }

          .new-user-photo-circle {
            bottom: 4px;
            position: absolute;
            right: -22px;
          }

          .icon-user {
            height: 120px !important;
            width: 120px;
          }
        }
      }
    }
  }

  .dashboard-body .dashboard-graphic .dashboard-user, .dashboard-body .dashboard-graphic .dashboard-statistic {
    padding: 10px 0;
  }

  .row-statistics-image {
    img {
      width: 200px;
    }
  }

  .dashboard-body .dashboard-graphic .dashboard-user .icon-user {
    width: 75px;
    height: 75px !important;
  }

  .row-statistics h3, .btn-statistics {
    margin-top: 5px;
  }

  .dashboard-body .dashboard-graphic .dashboard-user .icon-user-content {
    padding: 5px 0 10px 0;
  }

  .dashboard-body-profile {
    .dashboard-graphic {
      display: block;

      .dashboard-container-user {
        width: 100%;
        float: left;

        .icon-user-content {
          width: 28%;
        }

        .second-container {
          width: 72%;
          padding: 10px 20px 10px 0;
        }
      }

      .dashboard-statistic {
        margin-left: unset;
        width: 100%;
        float: left;
        margin-top: 10px;
      }
    }
  }

  .menu-user {
    margin-right: 10px;
  }

  .update-dashboard .update-icon {
    padding: 15px 20px;
  }

  #menu-search {
    margin-left: 300px;
  }

  .container-body-task {
    padding: 90px 0 20px 20px;
  }

  .task-bars {
    display: none;
  }

  .task-button {
    .task-container-drop-down {
      margin-right: 20px;
    }
  }

  .task-container-drop-down {
    display: block;
  }

  .container-filter-priority {
    position: fixed;

    .task-bars {
      padding-right: 0;
      width: 100%;
      margin-right: 0;
      justify-content: center;
    }
  }

  .container-header-task {
    .task-button {
      width: 50%;
      float: left;
    }

    .task-filter-type {
      padding-right: 10px;
      width: 50%;
      float: right;
      justify-content: flex-end;

      .container-icon {
        display: block;
        margin-left: 20px;
        margin-right: 15px;
        width: 25px;

        svg {
          height: 24px;
          width: 100%;
        }

        .icon-sort-content {
          width: 25px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .container-select-filter .label-select {
        padding: 5px 12px 5px 8px;
      }
    }
  }


  .modal-dialog {
    min-height: 300px;
  }

  .body-setting {
    padding: 70px 15px 0 15px;

    .setting-container {
      .setting-form-header-header {
        .row-setting-user {
          padding-left: 20px;

          .user-active-buttons {
            float: left;
          }
        }
      }

      .user-table {
        .container-table-users {
          min-height: 632px;
        }

        .table {
          .tbody-user {
            .rowUser {
              .td-actions-user {
                min-width: 76px;
                padding-right: 10px;
              }

              .td-status-superuser {
                padding-left: 10px;
                float: left;

                .pill-box {
                  //width: 84px;
                  width: auto;
                  min-width: max-content;
                  padding: 0 4px;
                  display: flex;
                  justify-content: center;

                  label {
                    font-size: 11px;
                  }
                }

                .icon-left {
                  span {
                    div {
                      padding: 0;
                    }
                  }
                }
              }

              td {
                font-size: 13px;
              }

              .title-photo-users-list {
                padding-left: 20px;
              }
            }
          }

          .title-forms-list {
            .title-photo-users-list {
              padding-left: 20px;
            }
          }
        }

        .container-user-pagination {
          @include item-center;
        }
      }
    }
  }

  .modal-deactivate {
    .modal-dialog {
      width: 38%;
    }
  }

  .user-modal-container, .rol-permission-modal {
    .modal-dialog {
      width: 85% !important;
    }
  }

  .menu-dealer-content {
    display: none;
  }

}

@media screen and (max-width: 1000px) {
  .subject-text2 {
    width: calc(25% - 100px);
  }
}

@media screen and (max-width: 980px) {

  .rowCustomer {
    td {
      .options {
        i {
          font-size: 16px;
        }
      }
    }
  }

  #menu-search {
    margin-left: 290px;
  }

}

@media screen and (max-width: 950px) {
  .modal-car {
    .panel-left {
      height: 311px;
    }
  }

  #menu-search {
    margin-left: 280px;
  }
  .subject-text2 {
    width: calc(20% - 100px);
  }

  .bottom-car .options {
    .icon-option {
      margin-right: 8px;
    }

    .ic-deactivate {
      width: 12px;
      height: 13px;
    }

    .ic-delete {
      width: 12px;
      height: 13px;
    }
  }
}

@media screen and (max-width: 900px) {
  #menu-search {
    margin-left: 240px;
  }
}

@media screen and (max-width: 880px) {
  #menu-search {
    margin-left: 200px;
  }
}

@media screen and (max-width: 840px) {

  .receipts-images {
    padding-right: 40px;

    .container-children {
      .left {
        left: 1%;
      }

      .right {
        right: 12%;
      }
    }
  }

  .container-header-task {
    .task-filter-type, .task-button {
      width: auto;
    }
  }
}

@media screen and (max-width: 820px) {
  #menu-search {
    margin-left: 160px;
  }
}

@media screen and (max-width: 800px) {
  .subject-text2 {
    width: calc(20% - 100px);
  }
  .dashboard-body .dashboard-graphic .dashboard-user {
    .data-user-rol {
      padding: 0 0 10px 0;
    }

    .data-user-phone {
      padding-bottom: 6px;
    }

    .data-user-name {
      span {
        font-size: 20px;
      }
    }

    .data-user-phone, .data-user {
      span {
        font-size: 12px;
      }
    }
  }

  .dashboard-body .dashboard-item .dashboard-item-content {
    padding: 10px 0;

    img {
      padding: 5px 10px;
    }
  }

  .task-button-add {
    margin-left: 5px;
  }
  .modal-car {
    .modal-content {
      .modal-body {
        .panel-right {
          .modal-buttons {
            padding: 0 5%;
          }
        }
      }
    }
  }
  .bottom-car .options {
    .icon-option {
      margin-right: 5px;
    }
  }

}

@media screen and (max-width: 780px) {

  .dashboard-body .dashboard-graphic {
    .dashboard-user {
      width: 33%;
    }

    .dashboard-statistic {
      width: 65%;
    }
  }

  .dashboard-body-profile {
    .dashboard-graphic {
      .dashboard-user, .dashboard-statistic {
        width: 100%;
      }
    }
  }

  .dashboard-body .dashboard-item .dashboard-item-customer {
    width: 33%;
  }

  .dashboard-item-car {
    width: 65%;
  }

  .dashboard-body .dashboard-item .dashboard-item-content img {
    padding: 2px 10px;
  }

  .dashboard-body .dashboard-item .dashboard-item-content h5 {
    font-size: 12px;
  }

  .dashboard-body .dashboard-graphic .dashboard-user .data-user, .dashboard-body .dashboard-graphic .dashboard-user .data-user-phone {
    span {
      font-size: 12px;
    }
  }

  #menu-search {
    margin-left: 140px;
  }

  .image-object-fit-no-pointer {
    //height: 100% !important;
    //-o-object-fit: contain !important;
    //object-fit: contain !important;
  }
}

@media screen and (max-width: 768px) {
  .hide-sidebar{
    .left-navigation {
      height: inherit !important;
    }
  }
  #sidebar {
    height: 40px;

    #sidebar-menu {
      //--width: 0;
      @include transition-all;

      .container-message-btn-setting {
        .sidebar-menu-setting {
          margin-bottom: 66px;
          position: absolute !important;
        }
        #sidebar-more-options {
          position: fixed !important;
          margin-bottom: 0px !important;
          cursor: default;
          .label-menu {
            display: block;
            position: absolute;
            top: -105px;
            padding-left: 15px;
          }
          .icon-container {
            float: none;
          }
        }
      }
      li {
        .icon-container{
          padding: 15px 15px;
        }
      }

      .label-menu{
        padding: 10px 0;
      }
    }
    .left-navigation {
      .menu-bar-collapse {
        padding: 5px;
        height: inherit;
      }
    }
  }

  .modal-car {
    .modal-dialog {
      width: 77%;
      min-height: 400px;

      .modal-content {
        height: 400px;
      }
    }

    .modal-body {
      display: initial;

      .panel-left, .panel-left-custom {
        height: 50% !important;
        width: 100%;
        border-radius: 10px 10px 0 0 !important;
        min-height: 200px;
      }

      .panel-right, .panel-right-cancel-sale {
        width: 100%;
        height: 50% !important;
        background: #ffffff;
        border-radius: 0 0 10px 10px;
        padding: 40px 0;
        min-height: 200px;
        box-shadow: none;

        .modal-buttons {
          padding: 0 25% !important;
        }
      }
    }

    .panel-right, .panel-right-cancel-sale {
      width: 100%;
      background: #ffffff;
      box-shadow: 0 3px 9px rgba(0, 0, 0, .5)
    }

    .panel-right {
      .car-run-right {
        display: none;
      }

      .icon-close-content {
        top: -216px !important;
      }
    }

    .icon-close-cancel-sale, .icon-close-content {
      //display: none;
    }

    .panel-left-custom {
      width: 100%;
    }

    .icon-close-cancel-sale-left {
      display: block;
    }

    .panel-add-car-to-inventory {
      padding: 9% 5% !important;

      .icon-close-content {
        display: none;
      }
    }
  }

  .dashboard-body {
    padding: 20px 30px;

    .dashboard-graphic {
      display: block;

      .dashboard-user {
        width: 100%;
        padding: 10px 0 10px 0;
        margin-right: 0;
        margin-bottom: 12px;

        .icon-user-content {
          width: 35%;
          padding: 20px 0 10px 0;
          @include item-center;
        }

        .data-user-name, .data-user, .data-user-rol, .data-user-phone {
          width: 65%;
          float: right;
        }

        .icon-user {
          width: 75px;
          float: right;
        }

        .new-user-photo-circle {
          bottom: 0;
          right: -22px;
        }

        .data-user-name {
          span {
            font-size: 24px;
          }
        }
      }

      .dashboard-container-user {
        .icon-user-content {
          width: 100%;
        }

        .second-container {
          width: 100%;
          padding: 10px 20px 10px 20px;
        }
      }

      .dashboard-statistic, .dashboard-theme {
        width: 100%;
        padding: 10px 0 10px 0;
        margin-left: 0;

        .row-statistics-user {
          .margin-top {
            width: 100%;

            .userInfoRow {
              width: 100%;

              .col100 {
                .profile-switch-container {
                  padding-left: 40px;
                  float: left;
                }
              }
            }
          }
        }
      }

      .dashboard-theme {
        margin-top: 12px;

        .content-button {
          padding-top: 56px;
        }
      }
    }
  }

  .dashboard-body .dashboard-item .dashboard-item-customer, .dashboard-item-car {
    width: 49%;
  }

  .dashboard-body .dashboard-item {
    padding-top: 12px;

    .left {
      width: 100%;
    }
  }

  .row-statistics-image {
    img {
      width: 220px;
    }
  }

  .main-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    background-color: #1b1e24; /* #f5f5f5 */
    border-right: 1px solid #eee;
    width: 230px;
  }

  .cropper-point.point-se {
    width: 15px;
    height: 15px;
  }

  .task-button {
    float: right;

    .task-container-drop-down {
      float: right;
      margin-right: 25px;
    }
  }

  .customer-button{
    .icon-add-task{
      > div {
        margin-top: 3px;
        svg {
          height: 35px;
          width: 35px;
        }
      }
    }
  }
  .container-header-task {
    height: 40px;
    .task-button {
      height: inherit;
      width: 70%;
      .container-task-button-add{
        margin-top: 6px;
        .icon-add-task{
          height: inherit;
          svg{
            width: 33px;
            height: 36px;
          }
        }
      }
    }
  }

  .modal-dialog {
    height: 200px;
  }

  .modal-delete {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .panel-left-delete {
            height: 150px !important;
            overflow: unset;

            h2 {
              z-index: 10;
            }

            .icon-close-content {
              display: block;
            }

            .icon-delete {
              div {
                svg {
                  width: 90px;
                  height: 90px;
                }
              }
            }
          }

          .panel-right-delete {
            height: 250px !important;
            padding: 20px !important;

            .container-icon-sub-title {
              padding-top: 10px;

              .container-icon-exclamation {
                padding: 0 5px 0 0;
              }
            }
          }
        }
      }
    }
  }

  .container-component {
    padding-left: 0;
    padding-bottom: 20px;

    .body-setting {
      padding: 70px 15px 0 15px;

      .setting-container {
        position: relative;

        .setting-form-header-header {
          .container-filters-mobile {
            z-index: 10;
            border-radius: 0 0 10px 10px;
            box-shadow: 0 0 10px #EFEFEF;
            @include transition-all;
            float: left;
            position: absolute;
            right: 0;
            background: #FFF;
            width: 100%;
            overflow: hidden;
            height: auto;
            max-height: 119px;

            .container-roles-permissions {
              float: left;
              width: 100%;

              .btn-container-dp-user {
                width: 50%;
                padding: 10px 10px 0 0;

                div {
                  width: 100% !important;

                  .icon-arrow-content {
                    width: 23px !important;
                    float: right;
                  }
                }
              }

              .btn-filter {
                padding: 10px 0 0 0;
              }
            }

            .container-buttons {
              padding: 15px 0 0 0;
              float: left;
              width: 100%;

              .panel-right {
                float: right;

                button {
                  height: 32px;
                }

                .btn-filter {
                  float: right;
                  width: 75px;
                  margin-right: 0;
                  background-color: #3A3A53;
                  color: #ffffff;
                  border-radius: 5px;
                  border-color: transparent;
                }

                .btn-cancel {
                  border: none;
                  color: $button-color-red;
                  margin-right: 15px;
                  background: transparent;
                }
              }
            }

            .panel-filters {
              padding: 15px;
              float: left;
              width: 100%;
            }
          }

          .row-setting-user {
            padding-left: 0;

            .user-active-buttons {
              float: left;
            }

            .panel-right-mobile {
              float: right;
              height: 100%;
              @include item-center;
              width: 70px;

              .container-icon {
                .icon-sort-content {
                  width: 25px;

                  svg {
                    fill: #787878;
                    height: 24px;
                    width: 100%;

                    &:hover {
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }

        .user-table {
          .table {
            .title-forms-list {
              .title-photo-users-list {
                width: 12%;
              }

              .title-name-users-list {
                width: 40%;
              }

              .th-status-superuser {
                width: 22%;
              }
            }
          }
        }
      }
    }
  }

  .modal-deactivate {
    .modal-dialog {
      width: 90%;

      .modal-content {
        .modal-body {
          width: 100%;
          display: block;

          .panel-left, .panel-right {
            width: 100%;
            height: 50%;
            border-radius: 10px 10px 0 0;
            min-height: 151px;
          }

          .panel-right {
            padding: 20px;

            .panel-message {
              font-size: 12px;
              padding: 0 0 10px 0;
            }

            .btn-action {
              text-align: center;
            }

            .icon-close-content {
              top: -164px;
            }

            .delete-superuser {
              padding: 10px 0 0 0;
              text-align: center;

              span {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .user-modal-container, .rol-permission-modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .user-modal {
            .user-modal-body {
              display: block;

              .seccion-1-edit {
                width: 100%;
                float: left;
                padding: 20px;

                .container-dropzone-image {
                  width: 40%;
                  float: left;
                }

                .user-modal-text {
                  width: 60%;
                  float: right;
                  margin-top: 0;

                  .user-name {
                    border-bottom: none;
                    width: 100%;

                    .pill-box {
                      margin-bottom: 0 !important;

                      label {
                        padding-bottom: 0;
                      }
                    }
                  }

                  .user-detail {
                    margin-left: 0;
                    padding-right: 0;

                    .container-roles {
                      span {
                        font-size: 9px;
                        color: $text-color;
                        font-weight: 300;
                      }

                      .dropdown-item {
                        margin-top: 5px;
                      }
                    }
                  }
                }
              }

              .container-line-section-separator {
                width: 100%;
                float: left;
                padding: 0 20px 5px 20px;

                .line-section-separator {
                  border-top: 1px solid #EFEFEF;
                }
              }

              .seccion-2-edit {
                width: 100%;
                float: left;
                padding: 0 0 0 20px;
              }
            }

            .user-info-body {
              .seccion-1 {
                width: 100%;
                padding: 20px 20px 0 20px;
                border-right: none;

                .user-modal-text {
                  .user-name {
                    padding-bottom: 8px;
                    margin: 0 20% 0 20%;
                  }
                }
              }

              .seccion-2 {
                width: 100%;
                padding: 10px 20px 20px 20px;
                border-top: 1px solid $icon-arrow-color;
              }
            }
          }
        }
      }
    }
  }

  .modal-dialog {
    height: 200px;
  }

  .modal-delete {
    .modal-dialog {
      min-height: 400px;

      .modal-content {
        .icon-close-content {
          display: block;
          top: -13px;
          right: -13px;
          z-index: 1060;
          height: 32px;
          width: 32px;
        }

        .modal-body {
          .panel-left-delete {
            height: 150px !important;

            h2 {
              z-index: 10;
            }

            .icon-delete {
              div {
                svg {
                  width: 90px;
                  height: 90px;
                }
              }
            }
          }

          .panel-right-delete {
            height: 250px !important;
            padding: 20px !important;

            .container-icon-sub-title {
              padding-top: 10px;

              .container-icon-exclamation {
                padding: 0 5px 0 0;
              }
            }
          }
        }
      }
    }
  }

  .user-active-buttons {
    .active, .inactive {
      width: 154px;
    }
  }

  .rowUser .superuser-actions .icon-right svg {
    height: 20px;
    width: 20px
  }

  .user-table {
    .table {
      .thead-setting-user {
        .title-forms-list {
          .title-graph-forms-list {
            min-width: 92px;
          }

          .title-photo-users-list, .title-name-users-list {
            padding-left: 10px;
          }
        }
      }

      .tbody-user {
        .rowUser {
          .title-photo-users-list, .title-name-users-list {
            position: relative;
            padding-left: 10px;
          }
        }
      }
    }

    .tbody-user {
      .rowUser {
        .td-actions-user {
          padding-right: 5px;
        }

        .superuser-actions {
          .icon-right {
            svg {
              height: 20px;
              width: 20px
            }

            span {
              div {
                padding: 0 2px;
              }
            }
          }
        }
      }
    }
  }

  .modal-promote {
    .modal-dialog {
      width: 90% !important;

      .modal-body {
        .panel-bottom {
          height: calc(100% - 58px);
          min-height: unset;
        }
      }
    }
  }

  .bottom-car .options {
    float: right;
    width: 30%;
    margin-top: 10px;

    .icon-option {
      float: right;
      cursor: pointer;
      margin-right: 13px;
    }

    .ic-delete {
      width: 15px;
      height: 16px;
      fill: $button-color-red;
    }

    .ic-deactivate {
      width: 16px;
      height: 11px;
      fill: #2194F1;
    }


    .ic-deactivate {
      width: 16px;
      height: 11px;
      fill: #2194F1;
    }

  }


  .container-tasks {
    .list-task-vertical {
      .col100 {
        .task-box-header-vertical {
          z-index: unset;

          .task-section-header-vertical {
            z-index: unset;
          }
        }
      }
    }
  }

  #quick-actions-info {
    .actions-container {
      .action-link:nth-child(3){
        display:none;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .container-task-edit {
    margin: 0;
  }

  #menu-search {
    margin-left: 0;
    width: 280px;
    font-size: 12px;
    margin-top: 16px;
  }

  .receipts-images {
    padding-right: 40px;

    .container-image {
      top: 0;
    }

    .container-children {
      .left {
        left: 1%;
      }

      .right {
        right: 7%;
      }
    }
  }

  .wrapper-header .menu-user .menu-user-u {
    padding: 0 30px 0 0 !important;
  }
}

@media screen and (max-width: 700px) {

}

@media screen and (max-width: 680px) {
  .container-header-task {
    .task-filter-type, .task-button {
      width: auto;
    }
  }
}


@media screen and (max-width: 660px) {
  #menu-search {
    //margin-left: 100px;
  }
}

@media screen and (max-width: 620px) {
  .task-button-add {
    margin-top: 10px;
    width: 36px;
    height: 36px;
  }
}

@media screen and (max-width: 600px) {
  .modal-message .panel-bottom .container-list-customer .image-successfully {
    width: 60%;
    height: 60%;
  }

}

@media screen and (max-width: 580px) {
  .modal-car {
    .modal-dialog {
      width: 90%;
    }
  }

  .body-edit-car {
    padding: 70px 15px 0 15px;
  }

  .menu-search a {
    padding: 17px 8px 17px 10px;
  }

  #menu-search {
    width: 250px;
    font-size: 10px;
    margin-top: 18px;
  }

  .task-box-header-vertical {
    padding: 0 5px 0 15px;
  }

  .list-task-vertical {
    padding-top: 70px;
  }

  .task-button .container-task-button-add {
    margin-left: 0;
  }

  .container-header-task {
    padding-left: 10px !important;
  }
}

@media screen and (max-width: 560px) {
}

@media screen and (max-width: 540px) {

}

@media screen and (max-width: 520px) {
}

@media screen and (max-width: 500px) {
  #menu-search {
    width: 230px;
  }

  .modal-message .panel-bottom .container-list-customer .image-successfully {
    width: 80%;
    height: 80%;
  }

  .modal-car {
    .modal-dialog {
      min-height: 400px;
    }

    .panel-left, .panel-right {
      min-height: 200px;
    }

    .panel-right {
      padding: 55px 0;
    }
  }

  .receipts-images {
    padding-right: 40px;

    .container-children {
      .left {
        left: 1%;
      }

      .right {
        right: 12%;
      }
    }
  }

}

@media screen and (max-width: 495px) {
  .modal-car {
    .panel-left {
      height: 356px;
      width: 51%;

      .car-run {
        width: 93%;
      }
    }

    .panel-right {
      width: 49%;

      .btn-not-save {
        //--margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  #menu-search {
    width: 238px;
  }
  .wrapper-header {
    .menu-search a {
      // padding: 17px 0 17px 20px;
    }

    .menu-user .menu-user-u {
      padding: 0 15px 0 0 !important;
    }
  }

  .dashboard-body {
    padding: 20px 10px;
  }

  .task-name {
    margin-left: 10px;
  }

  .task-button {
    .task-container-drop-down {
      margin-right: 10px;
    }

    .container-icon-user {
      padding: 15px 0 15px 20px;
      margin-left: -20px;
    }
  }


  .dropdown .dropdown-menu .dropdown-menu-option label, .dropdown .dropdown-menu .dropdown-menu-option-sign-out label, .dropdown .dropdown-menu .dropdown-menu-option-second label {
    font-size: 12px;
  }

  .page-on-build {
    img {
      width: 300px;
    }
  }

  .container-pill-box .pill-box label {
    font-size: 10px;
  }
}

@media screen and (max-width: 460px) {
  .btn-drop-down-task {
    width: 100px;

    .label-value {
      padding: 2px 0 0 5px;
    }
  }

  .container-header-task .task-filter-type .container-icon {
    margin-right: 5px;
  }
}

@media screen and (max-width: 440px) {
  #menu-search {
    //margin-left: 30px;
  }

  .task-button .task-container-drop-down {
    width: 100px;
  }

  .modal-car {
    .modal-content {
      .modal-body {
        .panel-right {
          .modal-buttons {
            padding: 0 15% !important;
          }
        }
      }
    }
  }

  .container-header .left-container {
    .title {
      margin-left: 10px;
      width: 85px;
    }

    .data {
      font-size: 16px;
      margin: 0 6px 0 6px;
    }
  }

}

@media screen and (max-width: 420px) {
  #menu-search {
    //margin-left: 20px;
  }

  .task-button .task-container-drop-down {
    width: 80px;
  }

  .btn-drop-down-task {
    width: 85px;

    .btn-drop-down {
      padding: 0 5px;
    }

    .icon-arrow-expand {
      width: 12px;
    }
  }

  .user-modal-container, .rol-permission-modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .user-modal {
            .user-modal-body {
              display: block;

              .seccion-1-edit {
                width: 100%;
                float: left;
                padding: 20px;

                .container-dropzone-image {
                  width: 40%;
                  float: left;
                }

                .user-modal-text {
                  width: 60%;
                  float: right;
                  margin-top: 0;

                  .user-name {
                    border-bottom: none;
                    width: 100%;
                  }

                  .user-detail {
                    margin-left: 0;

                    .container-roles {
                      span {
                        font-size: 9px;
                        color: $text-color;
                        font-weight: 300;
                      }

                      .dropdown-item {
                        margin-top: 5px;
                      }
                    }
                  }
                }
              }

              .container-line-section-separator {
                width: 100%;
                float: left;
                padding: 0 20px 5px 20px;

                .line-section-separator {
                  border-top: 1px solid #EFEFEF;
                }
              }

              .seccion-2-edit {
                width: 100%;
                float: left;
                padding: 0 0 0 20px;
              }
            }

            .user-info-body {
              .seccion-1 {
                .user-modal-text {
                  .user-name {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .dashboard-body .dashboard-item .dashboard-item-content {
    h5 {
      padding: 0 5px;
    }

    img {
      padding: 2px 2px;
    }
  }

  #menu-search {
    //margin-left: 20px;
    padding: 8px 5px;
    font-size: 12px;
    width: 210px;
  }

  .menu-user .menu-user-u {
    padding: 0px 5px 5px 0 !important;
  }

  .task-button .container-icon-user {
    width: 170px !important;
  }

  .inventory-content .container-header .right-container .mode-view #buttonOption {
    padding-right: 10px;
  }

}

@media screen and (max-width: 380px) {
  #menu-search {
    //margin-left: 10px;
  }

  .task-button .task-container-drop-down {
    width: 70px;
    margin-right: 13px;
  }

  .btn-drop-down-task {
    min-width: 70px;
    width: 80px;

    .btn-drop-down {
      padding: 0 3px;
    }

    .label-value {
      padding-top: 2px;
      font-size: 11px;
    }

    .icon-arrow-expand {
      width: 11px;
    }
  }

  .task-button-add {
    margin-top: 15px;
    width: 30px;
    height: 30px;
  }

  .page-on-build {
    img {
      width: 200px;
    }
  }
}

@media screen and (max-width: 360px) {
  .wrapper-header .menu-search a {
    padding: 17px 0 17px 10px;
  }

  #menu-search {
    width: 200px;
    font-size: 11px;
  }

  .task-button .task-container-drop-down {
    width: 60px;
  }

  .btn-drop-down-task {
    width: 60px;
  }

  .icon-user-task, .icon-user-task-arrow, .icon-user-task-popover {
    width: 26px;
    height: 26px;
  }

  .icon-user-task img {
    width: 26px;
    height: 26px;
  }

  .icon-next-user-svg, .icon-back-user-svg {
    width: 16px;
    height: 16px;
  }

  .task-name {
    font-size: 18px;
  }

  .task-button-add {
    width: 26px;
    height: 26px;

    .icon-add-svg {
      width: 10px;
      height: 10px;
    }
  }

  .task-button-add {
    margin-left: 0;
  }

  .task-name {
    font-size: 16px;
    margin-top: 18px;
  }

  .container-header-task {
    display: flex;
  }

  .container-header-task .task-filter-type .container-icon {
    margin-left: 10px;
  }

  .container-header-task .task-filter-type {
    width: 120px;
  }

  .wrapper-header .menu-user .menu-user-u {
    //--padding: 5px 10px 5px 0 !important;
  }

}

@media screen and (max-width: 340px) {
  .wrapper-header .menu-search a {
    padding: 17px 0 17px 2px;
  }

  #menu-search {
    //margin-left: 5px;
  }

  .modal-car {
    .modal-content {
      .modal-body {
        .panel-right {
          .modal-buttons {
            padding: 0 10% !important;
          }
        }
      }
    }
  }

  .page-on-build {
    img {
      width: 140px;
    }
  }

}

@media screen and (max-width: 320px) {
  .container-header-task .task-filter-type {
    padding-right: 30px;
    width: 120px;
  }
}

#icon-arrow-user-setting {
  float: right;
  padding: 1px 5px;
  display: inline-table;
  cursor: pointer;
  font-size: 17px !important;
}

.toolTip-finance {
  @include item-center;
  border-radius: 5px;
  border: 1px solid #D7DAE2;
  font-size: 0.8em;
  padding: 10px 10px;
  position: absolute;
  z-index: 100;
  display: none;
  width: max-content;
  left: -30px;
  top: 30px;
  height: 25px;
  color: #ffffff;
  background-color: #0f5d84;
}

.verify-email {
  width: 800px;
  display: table;
  margin: 0 auto;

  .container-box-1 {
    padding: 30px 45px;
    background-color: #FAFAFA;
    width: 100%;
    height: 250px;

    .greetings {
      text-align: center;
      justify-content: center;
      margin-top: 35px;
      border: 1px solid transparent;

      h3 {
        font-size: 56px;
        color: #2194F1;
        margin-bottom: 0;
        font-weight: $font-weight-bold;
      }

      h6 {
        margin-top: 0;
        font-weight: lighter;
        font-size: 24px;
        color: #000000;
      }
    }
  }

  .container-box-2 {
    padding: 30px 55px;
    background-color: #2194F1;
    width: 100%;
    height: 240px;

    .container-mail-body {
      margin-top: -75px;
      text-align: center;
      background-color: #FFFFFF;
      border-radius: 7px;
      box-shadow: 0 3px 30px rgba(192, 192, 192, 0.16);
      padding: 25px 0;

      p {
        font-size: 14px;
        font-weight: lighter;
        color: #000;
      }

      button {
        margin: 20px 0;
        border-radius: 4px;
        text-align: center;
        padding: 7px 45px;
        border: 1px solid #D7DAE2;
        background-color: #2194F1;
        color: white;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
      }
    }

    .container-mail-footer {
      text-align: center;
      color: white;
      font-size: 10px;

      p {
        padding: 17px 0 10px 0;
        font-weight: lighter;

        a {
          color: white;
          text-decoration: underline !important;
        }
      }

      b {
        padding: 20px 0;
        font-weight: 500;
      }
    }
  }

}

.list-task-edit {
  .options-archived-removed {
    gap: 8px;
    display: inline-flex;
  }

  i {
    cursor: pointer;
    padding: 4px 10px;
  }

  .icon-inactive, .icon-delete {
    fill: $button-color-red;
    height: 18px;
    cursor: pointer;
    width: 24px;
  }
}

.list-task-options, .options-edit {
  padding-top: 10px;
  color: #e0afae;
  font-size: 20px;
  position: relative;
  z-index: 200;

  .options-archived-removed {
    display: inline-flex;
  }

  i {
    cursor: pointer;
    padding: 0 4px;
  }

  .icon-add {
    cursor: pointer;
    height: 18px;
    width: 20px;
    fill: #4ada90;
  }

  .btn-delete {
    padding-top: 1px;
    padding-left: 6px;
    fill: $button-color-red;
    height: 21px;
    width: 22px;
  }
}

.car-image-column {
  .bottom-car {
    .options {
      display: none;
    }
  }
}

.car-image-column:hover {
  .bottom-car {
    .options {
      display: block;

    }
  }
}

@import "customer";
@import "dealer";
@import "inventory";
@import "sale";
@import "setting";


// Dashboard
#admin-layout, #sale-tech-layout, #subscription-layout, #update-plan-layout, #update-plan-mobile-layout {
  float: left;
  display: grid;
  width: 100%;
  height: calc(100% - 40px);
  min-height: 650px;
  padding: 25px 30px 15px 30px;
}

#update-plan-mobile-layout {
  grid-template-columns: 100% 100%;
  grid-template-areas: "report theme";
  display: flex;
  flex-direction: column;
  padding: 10px;

  #graphs {
    grid-area: report;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 10px 20px;
    margin-bottom: 10px;

    .plan-header-container {
      display: flex;
      height: auto;
      margin-bottom: 35px;

      .plan-header-info {
        display: flex;
        flex-direction: column;

        .plan-header-title {
          font-size: 25px;
          font-weight: bold;
        }

        .plan-header-price {
          font-size: 11px;
        }
      }

      .button-container {
        margin-left: auto;
        display: flex;
        align-items: center;

        .select-plan-btn {
          background-color: #2194f1;
          color: #fff;
          border-radius: 5px;
          border: 0 !important;
          font-size: 13px;
          width: 80px;
          height: 32px;
        }

        .disabled-select-plan-btn {
          background-color: #D7DAE2;
          color: #fff;
          border-radius: 5px;
          border: 0 !important;
          font-size: 13px;
          width: 80px;
          height: 32px;
          background-color: #D7DAE2;
        }

        .selected-current-plan {
          color: #2194f1;
          font-size: 13px;
        }
      }
    }

    .plan-basic-features-container {
      font-size: 14px;
      margin-bottom: 16px;

      .plan-basic-feature-item {
        margin-bottom: 6px;
      }
    }

    .plan-advanced-features-container {
      font-size: 21px;

      .plan-advanced-feature-title {
        font-weight: bold;
      }

      .plan-advanced-feature-item {
        @include item-center;
        border-bottom: 2px solid #F5F6FA;
        height: 60px;

        .icon-check{
          width: 17px;
          height: 21px;
          fill: #4AD991;
          display: flex;
          margin-left: auto;
        }
      }
    }

    .show-option {
      @include item-center;
      height: 30px;
      color: #2194f1;
      margin-top: 10px;
      cursor: pointer;
    }
  }

  #theme {
    grid-area: theme;
    display: flex;
    flex-direction: column;
    padding: 11px 0px;

    .subsection-container {
      margin-bottom: 15px;
      padding: 0px 20px 14px 20px;
      border-bottom: 2px solid #F5F6FA;

      .subsection-header {
        font-size: 15px;
        margin-bottom: 11px;
      }

      .subsection-text {
        font-size: 13px;
      }

      .subsection-credit-card-info {
        display: flex;
        align-items: center;
        margin-bottom: 7px;

        .icon-credit-card-logo{
          width: 44px;
          height: 19px;
        }
      }

      .subsection-link {
        font-size: 9px;
        color: #2194f1;
        cursor: pointer;
      }
    }

    .summary-info-container {
      margin-bottom: 15px;
      padding: 0px 20px 14px 20px;
      border-bottom: 2px solid #F5F6FA;

      .summary-info-header {
        font-size: 15px;
        margin-bottom: 11px;
        font-weight: bold;
      }

      .plan-title {
        font-size: 13px;
      }

      .plan-item-container {
        font-size: 13px;
        display: flex;

        .subtotal {
          margin-left: auto;
        }
      }

      .plan-item-negative-container {
        font-size: 13px;
        display: flex;
        color: #4AD991;

        .subtotal {
          margin-left: auto;
        }
      }
    }

    .total-container {
      margin-bottom: 15px;
      padding: 0px 20px 14px 20px;
      border-bottom: 2px solid #F5F6FA;
      font-size: 15px;
      font-weight: bold;
      display: flex;

      .subtotal {
        margin-left: auto;
      }
    }

    .submit-info-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      padding: 0px 20px 14px 20px;

      .submit-btn {
        background-color: #2194f1;
        color: #fff;
        border-radius: 5px;
        border: 0 !important;
        font-size: 13px;
        width: 150px;
        height: 39px;

        .icon-lock-logo{
          width: 8px;
          height: 11px;
          fill: #fff;
        }

        .submit-btn-text{
          padding-left: 5px;
        }
      }

      .submit-detailer-info {
        margin-top: 15px;
        font-size: 9px;

        .link {
          color: #2194F1;
          cursor: pointer;
        }
      }

    }

  }
}

#update-plan-layout {
  grid-template-columns: 70% 30%;
  grid-template-areas: "report theme";

  .subsection-header-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-bottom: 17px;

    .header-text {
      font-size: 21px;
    }

    .button-container {
      margin-left: auto;
      display: flex;
      align-items: center;
    }

    .btn-theme {
      background-color: #2194f1;
      color: #fff;
      border-radius: 5px;
      height: 32px;
      border: 0 !important;
      font-size: 13px;
      width: 73px;
    }

    .btn-theme {
      background-color: #2194f1;
      color: #fff;
      border-radius: 5px;
      height: 32px;
      border: 0 !important;
      font-size: 13px;
      width: 50%;
      margin: 20px auto;
    }
  }

  #graphs {
    grid-area: report;
    display: flex;
    flex-direction: column;
    padding: 0px;

    .plan-header-container {
      display: flex;
      font-size: 15px;
      height: auto;

      .select-plan-btn {
        background-color: #2194f1;
        color: #fff;
        border-radius: 5px;
        border: 0 !important;
        font-size: 13px;
        width: 80px;
        height: 32px;
      }

      .disabled-select-plan-btn {
        background-color: #D7DAE2;
        color: #fff;
        border-radius: 5px;
        border: 0 !important;
        font-size: 13px;
        width: 80px;
        height: 32px;
        background-color: #D7DAE2;
      }

      .selected-current-plan {
        height: 32px;
        display: flex;
        align-items: center;
        color: #2194f1;
        font-size: 13px;
      }

      .plan-option-title {
        font-size: 15px;
        font-weight: bold;
      }

      .plan-option-subtitle {
        font-size: 13px;
        margin-bottom: 15px;
      }

      .plan-title{
        font-weight: bold;
        align-items: center;
        display: flex;
        height: 100%;
        width: 40%;
        padding-top: 20px;
      }

      .plan-option {
        @include item-center;
        flex-direction: column;
        height: 100%;
        width: 15%;
        border-left: 2px solid #F5F6FA;
        padding-top: 20px;
      }
    }

    .feature-container {
      display: flex;
      font-size: 15px;
      height: 25px;

      .feature-title{
        font-weight: bold;
        align-items: center;
        display: flex;
        height: 100%;
        width: 40%;
        padding-left: 20px;
      }

      .feature-plan {
        @include item-center;
        height: 100%;
        width: 15%;
        border-left: 2px solid #F5F6FA;
      }
    }

    .subitem-container{
      display: flex;
      font-size: 13px;
      height: 45px;
      border-bottom: 2px solid #F5F6FA;

      .icon-check{
        width: 14px;
        height: 11px;
        fill: #4AD991;
      }

      .subitem-title{
        align-items: center;
        display: flex;
        height: 100%;
        width: 40%;
        padding-left: 20px;
      }

      .subitem-plan {
        @include item-center;
        height: 100%;
        width: 15%;
        border-left: 2px solid #F5F6FA;
        flex-direction: column;
      }
    }

  }

  #theme {
    grid-area: theme;
    display: flex;
    flex-direction: column;
    padding: 11px 0px;
    margin-left: 20px;

    .subsection-container {
      margin-bottom: 15px;
      padding: 0px 20px 14px 20px;
      border-bottom: 2px solid #F5F6FA;

      .subsection-header {
        font-size: 15px;
        margin-bottom: 11px;
      }

      .subsection-text {
        font-size: 13px;
      }

      .subsection-credit-card-info {
        display: flex;
        align-items: center;
        margin-bottom: 7px;

        .icon-credit-card-logo{
          width: 44px;
          height: 19px;
        }
      }

      .subsection-link {
        font-size: 9px;
        color: #2194f1;
        cursor: pointer;
      }
    }

    .summary-info-container {
      margin-bottom: 15px;
      padding: 0px 20px 14px 20px;
      border-bottom: 2px solid #F5F6FA;

      .summary-info-header {
        font-size: 15px;
        margin-bottom: 11px;
        font-weight: bold;
      }

      .plan-title {
        font-size: 13px;
      }

      .plan-item-container {
        font-size: 13px;
        display: flex;

        .subtotal {
          margin-left: auto;
        }
      }

      .plan-item-negative-container {
        font-size: 13px;
        display: flex;
        color: #4AD991;

        .subtotal {
          margin-left: auto;
        }
      }
    }

    .total-container {
      margin-bottom: 15px;
      padding: 0px 20px 14px 20px;
      border-bottom: 2px solid #F5F6FA;
      font-size: 15px;
      font-weight: bold;
      display: flex;

      .subtotal {
        margin-left: auto;
      }
    }

    .submit-info-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      padding: 0px 20px 14px 20px;

      .submit-btn {
        background-color: #2194f1;
        color: #fff;
        border-radius: 5px;
        border: 0 !important;
        font-size: 13px;
        width: 160px;
        height: 39px;

        .icon-lock-logo{
          width: 8px;
          height: 11px;
          fill: #fff;
        }

        .submit-btn-text{
          padding-left: 5px;
        }
      }

      .submit-btn--disabled {
        background-color: grey;
        color: #fff;
        border-radius: 5px;
        border: 0 !important;
        font-size: 13px;
        width: 160px;
        height: 39px;
        cursor: not-allowed;
        .icon-lock-logo{
          width: 8px;
          height: 11px;
          fill: #fff;
        }
        .submit-btn-text{
          padding-left: 5px;
        }
      }

      .submit-detailer-info {
        margin-top: 15px;
        font-size: 9px;

        .link {
          color: #2194F1;
          cursor: pointer;
        }
      }

    }

  }
}

#subscription-layout {
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-areas: "report theme";
  height: 332px;
  min-height: 0px;

  .subsection-header-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-bottom: 17px;

    .header-text {
      font-size: 21px;
    }

    .button-container {
      margin-left: auto;
      display: flex;
      align-items: center;
    }
  }

  #graphs {
    grid-area: report;
    display: flex;
    flex-direction: column;
    padding: 11px 20px;

    .btn-theme {
      background-color: #2194f1;
      color: #fff;
      border-radius: 5px;
      height: 32px;
      border: 0 !important;
      font-size: 13px;
      width: 73px;
    }

    .subsection-container {
      margin-bottom: 17px;
    }

    .subsection-header {
      font-size: 9px;
      color: #787878;
    }

    .subsection-text {
      font-size: 13px;
    }

    .subsection-credit-card-info {
      display: flex;
      align-items: center;
    }

    .subsection-link {
      font-size: 13px;
      color: #2194f1;
      cursor: pointer;
    }

    .icon-credit-card-logo{
      width: 44px;
      height: 19px;
    }
  }

  #theme {
    grid-area: theme;
    display: flex;
    flex-direction: column;
    padding: 11px 20px;

    .icon-check{
      width: 14px;
      height: 11px;
      fill: #4AD991;
    }

    .included-item-container {
      display: flex;

      .message-text {
        font-size: 13px;
        margin-left: 10px;
      }
    }

    .not-included-item-container {
      display: flex;
      color: #D7DAE2;
      align-items: center;
      height: 18px;

      .icon {
        font-size: 25px;
      }

      .message-text {
        font-size: 13px;
        margin-left: 10px;
      }
    }
  }

  #theme {
    margin-left: 20px;

    .btn-theme {
      background-color: #2194f1;
      color: #fff;
      border-radius: 5px;
      height: 32px;
      border: 0 !important;
      font-size: 13px;
      width: 50%;
      margin: 20px auto;
    }

    .progress-bar-label {
      padding-right: 5px;
      display: flex;
      align-items: start;
      min-height: 10px;
      font-size: 9px;
      color: #787878;
      margin-left: auto;
    }

    .progress-bar-container{
      width: 60px;
      min-height: 10px;
    }
  }
}

#admin-layout {
  grid-template-columns: 70% 30%;
  grid-template-areas: "report theme" "report quick-actions" "report user";

  #graphs {
    grid-area: report;
  }

  #theme {
    grid-area: theme;
    margin-bottom: 20px;
  }

  #quick-actions {
    grid-area: quick-actions;
    margin-bottom: 20px;
  }

  #user {
    grid-area: user;
  }

  #theme, #quick-actions, #user {
    margin-left: 20px;
  }
}

.section-container {
  display: flex;
  padding: 15px 10px 15px 20px;
}

#theme {
  .photo-container {
    width: 100%;
    padding: 10px;
    display: flex;

    .theme-photo {
      height: 100%;
      width: 100%;
      box-shadow: 0 0 10px #00000029;
      border-radius: 12px;

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }

  .theme-description {
    display: flex;
    flex-direction: column;

    .title-theme {
      font-size: 15px;
      font-weight: normal;
      color: #787878;
      padding-left: 20px;
    }

    .title-page {
      padding: 0 20px;
      font-size: 13px;
      cursor: pointer;
      @include one-single-line-text;
    }

    .btn-theme {
      background-color: #2194f1;
      color: #fff;
      border-radius: 5px;
      height: 32px;
      border: 0 !important;
      font-size: 13px;
      width: 50%;
      margin: 20px auto;
    }

    .btn-theme-disable {
      background-color: rgb(164, 175, 183);
      color: #fff;
      border-radius: 5px;
      height: 32px;
      border: 0 !important;
      font-size: 13px;
      width: 50%;
      margin: 20px auto;
    }
  }
}

#user-info {
  .user-info-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #fff;
    box-shadow: 0 0 20px #D7DAE2;
  }

  #user-info-description {
    float: left;
    margin: 5px 0 0 15px;
    max-width: 200px;
    display: flex;
    flex-direction: column;

    .user-name {
      font-size: 15px;
      color: #787878;
      font-weight: bold;
    }

    .user-job {
      font-size: 13px;
      color: #787878;
    }
  }

  .icon-edit {
    margin-left: auto;
    margin-top: -5px;
    margin-right: 5px;

    svg {
      width: 11px;
      height: 11px;
      fill: #2194F1;
      cursor: pointer;
    }
  }
}

#quick-actions-info {
  display: block;

  header {
    font-size: 15px;
    color: #787878;
    font-weight: bold;
    margin: 20px 0 0 20px;
  }

  .actions-container {
    width: 100%;
    margin-top: 10px;

    .action-link {
      display: flex;
      margin-bottom: 5px;
      cursor: pointer;

      .action-icon div {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .action-icon svg {
        width: 13px;
        height: 13px;
        margin-left: 20px;

        .a {
          fill: #787878;
        }
      }

      label {
        font-size: 13px;
        font-weight: normal;
        color: #787878;
        margin-left: 10px;
        margin-bottom: 0;
        align-self: center;
        cursor: pointer;
      }

      .action-icon-add {
        margin-left: auto;
        margin-right: 20px;

        svg {
          align-self: center;
          width: 11px;
          height: 11px;
          fill: #2194F1;
        }
      }
    }

    .action-link:hover {
      background-color: #00000012;
    }
  }
}

#graph, #calendar, #quick-actions, #user, #graphs, #theme {
  background-color: #fff;
  border: 1px solid #CACED5;
  border-radius: 12px;
  box-shadow: 0 0 15px #00000012;
}


@mixin user-name-size-sale-dashboard($size:28px) {
  #sale-tech-layout #user #user-info #user-info-description .user-name {
    font-size: $size;
  }
}

@include user-name-size-sale-dashboard();

#sale-tech-layout {
  grid-template-columns: 35% 35% 30%;
  grid-template-rows: 75% 25%;
  grid-template-areas: "report report calendar"
                       "quick-actions user none";

  #graph {
    grid-area: report;
    margin-bottom: 20px;
  }

  #calendar {
    grid-area: calendar;
    margin: 0 0 20px 20px;
  }

  #quick-actions {
    grid-area: quick-actions;
  }

  #user {
    grid-area: user;
    margin-left: 20px;

    #user-info {
      #user-info-description {
        max-width: 240px;
        margin-left: 20px;
        margin-top: 0;

        .user-name {
          color: #787878;
          font-weight: normal;
        }

        .user-job {
          font-size: 15px;
          color: #787878;
          font-weight: bold;
        }

        .user-description {
          font-size: 13px;
          color: #787878;
        }
      }
    }
  }
}

#calendar {
  header {
    margin: 20px 0 0 20px;
    color: #787878;
    font-size: 15px;
    font-weight: bold;
  }

  section {
    margin-top: 20px;
    display: flex;

    .MuiPickersStaticWrapper-staticWrapperRoot {
      margin: 0 auto;

      .MuiPickersBasePicker-container {
        .MuiPickersToolbar-toolbar {
          height: 70px;
          background-color: #2194F1;
        }

        .MuiPickersBasePicker-pickerView .MuiPickersSlideTransition-transitionContainer .MuiPickersDay-daySelected {
          background-color: #2194F1;
        }

        .MuiTypography-subtitle1, .MuiTypography-body1, .MuiTypography-caption, .MuiTypography-body2 {
          font-size: 1.5rem;
        }
      }
    }
  }
}

#graph, #graphs {
  padding: 20px;

  .graph-up, .graph-down {
    height: calc(50% - 10px);
    display: flex;
    flex-direction: column;
  }

  .graph-up {
    height: calc(50% - 30px);
  }

  .graph-down {
    height: calc(50% - 10px);
  }

  header {
    height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;

    form {
      .date-label {
        color: #7F7F7F;
        font-size: 12px;
        padding: 5px;
      }

      .report-date-filter {
        float: left;

        .MuiInputBase-root {
          width: 130px;
          font-size: 1.5rem;
        }
      }

      .date-filter-right {
        margin-left: 20px;
      }

      .report-filter-search {
        float: left;
        margin-left: 20px;
        background-color: #2194f1;
        color: #fff;
        border-radius: 5px;
        font-size: 13px;
        border: 0 !important;
        padding: 5px 15px;
      }
    }
  }

  .svg-icon-description {
    height: 16px;
    width: 16px;
    fill: #ffca83;
    border: 1px solid #fff4e5;
    border-radius: 50px;
    margin-left: 10px;
    transition: all .2s ease-in-out;
  }

  .svg-icon-description:hover {
    height: 20px;
    width: 20px;
    border: none;
    cursor: pointer;
    transform: scale(1.1);
  }

  //.report-dropdown .select {
  //  float: left;
  //  width: 190px;
  //  border: 1px solid #d9d9d9;
  //  border-radius: 5px;
  //  height: 34px;
  //  align-items: center;
  //  display: flex;
  //  padding-left: 10px;
  //  position: relative;
  //
  //  .container-label {
  //    width: 80%;
  //    display: flex;
  //    label {
  //      margin-bottom: 0;
  //      text-overflow: ellipsis;
  //      overflow: hidden;
  //      white-space: nowrap;
  //    }
  //  }
  //
  //  .container-icon {
  //    position: absolute;
  //    right: 10px;
  //    color: #979797;
  //  }
  //}

  //.popover-for-select {
  //  overflow: auto;
  //  max-height: 110px;
  //  box-shadow: 0 1px 6px rgba(0,0,0,.12),0 1px 4px rgba(0,0,0,.12);
  //  border-radius: 2px;
  //  padding: 5px 0;
  //
  //  .popover-body-for-select {
  //    padding: 0;
  //    margin: 0;
  //    color: #979797;
  //    z-index: 100;
  //    position: relative;
  //    .options-for-select .container-option-for-select, .container-option-for-select-checked{
  //      padding: 0 10px;
  //      label {
  //        font-size: 13px;
  //        font-weight: 400;
  //      }
  //    }
  //  }
  //}

  .report-description {
    width: 400px;
    box-shadow: 0 0 10px #0000002B;
    border: 1px solid #D7DAE2;
    border-radius: 4px;
    background: #fff;
    padding: 5px 20px 20px;
    z-index: 20;
    margin-left: 10px;
    color: #787878;
    min-height: 105px;
    display: none;

    header {
      font-size: 15px;
      font-weight: bold;
    }
  }

  #visualization-wrapper {
    height: 90%;
    margin-top: 10px;
    overflow: auto;

    table {
      border-collapse: collapse;

      tbody:nth-of-type(1) tr:nth-of-type(1) td {
        border-top: none !important;
      }

      th {
        position: sticky;
        top: 0;
        z-index: 5;
        background: #fff;
        background-clip: padding-box;
        border-top: none !important;
        border-bottom: none !important;
        box-shadow: inset 0 1px 0 #dddddd,
        inset 0 -1px 0 #dddddd;
        padding: 2px 0;
      }

      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
        white-space: nowrap !important;
      }

      tr:nth-child(even) {
        background-color: #dddddd;
      }
    }
  }

  .empty-visualization {
    padding: 20px 0;
  }

  .report-subtotal {
    margin-top: 10px;
  }
}

#graph #visualization-wrapper {
  height: 340px;
}

@media screen and (max-width: 1800px) {
  .view-previous-about-us .layout-about .about-testimonial .layout-customer-testimonial .content-up-down .semi-circle-up {
    left: 13% !important;
  }
}

@media screen and (min-width: 1680px) {

  #graph .report-description, #graphs .report-description {
    position: absolute;
    left: 320px;
  }

  #sale-tech-layout .user-info-img {
    width: 90px;
    height: 90px;
  }

  #quick-actions-info {
    header {
      font-size: 16px;
    }

    .actions-container {
      .action-icon svg {
        width: 14px;
        height: 14px;
      }

      .action-link label {
        font-size: 14px;
      }
    }
  }

  #user-info .icon-edit svg {
    width: 13px;
    height: 13px;
  }

  #calendar header {
    font-size: 16px;
  }

  #graphs #visualization-wrapper {
    height: 280px;
  }

  #graph #visualization-wrapper {
    height: calc(100% - 50px);
  }
}

@media screen and (max-width: 1680px) {
  #graphs #visualization-wrapper {
    height: 280px !important;
  }
}

.dashboard-error {
  width: 100%;
  height: 100%;

  header {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #F3A200;

    span {
      margin-left: 35px;
      color: #fff;
    }
  }

  section {
    background-color: #fff;
    box-shadow: 0 0 15px #00000012;
    border: 1px solid #CACED5;
    border-radius: 12px;
    margin: 20px 35px 0 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 115px);
    flex-flow: column;

    p {
      margin-bottom: 0;
      font-size: 13px;
      color: #787878;
    }

    .icon-warning div {
      display: flex;
      justify-content: center;

      svg {
        width: 30%;
        height: auto;
      }
    }
  }
}

.location-tax-setting {
  margin-top: 10px;

  .tax-value {
    border-radius: 0;
    height: 34px;
  }

  //.state-tax-select-location .select {
  //  float: left;
  //  width: 60%;
  //  border: 1px solid #d9d9d9;
  //  border-radius: 5px 0 0 5px;
  //  height: 34px;
  //  align-items: center;
  //  display: flex;
  //  padding-left: 10px;
  //  position: relative;
  //
  //  .container-label {
  //    width: 80%;
  //    display: flex;
  //    label {
  //      margin-bottom: 0;
  //      text-overflow: ellipsis;
  //      overflow: hidden;
  //      white-space: nowrap;
  //    }
  //  }
  //
  //  .container-icon {
  //    position: absolute;
  //    right: 10px;
  //    color: #979797;
  //  }
  //}

  //.popover-for-select {
  //  overflow: auto;
  //  max-height: 110px;
  //  box-shadow: 0 1px 6px rgba(0,0,0,.12),0 1px 4px rgba(0,0,0,.12);
  //  border-radius: 2px;
  //  padding: 5px 0;
  //
  //  .popover-body-for-select {
  //    padding: 0;
  //    margin: 0;
  //    color: #979797;
  //    z-index: 100;
  //    position: relative;
  //    .options-for-select .container-option-for-select, .container-option-for-select-checked{
  //      padding: 0 10px;
  //      label {
  //        font-size: 13px;
  //        font-weight: 400;
  //      }
  //    }
  //  }
  //}
}

#state-tax-wrapper {
  .panel-heading {
    color: #787878;
    font-size: 13px;
  }

  .btn-state-tax {
    float: right;
    border: none;
    border-radius: 5px;
    padding: 2px 10px;
    color: #fff;
    background-color: #787878;
    font-size: 12px;
  }

  span, input {
    color: #787878 !important;
  }

  .delete-option {
    width: 12px;
    height: 14px;
    fill: #787878;
  }
}

.simple-line {
  @include simple-line-separator;
}

@media screen and (max-width: 1380px) {

  #graph, #graphs {
    .report-description {
      position: absolute;
      left: 320px;
    }
  }

  #state-tax-wrapper {
    .panel-heading {
      font-size: 12px;
    }

    .btn-state-tax {
      font-size: 11px;
    }
  }
}

@media (min-width: 951px) and (max-width: 1200px) {

  // Dashboard
  #admin-layout, #sale-tech-layout {
    padding: 15px 19px 0;

    #graph {
      margin-bottom: 15px;
    }

    #graph, #graphs {

      .report-description {
        position: absolute;
        left: 270px;
      }

      .report-dropdown .select {
        width: 150px;
      }

      header form {
        .date-filter-right {
          margin-left: 10px;
        }

        .report-date-filter {
          .date-label {
            font-size: 10px;
          }

          .MuiFormControl-root {
            width: 130px;
          }
        }
      }
    }
  }

  #admin-layout {
    grid-template-rows: 53% 30% 17%;

    #theme, #quick-actions, #user {
      margin-left: 15px;
    }

    #theme {
      max-height: 340px;

      .theme-description .title-page {
        font-size: 11px;
      }
    }

    #theme, #quick-actions {
      margin-bottom: 15px;

      #quick-actions-info .actions-container .action-link label {
        font-size: 12px;
      }

      #quick-actions-info .actions-container .action-link .action-icon svg {
        width: 12px;
        height: 12px;
      }
    }

    #quick-actions {
      max-height: 170px;
    }

    #user {
      max-height: 110px;
    }
  }

  #sale-tech-layout {
    grid-template-areas:
        "report report calendar"
        "quick-actions user user";

    #calendar {
      margin: 0 0 15px 15px;
      max-height: 520px;
    }

    #quick-actions {
      max-height: 170px;

      #quick-actions-info .actions-container .action-link {
        margin-bottom: 0;

        .action-icon svg {
          width: 12px;
          height: 12px;
        }

        label {
          font-size: 12px;
        }
      }
    }

    #user {
      max-height: 170px;
      max-width: 400px;
      margin-left: 15px;
    }

    #calendar section {
      padding: 0 10px;

      .MuiPickersStaticWrapper-staticWrapperRoot {
        min-width: 240px;

        .MuiPickersBasePicker-container .MuiPickersBasePicker-pickerView {
          max-width: 240px;
          min-width: 240px;

          .MuiPickersCalendarHeader-switchHeader, .MuiPickersCalendarHeader-daysHeader, .MuiPickersCalendar-transitionContainer {
            max-width: 240px;

            .MuiPickersCalendar-week .MuiPickersDay-day {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  #admin-layout {
    #graphs {
      header form {
        .report-date-filter {
          .MuiFormControl-root {
            width: auto;
          }
          .MuiInputBase-root{
            width: 8vw;
            font-size: 1rem;
          }
          .MuiIconButton-root{
            padding: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 768px){
  .modal-message{
    .modal-dialog{
      width: 50%;
      min-height:auto;
    }
  }

  .wrapper-header {
    height: 40px;
    display: flex;
    .menu-search {
      height: inherit;
      width: 100%;
      display: flex;
      #menu-search {
        padding: 5px;
        margin-top: 0;
        margin-left: 12px;
        width: 80%;
        font-size: 14px;
      }
      a {
        height: inherit;
        padding: 6px 0 17px 20px;
      }
    }
  }

  .menu-user {
    margin: 0 10px 0 0;
    .menu-user-a {
      margin: 8px 0;
    }
    .menu-user-u {
      padding: 0 20px 0 0 !important;
    }
  }

  .container-header .left-container {
    .arrow-right{
      margin-top: 2px;
    }
  }

  .task-section-header-vertical{
    .container-section-task{
      .card{
        height: 45px;

        .icon-arrow-content{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 15px;
          svg{
            height: 8px;
          }
        }
        .card-name{
          padding-left: 0 !important;
          width: 55%;
          h4{
            font-size: 15px;
          }
        }
        .card-task{
          width: 45%;
          .card-number{
            justify-content: center;
            padding-left: 0;
            h1{
              font-size: 15px;
            }
          }
          .card-images{
            width: 45px;
          }
        }

      }
    }
  }

  .task-info-field-priority, .task-info-field-date{
    width: 100%;
    padding: 0;
  }

  .task-info-column-tit-desc{
    .task-info-title{
      .task-input{
        height: 31px;
        padding: 4px 5px 5px 7px !important;
        font-size: 13px;
      }
    }
    .task-info-description{
      .task-input{
        padding: 4px 5px 5px 7px !important;
        font-size: 13px;
      }
    }
  }
  .dashboard-header {
    height: 40px;
    .container-header-dashboard{
      padding-right: 10px;
    }
    .title-dashboard{
      h2{
        font-size: 18px;
      }
    }
  }
  .dashboard-body .dashboard-graphic .dashboard-statistic .row-statistics-user{
    .col100{
      .col50.container50{
        width: 100%;
        padding: 10px 0 10px 0;
      }
    }
  }
  .dashboard-body .dashboard-graphic .dashboard-container-user .second-container{
    .container-left, .container-right{
      width: 100%;
      padding: 0;
    }
    .container-right{
      margin-top: 12px;
    }
  }
}

@media (max-width: 950px){
  #admin-layout {
    #graphs {
      header form {
        .report-date-filter {
          .MuiFormControl-root {
            width: auto;
          }
          .MuiInputBase-root{
            width: 130px;
            font-size: 1.5rem;
          }
          .MuiIconButton-root{
            padding: 5px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 700px) and (max-width: 950px) and (max-height: 500px) {
  #admin-layout, #sale-tech-layout {
    #quick-actions {
      #quick-actions-info .actions-container .action-link {
        margin-bottom: 0;

        .action-icon svg {
          width: 13px;
          height: 13px;
        }

        label {
          font-size: 15px;
        }
      }
    }
  }

  #admin-layout {
    grid-template-columns: 50% 50%;
    grid-template-areas: "report report"
                         "theme quick-actions"
                         "theme user";

    #graphs {
      min-height: 620px;

      .graph-up {
        min-height: 260px;
      }
    }

    #theme {
      margin: 15px 0;
      min-height: 325px;
    }

    #quick-actions {
      margin: 15px 0 15px 15px;
    }

    #user {
      margin: 0 0 15px 15px;
      max-height: 100px;
    }
  }

  #sale-tech-layout {
    grid-template-columns: 50% 50%;
    grid-template-rows: 75% 25% 25%;
    grid-template-areas: "report report"
                       "theme quick-actions"
                       "theme user"
                       "theme none";

    #calendar {
      grid-area: theme;
      margin: 0 0 15px 0;

      section {
        .MuiPickersStaticWrapper-staticWrapperRoot {
          min-width: 320px;
          max-height: 340px;

          .MuiPickersBasePicker-container .MuiPickersBasePicker-pickerView {
            max-width: 320px;
            min-width: 320px;

            .MuiPickersCalendarHeader-switchHeader, .MuiPickersCalendarHeader-daysHeader, .MuiPickersCalendar-transitionContainer {
              max-width: 320px;

              .MuiPickersCalendar-week .MuiPickersDay-day {
                width: 36px;
                height: 36px;
              }
            }
          }
        }
      }
    }

    #quick-actions, #user {
      margin: 0 0 15px 15px;
    }

    #quick-actions #quick-actions-info {
      header {
        margin: 10px 0 0 20px;
      }

      .actions-container {
        margin-top: 5px;
      }
    }
  }
}

@media screen and (min-width: 700px) and (max-width: 950px) and (min-height: 600px) {
  #admin-layout, #sale-tech-layout {
    #quick-actions {
      #quick-actions-info .actions-container .action-link {
        margin-bottom: 0;

        .action-icon svg {
          width: 13px;
          height: 13px;
        }

        label {
          font-size: 15px;
        }
      }
    }
  }

  #admin-layout {
    grid-template-columns: 50% 50%;
    grid-template-areas: "report report"
                         "theme quick-actions"
                         "theme user";

    #graphs {
      min-height: 620px;

      .graph-up {
        min-height: 260px;
      }
    }

    #theme {
      margin: 15px 0;
    }

    #quick-actions {
      margin: 15px 0 15px 15px;
    }

    #user {
      margin: 0 0 15px 15px;
      max-height: 100px;
    }
  }

  #sale-tech-layout {
    grid-template-columns: 50% 50%;
    grid-template-rows: 75% 25% 25%;
    grid-template-areas: "report report"
                       "theme quick-actions"
                       "theme user"
                       "theme none";

    #calendar {
      grid-area: theme;
      margin: 0 0 15px 0;

      section {
        .MuiPickersStaticWrapper-staticWrapperRoot {
          min-width: 320px;
          max-height: 340px;

          .MuiPickersBasePicker-container .MuiPickersBasePicker-pickerView {
            max-width: 320px;
            min-width: 320px;

            .MuiPickersCalendarHeader-switchHeader, .MuiPickersCalendarHeader-daysHeader, .MuiPickersCalendar-transitionContainer {
              max-width: 320px;

              .MuiPickersCalendar-week .MuiPickersDay-day {
                width: 36px;
                height: 36px;
              }
            }
          }
        }
      }
    }

    #quick-actions, #user {
      margin: 0 0 15px 15px;
    }

    #quick-actions #quick-actions-info {
      header {
        margin: 10px 0 0 20px;
      }

      .actions-container {
        margin-top: 5px;
      }
    }
  }
}

/* ----------- Hide sidebar icons for Mobile and Tablet ----------- */
@media only screen and (max-height: 765px) and (-webkit-min-device-pixel-ratio: 1.5), (max-width: 1020px) {
  .sidebar-menu {
    .sidebar-menu-setting{
      display: none;
    }
    #sidebar-sale, #sidebar-marketing, #sidebar-message, #sidebar-reviews{ // pages not mobile suited for mobile or tablet yet
      display: none;
    }
    #sidebar-more-options {
      display: block;
      .label-menu {
        display: block;
        position: absolute;
        top: -105px;
        padding-left: 15px;
        cursor: default;
      }
      .icon-container {
        float: none;
        cursor: default;
      }
    }
    li {
      height: 40px;
    }
  }

  .message-header{
    .pagination-wrapper{
      width: 20%;
    }
  }
}

/* ----------- iPad----------- */
/* Portrait */
@media only screen and (min-width: 768px) and (max-height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  #admin-layout {
    height: 100%;
    margin-bottom: 80px;
  }
}

/* Landscape */
@media only screen and (min-width: 768px) and (min-height: 500px) and (max-height: 1024px) and (max-width: 1280px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  #admin-layout {
    grid-template-rows: 68% 22%;
    height: auto;
    margin-bottom: 0;

    #quick-actions {
      max-height: none;
    }
  }

  #sale-tech-layout {
    padding: 15px 19px 0;
    grid-template-rows: 75% 25%;

    #user, #quick-actions {
      margin-bottom: 15px;
    }
  }

  #sale-tech-layout #quick-actions #quick-actions-info header {
    margin: 15px 0 0 20px;
  }
}

/* ----------- iPad Pro 10----------- */
/* Portrait */
@media only screen and (min-width: 834px) and (max-height: 1112px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  #admin-layout {
    height: 100%;
    margin-bottom: 60px;
    grid-template-rows: 70% 20% 10%;
    grid-template-areas: "report report" "theme quick-actions" "theme user" "theme none";
  }
}

/* Landscape */
@media only screen and (min-width: 834px) and (min-height: 500px) and (max-height: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  #admin-layout {
    margin-bottom: 0;
    height: calc(100% - 40px);

    #quick-actions, #theme {
      max-height: none;
    }
  }
}

/* ----------- iPad Pro 12----------- */
/* Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {

  #sale-tech-layout, #admin-layout {
    #graph, #graphs {
      #visualization-wrapper {
        height: 90%;
      }
    }
  }

  #admin-layout {
    grid-template-rows: 25% 15% 10% 50%;
    grid-template-areas: "report theme" "report quick-actions" "report user" "report none";

    #quick-actions {
      max-height: none;
    }
  }

  #sale-tech-layout {
    grid-template-rows: 40% 15% 20% 35%;
    grid-template-columns: 70% 30%;
    grid-template-areas: "report calendar" "report quick-actions" "report user" "report none";

    #quick-actions {
      margin-left: 15px;
    }

    #user {
      max-height: 220px;
    }
  }
}

/* Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (min-height: 1280px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  #admin-layout, #sale-tech-layout {
    #graph, #graphs {
      #visualization-wrapper {
        height: 350px;
      }
    }
  }
  #admin-layout {
    grid-template-rows: 62%;
  }

  #sale-tech-layout {
    grid-template-rows: 80% 20%;
  }
}

/* ----------- Kindle----------- */
/* Portrait */
@media only screen and (min-width: 800px) and (max-height: 1280px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {

  #graph, #graphs {
    header form {
      .date-filter-right {
        margin-left: 10px;

        label {
          font-size: 10px;
        }
      }
    }
  }

  #admin-layout {
    // height: 95%;
    // grid-template-rows: 70% 20% 13%;
    height: auto;
    margin-bottom: 0;
  }

  #sale-tech-layout {
    grid-template-rows: 50% 17%;
  }
}

/* Landscape */
@media only screen and (min-width: 800px) and (max-width: 1280px) and (min-height: 500px) and (max-height: 1280px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  #admin-layout {
    grid-template-rows: 55%;
  }
}

.message-writer-wrapper {
  position: absolute;
  bottom: 0;
  margin-bottom: 12px;
}

@mixin graphPhoneHeader() {
  header {
    display: block;
    height: 90px;
    position: relative;

    .icon-error {
      width: 25px;
      float: left;
    }

    .report-description {
      left: -15px;
      top: 0;
      width: 280px;

      header {
        height: auto;
      }
    }

    form {
      float: left;
      margin-top: 9px;
      width: 100%;

      .date-label {
        font-size: 9px;
        margin: 0;
        padding: 0;
        width: 100%;
      }

      .date-filter-right {
        margin-left: 0;
      }

      .report-date-filter {
        width: auto;

        .MuiInputBase-root {
          font-size: 1.2rem;
          width: 85px;

          .MuiInputBase-input {
            padding: 0 0 7px;
          }

          .MuiIconButton-root {
            padding: 0 0 5px;
          }
        }
      }

      .report-filter-search {
        margin: 15px 0 0 0;
        font-size: 10px;
        padding: 6px 9px;
      }
    }
  }
}

/* ----------- Phones ----------- */
/* Portrait */
@media (max-width: 579px) {
  .container-component {
    position: initial;
  }

  #admin-layout, #sale-tech-layout {
    height: auto;
    padding: 7px 9px;

    #graphs, #graph {
      padding: 15px;
      @include graphPhoneHeader;

      #visualization-wrapper {
        height: 220px;
      }
    }
  }

  #admin-layout {
    grid-template-rows: 54% 14% 7% 26%;
    grid-template-columns: 100%;
    grid-template-areas: "report" "quick-actions" "user" "theme";

    #graphs {
      min-height: 710px;
    }

    #quick-actions {
      min-height: 170px;
    }

    #quick-actions, #user {
      margin: 9px 0 0;
    }

    #theme {
      margin: 9px 0 9px;
      min-height: 422px;

      .photo-container .theme-photo {
        height: 190px;
      }

      .theme-description .btn-theme {
        margin: 15px auto 15px;
      }
    }
  }

  #sale-tech-layout {
    grid-template-rows: 39% 15% 12% 36%;
    grid-template-columns: 100%;
    grid-template-areas: "report" "quick-actions" "user" "calendar";

    #graph {
      min-height: 450px;
      margin: 0;

      #visualization-wrapper {
        height: 310px;
      }
    }

    #quick-actions {
      min-height: 160px;
    }

    #quick-actions, #user, #calendar {
      margin: 9px 0 0;
    }

    #calendar {
      margin-bottom: 9px;

      section {
        margin-top: 10px;

        .MuiPickersStaticWrapper-staticWrapperRoot {
          min-width: 300px;
          max-height: 340px;
        }
      }
    }
  }

  @include user-name-size-sale-dashboard(20px)
}

@media (width: 414px){
  #admin-layout{
    #theme{
      min-height: 480px;
      margin: 9px 0 9px;
    }
  }
}
@media (height: 414px) and (width: 896px){
  #admin-layout{
    #theme{
      margin: 15px 0;
      min-height: 340px;
    }
  }
}

/* Landscape */
@media (min-width: 569px) and (max-width: 767px) {
  #admin-layout, #sale-tech-layout {
    height: auto;
    padding: 9px 15px 15px;

    #graphs, #graph {
      padding: 15px;
      min-height: 690px;

      .report-description {
        left: 220px;
        top: auto;
        width: 380px;
      }
    }

    #theme, #calendar {
      margin: 11px 0 0;
    }

    #quick-actions {
      margin: 11px 0 11px 11px;
    }

    #user {
      margin: 0 0 0 11px;
      max-height: 90px;
    }
  }

  #admin-layout {
    grid-template-rows: 70% 18% 15%;
    grid-template-columns: 50% 50%;
    grid-template-areas: "report report" "theme quick-actions" "theme user";
  }

  #sale-tech-layout {
    grid-template-columns: 55% 45%;
    grid-template-rows: 53% 20% 30%;
    grid-template-areas: "report report"
                       "theme quick-actions"
                       "theme user"
                       "theme none";

    #calendar {
      grid-area: theme;
    }

    #graph {
      margin-bottom: 0;
      min-height: 470px;
    }

    #quick-actions {
      min-height: 130px;

      #quick-actions-info {
        header {
          margin: 10px 0 0 20px;
        }

        .actions-container {
          margin-top: 0;
        }
      }
    }

    #user {
      max-height: 130px;

      #user-info {
        padding: 10px;

        #user-info-description {
          .user-job {
            font-size: 12px;
          }
        }
      }
    }
  }

  @include user-name-size-sale-dashboard(20px)
}

@media (min-width: 561px) and (max-width: 647px) {
  #graphs, #graph {
    @include graphPhoneHeader;
  }
}

@media (min-width: 648px) and (max-width: 830px) {
  #graphs, #graph {
    @include graphPhoneHeader;

    header {
      height: auto;
      display: flex;

      form {
        width: auto;
        margin-top: 0;
      }
    }

  }
}

.sent-messages-component, .finance-wrapper {
  .container-check {
    margin-left: 44px;
  }

  .row-table-finance .container-check, .row-icon-message .container-check {
    margin-left: 42px;
  }
}


.icons-chat {
  width: 15px;
  height: 13px;
  cursor: pointer;
}

.icons-send-chat {
  width: 32px;
  height: 32px;
  margin-top: 4px;
  cursor: pointer;
}

.input-search-chat {
  width: 128px;
  height: 18px;
  outline: none;
  border: 0;
  font-size: 13px;

  ::placeholder {
    color: #D7DAE2;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.options-header {
  font-size: 13px;
  margin: 0;
  color: #454545;
  width: 62px;
  margin-left: 14px
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.btn-attach {
  @extend .flex-row;
  gap: 21px;
  padding: 14px;
}

.message-inbox {
  color: rgb(69, 69, 69);
  font-size: 13px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 0;
  margin: 0;
}

.initial-name {
  height: 23px;
  width: 23px;
  border: 1px solid #D7DAE2;
  background-color: #F5F6FA;
  border-radius: 50%;
  font-size: 8px;
  color: #A4AFB7;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center
}

.initial-name-header {
  @extend .initial-name;
  width: 28px;
}

.container-header-view-app-left-side {
  @extend .flex-row;
  height: 23px;
  width: 33%;
  gap: 14px;
}

.container-header-view-app-right-side {
  @extend .flex-row;
  padding: 14px 15px;
  border-bottom: 1px solid #eeeef2;
  align-items: center;
  height: 60px;
}

.item-message {
  @extend .flex-row;
  width: 349px;
  height: 100px;
  padding: 12px;
  border-bottom: 1px solid #EEEEF2;

  &:hover {
    cursor: pointer;
    background-color: #EEEEF2;
  }
}

.left-item-message {
  margin: 7px 7px auto auto;
}

.right-item-message {
  @extend .flex-row;
  gap: 7px;
}

.label-name {
  font-size: 13px;
  margin: 0;
  color: #454545;
  font-weight: 700;
  width: 100%;
}

.icon-item-available {
  height: 9px;
  width: 9px;
  align-self: center;
  fill: #2194F1;
}

.icon-item-available-off {
  @extend .icon-item-available;
  fill: transparent;
}

.icon-item-favorite-on {
  @extend .icon-item-available;
  fill: #F3A200;
}

.icon-item-favorite-off {
  @extend .icon-item-available;
  fill: #D7DAE2;
}

.type-date {
  color: #787878;
  font-size: 8px;
  margin-bottom: 4px;
}

.btn-footer {
  @extend .flex-row;
  align-items: center;
  gap: 21px;
}

.btn-footer-left {
  @extend .flex-row;
  gap: 10px;
}

.btn-footer-center {
  @extend .btn-footer-left;
  align-items: baseline;

  i {
    font-size: 15px;
    cursor: pointer;
  }
}

.btn-footer-right {
  @extend .flex-row;
  margin: auto 0 auto auto;
  gap: 5px;
}

.container-input-message {
  width: 99%;
  height: 209px;
  background: #FFFFFF no-repeat padding-box;
  border: 1px solid #D7DAE2;
  border-radius: 17px;
  opacity: 1;
  margin: 0 auto;
  align-self: center;

  .first-row-container-message {
    height: 15.3%;
    border-bottom: 1px solid #eeeef2;
    padding-left: 15px;
    color: #A4AFB7;
    font-size: 9px;
    display: flex;

    .first-row-container-left {
      width: 16%;
      height: 100%;
      border-right: 1px solid #eeeef2;
      @extend .flex-row;
      align-items: center;

      a {
        @extend .right-item-message;
        font-weight: bold;

        i {
          color: #2194F1;
        }

        span {
          color: #2194F1;
          margin-left: 6px;
          cursor: pointer;
        }
      }
    }

    .first-row-container-right {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 15px;
    }
  }

  .second-row-container-message {
    height: 15%;
    border-bottom: 1px solid #eeeef2;
    padding-left: 15px;
    color: #A4AFB7;
    font-size: 9px;
    @extend .flex-row;
    align-items: center;
    gap: 5px;
  }

  textarea {
    width: 96%;
    resize: none;
    outline: none;
    height: 56%;
    border: 0;
    border-radius: 17px;
    margin-left: 15px;
    margin-top: 15px;
    font-size: 13px;

    ::placeholder {
      color: #A4AFB7;
    }
  }
}

.title-from-dealer-message {
  color: #454545;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.content-chat {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows:51% 42% 7%;
  height: 100%;
  padding: 25px;
}

.icon-star-header {
  width: 16px;
  height: 16px;
  fill: #F3A200;
  align-self: center;
}

.label-assign-small {
  @extend .label-assign;
  font-size: 9px;
}

.pr-0 {
  margin: auto 0 auto auto;
}

.menu-search-chat {
  @extend .flex-row;
  width: 179px;
  height: 33px;
  background: #FFFFFF;
  border: 1px solid #D7DAE2;
  border-radius: 4px;
  opacity: 1;
  padding: 6px 9px 8px 10px;
  gap: 7px;
  margin-left: 15px;

  i {
    font-size: 14px;
    color: #BEBCC7;
  }
}


.btn-footer {
  padding-left: 5px;
}

.icon-close-chat {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.width-100 {
  width: 100%;
}

.container-column-100 {
  @extend .flex-column;
  @extend .width-100
}

.br-5 {
  border-radius: 5px;
}

.message-from-chat {
  width: 323px;
  padding: 17px;
  background: #F5F6FA no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}


.icon-via-message {
  width: 10px;
  height: 10px;
  fill: #2194F1;
  padding-right: 2px;
  padding-top: 2px;
  cursor: pointer;
}

.icon-search-chat {
  height: 14px;
  width: 14px;
  fill: #BEBCC7;
}

.location-modal-container{
  background-color: #A4AFB74A;
  .modal-dialog{
    width: 400px;
    height: auto;
    .modal-content{
      border: none;
    }
  }
}

.modal-location-box{
  display: flex;
  padding: 9px 12px;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  .title{
    font-size: 22px;
    font-weight: 700;
  }
  .container-locations{
    padding-top: 15px;
    .item-container{
      border: 1px solid #D9D9D9;
      height: 32px;
      border-radius: 5px;
      margin-bottom: 10px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;

      .check-circle-icon{
        display: flex;
        width: 15px;
        >div{
          display: flex;
          svg{
            width: 15px;
            height: auto;
            fill: #2194F1;
          }
        }

        }
      }
    .container-button{
      display: flex;
      justify-content: end;
      button{
        height: 32px;
        width: 80px;
        background: #2194F1;
        color: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #D7DAE2;
      }
      .disabled-button{
        opacity: 0.5 !important;
      }
    }
    .locations-box{
      max-height: 378px;
      overflow: auto;
    }
    .container-message-info{
      padding: 0;
    }
  }

  .item-selected{
    border-color: #2194F1 !important;
  }

  .close-modal-icon{
    svg{
      width: 38px;
      height: 38px;
    }
  }
}

.sale-modal-container {
  background-color: #A4AFB74A;
}
